@if (data) {
  <div class="flex flex-row align-items-center gap-1">
    <app-badge-item
      [customIcon]="data?.icon"
      [customColor]="data?.color"
      [styleClass]="'min-w-0'"
      [showIcon]="true"
      [displayText]="false"
    ></app-badge-item>
    <div class="flex flex-column">
      <div class="w-full flex gap-2 justify-content-start align-items-center">
        <span class="h-fit flex align-items-center">
          <p>{{ data?.value }}</p>
        </span>
        @if (showNumeric && data?.numericValue != null) {
          <span class="h-fit flex align-items-center font-italic">
            <p>{{ ' (' + data?.numericValue + ')' }}</p>
          </span>
        }
      </div>
      @if (showDescription && data?.description) {
        <span class="h-fit flex align-items-center font-italic text-sm font-normal">
          <p>{{ data?.description }}</p>
        </span>
      }
    </div>
  </div>
}
