<app-dashlet [styleClass]="styleClass" [config]="{ title: title }">
  <ng-content></ng-content>
  @if (headerTemplate) {
    <ng-template let-config #titleTemplate>
      <ng-container *ngTemplateOutlet="headerTemplate; context: { $implicit: config }"></ng-container>
    </ng-template>
  }
  <div class="flex align-items-center justify-content-between">
    <div class="flex align-items-center gap-1">
      @if (cardContent?.icon) {
        <span
          [class]="'inline-flex border-circle align-items-center justify-content-center ' + ' mr-3'"
          [style]="'width: 38px; height: 38px; background-color:' + bgColor + ';'"
        >
          <i [class]="cardContent?.icon + ' text-2xl '" [style]="'color:' + textColor + ';'"></i>
        </span>
      }
      <!-- <span class="text-900 font-medium text-2xl">{{title}}</span> -->
    </div>
    @if (!isLoading) {
      <div class="text-4xl w-full text-center">{{ data }}</div>
    }
    @if (isLoading) {
      <p-skeleton width="4rem" height="2rem" styleClass="my-3"></p-skeleton>
    }
    <ng-content select="[header-actions]"></ng-content>
  </div>
  <!-- <div class="formgrid grid p-fluid w-full">
        @for(field of fields; track field){
            <div class="col-12"
            [ngClass]="{
                'md:col-6': field?.options?.colSpan != 12,
              }"
            >
                <app-input-view-switch [showLabelInViewMode]="true"
                    [mode]="'view'"
                    [data]="field?.passRowAsData ? data : getElementValue(field?.key, data)"
                    [dataType]="field?.dataType"
                    [label]="field?.name"
                    [options]="field?.options"
                    >
                </app-input-view-switch>
            </div>
        }
    </div> -->
</app-dashlet>
