import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { IFieldDefinition, TargetTypeEnum, UnsubscribeOnDestroyAdapter, getProp } from '@shared/classes';
import { AppDialogService, BaseFieldDefinitionsService, BaseRequestControllerService, PathResolverService, ToastService } from '@shared/services';

@Component({
  selector: 'app-dynamic-form-view',
  templateUrl: './dynamic-form-view.component.html',
  styleUrl: './dynamic-form-view.component.scss'
})
export class DynamicFormViewComponent extends UnsubscribeOnDestroyAdapter implements OnInit {

  private _config: { data: any, module: string };
  @Input() set config(config: { data: any, module: string }) {
    this._config = config;
    this.module = config.module;
    this.data = config.data;
    this.initData();
  }
  get config() {
    return this._config;
  }

  mappingService: BaseFieldDefinitionsService = null;
  dataService: BaseRequestControllerService<any> = null;
  fields: IFieldDefinition[] = [];
  data: any = null;
  module: string = null;

  constructor(
    public appDialogService: AppDialogService,
    public router: Router,
    private pathResolverService: PathResolverService,
    private toastService: ToastService
  ) {
    super();
  }

  ngOnInit(): void { }
  initData() {
    if (this.module && this.data) {
      this.mappingService = this.pathResolverService.getMappingServiceByTargetType(this.module as TargetTypeEnum);
      this.fields = [...this.mappingService.recordFields];
      // this.dataService = this.pathResolverService.getDataServiceByTargetType(this.module);
    }
  }

  getElementValue(key: string, data: any) {
    let value = data?.[key];
    value = getProp(data, key);
    return value;
  }
}
