import { Component, Input, OnInit } from '@angular/core';
import { DataTypeEnum, DynamicComponentBase } from '@shared/classes';

@Component({
  selector: 'app-inherited-risk-value-field',
  templateUrl: './inherited-risk-value-field.component.html',
  styleUrls: ['./inherited-risk-value-field.component.scss'],
})
export class InheritedRiskValueFieldComponent extends DynamicComponentBase implements OnInit {
  dataType: DataTypeEnum = DataTypeEnum.Text;
  @Input() riskMeth = null;
  @Input() viewModeOnly = false;
  @Input() readOnlyName = false;
  constructor() {
    super();
  }

  ngOnInit(): void {}
  setInputOptions() {
    this.riskMeth = this.inputOptions?.inheritedRiskInput?.riskMeth ?? this.riskMeth;
  }
  onFormChanges(changes) {
    this.control.patchValue(changes);
  }
}
