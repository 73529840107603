@for (obj of formObjects; track obj; let i = $index) {
  <ng-container class="grid w-full m-auto">
    @if (obj.active) {
      <div class="col-6">
        <app-number-input
          [label]="obj.label"
          [min]="0"
          [max]="255"
          [placeholder]="obj.label"
          [control]="obj.form"
          [viewMode]="viewMode"
        ></app-number-input>
      </div>
    }
  </ng-container>
}
