@if (viewMode == 'view') {
  <p class="font-bold w-full mb-1">
    {{ label }}
  </p>
}
<p-pickList
  #pickList
  [source]="sourceElements"
  [target]="targetElements"
  sourceHeader="Available Records"
  targetHeader="Selected Records"
  [dragdrop]="true"
  [responsive]="true"
  [sourceStyle]="{ height: '30rem' }"
  [targetStyle]="{ height: '30rem' }"
  filterBy="label"
  sourceFilterPlaceholder="Search records"
  targetFilterPlaceholder="Search records"
  [showSourceControls]="true"
  [showSourceFilter]="true"
  [showTargetControls]="true"
  [showTargetFilter]="false"
  (onMoveAllToSource)="onPickListChange()"
  (onMoveAllToTarget)="onPickListChange()"
  (onMoveToSource)="onPickListChange()"
  (onMoveToTarget)="onPickListChange()"
  class="w-full app-picklist"
  [class.pick-list-view-mode]="viewMode == 'view'"
>
  <ng-template pTemplate="sourceFilter" let-options="options">
    <div class="flex flex-row w-full">
      <div class="flex flex-1">
        <div class="p-picklist-filter w-full">
          <input
            [(ngModel)]="sourceFilterValue"
            [ngModelOptions]="{ standalone: true }"
            type="text"
            (ngModelChange)="this.sourceFilterValueUpdate.next($event)"
            role="textbox"
            class="p-picklist-filter-input p-inputtext p-component border-noround border-round-left"
            placeholder="Search by name"
          />
          @if (sourceFilterValue) {
            <span class="p-picklist-filter-icon pi pi-times" (click)="options.reset(); sourceFilterValue = ''"></span>
          } @else {
            <span [class]="'p-picklist-filter-icon ' + (loading ? 'pi pi-spin pi-spinner' : 'pi pi-search')"></span>
          }
        </div>
      </div>
      @if (false) {
        <div class="flex">
          <app-button
            pTooltip="Types"
            tooltipPosition="top"
            (onClickAction)="op.toggle($event)"
            [action]="{
              id: 2,
              label: '',
              icon: 'pi pi-table',
              iconPos: 'left',
              buttonStyle: 'raised',
              color: 'primary',
              buttonClass: 'border-noround border-round-right',
            }"
          ></app-button>
        </div>
      }
    </div>
  </ng-template>
  <ng-template let-item pTemplate="item">
    <div class="">
      <div class="align-items-center flex gap-2">
        @if (item?.code) {
          <app-badge-item
            [styleClass]="'text-xs'"
            [status]="item?.recordStatus || 'gray'"
            [text]="item.code"
          ></app-badge-item>
        }
        <span class="">{{ item?.name }} </span>
      </div>
    </div>
  </ng-template>
</p-pickList>
<p-overlayPanel #op [showCloseIcon]="true">
  <ng-template pTemplate>
    <p-listbox
      [options]="[]"
      [checkbox]="true"
      [filter]="true"
      [multiple]="true"
      [formControl]="typesControl"
      optionLabel="label"
      [optionValue]="'value'"
      [style]="{ width: '15rem' }"
    >
      <ng-template let-item pTemplate="item">
        <app-badge-item [status]="item.value" [text]="item.label"></app-badge-item>
      </ng-template>
    </p-listbox>
  </ng-template>
</p-overlayPanel>
<!-- <div class="card">
<p-dataView #dv [value]="targetElements">
  <ng-template let-product pTemplate="listItem">
    <div class="col-12">
      <div class="product-item">
        <div class="product-list-detail align-items-center flex gap-2">
          <app-badge-item *ngIf="product?.code" [styleClass]="'text-xs'" [status]="product?.recordStatus || 'gray'" [text]="product.code"></app-badge-item>
          <span class="product-category">{{product?.name}} </span>
        </div>
      </div>
    </div>
  </ng-template>
</p-dataView>
</div> -->
