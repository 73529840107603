<div class="p-2" style="width: 300px">
  <div class="flex align-items-center justify-content-between mb-3">
    <p class="text-900 font-medium text-xl">Watching</p>
  </div>
  @if (!loading) {
    <div class="flex flex-column gap-3 w-full">
      <div class="w-full align-content-start">
        <app-avatar-list [users]="userList"></app-avatar-list>
      </div>
      <div class="flex w-full flex-column">
        @if (!isSubscribed) {
          <app-button class="w-full" [action]="subscribeAction"></app-button>
        }
        @if (isSubscribed) {
          <app-button class="w-full" [action]="unsubscribeAction"></app-button>
        }
        @if (true) {
          <app-button class="w-full" [action]="addSubscribers"></app-button>
        }
      </div>
    </div>
  }
  @if (loading) {
    <div class="">
      <ul class="m-0 p-0 list-none">
        <li class="mb-3">
          <div class="flex">
            <p-skeleton shape="circle" size="2rem" styleClass="mr-2"></p-skeleton>
            <p-skeleton shape="circle" size="2rem" styleClass="mr-2"></p-skeleton>
            <p-skeleton shape="circle" size="2rem" styleClass="mr-2"></p-skeleton>
            <p-skeleton shape="circle" size="2rem" styleClass="mr-2"></p-skeleton>
            <p-skeleton shape="circle" size="2rem" styleClass="mr-2"></p-skeleton>
            <div style="flex: 1">
              <p-skeleton width="100%" styleClass="mb-2"></p-skeleton>
              <p-skeleton width="75%"></p-skeleton>
            </div>
          </div>
        </li>
        <li class="">
          <div class="flex">
            <div style="flex: 1">
              <p-skeleton width="100%" height="3rem" styleClass=""></p-skeleton>
            </div>
          </div>
        </li>
      </ul>
    </div>
  }
</div>
