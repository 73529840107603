import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import {
  AppPermissions,
  IColumn,
  ITableLoadEvent,
  NotificationDto,
  UnsubscribeOnDestroyAdapter,
} from '@shared/classes';
import { MyNotificationsDataService } from 'app/modules/activity-feed/services/data/my-notifications-data.service';
import { NgxPermissionsService } from 'ngx-permissions';
import { of } from 'rxjs';

@Component({
  selector: 'app-widget-notifications',
  templateUrl: './widget-notifications.component.html',
  styleUrl: './widget-notifications.component.scss',
})
export class WidgetNotificationsComponent extends UnsubscribeOnDestroyAdapter implements OnInit {
  @Input() title: string = '';
  appPermissions = AppPermissions;
  notifications = () => this.notificationData;
  notificationPageF = () => this.notificationPage;
  onLoadMore = this.fetchNotificationData.bind(this);
  isLoading = false;
  constructor(
    private notificationDataService: MyNotificationsDataService,
    private permissionService: NgxPermissionsService,
    private cdr: ChangeDetectorRef
  ) {
    super();
  }
  ngOnInit(): void {}
  onInitEvent() {}

  notificationColumns: IColumn[] = [];
  notificationKeys = [
    'code',
    'notificationType',
    'type',
    'relatedToCode',
    'lastModificationDate',
    'creationDate',
    'creatorName',
    'sender',
  ];
  notificationData: NotificationDto[] = [];
  notificationTableEvent: ITableLoadEvent = new ITableLoadEvent();
  notificationPage;
  fetchNotificationData(page) {
    this.isLoading = true;
    // this.notificationTableEvent = tableEvent;
    this.notificationTableEvent.pageInfo.pagination.page = page;
    this.notificationTableEvent.pageInfo.pagination.size = 5;
    this.notificationTableEvent.pageInfo.pagination.sort = ['lastModificationDate,desc'];

    this.permissionService.hasPermission(this.appPermissions.ReadMyNotification).then((isAllowed) => {
      this.subs.sink = (
        isAllowed
          ? this.notificationDataService.search<any>(
              {
                ...this.notificationTableEvent.pageInfo.pagination,
              },
              {
                projectionFields: this.notificationKeys,
                filters: this.notificationTableEvent.filters,
              }
            )
          : of({ content: [], totalElements: 0 } as any)
      ).subscribe({
        next: (res: any) => {
          this.isLoading = false;

          this.notificationPage = res;
          this.notificationData = [...this.notificationData, ...res.content];
          this.notificationTableEvent.pageInfo.totalElements = res?.totalElements ?? 0;
          this.cdr.detectChanges();
        },
        error: (error) => {},
        complete: () => {},
      });
    });
  }
}
