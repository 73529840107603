import { AfterViewInit, Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Base, IAction, ITablePageable, SearchBody } from '@shared/classes';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Subject, takeUntil } from 'rxjs';

// DO NOT USE THIS COMPONENT EVER
@Component({
  selector: 'app-entity-filter-popup',
  templateUrl: './entity-filter-popup.component.html',
  styleUrls: ['./entity-filter-popup.component.scss'],
})
export class EntityFilterPopupComponent extends Base implements OnInit, AfterViewInit {
  pageInfo: ITablePageable = new ITablePageable();
  submitButton: IAction = {
    id: 1,
    label: 'Apply',
    buttonType: 'button',
    icon: 'pi pi-check',
    iconPos: 'right',
    command: this.onSaveClick.bind(this),
  };

  form: FormGroup;
  orgFilter: FormControl;
  private searchText$ = new Subject<string>();

  appliedSearchBody: SearchBody = {
    projectionFields: ['id', 'name', 'entities', 'code', 'owner', 'recordStatus'],
    filters: [],
  };

  searchQ: any;
  searchQuestions$;

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig
  ) {
    super();
    this.initFormStructure();
  }

  ngAfterViewInit(): void {
    this.setupSubscriptions();
    this.onFilterInputChange();
  }

  ngOnInit(): void {
    // this.service.search(
    //     {...this.pageInfo.pagination,size:20},
    //     {
    //         filters: this._prepareQuery(""),
    //         projectionFields:
    //             this.appliedSearchBody.projectionFields,
    //     },
    //     {showLoading:true,showMsg:false}
    // ).pipe(
    //     takeUntil(this.destroy$)
    // ).subscribe(res => {
    //     this.searchQuestions$ = of(res.content)
    // })
  }

  getData() {
    return this.form.value;
  }

  initFormStructure(): void {
    this.form = new FormGroup({
      entity: new FormControl(null),
    });
  }

  setupSubscriptions(): void {
    this.form.statusChanges.pipe(takeUntil(this.destroy$)).subscribe((data) => {
      // this.formStatusChanged.emit(data);
    });
  }

  onSelectItem(e) {}

  search(keywords: string) {
    this.searchText$.next(keywords);
  }

  onFilterInputChange() {
    // this.searchText$
    //     .pipe(
    //         debounceTime(100),
    //         switchMap((keyword) =>
    //             this.service.search(
    //                 {...this.pageInfo.pagination,size:20},
    //                 {
    //                     filters: this._prepareQuery(keyword),
    //                     projectionFields:
    //                         this.appliedSearchBody.projectionFields,
    //                 },
    //                 { showLoading: true, showMsg: false }
    //             )
    //         )
    //     )
    //     .subscribe((res) => {
    //         this.searchQuestions$ = of(res.content)
    //     });
  }

  private _prepareQuery(searchTerm) {
    let query: any[] = [
      { property: 'category', operation: 'IN', value: this.config?.data?.categoryList, typeShape: 'NORMAL' },
    ];
    if (!!searchTerm) {
      // query.push({"property":"name","operation":"CONTAINS","value":searchTerm})
      query = [
        {
          left: { property: 'category', operation: 'IN', value: this.config?.data?.categoryList, typeShape: 'NORMAL' },
          right: { property: 'name', operation: 'CONTAINS', value: searchTerm, typeShape: 'NORMAL' },
          operand: 'AND',
        },
      ];
    }
    return query;
  }
  onSaveClick() {
    this.ref.close(this.searchQ);
  }
}
