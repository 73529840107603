import { ChartViewConfig } from '@shared/classes/model';
import { EChartsOption } from 'echarts';
import { EchartVariationBase } from '../../../echart-variation-base';
import { SunburstChart } from '../../base/sunburst-chart';

export class AncestorEmphasisSunburstChart extends SunburstChart implements EchartVariationBase {
  variation: ChartViewConfig.ChartVariationEnum = 'ANCESTOR_EMPHASIS';
  chartOptions: EChartsOption = {
    legend: null,
    series: [
      {
        type: 'sunburst',
        emphasis: {
          focus: 'ancestor',
        },
        data: [],
        radius: [0, '100%'],
        itemStyle: {
          borderWidth: 3,
          borderColor: 'rgba(0,0,0,0.1)',
        },
        label: {
          rotate: 'radial',
          overflow: 'truncate',
          ellipsis: '...',
          // width: 50,
        },
        // labelLayout: function (params) {
        //   const { align, verticalAlign, labelRect, rect } = params;
        //   let availableWidth = labelRect.width;
        //   let availableHeight = labelRect.height;
        //   if (align === 'left' || align === 'center' || align === 'right') {
        //     // Horizontally aligned, consider width
        //     availableWidth = (rect.width < labelRect.width ? rect.width : labelRect.width) - 5;
        //   }
        //   if (verticalAlign === 'top' || verticalAlign === 'middle' || verticalAlign === 'bottom') {
        //     // Vertically aligned, consider height
        //     availableHeight = (rect.height < labelRect.height ? rect.height : labelRect.height) - 8;
        //   }
        //   return {
        //     // hideOverlap: true,
        //     width:
        //       (availableWidth > availableHeight ? availableWidth : availableHeight) > 40
        //         ? availableWidth > availableHeight
        //           ? availableWidth
        //           : availableHeight
        //         : 40,
        //   };
        // },
      },
    ],
  };
  checkVariationCondition(variation: ChartViewConfig.ChartVariationEnum): boolean {
    return true;
  }
}
