import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControlStatus } from '@angular/forms';
import { Router } from '@angular/router';
import { IAction, AppPermissions } from '@shared/classes';
import { ViewModeService } from '@shared/services';
import { BulkOperationService } from '@shared/services/bulk-operation-service/bulk-operation.service';
import { Observable, of } from 'rxjs';

@Component({
  selector: 'app-bulk-operation-results-step',
  templateUrl: './bulk-operation-results-step.component.html',
  styleUrls: ['./bulk-operation-results-step.component.scss'],
})
export class BulkOperationResultsStepComponent implements OnInit, OnDestroy {
  public formValid = new Observable<boolean>();
  nextButton: IAction = {
    id: 1,
    label: 'Done',
    buttonType: 'submit',
    icon: 'pi pi-angle-right',
    iconPos: 'right',
    command: this.nextPage.bind(this),
    permission: AppPermissions.ReadGeography,
  };

  constructor(
    public stepperService: BulkOperationService,
    private router: Router,
    public viewModeService: ViewModeService
  ) {
    if (!this.stepperService.operation) {
      this.stepperService.goToStep1();
    }
  }
  basicInfo;
  ngOnInit(): void {}
  nextPage() {
    this.stepperService.returnToList();
  }
  prevPage() {
    this.stepperService.goToStep1();
  }
  onSubmit(data) {
    // this.stepperService.stepperItem = { ...this.stepperService.stepperItem, ...data };
    this.nextPage();
  }
  formStatusChanged(formState: FormControlStatus) {
    setTimeout(() => {
      this.formValid = of(formState == 'VALID');
    }, 1);
  }
  formChanges(data) {
    // this.basicInfo = { ...data };
  }
  ngOnDestroy(): void {
    // if (this.basicInfo)
    // this.stepperService.stepperItem = { ...this.stepperService.stepperItem, ...this.basicInfo };
  }
}
