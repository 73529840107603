import { Component, Input, OnInit } from '@angular/core';
import { DataTypeEnum, DynamicComponentBase, GroupRepresentation } from '@shared/classes';
import { GroupDataService } from 'app/modules/users/services/data/group-data.service';

@Component({
  selector: 'app-group-selector',
  templateUrl: './group-selector.component.html',
  styleUrls: ['./group-selector.component.scss'],
})
export class GroupSelectorComponent extends DynamicComponentBase implements OnInit {
  @Input() multi: boolean = true;
  constructor(private service: GroupDataService) {
    super();
  }
  groups: GroupRepresentation[] = [];
  dataType: DataTypeEnum = DataTypeEnum.Text;
  @Input() appendTo: string = null;
  isLoading: boolean = false;

  ngOnInit(): void {
    this.getOptions();
  }

  setInputOptions() {
    this.multi = this.inputOptions?.dropDownInput?.multi ?? this.multi;
    this.appendTo = this.inputOptions?.dropDownInput?.appendTo ?? this.appendTo;
  }
  getOptions() {
    this.isLoading = true;
    this.subs.sink = this.service.getSelector({ showLoading: false, showMsg: false }).subscribe({
      next: (res) => {
        this.groups = res;
      },
      complete: () => {
        this.isLoading = false;
      },
    });
  }
}
