import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';
import { DataTypeEnum, DynamicComponentBase } from '@shared/classes';

@Component({
  selector: 'app-planned-progress-bar-input',
  templateUrl: './planned-progress-bar-input.component.html',
  styleUrls: ['./planned-progress-bar-input.component.scss'],
  providers: [{ provide: DynamicComponentBase, useExisting: PlannedProgressBarInputComponent }],
})
export class PlannedProgressBarInputComponent extends DynamicComponentBase implements OnInit {
  dataType: DataTypeEnum = DataTypeEnum.PlannedProgressBar;
  @Input() viewDataType;
  @Input() viewOptions;
  @Input() optionValue;
  @Input() progressColor;
  @Input() control1: AbstractControl<any>;
  @Input() control2: AbstractControl<any>;
  @Input() useRawValue: boolean = false;

  valueObject;

  ngOnInit(): void {
    if (this.control1.value && this.control2.value) {
      this.valueObject = { value1: this.control1.value, value2: this.control2.value };
      this.valueChanged();
    }
    this.control1.valueChanges.subscribe((x) => {
      if (x) {
        this.valueObject = { ...this.valueObject, value1: x };
        this.valueChanged();
      }
    });

    this.control2.valueChanges.subscribe((x) => {
      if (x) {
        this.valueObject = { ...this.valueObject, value2: x };
        this.valueChanged();
      }
    });
  }

  valueChanged() {
    if (this.valueObject?.value1 && this.valueObject?.value2) {
      const n1 = this.valueObject.value1[this.optionValue];
      const n2 = this.valueObject.value2[this.optionValue];
      const patchValue = { ...this.valueObject.value2, [this.optionValue]: n2 - n1 };
      this.valueObject = { ...this.valueObject, value3: patchValue };
      this.control.patchValue(patchValue);
    }
  }

  setInputOptions() {}
}
