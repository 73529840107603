import { ChartViewConfig } from '@shared/classes/model';
import { EChartsOption } from 'echarts';
import { AssignChartDataOptions } from '../../../echart-base';
import { EchartVariationBase } from '../../../echart-variation-base';
import { VerticalBarChart } from '../../base/vertical-bar-chart';

export class PolarBarChart extends VerticalBarChart implements EchartVariationBase {
  variation: ChartViewConfig.ChartVariationEnum = 'POLAR';
  chartOptions: EChartsOption = {
    polar: {
      radius: [30, '70%'],
    },
    radiusAxis: {
      max: 4,
    },
    angleAxis: {
      type: 'category',
      data: [],
      startAngle: 75,
    },
    series: [
      {
        type: 'bar',
        data: [],
        coordinateSystem: 'polar',
        label: {
          show: true,
          position: 'middle',
          formatter: '{b}: {c}',
        },
      },
    ],
  };
  assignDataToChartOptions(options: AssignChartDataOptions): EChartsOption {
    let optionsCopy = { ...options?.chartOptions };
    optionsCopy.series[0].name = options?.name;
    optionsCopy.series[0].data = options?.data.map((x, i) => {
      return { ...x };
    });
    (optionsCopy.angleAxis as any).data = options?.data.map((x, i) => {
      return x.name;
    });
    return optionsCopy;
  }
  checkVariationCondition(variation: ChartViewConfig.ChartVariationEnum): boolean {
    return true;
  }
}
