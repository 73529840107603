<p-overlayPanel #op [showCloseIcon]="true">
  <ng-template pTemplate>
    @if (columnDefinition) {
      <div class="surface-card p-4 shadow-2 border-round" style="width: 340px">
        <ul class="list-none p-0 m-0">
          <li class="flex align-items-center py-3 px-2 flex-wrap surface-ground">
            <div class="text-500 w-full font-medium">Name</div>
            <div class="text-900 w-full">{{ columnDefinition?.field?.name }}</div>
          </li>
          <li class="flex align-items-center py-3 px-2 flex-wrap">
            <div class="text-500 w-full font-medium">Description</div>
            <div class="text-900 w-full line-height-3">
              {{ columnDefinition?.field?.name }}
            </div>
            <!-- <li class="flex align-items-center py-3 px-2 flex-wrap">
          </li>
          <div class="text-500 w-full font-medium">Skills</div>
          <div class="text-900 w-full">
            <p-tag  styleClass="mr-2" value="Algorithms" class="p-element"></p-tag>
            <p-tag styleClass="mr-2" severity="success" value="Javascript" class="p-element"></p-tag>
            <p-tag styleClass="mr-2" severity="danger" value="Python" class="p-element"></p-tag>
            <p-tag severity="warning" value="SQL" class="p-element"></p-tag>
          </div>
        </li> -->
            @if (columnDefinition?.field?.validators?.length > 0 || columnDefinition?.field.required) {
              <li class="flex align-items-center py-3 px-2 flex-wrap surface-ground">
                <div class="text-500 w-full font-medium">Validations</div>
                <div class="text-900 w-full">
                  <div class="grid mt-0 mr-0">
                    @if (columnDefinition?.field.required) {
                      <div class="col-12">
                        <div class="p-3 border-1 surface-border border-round surface-card">
                          <div class="text-900 mb-2">
                            <i [class]="importerValidators['required']?.icon + ' mr-2'"></i>
                            <span class="font-medium">{{ importerValidators['required']?.name }}</span>
                          </div>
                          <div class="text-700">{{ importerValidators['required']?.description }}</div>
                        </div>
                      </div>
                    }
                    @for (item of columnDefinition?.field?.validators; track item) {
                      @if (importerValidators[item.key]) {
                        <div class="col-12">
                          <div class="p-3 border-1 surface-border border-round surface-card">
                            <div class="text-900 mb-2">
                              <i [class]="importerValidators[item.key]?.icon + ' mr-2'"></i>
                              <span class="font-medium">{{ importerValidators[item.key]?.name }}</span>
                            </div>
                            <div class="text-700">{{ importerValidators[item.key]?.description }}</div>
                          </div>
                        </div>
                      }
                    }
                  </div>
                </div>
              </li>
            }
          </li>
        </ul>
      </div>
    }
  </ng-template>
</p-overlayPanel>
<div class="flex cursor-pointer" pTooltip="Field Info" tooltipPosition="top" (click)="op.toggle($event)">
  <i class="pi pi-question-circle"></i>
</div>
