import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { JWTTokenService } from '@core/services/JWT-token/jwttoken.service';
import { environment } from '@env/environment';
import {
  BaseListResponse,
  BaseResponse,
  RequestHandlerOptions,
  SubscriptionDto,
  SubscriptionItem,
} from '@shared/classes';
import { BaseRequestControllerService } from '@shared/services/api/base-request-controller.service';
import { DataService } from '@shared/services/api/data.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MySubscriptionDataService extends BaseRequestControllerService<SubscriptionDto> {
  private addWatchersApi: string = '/addWatchers';
  private unsubscribeApi: string = '/un-subscribe';
  private subscribeApi: string = '/subscribe';
  private mySubscriptionApi: string = '/mySubscription';
  private isSubscriberApi: string = '/isSubscriber';
  private allSubscriptionApi: string = '/allSubscription';

  constructor(
    private dataService: DataService,
    private router: Router,
    private jwtService: JWTTokenService
  ) {
    super(dataService, environment.userOperations, '/my-subscriptions', [
      'id',
      'code',
      'tags',
      'tagsCodes',
      'lockedBy',
      'lockedForEdit',
      'lockedUntil',
      'userAccessLevel',
      'recordStatus',
      'creatorName',
      'lastModifierName',
      'creationDate',
      'lastModificationDate',
      'lockedUntil',
    ]);
  }
  navigateToListPage() {
    this.router.navigateByUrl(`subscriptions/list`);
  }
  subscribe(code: string, options: RequestHandlerOptions = new RequestHandlerOptions()) {
    return this.dataService.postData<BaseResponse<SubscriptionDto>>(
      `${this.url}${this.subscribeApi}/${code}/${this.jwtService.getPreferredUsername()}`,
      null,
      options
    ) as Observable<BaseResponse<SubscriptionDto>>;
  }
  unSubscribe(code: string, options: RequestHandlerOptions = new RequestHandlerOptions()) {
    return this.dataService.postData<BaseResponse<SubscriptionDto>>(
      `${this.url}${this.unsubscribeApi}/${code}/${this.jwtService.getPreferredUsername()}`,
      null,
      options
    ) as Observable<BaseResponse<SubscriptionDto>>;
  }
  isSubscriber(code: string, options: RequestHandlerOptions = new RequestHandlerOptions()) {
    return this.dataService.getData<BaseResponse<SubscriptionDto>>(
      `${this.url}${this.isSubscriberApi}/${code}/${this.jwtService.getPreferredUsername()}`,
      options
    ) as Observable<BaseResponse<SubscriptionDto>>;
  }
  mySubscription(code: string, options: RequestHandlerOptions = new RequestHandlerOptions()) {
    return this.dataService.getData<BaseListResponse<SubscriptionItem>>(
      `${this.url}${this.mySubscriptionApi}/${code}`,
      options
    ) as Observable<BaseListResponse<SubscriptionItem>>;
  }
  allSubscription(code: string, options: RequestHandlerOptions = new RequestHandlerOptions()) {
    return this.dataService.getData<BaseListResponse<SubscriptionItem>>(
      `${this.url}${this.allSubscriptionApi}/${code}`,
      options
    ) as Observable<BaseListResponse<SubscriptionItem>>;
  }
  addWatchers(
    code: string,
    userList: SubscriptionItem[],
    options: RequestHandlerOptions = new RequestHandlerOptions()
  ) {
    return this.dataService.postData<BaseListResponse<SubscriptionDto>>(
      `${this.url}${this.addWatchersApi}/${code}`,
      { subscribers: userList },
      options
    ) as Observable<BaseListResponse<SubscriptionDto>>;
  }
}
