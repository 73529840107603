import { Component, Input, OnInit } from '@angular/core';
import { BaseViewItem } from '@shared/classes';
import { PathResolverService } from '@shared/services/path-resolver.service';

@Component({
  selector: 'app-code-with-lock-status-view',
  templateUrl: './code-with-lock-status-view.component.html',
  styleUrls: ['./code-with-lock-status-view.component.scss'],
})
export class CodeWithLockStatusViewComponent extends BaseViewItem implements OnInit {
  @Input() lockedForEdit: boolean = false;
  hrefPath: string = '';
  constructor(private pathResolverService: PathResolverService) {
    super();
  }

  ngOnInit(): void {}
  onCountDownEnd(event: { countDownOver: boolean }) {
    if (event.countDownOver) {
      this.lockedForEdit = false;
    }
  }
  onSetData(): void {
    if (!this.data?.code) return;
    this.hrefPath = this.pathResolverService.getPathByCode(this.data?.code);
  }
}
