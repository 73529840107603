import { DOCUMENT } from '@angular/common';
import {
  AfterContentInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  Injector,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  forwardRef,
} from '@angular/core';
import { ControlValueAccessor, FormControl, FormGroup, NG_VALUE_ACCESSOR, NgControl, Validators } from '@angular/forms';
import { AuditProjectDto, DataTypeEnum, DynamicComponentBase, isNullObj } from '@shared/classes';
import { AuditTaskTemplateDataService } from 'app/modules/audit-module/services/data/audit-task-template-data.service';
import { ControlDataService } from 'app/modules/org-framework/service/data/ControlDataService';
export const INPUT_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => AuditCaseInputItemFormControlComponent),
  multi: true,
};
@Component({
  selector: 'app-audit-case-input-item-form-control',
  templateUrl: './audit-case-input-item-form-control.component.html',
  styleUrls: ['./audit-case-input-item-form-control.component.scss'],
  providers: [INPUT_VALUE_ACCESSOR],
})
export class AuditCaseInputItemFormControlComponent
  extends DynamicComponentBase
  implements OnInit, AfterContentInit, OnChanges, ControlValueAccessor
{
  private ngControl: NgControl | null = null; // assign the current formControl to have access to all options ex: errors, dirty, hasValidator ...etc
  value: any; // end value of the control component
  fullControlData;
  public changed: (value: any) => void;
  public touched: () => void;
  public isDisabled: boolean;
  @Input() auditProject: AuditProjectDto;

  dataTypes = DataTypeEnum;
  formsInited = false;
  templateInited = false;
  subsSet = false;
  formGroup = new FormGroup({
    templateFormControl: new FormControl(null, Validators.required),
    nameControl: new FormControl(null, Validators.required),
    programControl: new FormControl(null, Validators.required),
    ownerControl: new FormControl(null, Validators.required),
    controlControl: new FormControl(null, Validators.required),
    respondentControl: new FormControl(null, Validators.required),
    auditQsrControl: new FormControl(null, Validators.required),
    evidenceQsrControl: new FormControl(null, Validators.required),
    auditorControl: new FormControl(null, Validators.required),
    auditeeControl: new FormControl(null),
  });

  constructor(
    @Inject(DOCUMENT) private document: Document,
    public el: ElementRef,
    private cd: ChangeDetectorRef,
    private readonly injector: Injector,
    public auditTaskTemplateDataService: AuditTaskTemplateDataService,
    public controlDataService: ControlDataService
  ) {
    super();
  }

  setInputOptions(): void {
    // for dynamic generation of the input
  }
  ngOnInit(): void {
    this.ngControl = this.injector.get(NgControl, null, { optional: true });
    //this.ngControl.control.markAsTouched = () => updateFormControlTree(this.formGroup);
  }
  ngAfterContentInit(): void {
    // for custom templates assignments, EX: pre-item-template

    this.ngControl;
  }
  ngOnChanges(changes: SimpleChanges): void {}
  writeValue(value: any): void {
    // if (!this.fullControlData && value?.control) {
    //   this.controlDataService.getById(value?.control).subscribe(res => {
    //     this.fullControlData = res.data;
    //   });
    // }
    this.initCseTemplate(value?.control, value?.controlGroup, value?.auditor, value?.owner);
    if (!this.formsInited) {
      this.formsInited = true;
      this.initForms(value);
    }
    this.value = value;
  }
  onInputValueChange(value: any) {
    this.value = value;
    this.changed(value);
  }
  registerOnChange(fn: any): void {
    this.changed = fn;
  }
  registerOnTouched(fn: any): void {
    this.touched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
    // this.cd.markForCheck(); // if ChangeDetectionStrategy is OnPush then it's needed
  }

  initCseTemplate(controlCode, controlGroupCode, auditor, owner) {
    if (!this.templateInited && (controlCode || controlGroupCode) && !auditor && !owner) {
      this.templateInited = true;
      this.auditTaskTemplateDataService
        .search(
          { page: 0, size: 1 },
          {
            filters: [
              {
                left: { property: 'matchingCriteria', operation: 'IN', value: [controlCode] },
                operand: 'OR',
                right: { property: 'matchingCriteria', operation: 'IN', value: [controlGroupCode] },
              },
            ],
          }
        )
        .subscribe((res) => {
          if (res?.content?.length) this.formGroup.controls.templateFormControl.patchValue(res.content[0]);
        });
    }
  }

  setFormListeners() {
    if (this.subsSet) {
      return;
    }
    this.subsSet = true;
    this.subs.sink = this.formGroup.controls.templateFormControl.valueChanges.subscribe((x) => {
      if (x) {
        const newVal = {
          ...this.value,
          auditCaseTemplate: x.code,
          auditor: x.defaultAuditor,
          auditQuestionnaire: x.auditorQuestionnaire,
          evidenceQuestionnaire: x.auditeeQuestionnaire,
          owner: this.value?.owner ?? this.auditProject.owner,
          respondent: this.value?.respondent ?? this.auditProject.owner,
          auditProgram: this.auditProject?.auditProgram,
        };
        this.initForms(newVal);
        this.control.patchValue(newVal);
      }
    });
    this.subs.sink = this.formGroup.controls.nameControl.valueChanges.subscribe((x) => {
      const newVal = { ...this.value, name: x };
      this.control.patchValue(newVal);
      this.checkValidity(x, this.formGroup.controls.nameControl);
    });
    this.subs.sink = this.formGroup.controls.ownerControl.valueChanges.subscribe((x) => {
      const newVal = { ...this.value, owner: x };
      this.control.patchValue(newVal);
      this.checkValidity(x, this.formGroup.controls.ownerControl);
    });
    this.subs.sink = this.formGroup.controls.controlControl.valueChanges.subscribe((x) => {
      const newVal = { ...this.value, control: x };
      this.control.patchValue(newVal);
      this.checkValidity(x, this.formGroup.controls.controlControl);
    });
    this.subs.sink = this.formGroup.controls.respondentControl.valueChanges.subscribe((x) => {
      const newVal = { ...this.value, respondent: x };
      this.control.patchValue(newVal);
      this.checkValidity(x, this.formGroup.controls.respondentControl);
    });
    this.subs.sink = this.formGroup.controls.auditQsrControl.valueChanges.subscribe((x) => {
      const newVal = { ...this.value, auditQuestionnair: x };
      this.control.patchValue(newVal);
      this.checkValidity(x, this.formGroup.controls.auditQsrControl);
    });
    this.subs.sink = this.formGroup.controls.evidenceQsrControl.valueChanges.subscribe((x) => {
      const newVal = { ...this.value, evidenceQuestionnair: x };
      this.control.patchValue(newVal);
      this.checkValidity(x, this.formGroup.controls.evidenceQsrControl);
    });
    this.subs.sink = this.formGroup.controls.auditorControl.valueChanges.subscribe((x) => {
      const newVal = { ...this.value, auditor: x };
      this.control.patchValue(newVal);
      this.checkValidity(x, this.formGroup.controls.auditorControl);
    });
    this.subs.sink = this.formGroup.controls.auditeeControl.valueChanges.subscribe((x) => {
      const newVal = { ...this.value, auditee: x };
      this.control.patchValue(newVal);
    });
  }
  checkValidity(value, control) {
    if (!value) {
      control.setErrors({ error: '' });
      control.markAsDirty();
      control.markAsTouched();
    } else {
      control.setErrors(null);
      control.markAsUntouched();
      control.markAsPending();
    }
    const controls = this.formGroup.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        this.fControl.setErrors({ error: '' });
        this.fControl.markAsTouched();
        return;
      }
    }
    this.fControl.setErrors(null);
    this.fControl.markAsUntouched();
  }

  initForms(value) {
    if (!isNullObj(value)) {
      if (!isNullObj(value.name)) this.formGroup.controls.nameControl.patchValue(value.name);
      else this.formGroup.controls.nameControl.patchValue(null);
      if (!isNullObj(value.owner)) this.formGroup.controls.ownerControl.patchValue(value.owner);
      else this.formGroup.controls.ownerControl.patchValue(null);
      if (!isNullObj(value.control)) this.formGroup.controls.controlControl.patchValue(value.control);
      else this.formGroup.controls.controlControl.patchValue(null);
      if (!isNullObj(value.respondent)) this.formGroup.controls.respondentControl.patchValue(value.respondent);
      else this.formGroup.controls.respondentControl.patchValue(null);
      if (!isNullObj(value.auditQuestionnaire))
        this.formGroup.controls.auditQsrControl.patchValue(value.auditQuestionnaire);
      else this.formGroup.controls.nameControl.patchValue(null);
      if (!isNullObj(value.evidenceQuestionnaire))
        this.formGroup.controls.evidenceQsrControl.patchValue(value.evidenceQuestionnaire);
      else this.formGroup.controls.evidenceQsrControl.patchValue(null);
      if (!isNullObj(value.auditor)) this.formGroup.controls.auditorControl.patchValue(value.auditor);
      else this.formGroup.controls.auditorControl.patchValue(null);
      if (!isNullObj(value.auditee)) this.formGroup.controls.auditeeControl.patchValue(value.auditee);
      else this.formGroup.controls.auditeeControl.patchValue(null);
      if (!isNullObj(value.auditProgram)) this.formGroup.controls.programControl.patchValue(value.auditProgram);
      else this.formGroup.controls.programControl.patchValue(null);
      this.setFormListeners();
    } else {
      this.formGroup.controls.nameControl.patchValue(null);
      this.formGroup.controls.ownerControl.patchValue(null);
      this.formGroup.controls.controlControl.patchValue(null);
      this.formGroup.controls.respondentControl.patchValue(null);
      this.formGroup.controls.auditQsrControl.patchValue(null);
      this.formGroup.controls.evidenceQsrControl.patchValue(null);
      this.formGroup.controls.auditorControl.patchValue(null);
      this.formGroup.controls.auditeeControl.patchValue(null);
    }
  }
}
