import { Component, OnInit } from '@angular/core';
import { BaseViewItem } from '@shared/classes';

@Component({
  selector: 'app-palette-view',
  templateUrl: './palette-view.component.html',
  styleUrl: './palette-view.component.scss',
})
export class PaletteViewComponent extends BaseViewItem implements OnInit {
  constructor() {
    super();
  }

  ngOnInit(): void {}
}
