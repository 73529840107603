import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BaseForm, IAction } from '@shared/classes';
import { AppDialogService, ViewModeService } from '@shared/services';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-control-objective-popup-confirmation',
  templateUrl: './control-objective-popup-confirmation.component.html',
  styleUrl: './control-objective-popup-confirmation.component.scss'
})
export class ControlObjectivePopupConfirmationComponent extends BaseForm<any> implements OnInit {
  readyToLink: any;
  readyToUnlink: any;
  readyToAddAndLink: any;
  confirmAction: IAction = {
    id: 1,
    label: 'Confirm',
    buttonType: 'submit',
    icon: 'pi pi-check',
    command: this.confirm.bind(this),
  };
  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private appDialogService: AppDialogService,
    public viewModeService: ViewModeService,
  ) {
    super(viewModeService);
    if (!this.data) this.data = {};
    this.readyToLink = this.config?.data?.readyToLink ?? [];
    this.readyToUnlink = this.config?.data?.readyToUnlink ?? [];
    this.readyToAddAndLink = this.config?.data?.addAndLink ?? [];
  }

  confirm() {
    this.ref.close({ readyToLink: this.readyToLink, readyToUnlink: this.readyToUnlink, addAndLink: this.readyToAddAndLink });
  }

  getData() {
    return this.data;
  }
  setData(data) {
    this.formGroup.patchValue(data);
  }
  initFormStructure(): void {
    this.formGroup = new FormGroup({});
  }
  ngOnInit(): void { }

  ngAfterViewInit(): void { }
}
