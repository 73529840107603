@if (dropDown) {
  <app-input-view-switch
    [showLabelInViewMode]="showLabelInViewMode"
    [mode]="viewMode"
    [data]="data?.name || control?.value?.name"
    [dataType]="dataType"
    [label]="label"
  >
    <span class="field w-full" [class.p-float-label]="floatLabel" [class.mb-1]="control?.dirty && this.control.errors">
      <p-treeSelect
        [options]="formattedEntities"
        [selectionMode]="multi ? 'checkbox' : 'single'"
        [(ngModel)]="selectedNodes"
        placeholder="Select Item"
        [filter]="true"
        [filterInputAutoFocus]="true"
        (ngModelChange)="onValueChanges($event)"
        [showClear]="true"
        [class.ng-invalid]="fControl?.invalid"
        [class.ng-dirty]="fControl?.dirty"
      ></p-treeSelect>
      @if (floatLabel) {
        <label [for]="name">{{ label }}<span [class]="isLoading ? 'ml-2 pi pi-spin pi-spinner' : ''"></span></label>
      }
    </span>
  </app-input-view-switch>
} @else {
  <p-organizationChart
    (selectionChange)="onValueChanges($event)"
    [value]="formattedEntities"
    [selectionMode]="multi ? 'multiple' : 'single'"
    [(selection)]="selectedNodes"
  ></p-organizationChart>
}
