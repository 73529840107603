<app-input-view-switch
  [showLabelInViewMode]="showLabelInViewMode"
  [mode]="viewMode"
  [data]="data || control?.value"
  [dataType]="dataType"
  [label]="label"
  [keepValueCallback]="setFieldResetable.bind(this)"
  [resetFieldCheckVisable]="resetFieldCheckVisable"
  [resetableChecked]="resetable"
>
  <app-drop-down-input
    [label]="label ?? 'Groups'"
    [placeholder]="'Search Groups'"
    [name]="'dropdown'"
    [optionLabel]="'name'"
    [items]="groups"
    [optionValue]="'name'"
    [control]="fControl"
    [multi]="multi"
    [floatLabel]="floatLabel"
    [appendTo]="appendTo"
    [loading]="isLoading"
  >
    <ng-template #resetCheckTemplate>
      @if (resetFieldCheckVisable) {
        <app-reset-switch-input [checked]="!resetable" (onChange)="setFieldResetable($event)"></app-reset-switch-input>
      }
    </ng-template>
  </app-drop-down-input>
</app-input-view-switch>
