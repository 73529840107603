import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { QuestionnaireAnswerDto, RiskAssessmentRequestDto, RiskMethodology, RiskProjectDto } from '@shared/classes';
import { ViewModeService } from '@shared/services/view-mode.service';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class RiskAssessmentStepperService {
  editMode: boolean = false;
  itemId: any;
  riskProject: RiskProjectDto;
  constructor(
    private router: Router,
    private viewModeService: ViewModeService
  ) {}
  riskAssessmentRequest: RiskAssessmentRequestDto = null;
  riskMeth: RiskMethodology = null;
  submitData: RiskAssessmentRequestDto = null;
  stepperItem: RiskAssessmentRequestDto = null;
  questionnaireAnswer: QuestionnaireAnswerDto = null;
  // {
  //     //basic
  //     name:'',
  //     description: '',

  //     // //statement
  //     // riskStatement:null,

  //     // //assessment
  //     // riskAssessment:null,

  // }
  assessmentValues: any[] = [];

  private _projectType = null;
  get projectType() {
    return this._projectType;
  }
  set projectType(data) {
    this._projectType = data;
    this.projectTypeChange.next(data);
  }
  private projectTypeChange = new Subject<any>();
  projectTypeChange$ = this.projectTypeChange.asObservable();

  private stepperComplete = new Subject<RiskAssessmentRequestDto>();
  stepperComplete$ = this.stepperComplete.asObservable();
  private stepperCompleteInPlace = new Subject<RiskAssessmentRequestDto>();
  stepperCompleteInPlace$ = this.stepperCompleteInPlace.asObservable();
  private saveQuestionnaireAnswer = new Subject<QuestionnaireAnswerDto>();
  saveQuestionnaireAnswer$ = this.saveQuestionnaireAnswer.asObservable();
  saveAnswers() {
    this.saveQuestionnaireAnswer.next(this.questionnaireAnswer);
  }
  complete(inPlace: boolean = false) {
    //NOTICE this is createItems format ({key,value}[]) and not the same type
    this.stepperItem.draftRisks = (this.submitData as any[])?.[0]?.value;
    inPlace ? this.stepperCompleteInPlace.next(this.submitData) : this.stepperComplete.next(this.submitData);
  }
  clearData() {
    this.stepperItem = null;
    // {
    //     name:'',
    //     description: '',
    //     type: null,
    //     riskStatement:null,
    //     riskAssessment:null,
    //     assignToAssetOwners: true,
    //     entityOwners:[]
    // };
    this.assessmentValues = [];
  }
  goToStep1() {
    this.router.navigate([`risk/risk-assessment-request/edit/${this.itemId}/assessment`]);
  }
  goToStep2() {
    this.router.navigate([`risk/risk-assessment-request/edit/${this.itemId}/risk-analysis`]);
  }
  setData(data: { assessmentRequest: RiskAssessmentRequestDto; riskMeth: RiskMethodology }) {
    this.stepperItem = data.assessmentRequest;
    this.riskAssessmentRequest = data.assessmentRequest;
    this.riskMeth = data.riskMeth;
  }
}
