import { Component, Input, OnInit } from '@angular/core';
import { IAction, lightenColor } from '@shared/classes';
import { PrimeIcons } from 'primeng/api';

@Component({
  selector: 'app-custom-card-view',
  templateUrl: './custom-card-view.component.html',
  styleUrls: ['./custom-card-view.component.scss'],
})
export class CustomCardViewComponent implements OnInit {
  @Input() icon: string = 'pi pi-image';
  @Input() title: string = '';
  @Input() description: string = '';
  private _color = '#85838d';
  @Input() set color(color: string) {
    this._color = color;
    this.onSetColor();
  }
  get color() {
    return this._color;
  }
  @Input() actionData: any;
  @Input() value: number = 0;
  @Input() showValue: boolean = true;
  @Input() showActionFooter: boolean = true;
  @Input() showColoredBorder: boolean = false;
  @Input() action: IAction = {
    id: 1,
    label: 'See More',
    buttonType: 'button',
    icon: PrimeIcons.ANGLE_RIGHT,
    color: 'help',
  };
  solidColor = '#85838d';
  bgColor = '#cecdd1';
  constructor() {}

  ngOnInit(): void {}
  onSetColor() {
    this.solidColor = lightenColor(this.color || 'gray', 30) || this.solidColor;
    this.bgColor = lightenColor(this.color || 'gray', 80) || this.bgColor;
  }
}
