import { Clipboard } from '@angular/cdk/clipboard';
import { Component, Input, OnInit } from '@angular/core';
import { BaseViewItem, DataTypeEnum } from '@shared/classes';
import { ToastService } from '@shared/services';

@Component({
  selector: 'app-color-view',
  templateUrl: './color-view.component.html',
  styleUrls: ['./color-view.component.scss'],
})
export class ColorViewComponent extends BaseViewItem implements OnInit {
  @Input() viewType: DataTypeEnum = DataTypeEnum.Color;
  @Input() options: any;
  @Input() copyColorOnClick: boolean = true;
  @Input() size = '28px';
  dataTypes = DataTypeEnum;

  constructor(
    private clipboard: Clipboard,
    private toastService: ToastService
  ) {
    super();
  }

  ngOnInit(): void {}

  copyToClipboard(text: string) {
    if (this.copyColorOnClick) {
      this.clipboard.copy(text);
      this.toastService.info('Color Copied to Clipboard!');
    }
  }
  get colorSize() {
    return `min-width:${this.size}; min-height:${this.size}; width:${this.size}; height:${this.size};`;
  }
}
