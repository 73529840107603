import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BasePage, IAction, IDynamicComponent } from '@shared/classes';
import { BasicInputComponent } from '@shared/components/ui/input-fields/basic-input/basic-input.component';
import { DropDownInputComponent } from '@shared/components/ui/input-fields/drop-down-input/drop-down-input.component';
import { ToastService } from '@shared/services/toast.service';
import { ViewModeService } from '@shared/services/view-mode.service';

@Component({
  selector: 'app-dynamic-form-example',
  templateUrl: './dynamic-form-example.component.html',
  styleUrls: ['./dynamic-form-example.component.scss'],
})
export class DynamicFormExampleComponent extends BasePage implements OnInit {
  dynamicFields: IDynamicComponent[] = [];
  formGroup: FormGroup = new FormGroup({});
  submitButtonAction: IAction = {
    id: 1,
    label: 'Save',
    buttonType: 'button',
    command: this.onSubmitForm.bind(this),
    icon: 'pi pi-save',
  };
  addMoreFieldsAction: IAction = {
    id: 1,
    label: 'Add More',
    buttonType: 'button',
    command: this.addMoreFields.bind(this),
    icon: 'pi pi-plus',
  };
  addMoreIndex = 1;
  constructor(
    private toastService: ToastService,
    public viewModeService: ViewModeService
  ) {
    super();
    this.SetPageValues({
      breadCrumb: {
        items: [{ label: 'Pages' }, { label: 'Dynamic Form Page', routerLink: ['/pages/dynamic-form'] }],
      },
    });
  }

  ngOnInit(): void {
    this.initDynamicFields();
  }
  initDynamicFields() {
    let exampleDynamicFieldNames = ['text', 'dropdown'];

    exampleDynamicFieldNames.forEach((cName) => {
      this.formGroup.addControl(cName, new FormControl(null, Validators.required));
    });

    //@NOTE you can do custom logic to handle how to get the names and
    // how to instantiate the components based and what data you have
    // this is just a simple example
    this.dynamicFields = [
      {
        componentType: BasicInputComponent,
        options: {
          label: 'Dynamic Basic Input',
          name: 'dynamic-basic-input',
          control: this.formGroup.controls[exampleDynamicFieldNames[0]],
        },
      },
      {
        componentType: DropDownInputComponent,
        options: {
          label: 'Dynamic Dropdown Input',
          name: 'dynamic-dd-input',
          control: this.formGroup.controls[exampleDynamicFieldNames[1]],
          inputOptions: {
            dropDownInput: {
              multi: false,
              items: [
                { name: 'Option 1', value: 'op1', id: 1 },
                { name: 'Option 2', value: 'op2', id: 2 },
              ],
              optionLabel: 'name',
              optionValue: 'value',
              showClear: true,
              badgeView: false,
            },
          },
        },
      },
    ];
  }
  addMoreFields() {
    let exampleDynamicFieldNames = ['text' + this.addMoreIndex, 'dropdown' + this.addMoreIndex];

    exampleDynamicFieldNames.forEach((cName) => {
      this.formGroup.addControl(cName, new FormControl(null, Validators.required));
    });
    this.dynamicFields.push({
      componentType: BasicInputComponent,
      options: {
        label: 'Dynamic Basic Input' + this.addMoreIndex,
        name: 'dynamic-basic-input' + this.addMoreIndex,
        control: this.formGroup.controls[exampleDynamicFieldNames[0]],
      },
    });
    this.dynamicFields.push({
      componentType: DropDownInputComponent,
      options: {
        label: 'Dynamic Dropdown Input' + this.addMoreIndex,
        name: 'dynamic-dd-input' + this.addMoreIndex,
        control: this.formGroup.controls[exampleDynamicFieldNames[1]],
        inputOptions: {
          dropDownInput: {
            multi: false,
            items: [
              { name: 'Option 1', value: 'op1', id: 1 },
              { name: 'Option 2', value: 'op2', id: 2 },
            ],
            optionLabel: 'name',
            optionValue: 'value',
            showClear: true,
            badgeView: false,
          },
        },
      },
    });
    this.addMoreIndex++;
  }
  onSubmitForm() {
    this.toastService.info('Form Content', JSON.stringify(this.formGroup.getRawValue()));
  }
}
