import { Component, Input, OnInit } from '@angular/core';
import { DataTypeEnum } from '@shared/classes';
import { DynamicComponentBase } from '@shared/classes/view/DynamicComponentBase';

@Component({
  selector: 'app-slider-input',
  templateUrl: './slider-input.component.html',
  styleUrls: ['./slider-input.component.scss'],
  providers: [{ provide: DynamicComponentBase, useExisting: SliderInputComponent }],
})
export class SliderInputComponent extends DynamicComponentBase implements OnInit {
  @Input() style = 'p-button-primary';
  @Input() styleClass = 'p-button-primary';
  @Input() min: number = undefined;
  @Input() max: number = undefined;
  @Input() step: number = 1;
  @Input() range: boolean = false;
  @Input() orientation: string = 'horizontal';

  value: number = 100;

  options = {
    floor: 0,
    ceil: 200,
  };

  dataType: DataTypeEnum = DataTypeEnum.Slider;

  constructor() {
    super();
  }

  ngOnInit(): void {}

  setInputOptions() {
    this.min = this.inputOptions?.sliderInput?.min ?? 0;
    this.max = this.inputOptions?.sliderInput?.max ?? 100;

    this.step = this.inputOptions?.sliderInput?.step ?? 1;
    this.range = this.inputOptions?.sliderInput?.range ?? false;

    this.disabled = this.inputOptions?.sliderInput?.disabled ?? false;

    this.orientation = this.inputOptions?.sliderInput?.orientation ?? 'horizontal';

    this.style = this.inputOptions?.sliderInput?.style ?? '';
    this.styleClass = this.inputOptions?.sliderInput?.styleClass ?? '';
  }
}
