<div class="flex">
  <div class="min-w-18rem max-w-18rem mr-2">
    <app-target-code-selector
      [label]="''"
      [placeholder]="'Search Audit Case Templates'"
      [control]="formGroup.controls.templateFormControl"
      [viewMode]="viewMode"
      [multi]="false"
      [optionLabel]="'name'"
      [optionValue]="undefined"
      [dataKey]="'code'"
      [extraFilters]="[
        {
          left: { property: 'matchingCriteria', operation: 'IN', value: value?.control ? [value?.control] : null },
          operand: 'OR',
          right: {
            property: 'matchingCriteria',
            operation: 'IN',
            value: value?.controlGroup ? [value?.controlGroup] : null,
          },
        },
      ]"
      [customProjectionFields]="[
        'code',
        'recordStatus',
        'label',
        'name',
        'defaultAuditor',
        'auditQuestionnaire',
        'auditorQuestionnaire',
        'auditeeQuestionnaire',
      ]"
      [targetTypes]="['AUDIT_CASE_TEMPLATE']"
    >
    </app-target-code-selector>
  </div>
  <div class="min-w-18rem max-w-18rem mr-2">
    <app-basic-input
      label=""
      [placeholder]="'Name'"
      [control]="formGroup.controls.nameControl"
      [viewMode]="viewMode"
    ></app-basic-input>
  </div>
  <div class="min-w-18rem max-w-18rem mr-2">
    <app-basic-input
      label=""
      [placeholder]="'Program'"
      [control]="formGroup.controls.programControl"
      [dataType]="dataTypes.CodeLink"
      [viewMode]="viewMode"
    ></app-basic-input>
  </div>
  <div class="min-w-18rem max-w-18rem mr-2">
    <app-basic-input
      label=""
      [placeholder]="'Control'"
      [control]="formGroup.controls.controlControl"
      [dataType]="dataTypes.CodeLink"
      [viewMode]="'view'"
    ></app-basic-input>
  </div>
  <div class="min-w-18rem max-w-18rem mr-2">
    <app-user-and-group-selector
      [label]="''"
      [placeholder]="'Search...'"
      [principleMode]="true"
      [control]="formGroup.controls.respondentControl"
      [optionValue]="undefined"
      [multi]="false"
      [viewMode]="viewMode"
    >
    </app-user-and-group-selector>
  </div>
  <div class="min-w-18rem max-w-18rem mr-2">
    <app-user-and-group-selector
      [label]="''"
      [placeholder]="'Search...'"
      [principleMode]="true"
      [control]="formGroup.controls.ownerControl"
      [optionValue]="undefined"
      [multi]="false"
      [viewMode]="viewMode"
    >
    </app-user-and-group-selector>
  </div>
  <div class="min-w-18rem max-w-18rem mr-2">
    <app-target-code-selector
      [label]="''"
      [placeholder]="'Search Questionnaire'"
      [control]="formGroup.controls.auditQsrControl"
      [viewMode]="viewMode"
      [multi]="false"
      [optionLabel]="'name'"
      [optionValue]="'code'"
      [customProjectionFields]="['code', 'recordStatus', 'label', 'name']"
      [targetTypes]="['QUESTIONNAIRE']"
    >
    </app-target-code-selector>
  </div>
  <div class="min-w-18rem max-w-18rem mr-2">
    <app-target-code-selector
      [label]="''"
      [placeholder]="'Search Questionnaire'"
      [control]="formGroup.controls.evidenceQsrControl"
      [viewMode]="viewMode"
      [multi]="false"
      [optionLabel]="'name'"
      [optionValue]="'code'"
      [customProjectionFields]="['code', 'recordStatus', 'label', 'name']"
      [targetTypes]="['QUESTIONNAIRE']"
    >
    </app-target-code-selector>
  </div>

  <div class="min-w-18rem max-w-18rem mr-2">
    <app-user-and-group-selector
      [label]="''"
      [placeholder]="'Search...'"
      [principleMode]="true"
      [control]="formGroup.controls.auditorControl"
      [optionValue]="undefined"
      [multi]="false"
      [viewMode]="viewMode"
    >
    </app-user-and-group-selector>
  </div>
  <div class="min-w-18rem max-w-18rem">
    <app-user-and-group-selector
      [label]="''"
      [placeholder]="'Search...'"
      [principleMode]="true"
      [control]="formGroup.controls.auditeeControl"
      [optionValue]="undefined"
      [optionLabel]="'name'"
      [multi]="false"
      [viewMode]="viewMode"
    >
    </app-user-and-group-selector>
  </div>
</div>
