<div class="">
  <app-stacked-progress-bar-view [data]="progressBarList"></app-stacked-progress-bar-view>

  <!-- <app-progressbar-view [data]="percentage"
  [containerStyle]="'margin-bottom: 2px; height:21px;'"
  [tooltipHint]="'%'+percentage"
[styleBasedColor]="progressColor"></app-progressbar-view> -->
  <div class="flex justify-content-between">
    @if (viewValue3 != null) {
      <div class="flex flex-row gap-2">
        <label>Planned</label>
        <app-view-switch [data]="viewValue" [options]="viewOptions" [viewType]="viewDataType"></app-view-switch>
      </div>
      <div class="flex flex-row gap-2">
        <label>Over Budget</label>
        <app-view-switch [data]="viewValue3" [options]="viewOptions" [viewType]="viewDataType"></app-view-switch>
      </div>
    } @else {
      <div class="flex flex-row gap-2">
        <label>Planned</label>
        <app-view-switch [data]="viewValue" [options]="viewOptions" [viewType]="viewDataType"></app-view-switch>
      </div>
      @if (viewValue2) {
        <div class="flex flex-row gap-2">
          <label>Budget Buffer</label>
          <app-view-switch [data]="viewValue2" [options]="viewOptions" [viewType]="viewDataType"></app-view-switch>
        </div>
      }
    }
  </div>
</div>
