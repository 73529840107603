import {
  AfterViewInit,
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import {
  AggregationField,
  ChartViewConfig,
  ContentViewDto,
  getEnumOptions,
  humanizeCasedString,
  IAction,
  isNullObj,
  isValidCode,
  setChartNameAndData,
  StatisticViewConfig,
  UnsubscribeOnDestroyAdapter,
  ViewCardItem,
} from '@shared/classes';
import { AggregateDataOptions, EchartBase } from '@shared/classes/view/echart-helpers/echart-base';
import { EchartFactory } from '@shared/classes/view/echart-helpers/echart-factory';
import { AppDialogService, BaseFieldDefinitionsService, PathResolverService } from '@shared/services';
import { AppComponent } from 'app/app.component';
import { GeneratedStatisticDataService } from 'app/modules/data-structure-module/services/data/generated-statistic-data.service';
import { NgxPermissionsService } from 'ngx-permissions';
import { MenuItem } from 'primeng/api';
import { of } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';
import { chartVariationConditions, ChartVariationMapper } from '../widget-chart/widget-chart.component';

@Component({
  selector: 'app-widget-statistic-chart',
  templateUrl: './widget-statistic-chart.component.html',
  styleUrl: './widget-statistic-chart.component.scss',
})
export class WidgetStatisticChartComponent extends UnsubscribeOnDestroyAdapter implements OnInit, AfterViewInit {
  @ViewChild('eChartContainer', { static: true }) eChartContainer: ElementRef;
  @ContentChild('headerTemplate') headerTemplate: TemplateRef<any>;
  @Input() showChartViewControls = true;
  @Input() styleClass: string = 'relative card m-0';
  @Input() title: string = 'some title';
  chartId = null;
  private _cardContent: ViewCardItem = null;
  myChart: echarts.ECharts;
  resizeOb: any;
  resizeObFired: any;
  animationFrameID: number;
  aggregationResult: { fields: AggregationField[]; value: any; formattedValues: { [x: string]: any } };
  aggregateDataOptions: AggregateDataOptions;
  @Input() set cardContent(cardContent: ViewCardItem) {
    this._cardContent = cardContent;
    this.initData();
  }
  get cardContent() {
    return this._cardContent;
  }
  mappingService: BaseFieldDefinitionsService = null;
  mergeOptions: any;
  factorOptions: any;
  factoryChart: EchartBase;
  options: any;
  option: any;
  @Input() chartType: any = 'TIME_SCALE';
  @Input() data: any[] = [];
  @Input() height = '100vh';
  chartViewTypeItems = getEnumOptions(ChartViewConfig.ViewTypeEnum);
  chartVariationItems = getEnumOptions(ChartViewConfig.ChartVariationEnum);
  tablePostionItems = getEnumOptions(ChartViewConfig.DataPositionEnum);
  chartTypeOptions = getEnumOptions(StatisticViewConfig.ChartTypeEnum).filter((x) => x.value != 'LINE_RACE');
  formGroup = new FormGroup({
    chartType: new FormControl('TIME_SCALE'),
    viewType: new FormControl('CHART'),
    chartVariation: new FormControl(null),
    dataPosition: new FormControl('DOWN'),
    colorPalette: new FormControl(null),
  });
  isLoading = true;
  activeDataIndex = 0;
  activeDataFilter = null;
  chartTitle;
  drillDownPreviousOptions: MenuItem[] = [];
  @Input() tableCardContent: ContentViewDto;
  @Input() filteredKeys = [];

  home: MenuItem = { icon: 'pi pi-home' };
  drillDownDataSet = null;
  initiatedComp = false;
  private _directConfig: { dataObject: any; chartType: ChartViewConfig.ChartTypeEnum };
  colorPallete = null;
  tooltipObject = null;
  @Input() set directConfig(directConfig: {
    dataObject: any;
    chartType: ChartViewConfig.ChartTypeEnum;
    colors?: { key: string; color: string }[];
    filteredKeys?: [string];
  }) {
    if (directConfig?.colors?.length > 0) {
      this.colorPallete = directConfig.colors.map((x) => x.color);
    }
    this.chartType = directConfig.chartType;
    if (directConfig?.dataObject?.tooltip) {
      this.tooltipObject = directConfig?.dataObject?.tooltip;
    }
    if (directConfig.chartType == 'SANKEY') {
      this.data = directConfig.dataObject;
    } else if (directConfig.chartType == 'TIME_SCALE') {
      this.data = directConfig.dataObject;
    } else {
      if (directConfig?.filteredKeys) {
        this.data = Object.entries(directConfig.dataObject)
          ?.filter((x) => !directConfig.filteredKeys.includes(x[0]))
          .map(([key, value]) => {
            return { name: humanizeCasedString(key), value: value };
          });
      } else {
        this.data = Object.entries(directConfig.dataObject)?.map(([key, value]) => {
          return { name: humanizeCasedString(key), value: value };
        });
      }
    }
    // if (this.options) {
    //     this.mergeOptions = setChartNameAndData(this.options, this.chartType, this.chartTitle || this.title, this.data);
    //     this.chartId = uuidv4();
    // } else {
    if (this.initiatedComp) {
      this.initEChart();
    }
    this._directConfig = directConfig;
    // }
  }
  get directConfig() {
    return this._directConfig;
  }
  @Output() onChartElementClick: EventEmitter<any> = new EventEmitter();
  @Output() onChartOptionsChange: EventEmitter<any> = new EventEmitter();
  editOptionsAction: IAction = {
    label: '',
    id: 1,
    color: 'secondary',
    icon: 'pi pi-pencil',
    iconPos: 'left',
    tooltipText: 'Edit Chart Options',
    tooltipPosition: 'top',
    tooltipOptions: {
      tooltipLabel: 'Edit Chart Options',
    },
    command: this.showEditOptions.bind(this),
  };
  @ViewChild('chartViewControlsDiv', { static: false }) chartViewControlsDiv!: ElementRef;
  constructor(
    public appDialogService: AppDialogService,
    public router: Router,
    private pathResolverService: PathResolverService,
    private permissionService: NgxPermissionsService,
    public app: AppComponent,
    private generatedStatisticService: GeneratedStatisticDataService
  ) {
    super();
  }
  ngAfterViewInit(): void {
    setTimeout(() => {
      this.initEChart();
      setTimeout(() => {
        this.initiatedComp = true;
      }, 20);
    });
  }
  ngOnInit(): void {
    // Create the echarts instance
    // let myChart = echarts.init(document.getElementById('main'));

    // Draw the chart
    // myChart.setOption(this.option);
    // this.myChart = echarts.init(this.eChartContainer.nativeElement, 'dark');
    this.formGroup.valueChanges.subscribe((values) => {
      this.overrideOptions = null;
      this.chartType = values.chartType;
      this.onChartOptionsChange.emit(values);
      // this.initEChart();
      this.factoryChart = EchartFactory.createChart({
        chartType: this.chartType || 'TIME_SCALE',
        chartVariation:
          this.cardContent?.contentViewConfig?.chartVariation ||
          this.cardContent?.contentViewObject?.statisticViewConfig?.chartVariation ||
          'LINE',
      });
      this.factorOptions = this.factoryChart.getEChartOptions({
        aggregationResult: {
          collectedStatistics: (this.aggergationRes as any as any[])
            .filter((item) => item?.aggregateDataOptions?.response?.aggregation_value)
            .map((item) => {
              return {
                payload: item?.aggregateDataOptions?.aggregationBody,
                response: item?.aggregateDataOptions?.response,
                collectionDate: item.collectionDate,
              };
            }),
        },
        viewCardItem: {
          contentViewCode: this.cardContent?.contentViewCode || this.cardContent?.contentViewObject?.code,
          contentViewConfig:
            this.cardContent?.contentViewConfig || this.cardContent?.contentViewObject?.chartViewConfig,
          contentViewObject: this.cardContent?.contentViewObject,
        },
      });

      // this.factorOptions = this.factoryChart.assignDataToChartOptions({
      //   chartOptions: this.factorOptions,
      //   name: this.chartTitle || this.title,
      //   data: this.data,
      // });
    });
  }
  initEChart() {
    // type EChartsOption = echarts.EChartsOption;
    // let option: EChartsOption;
    // option = cloneDeep({ ...EChartTypeMapper?.[this.chartType] });
    // // if (this.chartType != 'SANKEY') {
    // if (
    //   this.cardContent?.contentViewConfig?.colorPalette?.length > 0 ||
    //   this.cardContent?.contentViewObject?.chartViewConfig?.colorPalette?.length > 0 ||
    //   this.colorPallete?.length > 0
    // ) {
    //   option.color =
    //     this.cardContent?.contentViewConfig?.colorPalette ||
    //     this.cardContent?.contentViewObject?.chartViewConfig?.colorPalette ||
    //     this.colorPallete;
    // }
    // option = setChartNameAndData(option, this.chartType, this.chartTitle || this.title, this.data);
    // // }
    // option.tooltip = this.tooltipObject ?? option.tooltip;

    // this.options = { ...option };
    this.chartId = uuidv4();
    // option && this.myChart.setOption(option);

    // option && myChart.setOption(option);
    // this.resizeOb = this.ngZone.runOutsideAngular(() => new window.ResizeObserver((entries) => {
    //     for (const entry of entries) {
    //         if (entry.target === this.eChartContainer.nativeElement) {
    //             // Ignore first fire on insertion, no resize actually happened
    //           if (!this.resizeObFired) {
    //             this.resizeObFired = true
    //           } else {
    //             this.animationFrameID = window.requestAnimationFrame(() => {
    //             //   this.resize$.next();
    //                 this.myChart.resize();
    //             });
    //           }
    //         }
    //       }
    //   }));
    //   this.resizeOb.observe(this.eChartContainer.nativeElement);
    // const _this = this;
    // window.addEventListener('resize', _this.myChart.resize);
    // new ResizeObserver(() => this.myChart.resize()).observe(this.eChartContainer.nativeElement);
  }
  initData() {
    if (this.cardContent) {
      this.formGroup.patchValue(
        {
          chartType:
            // this.cardContent?.contentViewConfig?.chartType ||
            this.cardContent?.contentViewObject?.statisticViewConfig?.chartType,
          viewType:
            // this.cardContent?.contentViewConfig?.viewType ||
            this.cardContent?.contentViewObject?.statisticViewConfig?.viewType,
          chartVariation:
            // this.cardContent?.contentViewConfig?.chartVariation ||
            this.cardContent?.contentViewObject?.statisticViewConfig?.chartVariation,
          dataPosition:
            // this.cardContent?.contentViewConfig?.dataPosition ||
            this.cardContent?.contentViewObject?.statisticViewConfig?.dataPosition,
          colorPalette:
            // this.cardContent?.contentViewConfig?.colorPalette ||
            this.cardContent?.contentViewObject?.statisticViewConfig?.colorPalette,
        },
        { emitEvent: false }
      );
      this.chartType =
        // this.cardContent?.contentViewConfig?.chartType ||
        this.cardContent?.contentViewObject?.statisticViewConfig?.chartType;
      // this.mappingService = this.pathResolverService.getMappingServiceByTargetType(
      //   this.cardContent?.contentViewObject?.targetType as any
      // );
      this.fetchData();
    }
  }
  fetchData() {
    this.isLoading = true;
    this.permissionService.hasPermission(this.cardContent?.contentViewObject?.permissions).then((isAllowed) => {
      // const dataService = this.pathResolverService.getDataServiceByTargetType(
      //   this.cardContent?.contentViewObject?.targetType as any
      // );
      // const mappingService = this.pathResolverService.getMappingServiceByTargetType(
      //   this.cardContent?.contentViewObject?.targetType as any
      // );
      this.subs.sink = (
        isAllowed
          ? this.generatedStatisticService.search(
              { page: 0, size: 10, all: true },
              {
                projectionFields: ['code', 'collectionDate', 'aggregateDataOptions'],
                filters: [
                  {
                    property: 'collector',
                    operation: 'EQUAL',
                    value: this.cardContent?.contentViewObject?.statisticViewConfig?.collectorCode,
                  },
                ],
              }
            )
          : of([] as any)
      ).subscribe((res) => {
        this.isLoading = false;
        // const aggregateField = this.cardContent?.contentViewObject?.filterView?.aggregationBody?.groupByFields?.[0];
        // const aggregationFieldOperation: any =
        //   this.cardContent?.contentViewObject?.filterView?.aggregationBody?.aggregationFields
        //     ?.find((x) => x?.fieldName == aggregateField)
        //     ?.operation?.toLowerCase() || AggregateType.Count;
        // let chartDataList: { [x: string]: any } = {};
        // this.cardContent?.contentViewObject?.filterView?.aggregationBody?.aggregationFields?.forEach((field) => {
        //   chartDataList[field.fieldName] = parseAggregateDataV2(
        //     res?.aggregation_value,
        //     field.fieldName,
        //     aggregationFieldOperation,
        //     mappingService?.mappedFields?.[aggregateField]?.tableDefinition?.filter?.enumClass || null,
        //     this.cardContent?.contentViewObject?.filterView?.aggregationBody?.aggregationFields?.length > 1
        //       ? field.fieldName
        //       : null
        //   );
        // });

        // this.aggregationResult = {
        //   fields: this.cardContent?.contentViewObject?.filterView?.aggregationBody?.aggregationFields,
        //   value: res?.aggregation_value,
        //   formattedValues: chartDataList,
        // };
        // this.initialChartFormatData();
        // this.aggregateDataOptions = {
        //   response: res,
        //   payload: this.cardContent?.contentViewObject?.filterView?.aggregationBody,
        // };
        this.factoryChart = EchartFactory.createChart({
          chartType:
            // this.cardContent?.contentViewConfig?.chartType ||
            this.cardContent?.contentViewObject?.statisticViewConfig?.chartType || 'TIME_SCALE',
          chartVariation:
            this.cardContent?.contentViewConfig?.chartVariation ||
            this.cardContent?.contentViewObject?.statisticViewConfig?.chartVariation ||
            'LINE',
        });
        this.aggergationRes = res as any as any[];
        if ((this.cardContent?.contentViewObject?.statisticViewConfig?.chartType || 'TIME_SCALE') == 'BAR_RACE') {
          this.aggergationRes = (res as any as any[]).filter(
            (item) => item?.aggregateDataOptions?.response?.aggregation_value
          );
          if (this.barRaceUpdateDataTimeout) {
            clearTimeout(this.barRaceUpdateDataTimeout);
          }
          // this.barRaceUpdateData();
        }
        this.factorOptions = this.factoryChart.getEChartOptions({
          aggregationResult: {
            collectedStatistics: (res as any as any[])
              .filter((item) => item?.aggregateDataOptions?.response?.aggregation_value)
              .map((item) => {
                return {
                  payload: item?.aggregateDataOptions?.aggregationBody,
                  response: item?.aggregateDataOptions?.response,
                  collectionDate: item.collectionDate,
                };
              }),
          },
          viewCardItem: {
            contentViewCode: this.cardContent?.contentViewCode || this.cardContent?.contentViewObject?.code,
            contentViewConfig:
              this.cardContent?.contentViewConfig || this.cardContent?.contentViewObject?.statisticViewConfig,
            contentViewObject: this.cardContent?.contentViewObject,
          },
        });
        if ((this.cardContent?.contentViewObject?.statisticViewConfig?.chartType || 'TIME_SCALE') == 'BAR_RACE') {
        }

        // this.chartCObjClassificationsData = parseAggregateDataV2(res?.aggregation_value, aggregateField, aggregationFieldOperation, mappingService?.mappedFields?.[aggregateField]?.tableDefinition?.filter?.enumClass,this.cardContent?.filterView?.aggregationBody?.aggregationFields?.length > 1 ? 'recordStatus' : null);
        // this.chartCObjClassificationsTotal = this.getTotalAmount(this.chartCObjClassificationsData);
        // this.chartCObjClassificationsDetails = this.getChartData(this.chartCObjClassificationsData);
      });
    });
  }
  aggergationRes: any[];
  barRaceUpdateDataTimeout;
  barRaceUpdateData() {
    this.barRaceUpdateDataTimeout = setTimeout(() => {
      this.aggergationRes?.shift();
      if (this.aggergationRes?.length > 0) {
        this.factorOptions = this.factoryChart.getEChartOptions({
          aggregationResult: {
            collectedStatistics: (this.aggergationRes as any as any[]).map((item) => {
              return {
                payload: item?.aggregateDataOptions?.aggregationBody,
                response: item?.aggregateDataOptions?.response,
                collectionDate: item.collectionDate,
              };
            }),
          },
          viewCardItem: {
            contentViewCode: this.cardContent?.contentViewCode || this.cardContent?.contentViewObject?.code,
            contentViewConfig:
              this.cardContent?.contentViewConfig || this.cardContent?.contentViewObject?.chartViewConfig,
            contentViewObject: this.cardContent?.contentViewObject,
          },
        });
        this.barRaceUpdateData();
      }
    }, 3000);
  }
  initialChartFormatData() {
    if (
      isNullObj(this.aggregationResult?.value) ||
      isNullObj(this.aggregationResult?.fields) ||
      isNullObj(this.aggregationResult?.formattedValues)
    )
      return;
    const currentField = this.aggregationResult.fields[0]; //this.activeDataIndex
    if (isNullObj(currentField)) return;

    this.formatAggregateData(this.aggregationResult?.formattedValues?.[currentField.fieldName], currentField.fieldName);
  }
  formatAggregateData(dataObject, title) {
    const humanizeKeys = !!(this.mappingService?.mappedFields?.[title]?.tableDefinition?.filter?.enumClass || null);
    const formattedData = [];
    Object.entries(dataObject).forEach(([key, val], index) => {
      formattedData.push({ name: humanizeKeys ? humanizeCasedString(key) : key, value: val });
    });
    this.data = [...formattedData];
    this.chartTitle = humanizeCasedString(title);
    this.mergeOptions = setChartNameAndData(this.options, this.chartType, this.chartTitle || this.title, this.data);
  }
  onChartEvent(event: any, type: string) {
    this.onChartElementClick.emit(event);
    const formattedLabel = (event?.name as string).toUpperCase().replace(/ /g, '_');
    this.activeDataFilter = formattedLabel;
    this.activeDataIndex++;
    this.changeDrillDownView(event?.name);
  }
  changeDrillDownView(label) {
    if (this.aggregationResult?.fields?.length > this.drillDownPreviousOptions?.length) {
      const fieldName = this.aggregationResult?.fields?.[this.drillDownPreviousOptions?.length + 1]?.fieldName;
      const oldFieldName = this.aggregationResult?.fields?.[this.drillDownPreviousOptions?.length]?.fieldName;
      const formattedLabel = (label as string).toUpperCase().replace(/ /g, '_');
      const extraLabels = ['No ' + fieldName];
      const humanizedFieldName = humanizeCasedString(fieldName);
      let ret = {};
      this.aggregationResult?.value
        ?.filter((x) =>
          formattedLabel == 'EMPTY_VALUE'
            ? isNullObj(x.group_id?.[oldFieldName])
            : x.group_id?.[oldFieldName] == formattedLabel
        )
        .forEach((item) => {
          if (item?.group_id?.[fieldName]) {
            ret[item?.group_id?.[fieldName]] = ret[item?.group_id?.[fieldName]]
              ? ret[item?.group_id?.[fieldName]] + item?.[fieldName + '_count']
              : item?.[fieldName + '_count'];
          } else {
            ret['Empty Value'] = ret['Empty Value']
              ? ret['Empty Value'] + item?.[fieldName + '_count']
              : item?.[fieldName + '_count'];
          }
        });
      const agData = this.factoryChart.finalizeAggregateData(ret, fieldName);
      this.factorOptions = this.factoryChart.assignDataToChartOptions({
        chartOptions: this.factorOptions,
        name: agData.chartTitle,
        data: agData.data,
      });
      this.formatAggregateData(ret, fieldName);
      // this.drillDownDataSet = {
      //     datasets:[{
      //         backgroundColor:['#6c6a76', '#4caf50'],
      //         borderColor:'transparent',
      //         data: Object.entries(ret).map(x=> x[1]),
      //         fill:true,
      //     }],
      //     labels:Object.entries(ret).map(x=> x[0])
      // }

      this.drillDownPreviousOptions.push({
        label: isValidCode(label) ? label : humanizeCasedString(label),
        currentField: oldFieldName,
        currentLabelValue: formattedLabel,
        currentLabel: label,
      });

      const currentNullCompareField = 'Empty Value'.toUpperCase().replace(/ /g, '_');

      this.drillDownPreviousOptions = [...this.drillDownPreviousOptions];
      this.tableCardContent = {
        ...this.cardContent?.contentViewObject,
        tableViewConfig: {
          ...this.cardContent?.contentViewObject?.tableViewConfig,
          showActions: false,
          showGlobalFilter: false,
          showOperationActions: false,
          showDeleteAction: false,
          showEditAction: false,
          showLockAction: false,
          showActivateAction: false,
          showDeactivateAction: false,
          showStatusActions: false,
          showSettingsLink: false,
          showFileExportAction: false,
          showPackageExportAction: false,
          showBulkOperationAction: false,
        },
        filterView: {
          ...this.cardContent?.contentViewObject?.filterView,
          filter: {
            ...this.cardContent?.contentViewObject?.filterView.filter,
            filters: [
              ...(this.cardContent?.contentViewObject?.filterView?.filter?.filters
                ? this.cardContent?.contentViewObject?.filterView?.filter?.filters
                : []),
              ...this.drillDownPreviousOptions.map((x) => {
                return x?.currentLabelValue === currentNullCompareField
                  ? ({ property: x?.currentField, operation: 'IS_NULL' } as any)
                  : ({
                      property: x?.currentField,
                      operation: 'EQUAL',
                      value: x?.currentLabelValue,
                    } as any);
              }),
            ],
          },
        },
      };
    }
  }
  onDrillDownItemClick(event) {
    const item = event.item;
    if (item.icon) {
      this.drillDownPreviousOptions = [];
      this.initialChartFormatData();
      this.factorOptions = this.factoryChart.getEChartOptions({
        aggregationResult: { ...this.aggregateDataOptions },
        viewCardItem: {
          contentViewCode: this.cardContent?.contentViewCode || this.cardContent?.contentViewObject?.code,
          contentViewConfig:
            this.cardContent?.contentViewConfig || this.cardContent?.contentViewObject?.chartViewConfig,
          contentViewObject: this.cardContent?.contentViewObject,
        },
      });
    } else {
      const index = this.drillDownPreviousOptions.indexOf(item);
      this.drillDownPreviousOptions.splice(index, this.drillDownPreviousOptions.length - index);
      this.changeDrillDownView(item.currentLabel);
    }
  }
  showOptionsEditor = false;
  showEditOptions() {
    this.tempOptions = JSON.stringify(this.overrideOptions || this.factorOptions);
    this.showOptionsEditor = true;
    this.tempOptionsFormControl.patchValue(null);
    setTimeout(() => {
      this.tempOptionsFormControl.patchValue(this.tempOptions);
    }, 100);
  }
  overrideOptions = null;
  tempOptions = null;
  tempOptionsFormControl = new FormControl(null);
  assignNewOptions() {
    this.overrideOptions = JSON.parse(this.tempOptionsFormControl.value);
    this.showOptionsEditor = false;
  }
  get chartVariationOptions() {
    return ChartVariationMapper[
      this.formGroup?.controls?.chartType?.value ||
        this.cardContent?.contentViewConfig?.chartType ||
        this.cardContent?.contentViewObject?.statisticViewConfig?.chartType ||
        'TIME_SCALE'
    ][chartVariationConditions.TWO_D];
  }
  get tableHeight() {
    const baseHeight = this.chartViewControlsDiv?.nativeElement?.offsetHeight || 40.5;
    return this.formGroup?.controls?.viewType?.value == 'CHART_AND_DATA' &&
      (this.formGroup?.controls?.dataPosition?.value == 'UP' ||
        (this.formGroup?.controls?.dataPosition?.value || ChartViewConfig.DataPositionEnum.Down) == 'DOWN')
      ? `height:calc(50% - ${(this.showChartViewControls ? baseHeight : 0) + (this.aggregationResult?.fields?.length > 1 ? 24.5 : 0)}px);min-height:calc(50% - ${(this.showChartViewControls ? baseHeight : 0) + (this.aggregationResult?.fields?.length > 1 ? 24.5 : 0)}px);`
      : '';
  }
  get chartContainerHeight() {
    const baseHeight = this.chartViewControlsDiv?.nativeElement?.offsetHeight || 40.5;
    return `height:calc(100% - ${this.showChartViewControls ? baseHeight : 0}px);min-height:calc(100% - ${this.showChartViewControls ? baseHeight : 0}px);`;
  }
  get flexDirection() {
    return PositionFlexDirectionMapper[
      this.formGroup?.controls?.dataPosition?.value || ChartViewConfig.DataPositionEnum.Down
    ];
  }
}
const PositionFlexDirectionMapper = {
  [ChartViewConfig.DataPositionEnum.Up]: 'flex-column',
  [ChartViewConfig.DataPositionEnum.Down]: 'flex-column-reverse',
  [ChartViewConfig.DataPositionEnum.Left]: 'flex-row',
  [ChartViewConfig.DataPositionEnum.Right]: 'flex-row-reverse',
};
