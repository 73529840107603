import { Component, Input, OnInit, ViewChild } from '@angular/core';
import {
  BaseViewItem,
  FilterItem,
  ResponsibilityFieldDto,
  SearchBody,
  extractItemCode,
  removeStartingCode,
} from '@shared/classes';
import { PageableOptions } from '@shared/classes/model/frontend/pageable-options';
import { AvatarResponsibilityView } from '@shared/classes/view/AvatarResponsibility';
import { ResponsibilitiesDataService } from 'app/modules/entity-module/services/data/responsibilities-data.service';
import { GroupDataService } from 'app/modules/users/services/data/group-data.service';
import { OverlayPanel } from 'primeng/overlaypanel';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-responsibility-avatar-list',
  templateUrl: './responsibility-avatar-list.component.html',
  styleUrls: ['./responsibility-avatar-list.component.scss'],
})
export class AvatarResponsibilityListComponent extends BaseViewItem implements OnInit {
  @ViewChild('op') op: OverlayPanel;

  @Input() maxLength: number = 5;
  @Input() mode: AvatarResponsibilityView = AvatarResponsibilityView.Shrank;

  isLoading: boolean = false;

  avatarList: string[] = [];

  avatarStretchList: any[] = [];

  pageableOptions: PageableOptions = new PageableOptions();

  appliedSearchBody: SearchBody = {
    projectionFields: [
      'id',
      'name',
      'code',
      'members',
      'icon',
      'entityType', //@TODO:IMPORTANT do something to get the icon from entity type
    ],
    filters: [],
  };

  constructor(
    private entityDataService: ResponsibilitiesDataService,
    private groupDataService: GroupDataService
  ) {
    super();
  }

  ngOnInit(): void {
    this.getGroupNames()?.forEach((groupName) => {
      if (groupName) {
        this.avatarList.push(removeStartingCode(groupName));
        this.avatarList = [...this.avatarList];
      }
    });
  }

  show(e, t) {
    this.isLoading = true;
    const _this = this;

    this.subs.sink = forkJoin(_this.getRequests()).subscribe({
      next: (res) => {
        if (res?.length) {
          this.avatarList = [];
          this.avatarStretchList = [];
          res.forEach((responsibility) => {
            Object.keys(responsibility).forEach((groupName) => {
              if (groupName) {
                this.avatarList.push(removeStartingCode(groupName));
                this.avatarStretchList.push({ name: groupName, members: responsibility[groupName] });
                this.avatarList = [...this.avatarList];
                this.avatarStretchList = [...this.avatarStretchList];
              }
            });
            // let entity: ResponsibilityFieldDto = responsibility.content[0]
            // if (entity) {
            //     this.avatarList.push(entity?.name)
            //     this.avatarStretchList.push(entity)
            //     this.avatarList = [...this.avatarList]
            //     this.avatarStretchList = [...this.avatarStretchList]
            // }
          });
        }
        this.isLoading = false;
        if (this.avatarStretchList?.length) {
          this.op.show(e, t);
        }
      },
      error: (err) => {
        this.isLoading = false;
      },
    });
  }
  getGroupNames() {
    const requestOptions = { showLoading: false, showMsg: false, extras: { cacheKey: JSON.stringify(this.data) } };
    if (typeof this.data === 'string' || this.data instanceof String) {
      return [this.data];
    } else if (Array.isArray(this.data)) {
      const groupIds = (this.data as any[])
        ?.filter((x) => !!x)
        ?.map((item) => {
          const searchVal =
            item?.code ?? (typeof item === 'string' || item instanceof String ? item : (item?.name ?? item));
          return searchVal;
        });
      return groupIds;
    } else if (this.data?.name) {
      return [this?.data?.name];
    }
    return null;
  }

  //@TODO: Important change cache way to similar functionality as the code navigation component
  getRequests() {
    // let ret = null;
    const requestOptions = { showLoading: false, showMsg: false, extras: { cacheKey: JSON.stringify(this.data) } };
    if (typeof this.data === 'string' || this.data instanceof String) {
      // let filter: FilterItem = {
      //     property: 'code',
      //     operation: 'EQUAL',
      //     value: this.data,
      //     typeShape: 'NORMAL',
      // }

      // let body = { ...this.appliedSearchBody, filters: [filter] }

      // ret = this.entityDataService.search<ResponsibilityFieldDto>(this.pageableOptions, body, {
      //     showLoading: false,
      //     showMsg: false,
      //     extras: { cacheKey: this.data },
      // })
      return this.groupDataService.getGroupsMembers([this.data], requestOptions);
    } else if (Array.isArray(this.data)) {
      // ret = (this.data as any[])
      //     ?.filter((x) => !!x)
      //     ?.map((item) => {
      //         const searchVal =
      //             item?.code ??
      //             ((typeof item === 'string' || item instanceof String)
      //                 ? item
      //                 : item?.name
      //                 ? (extractItemCode(item?.name)?.code ?? item)
      //                 : item)
      //         let filter: FilterItem = {
      //             property: 'code',
      //             operation: 'EQUAL',
      //             value: searchVal,
      //             typeShape: 'NORMAL',
      //         }

      //         let body = { ...this.appliedSearchBody, filters: [filter] }

      //         return this.entityDataService.search<ResponsibilityFieldDto>(this.pageableOptions, body, {
      //             showLoading: false,
      //             showMsg: false,
      //             extras: { cacheKey: searchVal },
      //         })
      //     })
      const groupIds = (this.data as any[])
        ?.filter((x) => !!x)
        ?.map((item) => {
          const searchVal =
            item?.code ?? (typeof item === 'string' || item instanceof String ? item : (item?.name ?? item));
          return searchVal;
        });
      return this.groupDataService.getGroupsMembers(groupIds, requestOptions);
    } else if (this.data?.name) {
      return this.groupDataService.getGroupsMembers([this?.data?.name], requestOptions);
    }
    // else if (this.data?.code) {
    //     let filter: FilterItem = {
    //         property: 'code',
    //         operation: 'EQUAL',
    //         value: this.data.code,
    //         typeShape: 'NORMAL',
    //     }
    //     let body = {
    //         ...this.appliedSearchBody,
    //         filters: [filter],
    //     }
    //     ret = this.entityDataService.search<ResponsibilityFieldDto>(this.pageableOptions, body, {
    //         showLoading: false,
    //         showMsg: false,
    //         extras: { cacheKey: this.data.code },
    //     })
    // }

    return null;
  }
}
