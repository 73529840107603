import { ChartViewConfig } from '@shared/classes/model';
import { EChartsOption } from 'echarts';
import { AssignChartDataOptions, EchartBase } from '../../echart-base';

export class PieChart extends EchartBase {
  chartOptions: EChartsOption = {
    animationDurationUpdate: (idx) => idx * 600,
    series: [
      {
        name: '',
        type: 'pie',
        radius: '60%',
        center: ['50%', '65%'],
        label: {
          formatter: '{c}',
          position: 'inner',
        },
        data: [],
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: 'rgba(0, 0, 0, 0.5)',
          },
        },
      },
    ],
  };
  chartType: ChartViewConfig.ChartTypeEnum = 'PIE';
  assignDataToChartOptions(options: AssignChartDataOptions): EChartsOption {
    let optionsCopy = { ...options?.chartOptions };
    optionsCopy.series[0].name = options?.name;
    optionsCopy.series[0].data = options?.data;
    return optionsCopy;
  }
}
