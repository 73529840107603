import { Component, Input, OnInit } from '@angular/core';
import { DynamicComponentBase, ResponsibilityFieldDto as Entity, EntityTypeFieldDto } from '@shared/classes';
import { EntityDataService } from 'app/modules/entity-module/entity/entity-data.service';
import { groupBy, uniqBy } from 'lodash-es';
import { TreeNode } from 'primeng/api';

@Component({
  selector: 'app-entity-tree-selector',
  templateUrl: './entity-tree-selector.component.html',
  styleUrls: ['./entity-tree-selector.component.scss'],
})
export class EntityTreeSelectorComponent extends DynamicComponentBase implements OnInit {
  @Input() multi = false;
  @Input() items: any[] = [];
  @Input() dropDown: boolean = false;
  @Input() set selectedEntities(entities: Entity[]) {
    this.setSelectedItems(entities);
  }
  @Input() set entityType(entityType: EntityTypeFieldDto.CategoryEnum) {
    this._entityType = entityType;
    this.getOptions();
  }
  private _entityType: EntityTypeFieldDto.CategoryEnum = null;
  get entityType() {
    return this._entityType;
  }
  isLoading: boolean = false;

  selectedNodes: TreeNode[] = null;
  groupedEntities: { [index: string]: any[] };
  formattedEntities: TreeNode[];
  visited: any = {};
  treeNodes: any = {};

  constructor(private service: EntityDataService) {
    super();
  }

  ngOnInit(): void {
    this.getOptions();
    if (this.control) {
      this.subs.sink = this.fControl.valueChanges.subscribe((changes) => {
        this.onChanges.emit(changes);
      });
    }
  }
  setSelectedItems(list: any[]) {
    let newSelections = null;
    Object.keys(this.treeNodes).forEach((nodeId) => {
      if (this.checkIfIncluded(nodeId, list)) {
        if (!newSelections) newSelections = [];
        newSelections.push(this.treeNodes[nodeId]);
      }
    });
    this.selectedNodes = newSelections;
  }
  getOptions(filters: any = []) {
    this.isLoading = true;
    this.subs.sink = this.service.getList<Entity>({ showLoading: false, showMsg: false }).subscribe({
      next: (data) => {
        let list = [];
        // if(this.entityType == 'BUSINESS'){
        //     data.data.forEach(element => {
        //         if(element.type.assetType == this.entityType){
        //             list.push(element);
        //         }
        //     });
        // }else{
        list = data.data;
        // }
        this.groupedEntities = groupBy(list, 'parent.id');
        this.visited = {};
        this.formatDataForTreeView(list);
        this.items = uniqBy(list, 'id');
      },
      complete: () => {
        this.isLoading = false;
      },
    });
  }
  setInputOptions() {
    this.multi = this.inputOptions?.dropDownInput?.multi ?? this.multi;
    this.items = this.inputOptions?.dropDownInput?.items ?? this.items;
    this.dropDown = this.inputOptions?.dropDownInput?.treeDropDown ?? this.dropDown;
  }
  onValueChanges(value: any): void {
    // this.data = value;
    this.fControl.patchValue(this.dropDown ? value?.data : value);
  }
  formatDataForTreeView(data: Entity[]) {
    this.formatDataForTreeViewRecursive(data);
  }
  formatDataForTreeViewRecursive(data: Entity[]) {
    this.formattedEntities = [
      {
        label: 'Entities',
        data: data,
        expandedIcon: 'pi pi-folder-open',
        collapsedIcon: 'pi pi-folder',
        children: [],
        expanded: true,
        selectable: false,
      },
    ];
    this.selectedNodes = null;
    this.groupedEntities['undefined']?.forEach((el, index) => {
      if (!!!this.visited[el.id]) {
        this.visited[el.id] = true;
        this.formattedEntities[0].children.push(this.getFormattedChildren(el.id, el));
      }
    });
  }
  getFormattedChildren(id, data): TreeNode {
    let node = {
      label: data.name,
      data: data,
      expandedIcon: 'pi pi-folder-open',
      collapsedIcon: 'pi pi-folder',
      icon: 'pi pi-file',
      children: [],
      expanded: true,
      selectable: this.viewMode != 'view',
    };
    this.treeNodes[id] = node;
    if (this.checkIfIncludedInValue(id)) {
      if (!this.selectedNodes) this.selectedNodes = [];
      this.selectedNodes.push(node);
    }
    if (!!this.groupedEntities[id]) {
      this.groupedEntities[id].forEach((el, index) => {
        if (!!!this.visited[el.id]) {
          this.visited[el.id] = true;
          let ret = this.getFormattedChildren(el.id, el);
          node.children.push(ret);
        } else {
        }
      });
    } else {
      return node;
    }
    return node;
  }
  checkIfIncludedInValue(id) {
    if (!this.fControl?.value) return false;
    if (!this.multi) return (this.fControl.value as Entity).id == id;
    return (this.fControl?.value as Entity[])?.findIndex((x) => x.id == id) != -1;
  }
  checkIfIncludedInSelectedNodes(id) {
    if (!this.selectedNodes) return false;
    return this.selectedNodes?.findIndex((x) => x.data.id == id) != -1;
  }
  checkIfIncluded(id, data: Entity[]) {
    if (!data) return false;
    return data?.findIndex((x) => x.id == id) != -1;
  }
}
