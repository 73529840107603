import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BaseViewItem, IColumn, ITableLoadEvent, ITablePageable } from '@shared/classes';

@Component({
  selector: 'app-dashlet-table',
  templateUrl: './dashlet-table.component.html',
  styleUrls: ['./dashlet-table.component.scss'],
})
export class DashletTableComponent extends BaseViewItem implements OnInit {
  @Input() title: string = '';

  @Input() cols: IColumn[];

  @Input() tableData: any[];

  @Input() tableEventData: ITableLoadEvent;

  @Output() onTableFilterChange: EventEmitter<ITableLoadEvent> = new EventEmitter();

  onTableFilterChangeEvent(tableEvent) {
    this.onTableFilterChange.emit(tableEvent);
  }

  ngOnInit(): void {}
}
