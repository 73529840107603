import { Clipboard } from '@angular/cdk/clipboard';
import { Component, Input, OnInit } from '@angular/core';
import { BaseViewItem } from '@shared/classes';
import { ToastService } from '@shared/services';

@Component({
  selector: 'app-dynamic-object-viewer',
  templateUrl: './dynamic-object-viewer.component.html',
  styleUrl: './dynamic-object-viewer.component.scss',
})
export class DynamicObjectViewerComponent extends BaseViewItem implements OnInit {
  keys: string[] = [];
  collapsed = false;
  @Input() label: string = null;
  collapsedItems: { [x: string]: boolean } = {};
  constructor(
    private clipboard: Clipboard,
    private toastService: ToastService
  ) {
    super();
  }

  ngOnInit(): void {}
  onSetData(): void {
    if (this.data) {
      this.keys = Object.keys(this.data);
      this.collapsedItems = {};
      this.keys.forEach((key) => {
        this.collapsedItems[key] = false;
      });
    }
  }
  toggleCollapse(): void {
    this.collapsed = !this.collapsed;
  }

  toggleInnerCollapse(key: string): void {
    if (this.collapsedItems[key] === undefined) {
      this.collapsedItems[key] = true;
    } else {
      this.collapsedItems[key] = !this.collapsedItems[key];
    }
  }

  isObject(value: any): boolean {
    return value && typeof value === 'object' && value.constructor === Object;
  }

  isArray(value: any): boolean {
    return Array.isArray(value);
  }
  isPrimitive = (val) => {
    return val === Object(val) ? false : true;
  };
  copyToClipboard(text, event) {
    event.stopPropagation();
    this.clipboard.copy(this.isPrimitive(text) ? text : JSON.stringify(text));
    this.toastService.info('Copied to Clipboard!');
  }
}
