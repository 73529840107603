import { Component, Input, OnInit } from '@angular/core';
import { DynamicComponentBase, FilterItem, IAction } from '@shared/classes';
import { BaseRequestControllerService } from '@shared/services/api/base-request-controller.service';
import { Observable, Subject, debounceTime, distinctUntilChanged, of, switchMap } from 'rxjs';

@Component({
  selector: 'app-search-select',
  templateUrl: './search-select.component.html',
  styleUrls: ['./search-select.component.scss'],
})
export class SearchSelectComponent extends DynamicComponentBase implements OnInit {
  @Input() showAddItem: boolean = false;
  @Input() showAddItemAction: IAction;
  @Input() projectionFields = ['id', 'code', 'name', 'label'];
  requestService: BaseRequestControllerService<any>;
  private searchText$ = new Subject<string>();
  searchItems$: Observable<any[]>;
  searchQ;

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.onFilterInputChange();
  }

  setInputOptions(): void {}
  search(keywords: string) {
    this.searchText$.next(keywords);
  }

  onSelectItem(event) {
    this.searchQ = '';
  }
  onFilterInputChange() {
    this.searchText$
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        switchMap((keywords) => {
          return this.requestService.search(
            { page: 0, size: 20 },
            {
              projectionFields: this.projectionFields,
              filters: this.getFilters(keywords),
            }
          );
        })
      )
      .subscribe((res) => {
        if (res.content.length <= 0) {
          this.showAddItem = true;
        } else {
          this.showAddItem = false;
        }

        this.searchItems$ = of(res.content);
      });
  }

  getFilters(keywords): FilterItem[] {
    return keywords
      ? [
          { property: 'name', operation: 'CONTAINS', value: keywords },
          { property: 'fieldCategory', operation: 'EQUAL', value: 'TEMPLATE' },
        ]
      : [{ property: 'fieldCategory', operation: 'EQUAL', value: 'TEMPLATE' }];
  }
}
