import { Component, Input, OnInit } from '@angular/core';
import { BaseViewItem } from '@shared/classes';

@Component({
  selector: 'app-knob-progress-view',
  templateUrl: './knob-progress-view.component.html',
  styleUrls: ['./knob-progress-view.component.scss'],
})
export class KnobProgressViewComponent extends BaseViewItem implements OnInit {
  @Input() min: number = 0;
  @Input() max: number = 100;
  @Input() size: number = 100; //Size in pixels
  @Input() strokeWidth: number = 14;
  @Input() valueColor: string = null;
  @Input() rangeColor: string = 'var(--surface-200)';
  @Input() textColor: string = 'var(--text-color)';
  @Input() showValue: boolean = true;
  @Input() valueTemplate: string = '{value}%';
  @Input() disabled: boolean = false;
  @Input() readonly: boolean = false;
  @Input() colorScheme: { value: number; color: string }[] = [
    { value: 25, color: 'var(--red-400)' },
    { value: 75, color: 'var(--yellow-400)' },
    { value: 100, color: 'var(--green-400)' },
  ]; //Enter Values in sorted form ex : [{value : 10 , color:"red"} , {value: 20, color:"blue"}]
  value: number = 0;
  constructor() {
    super();
  }

  ngOnInit(): void {}
  onSetData(): void {
    this.valueColor = this.colorScheme?.find((x) => x?.value >= this.data)?.color || null;
    this.value = this.data;
  }
  onValueChanges(val) {
    this.valueColor = this.colorScheme?.find((x) => x?.value >= val)?.color || null;
  }
}
