import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ViewModeService } from '@shared/services/view-mode.service';
import { BaseForm } from 'app/shared/classes/view/BaseForm';

@Component({
  selector: 'app-form-example',
  templateUrl: './form-example.component.html',
  styleUrls: ['./form-example.component.scss'],
})
export class FormExampleComponent extends BaseForm<ExampleForm> implements OnInit {
  constructor(public viewModeService: ViewModeService) {
    super(viewModeService);
  }

  ngOnInit(): void {}

  getData() {
    return this.formGroup.getRawValue();
  }

  setData(data: ExampleForm) {
    // if (!data) return;
    this.formGroup.patchValue({ ...data });
    this.data = data;
  }

  initFormStructure(): void {
    this.formGroup = new FormGroup({
      text: new FormControl(null, Validators.required),
      email: new FormControl(null, [Validators.required, Validators.email]),
      checkbox: new FormControl(true),
      slider: new FormControl(false),
      radioGroup: new FormControl('option 2'),
      textArea: new FormControl('', Validators.required),
      textEditor: new FormControl(null, Validators.required),
      number: new FormControl(null, Validators.required),
      date: new FormControl(null, Validators.required),
      dropdown: new FormControl(null, Validators.required),
      multiDropdown: new FormControl(null, Validators.required),
    });
  }
}

interface ExampleForm {
  text: string;
  email: string;
  checkbox: any;
  slider: any;
  radioGroup: any;
  textArea: string;
  textEditor: string;
  number: number;
  date: Date;
  dropdown: any;
  multiDropdown: any;
}
