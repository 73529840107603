<app-form-repeater
  [viewMode]="viewMode"
  [title]="title"
  [cols]="cols"
  [loading]="loading"
  [control]="control"
  [showOptionsAction]="showOptionsAction"
  [showDeleteButton]="showDeleteButton"
  [showAddForm]="showAddForm"
  [showReorder]="showReorder"
  [showSelection]="showSelection"
  [offlineConfig]="offlineConfig"
  [pageInfo]="pageInfo"
  [rowsPerPageOptions]="rowsPerPageOptions"
  (onSelectedItemsChange)="onSelectedItemsChangeEvent($event)"
  (normalMode)="normalModeEvent($event)"
  [flyMode]="flyMode"
  (inlineEdit)="inlineEditEvent($event)"
  (filtersChanged)="onFiltersChanged($event)"
  (lazyLoad)="onLazyLoad($event)"
  [cellsStyle]="cellsStyle"
  [selectionMode]="selectionMode"
  [floatActions]="floatActions"
  [actionBodyClass]="actionBodyClass"
  [actionHeaderClass]="actionHeaderClass"
  [headerStyle]="headerStyle"
  [tableStyle]="tableStyle"
></app-form-repeater>
