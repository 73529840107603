import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UnsubscribeOnDestroyAdapter, getPathByCode } from '@shared/classes';

@Component({
  selector: 'app-app-path-redirect',
  templateUrl: './app-path-redirect.component.html',
  styleUrls: ['./app-path-redirect.component.scss'],
})
export class AppPathRedirectComponent extends UnsubscribeOnDestroyAdapter {
  constructor(
    private route: ActivatedRoute,
    private router: Router
  ) {
    super();
    this.subs.sink = this.route.queryParams.subscribe((params) => {
      const path = getPathByCode(params['code'], params['mode']);

      this.router.navigateByUrl(path);
    });
  }
}
