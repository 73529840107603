import { Component, OnInit } from '@angular/core';
import { BaseViewItem } from '@shared/classes';

@Component({
  selector: 'app-responsibility-one-all-mode',
  templateUrl: './responsibility-one-all-mode.component.html',
  styleUrls: ['./responsibility-one-all-mode.component.css'],
})
export class ResponsibilityOneAllModeComponent extends BaseViewItem implements OnInit {
  constructor() {
    super();
  }

  ngOnInit(): void {}
}
