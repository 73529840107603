<app-input-view-switch
  [showLabelInViewMode]="showLabelInViewMode"
  [mode]="viewMode"
  [data]="data?.name || control?.value?.name"
  [dataType]="dataType"
  [label]="label"
  [keepValueCallback]="setFieldResetable.bind(this)"
  [resetFieldCheckVisable]="resetFieldCheckVisable"
  [resetableChecked]="resetable"
>
  <app-drop-down-input
    [label]="label ?? 'Issues'"
    [placeholder]="'Search Issues'"
    [name]="'dropdown'"
    [optionLabel]="'name'"
    [items]="items"
    [optionValue]="optionValue"
    [control]="fControl"
    [multi]="multi"
    [dataKey]="dataKey"
    [lazy]="true"
    [loading]="isLoading"
    [onFilterCommand]="onFilter.bind(this)"
    [floatLabel]="floatLabel"
    [customFilter]="true"
  >
    <ng-template #resetCheckTemplate>
      @if (resetFieldCheckVisable) {
        <app-reset-switch-input [checked]="!resetable" (onChange)="setFieldResetable($event)"></app-reset-switch-input>
      }
    </ng-template>
  </app-drop-down-input>
</app-input-view-switch>
