import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { IAction, RiskMethodology } from '@shared/classes';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-inherited-risk-value-field-popup',
  templateUrl: './inherited-risk-value-field-popup.component.html',
  styleUrls: ['./inherited-risk-value-field-popup.component.scss'],
})
export class InheritedRiskValueFieldPopupComponent implements OnInit {
  loading: boolean = true;
  questions = [];
  riskMeth: RiskMethodology;
  formControl: FormControl = new FormControl(null);
  takeAction: IAction = {
    id: 1,
    label: 'Add Risk Item',
    buttonType: 'button',
    command: this.onSubmit.bind(this),
    icon: 'pi pi-save',
    buttonClass: '',
    color: 'primary',
  };
  cancelAction: IAction = {
    id: 2,
    label: 'Cancel',
    buttonType: 'button',
    command: this.onCancel.bind(this),
    icon: 'pi pi-times',
    buttonClass: '',
    color: 'secondary',
  };
  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig
  ) {
    this.riskMeth = this?.config?.data?.riskMeth;
    this?.config?.data?.data ? this.formControl.patchValue(this?.config?.data?.data) : null;
  }

  ngOnInit() {}

  onSubmit() {
    this.ref.close(this.formControl.value);
  }

  onCancel() {
    this.ref.close(null);
  }
}
