import { Component, Input, OnInit } from '@angular/core';
import { DataTypeEnum, DynamicComponentBase, ITablePageable, PolicyDto } from '@shared/classes';
import { PolicyDataService } from 'app/modules/org-framework/service/data/PolicyDataService';

@Component({
  selector: 'app-policy-selector',
  templateUrl: './policy-selector.component.html',
  styleUrls: ['./policy-selector.component.scss'],
})
export class PolicySelectorComponent extends DynamicComponentBase implements OnInit {
  @Input() multi: boolean = true;
  @Input() optionValue: string = 'id';
  @Input() dataKey: string = null;

  constructor(private service: PolicyDataService) {
    super();
  }

  items: any[] = [];
  pageInfo: ITablePageable = new ITablePageable();
  isLoading: boolean = false;
  dataType: DataTypeEnum = DataTypeEnum.CodeLink;

  ngOnInit(): void {
    this.getOptions();
    if (this.control) {
      this.subs.sink = this.fControl.valueChanges.subscribe((changes) => {
        this.onChanges.emit(changes);
      });
    }
  }

  setInputOptions() {
    this.multi = this.inputOptions?.dropDownInput?.multi ?? this.multi;
  }

  getOptions(filters: any[] = []) {
    this.isLoading = true;
    this.subs.sink = this.service
      .search<PolicyDto>(this.pageInfo.pagination, { filters: filters }, { showLoading: false, showMsg: false })
      .subscribe({
        next: (res) => {
          this.items = res.content;
          this.pageInfo.totalElements = res.totalElements;
        },
        complete: () => {
          this.isLoading = false;
        },
      });
  }

  onFilter(event) {
    this.getOptions([{ property: 'name', operation: 'CONTAINS', value: event }]);
  }
}
