import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { AuditProjectDto, DynamicComponentBase } from '@shared/classes';

@Component({
  selector: 'app-audit-case-input-item-form',
  templateUrl: './audit-case-input-item-form.component.html',
  styleUrls: ['./audit-case-input-item-form.component.scss'],
})
export class AuditCaseInputItemFormComponent extends DynamicComponentBase implements OnInit {
  @Input() auditProject: AuditProjectDto;

  constructor() {
    super();
  }
  ngOnInit(): void {}
  setInputOptions(): void {
    this.auditProject = this.inputOptions?.extra?.auditProject ?? this.auditProject;
  }
}
