import { Component, ContentChild, Input, OnInit, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import {
  ContentViewDto,
  IColumn,
  UnsubscribeOnDestroyAdapter,
  getProp
} from '@shared/classes';
import {
  AppDialogService,
  BaseFieldDefinitionsService,
  BaseRequestControllerService,
  PathResolverService,
  ToastService,
} from '@shared/services';

@Component({
  selector: 'app-widget-count',
  templateUrl: './widget-count.component.html',
  styleUrl: './widget-count.component.scss',
})
export class WidgetCountComponent extends UnsubscribeOnDestroyAdapter implements OnInit {
  @ContentChild('headerTemplate') headerTemplate: TemplateRef<any>;
  @Input() styleClass: string = 'relative card m-0';
  @Input() title: string = 'some title';
  @Input() isLoading: boolean = true;
  private _cardContent: ContentViewDto = null;
  @Input() set cardContent(cardContent: ContentViewDto) {
    this._cardContent = cardContent;
    this.initData();
  }
  get cardContent() {
    return this._cardContent;
  }
  textColor = null;
  bgColor = null;
  mappingService: BaseFieldDefinitionsService = null;
  dataService: BaseRequestControllerService<any> = null;
  fields: IColumn[] = [];
  data: any = null;
  constructor(
    public appDialogService: AppDialogService,
    public router: Router,
    private pathResolverService: PathResolverService,
    private toastService: ToastService
  ) {
    super();
  }
  ngOnInit(): void {}
  initData() {
    if (this.cardContent) {
      this.textColor = this.cardContent.color;
      this.bgColor = this.lightenColor(this.cardContent.color, 70);
      this.mappingService = this.pathResolverService.getMappingServiceByTargetType(this.cardContent?.targetType as any);
      this.dataService = this.pathResolverService.getDataServiceByTargetType(this.cardContent?.targetType as any);
      this.subs.sink = this.dataService
        .aggregate(
          { ...this.cardContent.filterView.aggregationBody, groupByFields: [] },
          { showLoading: false, showMsg: false }
        )
        .subscribe({
          next: (res) => {
            this.data =
              res?.aggregation_value?.[0]?.[
                this.cardContent?.filterView?.aggregationBody?.aggregationFields?.[0]?.fieldName + '_' + this.cardContent?.filterView?.aggregationBody?.aggregationFields?.[0]?.operation?.toLowerCase()
              ] ?? 0;
            this.isLoading = false;
          },
          error: (err) => {
            this.isLoading = false;
          },
        });
    }
    //     this.fields = this.cardContent?.recordField?.map(x=> {
    //         return {
    //             ...getTableColumnDefinitionFromField(this.mappingService.mappedFields?.[x.key]),
    //             options:{
    //                 ...getTableColumnDefinitionFromField(this.mappingService.mappedFields?.[x.key])?.options,
    //                 colSpan:x.colSpan
    //             }
    //         }
    //     })
    //     this.subs.sink = this.dataService.search({page:0,size:1},{projectionFields:this.cardContent?.recordField?.map(x=>x.key),filters:[{ 'property': 'code', 'operation': 'EQUAL', 'value': this.cardContent.recordCode}]}).subscribe({
    //         next:(res)=>{
    //             if(res?.content?.length > 0){
    //                 this.data = res?.content?.[0];
    //             }else{
    //                 this.toastService.error('Not Found!',`Record With Code ${this.cardContent.recordCode} Not Found`);
    //             }
    //         },
    //         error:(err)=>{
    //             // this.toastService.error('Not Found!',`Record With Code ${this.cardContent.recordCode} Not Found`);
    //         }
    //     })
    // }
  }
  getElementValue(key: string, data: any) {
    let value = data[key];
    value = getProp(data, key);
    return value;
  }
  lightenColor(hex: string, percent: number): string {
    // Ensure that hex is in the correct format
    if (hex.indexOf('#') === 0) {
      hex = hex.slice(1);
    }

    if (hex.length === 3) {
      hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
    }

    // Convert to decimal and change luminosity
    const num = parseInt(hex, 16);
    const r = (num >> 16) + Math.round(((255 - (num >> 16)) * percent) / 100);
    const g = ((num >> 8) & 0x00ff) + Math.round(((255 - ((num >> 8) & 0x00ff)) * percent) / 100);
    const b = (num & 0x0000ff) + Math.round(((255 - (num & 0x0000ff)) * percent) / 100);

    const newColor = '#' + (0x1000000 + (r << 16) + (g << 8) + b).toString(16).slice(1).toUpperCase();

    return newColor;
  }
}
