import { ChartViewConfig } from '@shared/classes/model';
import { EChartsOption } from 'echarts';
import { LineChart } from './line-chart';

export class AreaChart extends LineChart {
  smooth = false;
  areaStyle = {};
  stack = 'total';
  chartOptions: EChartsOption = {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross',
        label: {
          backgroundColor: '#6a7985',
        },
      },
      appendTo: 'body',
    },
    xAxis: [
      {
        type: 'category',
        data: [],
      },
    ],
    yAxis: {
      type: 'value',
    },
    series: [
      {
        data: [],
        type: 'line',
        smooth: this.smooth,
        areaStyle: this.areaStyle
      },
    ],
  };
  chartType: ChartViewConfig.ChartTypeEnum = 'AREA';
}
