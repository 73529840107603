import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AppEnumIcons, AuditTrailDto, BadgeColor, IAction, PaginationModel } from '@shared/classes';
import { BaseViewItem } from '@shared/classes/view/BaseViewItem';

@Component({
  selector: 'app-timeline-view',
  templateUrl: './timeline-view.component.html',
  styleUrls: ['./timeline-view.component.scss'],
})
export class TimelineViewComponent extends BaseViewItem<AuditTrailDto[]> implements OnInit, AfterViewInit {
  @ViewChild('componentContainer', { static: true }) componentContainer: ElementRef;
  iconMap = AppEnumIcons;
  colorMap = BadgeColor;
  events = [
    {
      i: 1,
      color: '#FFFFFF',
      icon: 'sf sf-assets',
    },
    {
      i: 2,
      color: '#FFFFFF',
      icon: 'sf sf-assets',
    },
    {
      i: 3,
      color: '#FFFFFF',
      icon: 'sf sf-assets',
    },
    {
      i: 4,
      color: '#FFFFFF',
      icon: 'sf sf-assets',
    },
    {
      i: 5,
      color: '#FFFFFF',
      icon: 'sf sf-assets',
    },
    {
      i: 6,
      color: '#FFFFFF',
      icon: 'sf sf-assets',
    },
    {
      i: 7,
      color: '#FFFFFF',
      icon: 'sf sf-assets',
    },
    {
      i: 8,
      color: '#FFFFFF',
      icon: 'sf sf-assets',
    },
    {
      i: 9,
      color: '#FFFFFF',
      icon: 'sf sf-assets',
    },
    {
      i: 10,
      color: '#FFFFFF',
      icon: 'sf sf-assets',
    },
    {
      i: 11,
      color: '#FFFFFF',
      icon: 'sf sf-assets',
    },
    {
      i: 12,
      color: '#FFFFFF',
      icon: 'sf sf-assets',
    },
    {
      i: 13,
      color: '#FFFFFF',
      icon: 'sf sf-assets',
    },
    {
      i: 14,
      color: '#FFFFFF',
      icon: 'sf sf-assets',
    },
    {
      i: 15,
      color: '#FFFFFF',
      icon: 'sf sf-assets',
    },
    {
      i: 16,
      color: '#FFFFFF',
      icon: 'sf sf-assets',
    },
  ];

  @Input() pageData: PaginationModel<AuditTrailDto>;

  @Input() isLoading: boolean = true;

  @Output() onLoadMore: EventEmitter<number> = new EventEmitter();

  loadMoreAction: IAction = {
    id: 1,
    label: 'Load More',
    buttonType: 'button',
    command: this.loadMore.bind(this),
    icon: 'pi pi-angle-down',
    color: 'secondary',
    buttonStyle: 'text',
    buttonClass: 'w-full',
  };
  constructor(
    private breakpointObserver: BreakpointObserver,
    private el: ElementRef
  ) {
    super();
  }

  isLargeScreen = true;

  ngOnInit(): void {
    this.breakpointObserver.observe([Breakpoints.Large, Breakpoints.XLarge]).subscribe((result) => {
      // this.isLargeScreen = result.matches;
    });
  }

  loadMore() {
    if (this.onLoadMore) {
      this.onLoadMore.emit(this.pageData.number + 1);
    }
  }
  private resizeObserver: ResizeObserver;
  ngAfterViewInit(): void {
    this.resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        this.checkSize(entry.contentRect.width);
      }
    });
    this.resizeObserver.observe(this.el.nativeElement);
  }

  onDestroy(): void {
    this.resizeObserver.unobserve(this.el.nativeElement);
  }

  private checkSize(width: number): void {
    if (width >= 800) {
      this.isLargeScreen = true;
    } else {
      this.isLargeScreen = false;
    }
  }
}
