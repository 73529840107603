import { Component, Input, OnInit } from '@angular/core';
import { DataTypeEnum, DynamicComponentBase, ITablePageable, RiskStatementCategory } from '@shared/classes';
import { RiskStatementCategoryDataService } from 'app/modules/risk/services/data/risk-statement-category-data.service';

@Component({
  selector: 'app-risk-statement-category-selector',
  templateUrl: './risk-statement-category-selector.component.html',
  styleUrls: ['./risk-statement-category-selector.component.scss'],
})
export class RiskStatementCategorySelectorComponent extends DynamicComponentBase implements OnInit {
  @Input() multi: boolean = true;
  @Input() optionValue: string = 'id';

  constructor(private service: RiskStatementCategoryDataService) {
    super();
  }
  items: any[] = [];
  pageInfo: ITablePageable = new ITablePageable();
  isLoading: boolean = false;
  dataType: DataTypeEnum = DataTypeEnum.Text;
  ngOnInit(): void {
    this.getOptions();
  }

  setInputOptions() {
    this.multi = this.inputOptions?.dropDownInput?.multi ?? this.multi;
  }
  getOptions(filters: any = []) {
    this.isLoading = true;
    this.subs.sink = this.service
      .search<RiskStatementCategory>(
        this.pageInfo.pagination,
        { filters: filters },
        { showLoading: false, showMsg: false }
      )
      .subscribe({
        next: (res) => {
          this.items = res.content;
          this.pageInfo.totalElements = res.totalElements;
        },
        complete: () => {
          this.isLoading = false;
        },
      });
  }
  onFilter(event) {
    this.getOptions([{ property: 'name', operation: 'CONTAINS', value: event }]);
  }
}
