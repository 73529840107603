<div class="flex gap-2">
  @for (item of data; track item) {
    <div>
      <app-view-switch
        [data]="checkType(item?.value) ? item : item?.value"
        [viewType]="gvlDataType"
        [options]="{
          showNumeric: showNumeric,
          showDescription: showDescription,
        }"
      >
      </app-view-switch>
    </div>
  }
</div>
