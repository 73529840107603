<app-input-view-switch
  [showLabelInViewMode]="showLabelInViewMode"
  [mode]="viewMode"
  [dataType]="dataType"
  [label]="label"
>
  <p-table [value]="data" #myTable>
    <ng-template pTemplate="header">
      <tr class="mb-3">
        <th class="">
          @if (!disableSelectAll && data?.length > 0) {
            <p-checkbox
              [binary]="true"
              [(ngModel)]="allChecked"
              (ngModelChange)="checkAll($event)"
              [inputId]="key"
            ></p-checkbox>
          } @else {
            <div
              style="
                width: 20px;
                height: 20px;
                background: rgba(255, 255, 255, 0.05);
                border-radius: var(--border-radius);
              "
            ></div>
          }
        </th>
        @for (col of cols; track col) {
          <th class="align-items-start justify-content-start white-space-nowrap">
            {{ col.name }}
          </th>
        }
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-product let-rowIndex="rowIndex">
      <tr class="">
        @if (!product[disableCheckOptionKey]) {
          <td class="">
            <p-checkbox
              [binary]="true"
              [(ngModel)]="product[checkOptionValue]"
              (onChange)="onCheckboxChange($event, rowIndex)"
              [inputId]="rowIndex + key"
            ></p-checkbox>
          </td>
        } @else {
          <td class="" style="max-width: 24rem; min-width: 11rem">
            <div
              style="
                width: 20px;
                height: 20px;
                background: rgba(255, 255, 255, 0.05);
                border-radius: var(--border-radius);
              "
            ></div>
          </td>
        }

        @for (col of cols; track col) {
          <td
            class="align-items-start justify-content-start border-bottom-1 surface-border"
            style="max-width: 24rem; min-width: 11rem"
          >
            <div class="w-full">
              <app-view-switch
                [data]="product[col.key]"
                [options]="col.options"
                [viewType]="col.dataType"
              ></app-view-switch>
            </div>
          </td>
        }
      </tr>
    </ng-template>
  </p-table>
</app-input-view-switch>
