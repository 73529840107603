import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@env/environment';
import { RequestHandlerOptions } from '@shared/classes';
import { BaseRequestControllerService } from '@shared/services/api/base-request-controller.service';
import { DataService } from '@shared/services/api/data.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CheckItemDataService extends BaseRequestControllerService<any> {
  constructor(
    private dataService: DataService,
    private router: Router
  ) {
    super(dataService, environment.framework, '/check-items', ['id', 'code', 'tags',
      'tagsCodes', 'name', 'relatedTo']);
  }

  createBulk(body: any, options: RequestHandlerOptions = new RequestHandlerOptions()): Observable<any> {
    return this.dataService.postData(`${this.url}/bulk`, body, options) as Observable<any>;
  }
}
