import { Component, ContentChild, Input, OnInit, TemplateRef } from '@angular/core';
import { FormControl } from '@angular/forms';
import { DataTypeEnum, DynamicComponentBase } from '@shared/classes';
import { LazyLoadEvent } from 'primeng/api';
import { Subject, SubscriptionLike, debounceTime, distinctUntilChanged } from 'rxjs';

@Component({
  selector: 'app-pick-list-input',
  templateUrl: './pick-list-input.component.html',
  styleUrls: ['./pick-list-input.component.scss'],
})
export class PickListInputComponent extends DynamicComponentBase implements OnInit {
  originalElements: any[] = [];
  sourceElements: any[] = [];

  @Input() targetElements: any[] = [];

  dataType: DataTypeEnum = DataTypeEnum.Text;
  @Input() editable = false;
  @Input() multi = false;
  private _items: any[] = [];
  currentChangesSub: SubscriptionLike;
  @Input() set items(items: any[]) {
    this._items = items;
  }
  @Input() optionLabel: string;
  @Input() showClear = true;
  @Input() optionValue: string; //@Note: only 1 field is bindable through primeng, if we need to modify the object a lot more then consider changing the formcontrol into onSelect changes
  private _badgeView = false;
  @Input() set badgeView(badgeView) {
    this._badgeView = badgeView;
    this.dataType = this.badgeView ? DataTypeEnum.Badge : DataTypeEnum.Text;
  }
  get badgeView() {
    return this._badgeView;
  }
  @Input() viewType: DataTypeEnum = DataTypeEnum.Text;
  @Input() dataKey = null;

  @Input() virtualScroll: boolean; //@TODO the implementation for virtual scroll should be remapped to handle pagination
  @Input() virtualScrollItemSize: number = 38;
  @Input() lazy: boolean = false;
  @Input() onLazyLoadCommand: Function = (event: LazyLoadEvent) => {};
  @Input() onFilterCommand: Function = (event: any) => {};
  @Input() loading: boolean = false;
  @Input() customFilter: boolean = false;
  @Input() showFilter: boolean = true;
  @Input() showStatusBadge: boolean = true;
  @Input() appendTo: string = null;
  @Input() linkedControl: FormControl = null;
  @Input() linkedControlValue: any = null;
  @Input() useCustomBadgeMode: boolean = false;
  @Input() customBadgeColorField: string = 'color';
  @Input() customBadgeIconField: string = 'icon';
  @Input() group: boolean = false;
  @Input() listBoxMode: boolean = false;
  dataTypes = DataTypeEnum;

  public sourceFilterValue: string;
  sourceFilterValueUpdate = new Subject<string>();
  public targetFilterValue: string;
  targetFilterValueUpdate = new Subject<string>();
  @ContentChild('itemTemplate') itemTemplate: TemplateRef<any>;
  typesControl: FormControl = new FormControl(null);
  constructor() {
    super();
    this.sourceFilterValueUpdate.pipe(debounceTime(500), distinctUntilChanged()).subscribe((value) => {
      this.onFilterCommand(value);
    });
    this.targetFilterValueUpdate.pipe(debounceTime(500), distinctUntilChanged()).subscribe((value) => {
      this.onFilterCommand(value);
    });
  }

  ngOnInit(): void {}
  onPickListChange() {
    this.fControl.patchValue(this.targetElements);
  }

  setInputOptions() {
    this.multi = this.inputOptions?.dropDownInput?.multi ?? this.multi;
    this.items = this.inputOptions?.dropDownInput?.items ?? this.items;
    this.optionLabel = this.inputOptions?.dropDownInput?.optionLabel ?? this.optionLabel;
    this.showClear = this.inputOptions?.dropDownInput?.showClear ?? this.showClear;
    this.optionValue = this.inputOptions?.dropDownInput?.optionValue ?? this.optionValue;
    this.badgeView = this.inputOptions?.dropDownInput?.badgeView ?? this.badgeView;
    this.dataKey = this.inputOptions?.dropDownInput?.dataKey ?? this.dataKey;

    this.dataType = this.badgeView ? DataTypeEnum.Badge : DataTypeEnum.Text;

    this.virtualScroll = this.inputOptions?.dropDownInput?.virtualScroll ?? this.virtualScroll;
    this.virtualScrollItemSize = this.inputOptions?.dropDownInput?.virtualScrollItemSize ?? this.virtualScrollItemSize;
    this.lazy = this.inputOptions?.dropDownInput?.lazy ?? this.lazy;
    this.loading = this.inputOptions?.dropDownInput?.loading ?? this.loading;
    this.onLazyLoadCommand = this.inputOptions?.dropDownInput?.onLazyLoadCommand ?? this.onLazyLoadCommand;
    this.onFilterCommand = this.inputOptions?.dropDownInput?.onFilterCommand ?? this.onFilterCommand;
    this.customFilter = this.inputOptions?.dropDownInput?.customFilter ?? this.customFilter;
    this.showFilter = this.inputOptions?.dropDownInput?.showFilter ?? this.showFilter;
    this.appendTo = this.inputOptions?.dropDownInput?.appendTo ?? this.appendTo;
    this.showStatusBadge = this.inputOptions?.dropDownInput?.showStatusBadge ?? this.showStatusBadge;
    this.useCustomBadgeMode = this.inputOptions?.dropDownInput?.useCustomBadgeMode ?? this.useCustomBadgeMode;
    this.customBadgeColorField = this.inputOptions?.dropDownInput?.customBadgeColorField ?? this.customBadgeColorField;
    this.customBadgeIconField = this.inputOptions?.dropDownInput?.customBadgeIconField ?? this.customBadgeIconField;

    this.linkedControl = this.inputOptions?.linkedControl ?? this.linkedControl;
    this.linkedControlValue = this.inputOptions?.linkedControlValue ?? this.linkedControlValue;
    // if(this.inputOptions?.dropDownInput?.defaultOption){
    //     if(this.multi){
    //         this.fControl?.patchValue([this.inputOptions?.dropDownInput?.defaultOption])
    //     }else{
    //         this.fControl?.patchValue(this.inputOptions?.dropDownInput?.defaultOption)
    //     }
    // }
    if (this.linkedControl) {
      if (this.currentChangesSub) {
        this.currentChangesSub.unsubscribe();
      }
      this.currentChangesSub = this.linkedControl.valueChanges.subscribe((value) => {
        this.linkedControlValue = value;
      });
      this.subs.sink = this.currentChangesSub;
    }
    this.formatGetItems = this.inputOptions?.dropDownInput?.formatGetItems ?? this.formatGetItems;
  }
  logData(event) {}
  @Input() formatGetItems(items: any[], linkedControlValue?: any): any[] {
    return items;
  }
  get items() {
    return this.formatGetItems(this._items, this.linkedControlValue);
  }
}
