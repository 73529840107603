import { Component, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { DomHandler } from 'primeng/dom';
import { TabMenu } from 'primeng/tabmenu';

@Component({
  selector: 'app-tab-menu',
  templateUrl: './tab-menu.component.html',
  styleUrl: './tab-menu.component.scss',
})
export class TabMenuComponent extends TabMenu implements OnChanges, OnInit {
  @ViewChild(TabMenu) tabMenuRef: TabMenu;

  ngOnInit(): void {}
  ngAfterViewInit() {
    this.tabMenuRef.updateButtonState = (): void => {
      const content = this.tabMenuRef.content?.nativeElement;
      const { scrollLeft, scrollWidth } = content;
      const width: number = DomHandler.getWidth(content);
      this.tabMenuRef.backwardIsDisabled = scrollLeft === 0;
      this.tabMenuRef.forwardIsDisabled = scrollWidth - width - scrollLeft < 2;
    };
    this.tabMenuRef.ngAfterViewInit();
  }
  //Fix for updating state when items (model) are changed
  ngOnChanges(changes: SimpleChanges) {
    if (changes['model'] || changes.activeItem) {
      // this.ngAfterViewInit();
      this.tabMenuRef?.ngAfterViewInit();
    }
  }
  //Fix for correctly hide/show scroll buttons
  // updateButtonState(): void {
  //   const content = this.tabMenuRef?.content?.nativeElement;
  //   const { scrollLeft, scrollWidth } = content;
  //   const width:number = DomHandler.getWidth(content);
  //   this.tabMenuRef.backwardIsDisabled = scrollLeft === 0;
  //   this.tabMenuRef.forwardIsDisabled = Math.ceil(scrollLeft) === scrollWidth - width;
  // }
  onActiveItemChange(event) {
    this.activeItem = event;
  }
}
