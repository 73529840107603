import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BaseForm } from '@shared/classes';

@Component({
  selector: 'app-folder-bucket-form',
  templateUrl: './folder-bucket-form.component.html',
  styleUrls: ['./folder-bucket-form.component.scss'],
})
export class FolderBucketFormComponent extends BaseForm<any> implements OnInit {
  ngOnInit(): void {}
  getData() {
    return { ...this.formGroup.getRawValue() };
  }
  setData(data: any) {
    this.formGroup.patchValue({ ...data });
  }
  initFormStructure(): void {
    this.formGroup = new FormGroup({
      name: new FormControl(null, Validators.required),
    });
  }
}
