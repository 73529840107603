<form [formGroup]="formGroup" (ngSubmit)="onSubmitForm()">
  <div class="formgrid grid p-fluid">
    <div class="col-12">
      <app-basic-input
        label="Name"
        [placeholder]="'Enter Name'"
        [control]="formGroup?.controls?.name"
        [viewMode]="fieldViewMode"
      ></app-basic-input>
    </div>
  </div>
  <div class="flex flex-row-reverse justify-content-between align-items-center">
    <div class="flex flex-row-reverse gap-2">
      <!-- <app-button *ngIf="showSaveButton" [action]="submitButtonAction"></app-button> -->
      @if (showSaveButton) {
        <app-button [action]="submitSaveButtonAction"></app-button>
      }
      <!-- <app-button *ngIf="showInPlaceButton" [action]="submitInPlaceButtonAction"></app-button> -->
      <!-- <app-button *ngIf="fieldViewMode == 'create'" [action]="cancelButtonAction"></app-button> -->
    </div>
    <!-- <app-switch-input
    *ngIf="fieldViewMode == 'create'"
    [label]="'general.messages.create another record' | translate"
    [name]="'inPlaceCreate'" [labelWidthFit]="true"
    [control]="inPlaceCreateControl"
    [viewMode]="'create'"
    [styleClass]="'mb-0'"
    >
  </app-switch-input> -->
  </div>
</form>
