import { DOCUMENT } from '@angular/common';
import {
  AfterContentInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  Injector,
  OnChanges,
  OnInit,
  SimpleChanges,
  forwardRef,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, NgControl } from '@angular/forms';
import { DynamicComponentBase } from '@shared/classes';
import { toUpper } from 'lodash-es';
export const WRAPPED_NUMBER_INPUT_VALUE_ACCESSOR: any = {
  provide: [NG_VALUE_ACCESSOR, DynamicComponentBase],
  useExisting: forwardRef(() => WrappedNumberInputComponent),
  multi: true,
};

@Component({
  selector: 'app-wrapped-number-input',
  templateUrl: './wrapped-number-input.component.html',
  styleUrls: ['./wrapped-number-input.component.scss'],

  //   changeDetection: ChangeDetectionStrategy.OnPush, // this is to stop auto value detection and send events manually
  providers: [WRAPPED_NUMBER_INPUT_VALUE_ACCESSOR],
  //   encapsulation: ViewEncapsulation.None, // this is for styles encapsulation
})
export class WrappedNumberInputComponent
  extends DynamicComponentBase
  implements OnInit, AfterContentInit, OnChanges, ControlValueAccessor
{
  private ngControl: NgControl | null = null; // assign the current formControl to have access to all options ex: errors, dirty, hasValidator ...etc
  value: any; // end value of the control component
  public changed: (value: any) => void;
  public touched: () => void;
  public isDisabled: boolean;
  constructor(
    @Inject(DOCUMENT) private document: Document,
    public el: ElementRef,
    private cd: ChangeDetectorRef,
    private readonly injector: Injector
  ) {
    super();
  }

  setInputOptions(): void {
    // for dynamic generation of the input
  }
  ngOnInit(): void {
    this.ngControl = this.injector.get(NgControl, null, { optional: true });

    // use this when you are handling the case of multiple inputs within a single control
    // this.ngControl.control.markAsTouched  = () => updateFormControlTree(this.form);
  }
  ngAfterContentInit(): void {
    // for custom templates assignments, EX: pre-item-template
  }
  ngOnChanges(changes: SimpleChanges): void {
    //for checks on @Input() items changes
  }
  writeValue(value: any): void {
    this.value = toUpper(value);
    // this.cd.markForCheck(); // if ChangeDetectionStrategy is OnPush then it's needed
  }
  onInputValueChange(value: any) {
    this.changed(value);
  }
  registerOnChange(fn: any): void {
    this.changed = fn;
  }
  registerOnTouched(fn: any): void {
    this.touched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
    // this.cd.markForCheck(); // if ChangeDetectionStrategy is OnPush then it's needed
  }
}
