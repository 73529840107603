import { ChartViewConfig } from '@shared/classes/model';
import { AggregateFunctionOptions } from '../../../echart-base';
import { EchartVariationBase } from '../../../echart-variation-base';
import { getGroupFieldIdKey, groupData } from '../../../helpers/general-functions';
import { VerticalBarChart } from '../../base/vertical-bar-chart';

export class StackBarChart extends VerticalBarChart implements EchartVariationBase {
  variation: ChartViewConfig.ChartVariationEnum = 'STACKED';
  //   chartOptions: EChartsOption = {
  //     xAxis: {
  //       type: 'category',
  //       data: [],
  //     },
  //     yAxis: {
  //       type: 'value',
  //     },
  //     series: [
  //       {
  //         data: [],
  //         type: 'line',
  //         smooth: true,
  //       },
  //     ],
  //   };
  checkVariationCondition(variation: ChartViewConfig.ChartVariationEnum): boolean {
    return true;
  }
  formatAggregateData(options: AggregateFunctionOptions) {
    if (options?.payload?.groupByFields?.length > 1) {
      const data = groupData(
        options?.response?.aggregation_value,
        options?.payload?.groupByFields,
        options?.drillDownAggregationField
      );
      let secondGroupValues = {};
      options?.response?.aggregation_value?.forEach((val) => {
        secondGroupValues[val?.group_id?.[getGroupFieldIdKey(options?.payload?.groupByFields?.[1])] || 'EMPTY_VALUE'] =
          secondGroupValues[val?.group_id?.[getGroupFieldIdKey(options?.payload?.groupByFields?.[1])] || 'EMPTY_VALUE']
            ? {
                ...secondGroupValues[val?.group_id?.[getGroupFieldIdKey(options?.payload?.groupByFields?.[1])] || 'EMPTY_VALUE'],
                [val?.group_id?.[getGroupFieldIdKey(options?.payload?.groupByFields?.[0])] || 'EMPTY_VALUE']:
                  val?.[options?.drillDownAggregationField],
              }
            : {
                [val?.group_id?.[getGroupFieldIdKey(options?.payload?.groupByFields?.[0])] || 'EMPTY_VALUE']:
                  val?.[options?.drillDownAggregationField],
              };
        // secondGroupValues[val?.group_id?.[getGroupFieldIdKey(options?.payload?.groupByFields?.[1])]] ? [...secondGroupValues[val?.group_id?.[getGroupFieldIdKey(options?.payload?.groupByFields?.[1])]],{[val?.group_id?.[getGroupFieldIdKey(options?.payload?.groupByFields?.[0])]]:val?.[getGroupFieldIdKey(options?.payload?.groupByFields?.[1])+'_count']}] : [{[val?.group_id?.[getGroupFieldIdKey(options?.payload?.groupByFields?.[0])]]:val?.[getGroupFieldIdKey(options?.payload?.groupByFields?.[1])+'_count']}];
      });
      let ret = [];
      // const rawData: number[][] = [];
      // data.forEach((item, ind) => {
      //   rawData.push(
      //     Object.entries(secondGroupValues).map(([key, value]) => {
      //       return value?.[item?.name] || 0;
      //     })
      //   );
      // });
      // const totalData = [];
      // for (let i = 0; i < rawData[0].length; ++i) {
      //   let sum = 0;
      //   for (let j = 0; j < rawData.length; ++j) {
      //     sum = sum + (rawData[j][i] || 0);
      //   }
      //   totalData.push(sum);
      // }
      data.forEach((item, ind) => {
        let series = {
          data: Object.entries(secondGroupValues).map(([key, value]) => {
            return value?.[item?.key] || 0;
          }),
          type: 'bar',
          // barWidth: '60%',
          stack: 'total',
          label: {
            show: true,
            formatter: function (params):string {
              return params.value != 0 ? params.value.toString() : '';
            },
          },
          name: item.name,
        };
        ret.push(series);
      });
      return {
        data: [{ series: ret }, { xAxis: Object.keys(secondGroupValues) }],
        chartTitle: null,
      };
    } else {
      return this.aggregateToSingleDimension(options);
    }
  }
}
