import { Component, Input, OnInit } from '@angular/core';
import { BaseViewItem, DataTypeEnum } from '@shared/classes';

@Component({
  selector: 'app-slider-view',
  templateUrl: './slider-view.component.html',
  styleUrls: ['./slider-view.component.scss'],
})
export class SliderViewComponent extends BaseViewItem implements OnInit {
  @Input() viewType: DataTypeEnum = DataTypeEnum.Slider;
  @Input() options: any;

  value: number = 100;

  options1 = {
    floor: 0,
    ceil: 200,
  };

  dataTypes = DataTypeEnum;

  constructor() {
    super();
  }

  ngOnInit(): void {}
}
