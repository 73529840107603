import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-link-view',
  templateUrl: './link-view.component.html',
  styleUrl: './link-view.component.scss',
})
export class LinkViewComponent implements OnInit {
  private _href: string = null;
  @Input() icon: string = null;
  @Input() set href(href: string) {
    this._href = href;
    this.isExternal = !this.href.startsWith(this.baseHref);
  }
  get href() {
    return this._href;
  }
  isExternal: boolean = false;
  private baseHref: string;

  constructor(private router: Router) {
    this.baseHref = window.location.origin;
  }

  ngOnInit(): void {}

  navigate(event: MouseEvent): void {
    if (!this.isExternal) {
      event.preventDefault();
      this.router.navigateByUrl(this.href.replace(this.baseHref, ''));
    }
  }
}
