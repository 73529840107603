<app-base-page [breadCrumb]="breadCrumb" [pageActions]="pageActions" [pageActionsCommandData]="pageActionsCommandData">
  <ng-container page-header>
    <h4>Example table with filters</h4>
  </ng-container>
  <app-table
    [cols]="cols"
    [tableActions]="tableActions"
    [actionCol]="actionCol"
    [inputData]="tableData"
    (filtersChanged)="onFilterChange($event)"
    [pageInfo]="tableEvent.pageInfo"
    (onExportData)="loadExportData($event)"
    (onFixRelationAction)="onFixRelations($event)"
    [pageActions]="pageActions"
    [pageActionsCommandData]="pageActionsCommandData"
    [sessionStorageKey]="sessionStorageKey"
    [isLoading]="isLoading"
  ></app-table>
</app-base-page>
