<div
  class="shadow-2 border-round surface-card mb-3 h-full flex-column justify-content-between flex"
  [style]="
    showColoredBorder && color
      ? 'border-top: 8px solid ' + color + '!important'
      : 'border-top: 8px solid transparent !important'
  "
>
  <div class="p-4">
    <div class="flex align-items-center justify-content-between">
      <div class="flex align-items-center gap-3 min-w-0">
        @if (icon) {
          <span
            [class]="'inline-flex border-circle align-items-center justify-content-center'"
            [style]="{ backgroundColor: bgColor }"
            style="width: 76px; height: 76px; min-width: 76px"
          >
            <i [class]="icon + ' text-5xl '" [style]="{ color: solidColor }"></i>
          </span>
        }
        <span
          [pTooltip]="title"
          tooltipPosition="top"
          class="text-900 font-medium text-2xl line-clamp-1-ellipsis min-w-0"
          >{{ title }}</span
        >
      </div>

      @if (showValue) {
        <div class="line-height-4 text-4xl">{{ value }}</div>
      }

      <ng-content select="[header-actions]"></ng-content>
    </div>
    <!-- <div class="text-900 my-3 text-xl font-medium"></div> -->
    @if (description) {
      <p class="mt-0 mb-3 text-700 line-height-3">
        {{ description }}
      </p>
    }
    <ng-content></ng-content>
  </div>
  @if (action) {
    <div class="px-4 py-3 surface-100 text-right">
      <app-button [action]="action" [commandData]="actionData"></app-button>
    </div>
  }
  @if (!action && showActionFooter) {
    <div class="px-4 py-3 surface-100 text-right flex flex-row gap-3 justify-content-end">
      <ng-content></ng-content>
    </div>
  }
</div>
