<div class="card">
  <div class="formgrid grid p-fluid w-full">
    @for (field of fields; track field) {
      <div class="col-12">
        <!-- <app-view-switch [viewType]="field?.dataType"
            [data]="field?.passRowAsData ? data : getElementValue(field?.key, data)"
            [options]="field?.options">
          </app-view-switch> -->
        <app-input-view-switch
          [showLabelInViewMode]="true"
          [mode]="'view'"
          [data]="field?.tableDefinition?.passRowAsData ? data : getElementValue(field?.key, data)"
          [dataType]="field?.dataType"
          [label]="field?.name"
          [options]="field?.tableDefinition?.options"
        >
        </app-input-view-switch>
      </div>
    }
  </div>
</div>
