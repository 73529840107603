import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import {
  DataTypeEnum,
  DynamicComponentBase,
  DynamicFieldConfig,
  FieldDto,
  IAction,
  IDynamicComponent,
  getFieldValidators,
} from '@shared/classes';
import { GvlSelectorComponent } from '@shared/components/selectors/gvl-selector/gvl-selector.component';
import { TargetCodeSelectorComponent } from '@shared/components/selectors/target-code-selector/target-code-selector.component';
import sortBy from 'lodash-es/sortBy';
import { Observable, Subject, takeUntil } from 'rxjs';
import { AttachmentInputComponent } from '../attachment-input/attachment-input.component';
import { BasicInputComponent } from '../basic-input/basic-input.component';
import { IpInputComponent } from '../customised-fields/ip-input/ip-input.component';
import { IP6InputComponent } from '../customised-fields/ip6-input/ip6-input.component';
import { Ipv4MaskInputComponent } from '../customised-fields/ipv4-mask-input/ipv4-mask-input.component';
import { MacInputComponent } from '../customised-fields/mac-input/mac-input.component';
import { DateInputComponent } from '../date-input/date-input.component';
import { DropDownInputComponent } from '../drop-down-input/drop-down-input.component';
import { ImageInputComponent } from '../image-input/image-input.component';
import { MCQComponent } from '../mcq/mcq.component';
import { NumberInputComponent } from '../number-input/number-input.component';
import { RatingInputComponent } from '../rating-input/rating-input.component';
import { SwitchInputComponent } from '../switch-input/switch-input.component';
import { TextEditorComponent } from '../text-editor/text-editor.component';

@Component({
  selector: 'app-dynamic-field-list-input-custom',
  templateUrl: './dynamic-field-list-input-custom.component.html',
  styleUrls: ['./dynamic-field-list-input-custom.component.scss'],
  providers: [{ provide: DynamicComponentBase, useExisting: DynamicFieldListInputCustomComponent }],
})
export class DynamicFieldListInputCustomComponent extends DynamicComponentBase implements OnInit {
  dataType: DataTypeEnum = DataTypeEnum.Text;
  dynamicFields: IDynamicComponent[] = [];

  notImageFields: IDynamicComponent[] = [];

  private formValid = new Subject<boolean>();
  private _config;

  @Input() set config(conf: { dynamicFields: FieldDto[]; dynamicFieldValues: { [key: string]: any } }) {
    this._config = conf;
    this.initInputFields();
  }
  get config() {
    return this._config;
  }
  @Input() loadingFields: boolean = false;
  @Input() showGetValueButton: boolean = false;
  @Input() showPopupEditButton: boolean = false;
  @Input() set showGetValueButtonText(showGetValueButtonText: string) {
    this.getFieldValueAction.label = showGetValueButtonText;
    this.getFieldValueAction = { ...this.getFieldValueAction };
  }
  @Input() set getValueButtonLoader(getValueButtonLoader: Observable<boolean>) {
    this.getFieldValueAction.loading$ = getValueButtonLoader;
    this.getFieldValueAction = { ...this.getFieldValueAction };
  }
  getFieldValueAction: IAction = {
    id: 2,
    label: 'Save',
    buttonType: 'button',
    command: this.getFieldValue.bind(this),
    icon: 'pi pi-save',
  };
  showEditPopupAction: IAction = {
    id: 3,
    label: 'Edit',
    buttonType: 'button',
    command: this.showDialog.bind(this),
    icon: 'pi pi-pencil',
  };

  showNotImageEditPopupAction: IAction = {
    id: 3,
    label: 'Edit',
    buttonType: 'button',
    command: this.showNotImageDialog.bind(this),
    icon: 'pi pi-pencil',
  };

  @Output() onGetFieldValue: EventEmitter<any> = new EventEmitter();

  constructor(private fb: FormBuilder) {
    super();
  }

  ngOnInit(): void {
    // this.initInputFields();
  }
  getFieldValue(field: IDynamicComponent) {
    this.onGetFieldValue.emit({
      value: field?.options?.control?.value,
      key: field.options?.name,
    });
  }
  setInputOptions() {}
  initInputFields() {
    let fields: IDynamicComponent[] = [];

    this.fControl.statusChanges.pipe(takeUntil(this.destroy$)).subscribe((res) => {
      this.formValid.next(res == 'VALID');
    });

    (this.fControl as any as FormGroup)?.reset();

    Object.keys((this.fControl as any as FormGroup).controls).forEach((key) => {
      (this.fControl as any as FormGroup).removeControl(key);
    });

    if (!(this.config?.dynamicFields == null || this.config?.dynamicFields == undefined))
      fields = Object.keys(this.config.dynamicFields)?.map((key, i) => {
        const field = this.config.dynamicFields[key];

        if (
          (this.fControl as any as FormGroup).get(field.key) === null ||
          (this.fControl as any as FormGroup).get(field.key) === undefined
        ) {
          (this.fControl as any as FormGroup).addControl(
            field.key,
            new FormGroup({
              field: new FormControl(field.code),
              value: new FormControl(
                this.config?.dynamicFieldValues?.[field.key]?.value ?? null,
                getFieldValidators(field?.options)
              ),
            })
          );
        } else {
          (this.fControl as any as FormGroup).patchValue({
            [field.key]: {
              field: field.code,
              value: this.config?.dynamicFieldValues?.[field.key]?.value ?? null,
            },
          });
        }
        return {
          componentType: DynamicFieldMap[field.type],
          options: {
            label: field.label,
            name: field.key,
            control: ((this.fControl as any as FormGroup).controls?.[field.key] as FormGroup)?.controls?.value,
            inputOptions: {
              numberInput: {
                maxFractionDigits:
                  field.type == 'INTEGER' ? 0 : (field?.options?.[DynamicFieldConfig.NUMBER_MAX_FRACTIONS] ?? 5),
                showButtons: true,
                min: field?.options?.[DynamicFieldConfig.GENERAL_MIN] ?? undefined,
                max: field?.options?.[DynamicFieldConfig.GENERAL_MAX] ?? undefined,
              },
              attachmentInput: {
                bucketId: field?.options?.[DynamicFieldConfig.ATTACHMENT_FOLDER] ?? 'root',
                mode: 'basic',
                multiple: field?.options?.[DynamicFieldConfig.ATTACHMENT_MULTIPLE] ?? false,
                accept: field?.options?.[DynamicFieldConfig.ATTACHMENT_ACCEPT] ?? null,
                maxFileSize: field?.options?.[DynamicFieldConfig.ATTACHMENT_MAX_FILE_SIZE] ?? null,
                fileLimit: field?.options?.[DynamicFieldConfig.ATTACHMENT_FILE_LIMIT] ?? null,
              },
              dropDownInput: {
                items: field?.options?.[DynamicFieldConfig.LIST_OPTIONS]
                  ? (field?.options?.[DynamicFieldConfig.LIST_OPTIONS] as string[]).map((x) => {
                      return { label: x, value: x };
                    })
                  : [],
                multi: field.type == FieldDto.TypeEnum.Multiselect ? true : false,
                optionLabel: 'label',
                optionValue: 'value',
              },
              dateInput: {
                minDate: field?.options?.[DynamicFieldConfig.MIN_DATE]
                  ? new Date(field?.options?.[DynamicFieldConfig.MIN_DATE])
                  : null,
                maxDate: field?.options?.[DynamicFieldConfig.MAX_DATE]
                  ? new Date(field?.options?.[DynamicFieldConfig.MAX_DATE])
                  : null,
                showTime: field.type == FieldDto.TypeEnum.Datetime ? true : false,
                selectionMode: field?.options?.[DynamicFieldConfig.DATE_SELECTION_MODE] ?? 'single',
                mode: field?.options?.[DynamicFieldConfig.DATE_VIEW_MODE] ?? 'date',
              },
              ratingInput: {
                maxNumber: field?.options?.[DynamicFieldConfig.RATING_MAX_NUMBER] ?? 5,
              },
              radioInput: {
                groupItems: field?.options?.[DynamicFieldConfig.LIST_OPTIONS]
                  ? (field?.options?.[DynamicFieldConfig.LIST_OPTIONS] as string[]).map((x) => {
                      return { label: x, value: x };
                    })
                  : [],
                selectionMode: field?.options?.[DynamicFieldConfig.SINGLE_SELECTION_MODE] ?? 'radio',
              },
              codeSelectorInput: {
                targetTypes: field?.options?.[DynamicFieldConfig.RELATION_TARGET_TYPE]
                  ? [field?.options?.[DynamicFieldConfig.RELATION_TARGET_TYPE]]
                  : null,
                // relationType:
                //     field?.options?.[
                //         DynamicFieldConfig.RELATION_TYPE
                //     ] ? [field?.options?.[
                //         DynamicFieldConfig.RELATION_TYPE
                //     ]] : null,
              },
              imageInput: {
                maintainAspectRatio: field?.options?.[DynamicFieldConfig.MAINTAIN_ASPECT_RATIO] ?? null,
                aspectRatio: field?.options?.[DynamicFieldConfig.ASPECT_RATIO] ?? null,
                format: field?.options?.[DynamicFieldConfig.OUTPUT_IMAGE_FORMAT] ?? null,
              },
              extra: field,
              networkInput: {
                defaultBlockValues: field?.options?.[DynamicFieldConfig.NETWORK_DEFAULT_BLOCK_VALUES] ?? null,
                disabledBlocks: field?.options?.[DynamicFieldConfig.NETWORK_DISABLED_BLOCKS] ?? null,
              },
              gvlInput: {
                gvlCode: field?.options?.[DynamicFieldConfig.GVL_CODE] ?? null,
                gvlListMode: field?.options?.[DynamicFieldConfig.GVL_LIST_MODE] ?? false,
                gvlSelectionMode: field?.options?.[DynamicFieldConfig.GVL_SELECTION_MODE] ?? false,
                gvlInlineMode: field?.options?.[DynamicFieldConfig.GVL_INLINE_MODE] ?? false,
                gvlMinSelection: field?.options?.[DynamicFieldConfig.GENERAL_MIN_LENGTH] ?? null,
                gvlMaxSelection: field?.options?.[DynamicFieldConfig.GENERAL_MAX_LENGTH] ?? null,
                gvlShowDescription: field?.options?.[DynamicFieldConfig.GVL_SHOW_DESCRIPTION] ?? null,
                gvlShowNumeric: field?.options?.[DynamicFieldConfig.GVL_SHOW_NUMERIC] ?? null,
              },
            },
            inputMask: field?.options?.[DynamicFieldConfig.INPUT_MASK] ?? null,
            viewMode: this.viewMode,
            id: field?.options?.[DynamicFieldConfig.ORDER] ?? i,
            data: this.config?.dynamicFieldValues?.[field.key]?.value ?? null,
          },
        };
      });
    // }

    if (fields) {
      fields = sortBy(fields, 'options.id');
      this.dynamicFields = [...fields];

      this.notImageFields = this.dynamicFields.filter((el) => el.componentType != ImageInputComponent);
      this.dynamicFields = this.dynamicFields.filter((el) => el.componentType == ImageInputComponent);
    } else {
      this.dynamicFields = [];
      this.notImageFields = [];
    }

    setTimeout(() => {
      this.formValid.next((this.fControl as any as FormGroup).valid);
    }, 1);
  }
  popupActiveDynamicFieldIndex: number = 0;
  displayDialog: boolean = false;
  showDialog(fieldIndex: number) {
    this.popupActiveDynamicFieldIndex = fieldIndex;
    this.displayDialog = true;
  }

  popupActiveNotImageDynamicFieldIndex: number = 0;
  displayNotImageDialog: boolean = false;
  showNotImageDialog(fieldIndex: number) {
    this.popupActiveNotImageDynamicFieldIndex = fieldIndex;
    this.displayNotImageDialog = true;
  }

  isHovered: boolean[] = [];

  onMouseOver(index: number) {
    // Set the corresponding index to true when hovering over
    this.isHovered[index] = true;
    event.stopPropagation();
  }

  onMouseOut(index: number) {
    // Set the corresponding index to false when hovering out
    this.isHovered[index] = false;
    event.stopPropagation();
  }
}
export const DynamicFieldMap = {
  ['INTEGER']: NumberInputComponent,
  ['DOUBLE']: NumberInputComponent,
  ['BOOLEAN']: SwitchInputComponent,
  ['STRING']: BasicInputComponent,
  ['NUMBER']: NumberInputComponent,
  ['SINGLE_SELECTION']: MCQComponent,
  ['ATTACHMENT']: AttachmentInputComponent,
  ['IP']: IpInputComponent,
  ['IP6']: IP6InputComponent,
  ['MAC']: MacInputComponent,
  ['IPV4_MASK']: Ipv4MaskInputComponent,
  ['DATE']: DateInputComponent,
  ['DATETIME']: DateInputComponent,
  ['MULTISELECT']: DropDownInputComponent,
  ['RATING']: RatingInputComponent,
  ['LONG_TEXT']: TextEditorComponent,
  ['RELATION']: TargetCodeSelectorComponent,
  ['IMAGE']: ImageInputComponent,
  ['GROUP_VALUE_LIST']: GvlSelectorComponent,
};
