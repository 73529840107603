import { Component, ContentChild, Input, OnInit, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { UnsubscribeOnDestroyAdapter, ContentViewDto } from '@shared/classes';
import { AppDialogService, BaseFieldDefinitionsService, PathResolverService } from '@shared/services';

@Component({
  selector: 'app-widget-links',
  templateUrl: './widget-links.component.html',
  styleUrl: './widget-links.component.scss',
})
export class WidgetLinksComponent extends UnsubscribeOnDestroyAdapter implements OnInit {
  @ContentChild('headerTemplate') headerTemplate: TemplateRef<any>;
  @Input() styleClass: string = 'relative card m-0';
  @Input() title: string = 'some title';
  private _cardContent: ContentViewDto = null;
  @Input() set cardContent(cardContent: ContentViewDto) {
    this._cardContent = cardContent;
    this.initData();
  }
  get cardContent() {
    return this._cardContent;
  }
  mappingService: BaseFieldDefinitionsService = null;
  constructor(
    public appDialogService: AppDialogService,
    public router: Router,
    private pathResolverService: PathResolverService
  ) {
    super();
  }
  ngOnInit(): void {}
  initData() {
    if (this.cardContent) {
      // this.mappingService = this.pathResolverService.getMappingServiceByTargetType(this.cardContent?.targetType as any);
    }
  }
}
