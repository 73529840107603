<app-input-view-switch
  [showLabelInViewMode]="showLabelInViewMode"
  [mode]="viewMode"
  [data]="data || control?.value?.label || control?.value?.name || control?.value?.value || control?.value"
  [dataType]="dataType"
  [label]="label"
  [keepValueCallback]="setFieldResetable.bind(this)"
  [resetFieldCheckVisable]="resetFieldCheckVisable"
  [resetableChecked]="resetable"
>
  <span class="field w-full" [class.p-float-label]="floatLabel">
    <p-treeSelect
      [(ngModel)]="selectedNodes"
      [ngModelOptions]="{ standalone: true }"
      selectionMode="multiple"
      [metaKeySelection]="false"
      [options]="items"
      (onNodeSelect)="onNodeSelect($event)"
      (onNodeUnselect)="onNodeUnselect($event)"
      (ngModelChange)="onModelChange($event)"
    ></p-treeSelect>
    @if (floatLabel) {
      <label [for]="name">
        {{ label }}
        @if (isControlRequired) {
          <span class="text-red-500"> *</span>
        }
        @if (resetFieldCheckVisable) {
          <br />
          <app-reset-switch-input
            [checked]="!resetable"
            (onChange)="setFieldResetable($event)"
          ></app-reset-switch-input>
        }
      </label>
    }
  </span>
</app-input-view-switch>
