import { ChangeDetectorRef, Directive, Input } from '@angular/core';
import { ValidationErrors } from '@angular/forms';
import { BaseControlValueAccessor } from './base-contorl-value-accessor.model';
import { Field } from './field';

/**
 * @TODO Make this class more generic for all kinds of control value accessor components
 */
@Directive()
export abstract class BaseEditableDynamicComponent extends BaseControlValueAccessor {
  @Input() mode: 'IMPLICIT' | 'DYNAMIC';
  @Input() label: string;
  @Input() isMultiple: boolean;
  @Input() options: any;
  @Input() placeholder: string = 'Search for a user';
  @Input() set field(field: any) {
    this._field = new Field(field);
    this.label = this.field.label;
    this.isMultiple = this.field.options?.isMultiple;
    this.options = this.field.options;
    this.placeholder = this.field.placeholder;
  }
  ngModelHolder;

  get field(): Field {
    return this._field;
  }

  protected _field: Field;

  constructor(protected cd?: ChangeDetectorRef) {
    super();
  }

  combineValidatorErrors(...args: ValidationErrors[]): ValidationErrors | null {
    if (!args?.length) {
      return null;
    }

    return args.reduce((acc, curr) => {
      if (curr) {
        return { ...acc, ...curr };
      } else {
        return acc;
      }
    }, {});
  }
}
