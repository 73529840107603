import { ChartViewConfig } from '@shared/classes/model';
import { EchartVariationBase } from '../../../echart-variation-base';
import { VerticalBarChart } from '../../base/vertical-bar-chart';

export class PolarLogarithmicBarChart extends VerticalBarChart implements EchartVariationBase {
  variation: ChartViewConfig.ChartVariationEnum = 'POLAR_LOGARITHMIC';
  //   chartOptions: EChartsOption = {
  //     xAxis: {
  //       type: 'category',
  //       data: [],
  //     },
  //     yAxis: {
  //       type: 'value',
  //     },
  //     series: [
  //       {
  //         data: [],
  //         type: 'line',
  //         smooth: true,
  //       },
  //     ],
  //   };
  checkVariationCondition(variation: ChartViewConfig.ChartVariationEnum): boolean {
    return true;
  }
}
