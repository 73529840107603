<form class="w-full" [formGroup]="form">
  <p-autoComplete
    placeholder="Search Entity Groups..."
    formControlName="entity"
    styleClass="w-full"
    [(ngModel)]="searchQ"
    (onSelect)="onSelectItem($event)"
    [suggestions]="searchQuestions$ | async"
    (completeMethod)="search($event.query)"
    [dropdown]="true"
    field="name"
  >
    <ng-template let-option pTemplate="item">
      <div class="flex flex-1 gap-2">
        <app-badge-item
          [styleClass]="'text-xs'"
          [status]="option?.recordStatus || 'cyan'"
          [text]="option.code"
        ></app-badge-item>
        <div>{{ option.name }}</div>
      </div>
    </ng-template>
  </p-autoComplete>

  @if (searchQ?.name) {
    <div class="my-5">
      <span class="text-lg">Entities Will be added to Selected Entities when clicking on apply button:</span>
      <div class="mt-5">
        @for (item of searchQ.entities; track item) {
          <p-chip styleClass="mr-2 my-2" label="{{ item }}"></p-chip>
        }
      </div>
    </div>
  }

  @if (!searchQ?.name) {
    <div class="my-5">
      <span class="text-lg">No Filter selected.</span>
      <br />
      <br />
      <br />
      <br />
    </div>
  }
  <div class="flex flex-wrap m-0 justify-content-end">
    <app-button [action]="submitButton"></app-button>
  </div>
</form>
