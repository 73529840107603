<form [formGroup]="formGroup" (ngSubmit)="onSubmitForm()">
  <div class="formgrid grid p-fluid">
    <div class="col-12 md:col-12">
      <app-user-and-group-selector
        [label]="'Users'"
        [placeholder]="'Search Users'"
        [control]="formGroup?.controls?.principle"
        [multi]="false"
        [viewMode]="fieldViewMode"
      >
      </app-user-and-group-selector>
    </div>
  </div>
  <!-- <ng-content></ng-content> -->
  <div class="flex flex-row-reverse gap-2">
    <!-- <app-button *ngIf="fieldViewMode != 'view'  && showSaveAndClose" [action]="submitButtonAction"></app-button>
        <app-button *ngIf="showSaveButton" [action]="submitSaveButtonAction"></app-button> -->
  </div>
</form>
