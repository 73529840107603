import { Component, Input, OnInit } from '@angular/core';
import { BaseViewItem, RelationTypeDto } from '@shared/classes';

@Component({
  selector: 'app-relation-type-cell',
  templateUrl: './relation-type-cell.component.html',
  styleUrls: ['./relation-type-cell.component.scss'],
})
export class RelationTypeCellComponent extends BaseViewItem<RelationTypeDto> implements OnInit {
  private _value;
  private _relationTypes;
  get value() {
    return this._value;
  }
  get relationTypes() {
    return this._relationTypes;
  }
  @Input() set value(value) {
    this._value = value;
    this.data = this.getMatchingRelation(this.relationTypes, value);
  }
  @Input() set relationTypes(relationTypes: RelationTypeDto[]) {
    if (relationTypes) {
      this._relationTypes = relationTypes;
      this.data = this.getMatchingRelation(this.relationTypes, this.value);
    }
  }
  @Input()
  fullWidth: boolean = false;

  constructor() {
    super();
  }

  ngOnInit(): void {}
  getMatchingRelation(relationTypes: RelationTypeDto[], name: string) {
    return relationTypes?.find((x) => x.name == name);
  }
}
