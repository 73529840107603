import { Component, OnInit } from '@angular/core';
import { DynamicComponentBase } from '@shared/classes';

@Component({
  selector: 'app-mail-to-input',
  templateUrl: './mail-to-input.component.html',
  styleUrl: './mail-to-input.component.scss'
})
export class MailToInputComponent  extends DynamicComponentBase implements OnInit {
  constructor() {
    super();
  }
  ngOnInit(): void {}
  setInputOptions(): void {}
}
