import { ChartViewConfig } from '@shared/classes/model';
import { EChartsOption } from 'echarts';
import { AggregateFunctionOptions, AssignChartDataOptions, EchartBase } from '../../echart-base';
import { getGroupFieldIdKey, groupData } from '../../helpers/general-functions';

export class LineChart extends EchartBase {
  smooth = false;
  areaStyle = null;
  stack = null;
  useNullInsteadOfZero = true;
  chartOptions: EChartsOption = {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross',
        label: {
          backgroundColor: '#6a7985',
        },
      },
      appendTo: 'body',
    },
    xAxis: [
      {
        type: 'category',
        data: [],
      },
    ],
    yAxis: {
      type: 'value',
    },
    series: [
      {
        data: [],
        type: 'line',
        smooth: this.smooth,
      },
    ],
  };
  chartType: ChartViewConfig.ChartTypeEnum = 'LINE';
  assignDataToChartOptions(options: AssignChartDataOptions): EChartsOption {
    let optionsCopy = { ...options?.chartOptions };
    const seriesObj = options?.data?.find((x) => x?.['series']);
    const xAxisObj = options?.data?.find((x) => x?.['xAxis']);
    if (seriesObj) {
      optionsCopy.series = seriesObj.series;
      optionsCopy.xAxis[0].data = xAxisObj.xAxis;
    } else {
      optionsCopy.series[0].name = options?.name;
      optionsCopy.series[0].data = options?.data.map((x, i) => {
        return { ...x };
      });
      optionsCopy.xAxis[0].data = options?.data.map((x, i) => {
        return x.name;
      });
    }
    return optionsCopy;
  }
  formatAggregateData(options: AggregateFunctionOptions) {
    if (options?.payload?.groupByFields?.length > 1) {
      const data = groupData(
        options?.response?.aggregation_value,
        options?.payload?.groupByFields,
        options?.drillDownAggregationField
      );
      let secondGroupValues = {};
      options?.response?.aggregation_value?.forEach((val) => {
        secondGroupValues[val?.group_id?.[getGroupFieldIdKey(options?.payload?.groupByFields?.[1])] || 'EMPTY_VALUE'] =
          secondGroupValues[val?.group_id?.[getGroupFieldIdKey(options?.payload?.groupByFields?.[1])] || 'EMPTY_VALUE']
            ? {
                ...secondGroupValues[
                  val?.group_id?.[getGroupFieldIdKey(options?.payload?.groupByFields?.[1])] || 'EMPTY_VALUE'
                ],
                [val?.group_id?.[getGroupFieldIdKey(options?.payload?.groupByFields?.[0])] || 'EMPTY_VALUE']:
                  val?.[options?.drillDownAggregationField],
              }
            : {
                [val?.group_id?.[getGroupFieldIdKey(options?.payload?.groupByFields?.[0])] || 'EMPTY_VALUE']:
                  val?.[options?.drillDownAggregationField],
              };
        // secondGroupValues[val?.group_id?.[getGroupFieldIdKey(options?.payload?.groupByFields?.[1])]] ? [...secondGroupValues[val?.group_id?.[getGroupFieldIdKey(options?.payload?.groupByFields?.[1])]],{[val?.group_id?.[getGroupFieldIdKey(options?.payload?.groupByFields?.[0])]]:val?.[getGroupFieldIdKey(options?.payload?.groupByFields?.[1])+'_count']}] : [{[val?.group_id?.[getGroupFieldIdKey(options?.payload?.groupByFields?.[0])]]:val?.[getGroupFieldIdKey(options?.payload?.groupByFields?.[1])+'_count']}];
      });
      let ret = [];
      data.forEach((item) => {
        let series = {
          data: Object.entries(secondGroupValues).map(([key, value]) => {
            return value?.[item?.key] || (this.useNullInsteadOfZero ? null : 0);
          }),
          type: 'line',
          smooth: this.smooth,
          name: item.name,
          areaStyle: this.areaStyle,
          stack: this.stack,
          connectNulls: true,
          emphasis: {
            focus: 'series',
          },
          label: {
            show: true, // Display values on data points
            position: 'top', // Position of the value label
            textStyle: {
              fontSize: 12, // Customize font size if needed
            },
          },
        };
        ret.push(series);
      });
      return {
        data: [{ series: ret }, { xAxis: Object.keys(secondGroupValues) }],
        chartTitle: null,
      };
    } else {
      return this.aggregateToSingleDimension(options);
    }
  }
}
