import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControlStatus } from '@angular/forms';
import { Router } from '@angular/router';
import { AppPermissions, IAction } from '@shared/classes';
import { BulkOperation } from '@shared/classes/view/BulkOperation';
import { ViewModeService } from '@shared/services';
import { BulkOperationService } from '@shared/services/bulk-operation-service/bulk-operation.service';
import { Observable, of } from 'rxjs';

@Component({
  selector: 'app-bulk-operation-choice-step',
  templateUrl: './bulk-operation-choice-step.component.html',
  styleUrls: ['./bulk-operation-choice-step.component.scss'],
})
export class BulkOperationChoiceStepComponent implements OnInit, OnDestroy {
  public formValid = new Observable<boolean>();
  nextButton: IAction = {
    id: 1,
    label: 'Next',
    buttonType: 'submit',
    icon: 'pi pi-angle-right',
    iconPos: 'right',
    command: this.nextPage.bind(this),
    permission: AppPermissions.ReadGeography,
  };
  constructor(
    public stepperService: BulkOperationService,
    private router: Router,
    public viewModeService: ViewModeService
  ) {
    if (!this.stepperService.items?.length) {
      this.stepperService.returnToList();
    }
  }
  basicInfo;
  ngOnInit(): void { }
  nextPage() {
    if (this.stepperService.operation == BulkOperation.Delete) {
      this.stepperService.goToStep3();

    } else {
      this.stepperService.goToStep2();
    }
  }
  prevPage() {
    this.stepperService.goToStep1();
  }
  onSubmit(data) {
    this.stepperService.operation = data.operation;
    this.nextPage();
  }
  formStatusChanged(formState: FormControlStatus) {
    setTimeout(() => {
      this.formValid = of(formState == 'VALID');
    }, 1);
  }
  formChanges(data) {
    this.stepperService.operation = data.operation;
    this.basicInfo = data;
  }
  ngOnDestroy(): void {
    if (this.basicInfo) this.stepperService.operation = this.basicInfo.operation;
  }
}
