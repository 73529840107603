import { Component, OnInit } from '@angular/core';
import { BaseViewItem } from '@shared/classes';

@Component({
  selector: 'app-currency-view',
  templateUrl: './currency-view.component.html',
  styleUrls: ['./currency-view.component.scss'],
})
export class CurrencyViewComponent extends BaseViewItem implements OnInit {
  currency: string = '';
  value: string = '0';
  constructor() {
    super();
  }

  onSetData(): void {
    if (this.data) {
      this.currency = currencySymbols[this.data.currency];
      this.value = this.data.value;
    }
  }
  ngOnInit(): void {}
}

export const currencySymbols = {
  AED: 'AED', // United Arab Emirates Dirham
  AFN: 'AFN', // Afghan Afghani
  ALL: 'L', // Albanian Lek
  AMD: 'AMD', // Armenian Dram
  ANG: 'ƒ', // Netherlands Antillean Guilder
  AOA: 'Kz', // Angolan Kwanza
  ARS: '$', // Argentine Peso
  AUD: '$', // Australian Dollar
  AWG: 'ƒ', // Aruban Florin
  AZN: '₼', // Azerbaijani Manat
  BAM: 'КМ', // Bosnia-Herzegovina Convertible Mark
  BBD: '$', // Barbadian Dollar
  BDT: '৳', // Bangladeshi Taka
  BGN: 'лв', // Bulgarian Lev
  BHD: 'BHD', // Bahraini Dinar
  BIF: 'FBu', // Burundian Franc
  BMD: '$', // Bermudian Dollar
  BND: '$', // Brunei Dollar
  BOB: 'Bs.', // Bolivian Boliviano
  BRL: 'R$', // Brazilian Real
  BSD: '$', // Bahamian Dollar
  BTN: 'Nu.', // Bhutanese Ngultrum
  BWP: 'P', // Botswana Pula
  BYN: 'Br', // Belarusian Ruble
  BZD: '$', // Belize Dollar
  CAD: '$', // Canadian Dollar
  CDF: 'FC', // Congolese Franc
  CHF: 'CHF', // Swiss Franc
  CLP: '$', // Chilean Peso
  CNY: '¥', // Chinese Yuan Renminbi
  COP: '$', // Colombian Peso
  CRC: '₡', // Costa Rican Colon
  CUP: '$', // Cuban Peso
  CVE: '$', // Cape Verdean Escudo
  CZK: 'Kč', // Czech Koruna
  DJF: 'Fdj', // Djiboutian Franc
  DKK: 'kr', // Danish Krone
  DOP: 'RD$', // Dominican Peso
  DZD: 'DZD', // Algerian Dinar
  EGP: '£', // Egyptian Pound
  ERN: 'Nfk', // Eritrean Nakfa
  ETB: 'Br', // Ethiopian Birr
  EUR: '€', // Euro
  FJD: 'FJ$', // Fijian Dollar
  FKP: '£', // Falkland Islands Pound
  FOK: 'kr', // Faroese Króna
  GBP: '£', // British Pound Sterling
  GEL: '₾', // Georgian Lari
  GGP: '£', // Guernsey Pound
  GHS: '₵', // Ghanaian Cedi
  GIP: '£', // Gibraltar Pound
  GMD: 'D', // Gambian Dalasi
  GNF: 'FG', // Guinean Franc
  GTQ: 'Q', // Guatemalan Quetzal
  GYD: '$', // Guyanese Dollar
  HKD: '$', // Hong Kong Dollar
  HNL: 'L', // Honduran Lempira
  HRK: 'kn', // Croatian Kuna
  HTG: 'G', // Haitian Gourde
  HUF: 'Ft', // Hungarian Forint
  IDR: 'Rp', // Indonesian Rupiah
  ILS: '₪', // Israeli New Shekel
  IMP: '£', // Isle of Man Pound
  INR: '₹', // Indian Rupee
  IQD: 'IQD', // Iraqi Dinar
  IRR: 'IRR', // Iranian Rial
  ISK: 'kr', // Icelandic Króna
  JEP: '£', // Jersey Pound
  JMD: 'J$', // Jamaican Dollar
  JOD: 'JOD', // Jordanian Dinar
  JPY: '¥', // Japanese Yen
  KES: 'KSh', // Kenyan Shilling
  KGS: 'с', // Kyrgyzstani Som
  KHR: 'KHR', // Cambodian Riel
  KID: '$', // Kiribati Dollar
  KMF: 'CF', // Comorian Franc
  KPW: '₩', // North Korean Won
  KRW: '₩', // South Korean Won
  KWD: 'KWD', // Kuwaiti Dinar
  KYD: '$', // Cayman Islands Dollar
  KZT: '₸', // Kazakhstani Tenge
  LAK: '₭', // Lao Kip
  LBP: 'LBP', // Lebanese Pound
  LKR: 'LKR', // Sri Lankan Rupee
  LRD: '$', // Liberian Dollar
  LSL: 'L', // Lesotho Loti
  LYD: 'LYD', // Libyan Dinar
  MAD: 'MAD', // Moroccan Dirham
  MDL: 'L', // Moldovan Leu
  MGA: 'Ar', // Malagasy Ariary
  MKD: 'ден', // Macedonian Denar
  MMK: 'K', // Myanmar Kyat
  MNT: '₮', // Mongolian Tugrik
  MOP: 'MOP$', // Macanese Pataca
  MRU: 'UM', // Mauritanian Ouguiya
  MUR: '₨', // Mauritian Rupee
  MVR: 'ރ.', // Maldivian Rufiyaa
  MWK: 'MK', // Malawian Kwacha
  MXN: '$', // Mexican Peso
  MYR: 'RM', // Malaysian Ringgit
  MZN: 'MT', // Mozambican Metical
  NAD: '$', // Namibian Dollar
  NGN: '₦', // Nigerian Naira
  NIO: 'C$', // Nicaraguan Córdoba
  NOK: 'kr', // Norwegian Krone
  NPR: '₨', // Nepalese Rupee
  NZD: '$', // New Zealand Dollar
  OMR: 'OMR', // Omani Rial
  PAB: 'B/.', // Panamanian Balboa
  PEN: 'S/.', // Peruvian Nuevo Sol
  PGK: 'K', // Papua New Guinean Kina
  PHP: '₱', // Philippine Peso
  PKR: '₨', // Pakistani Rupee
  PLN: 'zł', // Polish Zloty
  PYG: '₲', // Paraguayan Guarani
  QAR: 'QAR', // Qatari Riyal
  RON: 'lei', // Romanian Leu
  RSD: 'дин.', // Serbian Dinar
  RUB: 'RUB', // Russian Ruble
  RWF: 'RF', // Rwandan Franc
  SAR: 'SAR', // Saudi Riyal
  SBD: '$', // Solomon Islands Dollar
  SCR: '₨', // Seychellois Rupee
  SDG: 'SDG', // Sudanese Pound
  SEK: 'kr', // Swedish Krona
  SGD: '$', // Singapore Dollar
  SHP: '£', // Saint Helena Pound
  SLL: 'Le', // Sierra Leonean Leone
  SOS: 'S', // Somali Shilling
  SRD: '$', // Surinamese Dollar
  SSP: '£', // South Sudanese Pound
  STN: 'Db', // São Tomé and Príncipe Dobra
  SVC: '$', // Salvadoran Colón
  SYP: '£', // Syrian Pound
  SZL: 'L', // Swazi Lilangeni
  THB: '฿', // Thai Baht
  TJS: 'ЅМ', // Tajikistani Somoni
  TMT: 'm', // Turkmenistani Manat
  TND: 'TND', // Tunisian Dinar
  TOP: 'T$', // Tongan Pa'anga
  TRY: '₺', // Turkish Lira
  TTD: 'TT$', // Trinidad and Tobago Dollar
  TWD: 'NT$', // New Taiwan Dollar
  TZS: 'TSh', // Tanzanian Shilling
  UAH: '₴', // Ukrainian Hryvnia
  UGX: 'USh', // Ugandan Shilling
  USD: '$', // United States Dollar
  UYU: '$', // Uruguayan Peso
  UZS: 'лв', // Uzbekistan Som
  VES: 'Bs.S', // Venezuelan Bolívar Soberano
  VND: '₫', // Vietnamese Dong
  VUV: 'VT', // Vanuatu Vatu
  WST: 'WS$', // Samoan Tala
  XAF: 'FCFA', // Central African CFA Franc
  XCD: 'EC$', // East Caribbean Dollar
  XOF: 'CFA', // West African CFA Franc
  XPF: '₣', // CFP Franc
  YER: 'YER', // Yemeni Rial
  ZAR: 'R', // South African Rand
  ZMW: 'ZK', // Zambian Kwacha
  ZWL: 'Z$', // Zimbabwean Dollar
};
