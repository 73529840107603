import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import {
  DataTypeEnum,
  DynamicComponentBase,
  IViewMode,
  MessageKeys,
  ModuleKeywords,
  QuestionnaireAnswerDto,
  QuestionnaireQuestionsDto,
  getDataKeyValueFormat,
} from '@shared/classes';
import { ToastService } from '@shared/services';
import { AppDialogService } from '@shared/services/app-dialog.service';
import { ViewModeService } from '@shared/services/view-mode.service';
import { QuestionnaireDataService } from 'app/modules/questions/service/data/questionnaire-data.service';
import { QuestionsValueDataService } from 'app/modules/questions/service/data/questionnaire-value-data.service';
import { AssessmentFieldPopupComponent } from '../assessment-field-popup/assessment-field-popup.component';

@Component({
  selector: 'app-assessment-field',
  templateUrl: './assessment-field.component.html',
  styleUrls: ['./assessment-field.component.scss'],
})
export class AssessmentFieldComponent extends DynamicComponentBase implements OnInit {
  dataType: DataTypeEnum = DataTypeEnum.Text;
  @Output() onFocus: EventEmitter<any> = new EventEmitter();
  loading: Boolean = true;
  questions = [];
  questionnaire: QuestionnaireQuestionsDto;
  @Input() viewModeOnly: boolean = false;
  private _questionnaireCode;
  @Input() set questionnaireCode(questionnaireCode: string) {
    this._questionnaireCode = questionnaireCode;
    this.getQuestionnaire();
  }
  get questionnaireCode() {
    return this._questionnaireCode;
  }
  questionnaireValue: QuestionnaireAnswerDto;
  private _questionnaireValueCode;
  @Input() set questionnaireValueCode(questionnaireValueCode: string) {
    this._questionnaireValueCode = questionnaireValueCode;
    this.getQuestionnaireValue();
    // this.placeholderControl?.patchValue(questionnaireValueCode ? 'Check Questionnaire': 'Answer Questionnaire')
  }
  get questionnaireValueCode() {
    return this._questionnaireValueCode;
  }
  placeholderControl = new FormControl('Answer Questionnaire');
  placeholderCheckControl = new FormControl('Check Questionnaire');
  isLockedForEdit: boolean = false;
  constructor(
    private appDialogService: AppDialogService,
    private questionnaireService: QuestionnaireDataService,
    private questionnaireValueService: QuestionsValueDataService,
    public viewModeService: ViewModeService,
    private toastService: ToastService
  ) {
    super();
  }

  ngOnInit(): void {}
  onDestroy(): void {
    if (this.questionnaireValueCode && this.isLockedForEdit) {
      this.questionnaireValueService.releaseLock(this.questionnaireValueCode).subscribe((res) => {});
      this.isLockedForEdit = false;
    }
  }
  setInputOptions() {}
  onFocusChange(event) {
    this.onFocus.emit(event);
  }
  onEditQuestionnaire() {
    this.appDialogService.showDialog(
      AssessmentFieldPopupComponent,
      'Questionnaire',
      (e) => {
        if (e?.answers && this.questionnaireValueCode) {
          this.control.patchValue(e);
          const rawValue = getDataKeyValueFormat({ answers: this.control.getRawValue().answers });
          this.questionnaireValueService
            .patchSingleUpdate(rawValue.createItems, this.questionnaireValueCode)
            .subscribe({
              next: (res) => {
                this.toastService.success(
                  MessageKeys.success,
                  MessageKeys[`update${ModuleKeywords.QuestionnaireValue}`]
                );
              },
              error: (error) => {
                this.toastService.success(MessageKeys.errorHappened, MessageKeys.tryAgainLater);
              },
              complete: () => {},
            });
        }
      },
      {
        data: {
          questions: this.questions,
          questionnaire: this.questionnaire,
          questionnaireValue: this.control?.value
            ? this.control?.value?.answers
            : this.questionnaireValue?.answers
              ? this.questionnaireValue?.answers
              : this.questionnaireValue,
          viewModeOnly: this.viewModeOnly,
        },
      }
    );
  }
  getQuestionnaire() {
    if (!this.questionnaireCode) {
      this.loading = false;
      return;
    }
    this.loading = true;
    this.questionnaireService.getByIdOrCode<QuestionnaireQuestionsDto>(this.questionnaireCode).subscribe({
      next: (res) => {
        this.loading = false;
        this.questions = res.data.questions;
        this.questionnaire = res.data;
      },
      error: (err) => {
        this.loading = false;
      },
    });
  }
  getQuestionnaireValue() {
    if (!this.questionnaireValueCode) {
      this.loading = false;
      return;
    }
    this.loading = true;
    this.isLockedForEdit = this.fieldViewMode != 'view';
    (this.fieldViewMode != 'view'
      ? this.questionnaireValueService.getByIdOrCodeForEdit(this.questionnaireValueCode)
      : this.questionnaireValueService.getByIdOrCode(this.questionnaireValueCode)
    ).subscribe({
      next: (res) => {
        this.loading = false;
        this.questionnaireValue = res.data;
        if (!this.questionnaireCode) {
          this.questionnaireCode = this.questionnaireValue.questionnaire;
        }
      },
      error: (err) => {
        this.loading = false;
      },
    });
  }

  get fieldViewMode(): IViewMode {
    return this.viewModeOnly ? 'view' : this.viewModeService.viewMode;
  }
  onEditModalInputKeyDown(event) {
    if (event.key === 'Enter' || event.key === ' ') {
      // Enter or Space key was pressed
      this.onEditQuestionnaire();
    }
  }
}
