import { Component, Input, OnInit } from '@angular/core';
import { JWTTokenService } from '@core/services/JWT-token/jwttoken.service';
import { AppEnumIcons, BadgeColor, IAction, NotificationDto, humanizeCasedString } from '@shared/classes';
import { LoaderService } from '@shared/services/loader.service';
import { PathResolverService } from '@shared/services/path-resolver.service';
import { ToastService } from '@shared/services/toast.service';
import { MyNotificationsDataService } from 'app/modules/activity-feed/services/data/my-notifications-data.service';
import { SubscriptionDataService } from 'app/modules/activity-feed/services/data/subscription-data.service';

@Component({
  selector: 'app-notification-item',
  templateUrl: './notification-item.component.html',
  styleUrls: ['./notification-item.component.scss'],
})
export class NotificationItemComponent implements OnInit {
  colorMap = BadgeColor;
  iconMap = AppEnumIcons;
  private _notification: NotificationExtraProps = {
    principle: { name: 'Nabeeh Sawaf' },
    code: 'RES-1',
    relatedToCode: 'ISS-14',
    relatedToType: 'ISSUE',
    template: 'Template string',
  };
  @Input() set notification(notification: NotificationExtraProps) {
    notification.isSeen = notification?.seenBy?.includes(this.jwtTokenService.getPreferredUsername());
    this._notification = notification;
  }
  get notification() {
    return this._notification;
  }
  unsubscribeAction: IAction = {
    id: 1,
    buttonType: 'button',
    command: this.unsubscribe.bind(this),
    icon: 'fa fa-bell-slash-o',
    color: 'secondary',
    buttonStyle: 'text',
    tooltipText: 'Unsubscribe',
  };
  playAnimation: boolean = false;
  @Input()
  add: boolean = false;
  hideCard: boolean = false;
  constructor(
    public pathResolver: PathResolverService,
    private requestService: MyNotificationsDataService,
    private subscribeService: SubscriptionDataService,
    private toastService: ToastService,
    public loaderService: LoaderService,
    private jwtTokenService: JWTTokenService
  ) {}

  ngOnInit(): void {}
  markAsSeen() {
    this.requestService.markAsSeen(this.notification?.code).subscribe((res) => {
      // this.notification.seen = true;
      this.playAnimation = true;
      setTimeout(() => {
        this.hideCard = true;
      }, 500);
      // this.toastService.success("Marked as seen",'',null,primengToastPlacement['top-left']);
    });
  }
  unsubscribe() {
    this.subscribeService.unSubscribe(this.notification?.code).subscribe((res) => {
      // this.toastService.success("Unsubscribed from this feed",'',null,primengToastPlacement['top-left']);
    });
  }

  getNotificationText(notification: NotificationDto) {
    const notificationType = notification?.notificationType;
    const moduleKeyword = this.pathResolver.getModuleKeywordByCode(notification?.relatedToCode);
    const humanizedModuleKeyword = humanizeCasedString(
      this.pathResolver.getModuleKeywordByCode(notification?.relatedToCode)
    );
    const relatedToCode = notification?.relatedToCode;
    const sender = notification?.sender;

    switch (notificationType) {
      case NotificationDto.NotificationTypeEnum.AuditTrail: {
        return `Changed ${humanizedModuleKeyword}`;
      }
      case NotificationDto.NotificationTypeEnum.Task: {
        // if(notification?.data?.fromTask != null) {
        //     return notification?.data?.title;
        // }

        return `Assigned you new Task${notification?.data?.fromTask != null ? ': ' + notification?.data?.title : ''}`;
      }
      case NotificationDto.NotificationTypeEnum.Comment: {
        return `Commented On ${humanizedModuleKeyword}`;
      }
      case NotificationDto.NotificationTypeEnum.Updated: {
        return `Updated ${humanizedModuleKeyword}`;
      }
      case NotificationDto.NotificationTypeEnum.Created: {
        return `Created New ${humanizedModuleKeyword}`;
      }
      case NotificationDto.NotificationTypeEnum.UnLocked: {
        return `Unlocked ${humanizedModuleKeyword}`;
      }
      case NotificationDto.NotificationTypeEnum.LockedForEdit: {
        return `Locked From Edit ${humanizedModuleKeyword}`;
      }
      case NotificationDto.NotificationTypeEnum.UnLockedForEdit: {
        return `Unlocked For Edit ${humanizedModuleKeyword}`;
      }
      case NotificationDto.NotificationTypeEnum.Locked: {
        return `Locked ${humanizedModuleKeyword}`;
      }
      case NotificationDto.NotificationTypeEnum.Archived: {
        return `Archived ${humanizedModuleKeyword}`;
      }
      case NotificationDto.NotificationTypeEnum.ChangeState: {
        return `Changed Status Of ${humanizedModuleKeyword}`;
      }
      case NotificationDto.NotificationTypeEnum.Events: {
        return `Added Event On ${humanizedModuleKeyword}`;
      }
      case NotificationDto.NotificationTypeEnum.Active: {
        return `Activated ${humanizedModuleKeyword}`;
      }
      case NotificationDto.NotificationTypeEnum.InActive: {
        return `Archived ${humanizedModuleKeyword}`;
      }
      default:
        return 'UN_KNOWN TYPE';
    }
  }
}
interface NotificationExtraProps extends NotificationDto {
  children?: NotificationDto[];
  isSeen?: boolean;
}
