import { Component, OnInit } from '@angular/core';
import { BaseViewItem, DataItem, DataTypeEnum } from '@shared/classes';

enum ViewType {
  Created,
  Updated,
  Deleted,
}

@Component({
  selector: 'app-acl-view',
  templateUrl: './acl-view.component.html',
  styleUrls: ['./acl-view.component.scss'],
})
export class AclViewComponent extends BaseViewItem<DataItem> implements OnInit {
  actions: Array<any> = [];

  viewType = ViewType;

  dataTypes = DataTypeEnum;

  constructor() {
    super();
  }

  ngOnInit(): void {}
  onSetData(): void {
    // this.data;
    this.processAclData();
  }

  processAclData(): void {
    try {
      const aclNew = this.data.newValue
        ? typeof this.data.newValue === 'string'
          ? JSON.parse(this.data.newValue)
          : this.data.newValue
        : [];
      const aclOld = this.data.oldValue
        ? typeof this.data.oldValue === 'string'
          ? JSON.parse(this.data.oldValue)
          : this.data.oldValue
        : [];
      let newMap: Map<any, any> = new Map<any, any>();
      let oldMap: Map<any, any> = new Map<any, any>();

      // Setting up the maps
      aclNew.forEach((val) => {
        newMap.set(val.principle + val.principleType + val.accessType + '', val);
      });

      aclOld.forEach((val) => {
        oldMap.set(val.principle + val.principleType + val.accessType + '', val);
      });

      // Construct created
      aclNew.forEach((val) => {
        if (!oldMap.has(val.principle + val.principleType + val.accessType + '')) {
          this.actions.push({
            state: ViewType.Created,
            oldValue: '',
            newValue: val,
          });
        }
      });

      // Construct Updated
      aclOld.forEach((val) => {
        if (
          newMap.has(val.principle + val.principleType + val.accessType + '') &&
          newMap.get(val.principle + val.principleType + val.accessType + '').accessLevel != val.accessLevel
        ) {
          this.actions.push({
            state: ViewType.Updated,
            oldValue: val,
            newValue: newMap.get(val.principle + val.principleType + val.accessType + ''),
          });
        } else if (!newMap.has(val.principle + val.principleType + val.accessType + '')) {
          this.actions.push({
            state: ViewType.Deleted,
            oldValue: val,
            newValue: '',
          });
        }
      });
    } catch (e) {}
  }
}
