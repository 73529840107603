import { Directive, Input } from '@angular/core';
import { AbstractControl, ControlValueAccessor, ValidationErrors, Validator } from '@angular/forms';
import { Base } from 'app/shared/classes';

/**
 * @TODO Make this class more generic for all kinds of control value accessor components
 */
@Directive()
export abstract class BaseControlValueAccessor extends Base implements ControlValueAccessor, Validator {
  value: any;

  disabled: boolean;
  readOnly: boolean;
  @Input() required: boolean = false;
  onChange: any = () => {};
  onTouch: any = () => {};
  onValidationChange: any = () => {};

  constructor() {
    super();
  }

  abstract writeValue(obj: any): void;

  abstract validate(control: AbstractControl): ValidationErrors;

  registerOnValidatorChange?(fn: () => void): void {
    this.onValidationChange = fn;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
