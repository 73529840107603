<form [formGroup]="formGroup" (ngSubmit)="onSubmitForm()">
  <div class="formgrid grid p-fluid">
    <div class="col-12 md:col-6">
      <ng-container>
        <app-basic-input
          label="Package Url"
          [placeholder]="'Enter Package Url'"
          [control]="formGroup?.controls?.documentUrl"
          [viewMode]="'create'"
        ></app-basic-input>
      </ng-container>
    </div>
    <div class="col-12 md:col-6 flex gap-2 align-items-end mb-3">
      <p-fileUpload
        mode="basic"
        name="documentUrl[]"
        [styleClass]="'px-3'"
        [chooseLabel]="'Browse'"
        (uploadHandler)="onBasicUpload($event)"
        [customUpload]="true"
        (onSelect)="onSelectFile()"
        #fileBrowser
      ></p-fileUpload>
      @if (showCancelFile) {
        <app-button [action]="cancelFileSelectionAction" [commandData]="fileBrowser"></app-button>
      }
    </div>
  </div>
  <div class="flex flex-row-reverse gap-2">
    <!-- <app-button
  [action]="submitButtonAction"></app-button> -->
    <app-button [action]="submitSaveButtonAction"></app-button>
  </div>
</form>
