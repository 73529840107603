import { ChartViewConfig } from '@shared/classes/model';
import { EChartsOption } from 'echarts';
import { AggregateFunctionOptions, AssignChartDataOptions } from '../../../echart-base';
import { EchartVariationBase } from '../../../echart-variation-base';
import { groupData } from '../../../helpers/general-functions';
import { BubbleChart } from '../../base/bubble-chart';

export class PolarBubbleChart extends BubbleChart implements EchartVariationBase {
  variation: ChartViewConfig.ChartVariationEnum = 'POLAR';
  chartOptions: EChartsOption = {
    tooltip: {
      trigger: 'item',
      appendTo: 'body',
      valueFormatter: (value) => value[2],
    },
    angleAxis: [
      {
        type: 'category',
        data: [],
        boundaryGap: false,
        splitLine: {
          show: true,
        },
        axisLine: {
          show: false,
        },
      },
    ],
    radiusAxis: [
      {
        type: 'category',
        data: [],
        axisLine: {
          show: false,
        },
        axisLabel: {
          rotate: 45,
        },
      },
    ],
    polar: { radius: '60%', center: ['50%', '60%'] },
    series: [
      {
        data: [],
        type: 'scatter',
        coordinateSystem: 'polar',
        symbolSize: function (data) {
          return data[2];
        },
        emphasis: {
          focus: 'series',
          label: {
            show: true,
            formatter: function (param) {
              return param.data[2];
            },
            position: 'top',
          },
        },
      },
    ],
  };
  checkVariationCondition(variation: ChartViewConfig.ChartVariationEnum): boolean {
    return true;
  }
  assignDataToChartOptions(options: AssignChartDataOptions): EChartsOption {
    let optionsCopy = { ...options?.chartOptions };
    optionsCopy.series[0].name = null; //options?.name;
    if (options.initOptions.aggregationResult.payload.groupByFields.length > 2) {
      const heatMaps: {
        xAxis: string[];
        yAxis: string[];
        heatmap: number[][];
        minValue: number;
        maxValue: number;
      }[] = [];
      let minValue = Infinity;
      let maxValue = -Infinity;
      options?.data?.forEach((treeNode, idx) => {
        heatMaps.push(
          this.generateHeatmapData(
            treeNode.children,
            idx != 0 ? heatMaps[idx - 1].xAxis : null,
            idx != 0 ? heatMaps[idx - 1].yAxis : null
          )
        );
        if (heatMaps[idx].minValue < minValue) minValue = heatMaps[idx].minValue;
        if (heatMaps[idx].maxValue > maxValue) maxValue = heatMaps[idx].maxValue;
      });
      heatMaps.forEach((heatMap, idx) => {
        optionsCopy.angleAxis[0].data = heatMap?.yAxis;
        optionsCopy.radiusAxis[0].data = heatMap?.xAxis;
        if (idx == 0) {
          optionsCopy.series[0].name = options?.data?.[idx]?.name;
          optionsCopy.series[0].data = heatMap?.heatmap;
          optionsCopy.series[0].symbolSize = (data) => {
            // First, settle on some min/max bubble sizes that work well for your chart
            const BUBBLE_MIN_SIZE = 10;
            const BUBBLE_MAX_SIZE = 75;

            // Square them because we'll eventually use `Math.sqrt`
            // See https://chartio.com/learn/charts/bubble-chart-complete-guide/#scale-bubble-area-by-value
            const squaredMax = Math.pow(BUBBLE_MAX_SIZE, 2);
            const squaredMin = Math.pow(BUBBLE_MIN_SIZE, 2);

            // Index of the field in your data that represents the bubble size
            const bubbleSizeIdx = 2;

            // Find min and max values in your data for the column
            // const minValue = minValue;
            // const maxValue = maxValue;

            // Scales each bubble so it's sized relative to the mix and max values in the dataset
            const scale = (data[bubbleSizeIdx] - minValue) / (maxValue - minValue);
            const scaledSize = scale * (squaredMax - squaredMin) + squaredMin;
            return Math.sqrt(scaledSize);
          };
        } else {
          (optionsCopy.series as any[]).push({
            ...optionsCopy.series[0],
            data: heatMap?.heatmap,
            name: options?.data?.[idx]?.name,
          });
        }
      });
    } else {
      const heatMap = this.generateHeatmapData(options?.data);
      optionsCopy.series[0].data = heatMap?.heatmap;
      optionsCopy.angleAxis[0].data = heatMap?.yAxis;
      optionsCopy.radiusAxis[0].data = heatMap?.xAxis;
      optionsCopy.series[0].symbolSize = (data) => {
        // First, settle on some min/max bubble sizes that work well for your chart
        const BUBBLE_MIN_SIZE = 10;
        const BUBBLE_MAX_SIZE = 75;

        // Square them because we'll eventually use `Math.sqrt`
        // See https://chartio.com/learn/charts/bubble-chart-complete-guide/#scale-bubble-area-by-value
        const squaredMax = Math.pow(BUBBLE_MAX_SIZE, 2);
        const squaredMin = Math.pow(BUBBLE_MIN_SIZE, 2);

        // Index of the field in your data that represents the bubble size
        const bubbleSizeIdx = 2;

        // Find min and max values in your data for the column
        const minValue = heatMap?.minValue;
        const maxValue = heatMap?.maxValue;

        // Scales each bubble so it's sized relative to the mix and max values in the dataset
        const scale = (data[bubbleSizeIdx] - minValue) / (maxValue - minValue);
        const scaledSize = scale * (squaredMax - squaredMin) + squaredMin;
        return Math.sqrt(scaledSize);
      };
    }
    // optionsCopy.visualMap[0].min = heatMap?.minValue;
    // optionsCopy.visualMap[0].max = heatMap?.maxValue;
    //@TODO: children format original data
    return optionsCopy;
  }

  formatAggregateData(options: AggregateFunctionOptions) {
    return {
      data: groupData(
        options?.response?.aggregation_value,
        options?.payload?.groupByFields,
        options?.drillDownAggregationField
      ),
      chartTitle: null,
    };
  }
  generateHeatmapData(
    tree: any[],
    previousXAxis = null,
    previousYAxis = null
  ): {
    xAxis: string[];
    yAxis: string[];
    heatmap: number[][];
    minValue: number;
    maxValue: number;
  } {
    const xAxis: string[] = previousXAxis || [];
    const yAxis: string[] = previousYAxis || [];
    const heatmap: number[][] = [];
    let minValue = Infinity;
    let maxValue = -Infinity;

    tree.forEach((node, xIndex) => {
      if (!xAxis.includes(node.name)) {
        xAxis.push(node.name);
      }
      const xAxisIndex = xAxis.indexOf(node.name);
      if (node?.children) {
        node?.children?.forEach((child, yIndex) => {
          if (!yAxis.includes(child.name)) {
            yAxis.push(child.name);
          }
          const value = child.value;
          heatmap.push([xAxisIndex, yAxis.indexOf(child.name), value]);
          if (value < minValue) minValue = value;
          if (value > maxValue) maxValue = value;
        });
      }
    });

    return { xAxis, yAxis, heatmap, minValue, maxValue };
  }
}
