import { Component, Input, OnInit } from '@angular/core';
import { BaseViewItem, DataTypeEnum, IMeterGroup } from '@shared/classes';

@Component({
  selector: 'app-meter-group-view',
  templateUrl: './meter-group-view.component.html',
  styleUrl: './meter-group-view.component.scss',
})
export class MeterGroupViewComponent extends BaseViewItem<IMeterGroup[]> implements OnInit {
  @Input() viewType: DataTypeEnum = DataTypeEnum.MeterGroup;
  constructor() {
    super();
  }

  ngOnInit(): void {}
}
