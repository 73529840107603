import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { BucketDto, DynamicComponentBase, IAction } from '@shared/classes';
import { BucketDataService } from 'app/modules/file-manager-module/services/data/bucket-data.service';
import { TreeNode } from 'primeng/api';

@Component({
  selector: 'app-folder-bucket-tree-selector',
  templateUrl: './folder-bucket-tree-selector.component.html',
  styleUrls: ['./folder-bucket-tree-selector.component.scss'],
})
export class FolderBucketTreeSelectorComponent extends DynamicComponentBase implements OnInit {
  readonly rootCode = 'root';
  @Input() inlineMode: boolean = false;
  editModalVisible: boolean = false;
  editModalControl: FormControl = new FormControl(null);
  inlineControl: FormControl = new FormControl(null);
  setInputOptions(): void {}
  @ViewChild('cm') contextMenu: any;

  loading: boolean = false;

  folders: TreeNode<BucketDto>[] = [
    {
      label: 'Home',
      data: { code: this.rootCode, label: 'Home', physicalName: this.rootCode },
      leaf: false,
      children: [],
      icon: 'pi pi-folder',
    },
  ];
  selectedFolderPath: string = '';
  selectedFile;
  contextMenuItems: any[] = [
    {
      label: 'Add Folder',
      icon: 'pi pi-plus',
      command: () => this.addFolder(),
    },
  ];
  addFolderAction: IAction = {
    id: 1,
    label: '',
    buttonType: 'button',
    command: this.addFolder.bind(this),
    icon: 'pi pi-plus',
    buttonStyle: 'text',
    tooltipText: 'Add Folder',
    tooltipPosition: 'top',
    buttonClass: 'h-22px',
  };
  addFolderModal = false;
  constructor(private bucketDataService: BucketDataService) {
    super();
  }
  ngOnInit(): void {
    // this.bucketDataService.getBucketsChildren("root").subscribe(res => {
    //     this.folders = res.map(bucket => this.parseBucketToTreeNode(bucket));
    // })
    this.fControl.valueChanges.subscribe((changes) => {});
    let patchVal = this.control?.getRawValue();
    this.editModalControl.patchValue(patchVal);
    this.inlineControl.patchValue(patchVal);
    this.subs.sink = this.onChanges.subscribe((res) => {
      let patchVal = this.control?.getRawValue();

      this.editModalControl.patchValue(patchVal);
      this.inlineControl.patchValue(res);
    });
  }
  parseBucketToTreeNode(bucket: BucketDto): TreeNode<BucketDto> {
    let node: TreeNode<BucketDto> = {
      label: bucket?.label || bucket?.name,
      data: { ...bucket },
      leaf: false,
      children: [],
      icon: 'pi pi-folder',
    };
    return node;
  }
  getSelectedFolderPath(node: TreeNode): string {
    let path = '';

    while (node) {
      path = '/' + node.label + path;
      node = node.parent;
    }

    return path;
  }
  nodeSelect(event: any) {
    this.selectedFolderPath = this.getSelectedFolderPath(event.node);
    this.inlineControl?.patchValue(event?.node?.data?.code);
  }
  nodeUnselect(event: any) {
    this.selectedFolderPath = '';
    this.inlineControl?.patchValue(null);
  }
  loadChildFolders(event: any) {
    const node: TreeNode<BucketDto> = event.node;

    if (node && !node.leaf) {
      this.loading = true;
      this.bucketDataService.getBucketsChildren(node?.data?.code).subscribe((res) => {
        const childFolders = res.map((bucket) => this.parseBucketToTreeNode(bucket));
        node.children = childFolders;
        // if (childFolders.length == 0) {
        //     node.leaf = true
        // }
        this.loading = false;
      });
    }
  }

  handleContextMenu(event: any) {
    event.originalEvent.preventDefault();
    this.contextMenu.show(event.originalEvent);
  }
  onSubmit(data) {
    this.createFolder(data.name);
  }
  addFolder(currentNode: TreeNode = this.selectedFile) {
    this.selectedFile = currentNode;
    this.addFolderModal = true;
  }
  createFolder(folderName: string, currentNode: TreeNode = this.selectedFile) {
    const selectedNode = currentNode;
    if (folderName) {
      const body = {
        createItems: [
          { key: 'name', value: folderName },
          ...(selectedNode?.data?.physicalName !== 'root' && selectedNode?.data?.code
            ? [{ key: 'parent', value: selectedNode?.data?.code }]
            : []),
        ],
      };
      this.bucketDataService.create(body).subscribe((res) => {
        if (folderName) {
          const newFolder: TreeNode<BucketDto> = this.parseBucketToTreeNode(res.data);
          if (selectedNode) {
            selectedNode.children = selectedNode.children || [];
            selectedNode.children.push(newFolder);
            // this.tree.updateNode(selectedNode);
          } else {
            this.folders.push(newFolder);
          }
          this.addFolderModal = false;
        }
      });
    }
  }
  openEditModal() {
    if (!!this.linkedControl?.invalid || this.linkedControl?.disabled || this.fControl.disabled) return;
    let patchVal = this.control?.getRawValue();
    this.editModalControl.patchValue(patchVal);
    this.inlineControl.patchValue(this.control?.getRawValue());
    this.editModalVisible = true;
  }
  onFocusChange(event) {
    // this.openEditModal();
  }
  onEditModalInputKeyDown(event) {
    if (event.key === 'Enter' || event.key === ' ') {
      // Enter or Space key was pressed
      this.openEditModal();
    }
  }
}
