import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PaginationModel } from '@shared/classes/model/backend/base-responses';
import { NotificationDto } from '@shared/classes/model/backend/task/model/notificationDto';
import { BaseViewItem } from '@shared/classes/view/BaseViewItem';
import { IAction } from '@shared/classes/view/ButtonAction';

@Component({
  selector: 'app-dashlet-notification',
  templateUrl: './dashlet-notification.component.html',
  styleUrls: ['./dashlet-notification.component.scss'],
})
export class DashletNotificationComponent extends BaseViewItem implements OnInit {
  @Input() title: string = '';

  @Input() notifications: NotificationDto[] = [];

  @Input() isLoading: boolean = true;

  @Input() notificationPage: PaginationModel<NotificationDto>;

  @Output() onInit: EventEmitter<any> = new EventEmitter();

  @Output() onLoadMore: EventEmitter<number> = new EventEmitter();

  loadMoreAction: IAction = {
    id: 1,
    label: 'Load More',
    buttonType: 'button',
    command: this.loadMore.bind(this),
    icon: 'pi pi-angle-down',
    color: 'secondary',
    buttonStyle: 'text',
    buttonClass: 'w-full',
  };

  ngOnInit(): void {
    if (this.onInit) {
      this.onInit.emit();
    }
    if (this.onLoadMore) {
      this.onLoadMore.emit(0);
    }
  }

  loadMore() {
    if (this.onLoadMore) {
      this.onLoadMore.emit(this.notificationPage.number + 1);
    }
  }
}
