<!-- 
<p-listbox
  #listBox
  [options]="listOfData"
  class="custom-listbox"
  [readonly]="true"
  [filter]="true"
  filterBy="code,label"
  [filterFields]="['code', 'label']"
  [style]="{ width: '100%' }"
  (onClick)="preventSelectionFunc($event)"
  [listStyle]="{
    'max-height': heigth,
    'min-height': listOfData.length > 4 ? heigth : '0',
    height: listOfData.length ? heigth : '0',
  }"
>
  <ng-template let-item pTemplate="item">
    <app-code-navigation class="w-full" [showTooltip]="true" [data]="item?.code"></app-code-navigation>
  </ng-template>

  <ng-template pTemplate="filter" let-options="options">
    <div class="flex gap-1 w-full">
      <div class="p-inputgroup" (click)="$event.stopPropagation()">
        <span class="p-inputgroup-addon"><i class="pi pi-search"></i></span>
        <input
          type="text"
          pInputText
          placeholder="Filter"
          [(ngModel)]="filterValue"
          (keyup)="customFilterFunction($event, options)"
        />
      </div>
      @if (onButtonClick) {
        <button
          pButton
          type="button"
          label="Edit"
          icon="pi pi-pencil"
          (click)="buttonClick()"
          class="add-button"
          [style]="{ 'min-width': '6rem' }"
        ></button>
      }
    </div>
  </ng-template>
</p-listbox> -->

<div class="flex flex-column w-full">
  <div class="p-inputgroup flex w-full">
    <div
      class="p-input-icon-left h-fit w-full"
      (click)="$event.stopPropagation()"
      style="max-width: none; margin-bottom: none"
    >
      <i class="pi pi-search" [style]="disabled ? { background: 'transperant' } : {}"></i>
      <input
        type="text"
        pInputText
        [placeholder]="!listOfData?.length ? 'No Selected Data' : 'Search'"
        [(ngModel)]="filterValue"
        (keyup)="customFilterFunction($event)"
        [class.ng-invalid]="invalid"
        [class.ng-dirty]="dirty"
        [disabled]="!listOfData?.length && !addButtonEnabled ? true : disabled"
        [style]="disabled ? { background: 'transperant' } : {}"
        [class.border-noround-bottom]="listOfData.length > 0"
        [style]="
          onButtonClick && buttonEnabled
            ? 'border-bottom-right-radius: 0 !important; border-top-right-radius: 0 !important;'
            : ''
        "
      />
    </div>
    @if (onButtonClick && buttonEnabled) {
      @if (addButtonEnabled && filterValue?.length > 0) {
        <button
          pButton
          type="button"
          icon="pi pi-plus"
          (click)="addButtonClick()"
          class="white-space-nowrap"
          [class.border-noround-bottom]="listOfData.length > 0"
          [style]="listOfData.length > 0 ? 'border-bottom-right-radius: 0 !important;' : ''"
        ></button>
      }
      <button
        pButton
        type="button"
        icon="pi pi-pencil"
        (click)="buttonClick()"
        class="white-space-nowrap"
        [class.border-noround-bottom]="listOfData.length > 0"
        [style]="listOfData.length > 0 ? 'border-bottom-right-radius: 0 !important;' : ''"
      ></button>
    }
  </div>
  <div
    class="flex flex-column gap-3"
    [class]="listOfData.length ? 'bg-input-field border-round-bottom px-2' : ''"
    [style]="{
      'max-height': heigth,
      'min-height': listOfData.length > 4 ? heigth : '0',
      height: listOfData.length ? heigth : '0',
    }"
  >
    @if (menuItems?.length && listOfData?.length && currentItem) {
      <p-tabMenu [(activeItem)]="currentItem" [model]="menuItems" [activeItem]="menuItems[0]" [scrollable]="true">
        <ng-template pTemplate="item" let-item>
          <a pRipple class="flex align-items-center gap-3 p-menuitem-link">
            <span class="font-bold white-space-nowrap">{{ item.label }} </span>
            <p-badge [value]="item?.list?.length ?? 0" />
          </a>
        </ng-template>
      </p-tabMenu>
    }
    <div class="overflow-y-auto flex flex-column gap-2">
      @for (item of filteredData; track item) {
        <app-code-navigation class="w-full" [showTooltip]="true" [data]="item?.code"></app-code-navigation>
      }
    </div>
  </div>
</div>
