<div class="flex flex-row gap-4">
  <div class="flex-1 text-right">
    <app-view-switch [viewType]="dataTypes.CodeLink" [data]="checkSwitchCodes() ? data.targetCode : data.sourceCode">
    </app-view-switch>
  </div>
  <div class="text-center" style="min-width: 12rem">
    <app-view-switch
      [viewType]="dataTypes.RelationTypeCell"
      [data]="data.relationType"
      [options]="{ relationTypes: relationTypes, fullWidth: true }"
    >
    </app-view-switch>
  </div>
  <div class="flex-1 text-left">
    <app-view-switch [viewType]="dataTypes.CodeLink" [data]="checkSwitchCodes() ? data.sourceCode : data.targetCode">
    </app-view-switch>
  </div>
</div>
