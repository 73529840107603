<span class="flex gap-2 align-items-center"
  ><a [routerLink]="hrefPath">{{ data?.code }}</a>
  <!-- <app-button *ngIf="lockedForEdit" pTooltip="Check Lock Status" tooltipPosition="top" (mouseenter)="op.toggle($event)" (mouseleave)="op.toggle($event)" (onClickAction)="op.toggle($event)" [action]="{
        id: 2,
        label: '',
        icon: 'pi pi-lock',
        iconPos: 'left',
        buttonStyle: 'text',
        color:'secondary'
    }"></app-button> -->
  @if (lockedForEdit) {
    <i
      class="pi pi-lock"
      (mouseenter)="op.toggle($event)"
      (mouseleave)="op.toggle($event)"
      (onClickAction)="op.toggle($event)"
    ></i>
  }
</span>
<p-overlayPanel #op [showCloseIcon]="false">
  <ng-template pTemplate>
    <app-count-down-view
      [badgeView]="true"
      [badgeText]="'Locked By ' + data?.lockedBy + ' For: '"
      [date]="data?.lockedUntil"
      style="width: 250px"
      (onCountDownEnd)="onCountDownEnd($event)"
    >
      <ng-container badge-text>
        <span class="font-medium white-space-nowrap">
          Locked By <strong> {{ data?.lockedBy }} </strong> For:
        </span>
      </ng-container>
    </app-count-down-view>
  </ng-template>
</p-overlayPanel>
