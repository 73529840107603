@if (widgetVisible) {
  <div
    style="
      height: 100%;
      display: block;
      border-top-width: 8px;
      border-top-style: solid;
      border-radius: var(--border-radius);
    "
    [style]="{ borderTopColor: activeItemViewObject?.color ? activeItemViewObject?.color : 'var(--primary-bg-400)' }"
  >
    <ng-container *ngTemplateOutlet="gridItemTemplate"></ng-container>
  </div>
}
<p-sidebar [(visible)]="sidebarVisible" [fullScreen]="true" [appendTo]="'body'">
  <ng-template pTemplate="header">
    <!-- <ng-container *ngTemplateOutlet="cardSwitch"></ng-container> -->
  </ng-template>
  @if (widgetVisible && sidebarVisible) {
    <ng-container *ngTemplateOutlet="gridItemTemplate"></ng-container>
  }
</p-sidebar>
<ng-template #gridItemTemplate>
  @if (activeItemViewObject?.type == 'TABLE' && activeItemViewObject?.targetType && activeItemViewObject?.filterView) {
    <app-widget-table
      class=""
      [title]="activeItemViewObject?.name"
      [cardContent]="activeItemViewObject"
      [styleClass]="'relative m-0 ' + (sidebarVisible ? '' : 'card')"
    >
      <ng-template let-config #headerTemplate>
        <ng-container *ngTemplateOutlet="cardSwitch"></ng-container>
      </ng-template>
      <ng-container *ngTemplateOutlet="topRightHeaderTemplate"></ng-container>
    </app-widget-table>
  } @else if (activeItemViewObject?.type == 'LINKS') {
    <app-widget-links
      [title]="activeItemViewObject?.name"
      [cardContent]="activeItemViewObject"
      [styleClass]="'relative m-0 ' + (sidebarVisible ? '' : 'card')"
    >
      <ng-template let-config #headerTemplate>
        <ng-container *ngTemplateOutlet="cardSwitch"></ng-container>
      </ng-template>
      <ng-container *ngTemplateOutlet="topRightHeaderTemplate"></ng-container>
    </app-widget-links>
  } @else if (
    activeItemViewObject?.type == 'CHART' &&
    activeItemViewObject?.filterView?.aggregationBody &&
    activeItemViewObject?.chartViewConfig?.chartType &&
    activeItemViewObject?.chartViewConfig?.viewType
  ) {
    <app-widget-chart
      [dashboardOjbect]="dashboardOjbect"
      [title]="activeItemViewObject?.name"
      [cardContent]="activeItem"
      [styleClass]="'relative m-0 ' + (sidebarVisible ? '' : 'card')"
      [showChartViewControls]="showEditControls"
      (onChartOptionsChange)="onChartOptionsChange($event)"
    >
      <ng-template let-config #headerTemplate>
        <ng-container *ngTemplateOutlet="cardSwitch"></ng-container>
      </ng-template>
      <ng-container *ngTemplateOutlet="topRightHeaderTemplate"></ng-container>
    </app-widget-chart>
  } @else if (activeItemViewObject?.type == 'STATISTICS' && activeItemViewObject?.statisticViewConfig?.collectorCode) {
    <app-widget-statistic-chart
      [title]="activeItemViewObject?.name"
      [cardContent]="activeItem"
      [styleClass]="'relative m-0 ' + (sidebarVisible ? '' : 'card')"
      [showChartViewControls]="showEditControls"
      (onChartOptionsChange)="onChartOptionsChange($event)"
    >
      <ng-template let-config #headerTemplate>
        <ng-container *ngTemplateOutlet="cardSwitch"></ng-container>
      </ng-template>
      <ng-container *ngTemplateOutlet="topRightHeaderTemplate"></ng-container>
    </app-widget-statistic-chart>
  } @else if (
    activeItemViewObject?.type == 'RECORD' &&
    activeItemViewObject?.targetType &&
    activeItemViewObject?.recordCode &&
    activeItemViewObject?.recordField?.length > 0
  ) {
    <app-widget-dynamic-form
      [title]="activeItemViewObject?.name"
      [cardContent]="activeItemViewObject"
      [styleClass]="'relative m-0 ' + (sidebarVisible ? '' : 'card')"
    >
      <ng-template let-config #headerTemplate>
        <ng-container *ngTemplateOutlet="cardSwitch"></ng-container>
      </ng-template>
      <ng-container *ngTemplateOutlet="topRightHeaderTemplate"></ng-container>
    </app-widget-dynamic-form>
  } @else if (
    activeItemViewObject?.type == 'DATA_COUNT' &&
    activeItemViewObject?.targetType &&
    activeItemViewObject?.filterView?.aggregationBody
  ) {
    <app-widget-count
      [title]="activeItemViewObject?.name"
      [cardContent]="activeItemViewObject"
      [styleClass]="'relative m-0 ' + (sidebarVisible ? '' : 'card')"
    >
      <ng-template let-config #headerTemplate>
        <ng-container *ngTemplateOutlet="cardSwitch"></ng-container>
      </ng-template>
      <ng-container *ngTemplateOutlet="topRightHeaderTemplate"></ng-container>
    </app-widget-count>
  } @else if (activeItemViewObject?.type == 'STATIC') {
    <div class="overflow-auto h-full">
      <div
        class="flex flex-column gap-2 relative"
        [class.card]="!sidebarVisible"
        [class.px-1]="sidebarVisible"
        style="min-height: calc(100%); margin: 0; height: 100%"
      >
        <ng-container *ngTemplateOutlet="cardSwitch"></ng-container>
        <app-html-text-view class="overflow-auto" [data]="activeItemViewObject?.staticViewData"></app-html-text-view>
        <ng-container *ngTemplateOutlet="topRightHeaderTemplate"></ng-container>
      </div>
    </div>
  } @else if (activeItemViewObject?.type == 'CUSTOM' && activeItemViewObject?.customViewConfig?.componentType) {
    <app-widget-custom
      [title]="activeItemViewObject?.name"
      [cardContent]="activeItemViewObject"
      [styleClass]="'relative m-0 ' + (sidebarVisible ? '' : 'card')"
    >
      <ng-template let-config #headerTemplate>
        <ng-container *ngTemplateOutlet="cardSwitch"></ng-container>
      </ng-template>
      <ng-container *ngTemplateOutlet="topRightHeaderTemplate"></ng-container>
    </app-widget-custom>
  } @else {
    <div class="overflow-auto h-full w-full">
      <div
        class="flex flex-column gap-2 relative h-full max-h-full"
        [class.card]="!sidebarVisible"
        [class.px-1]="sidebarVisible"
        style="min-height: calc(100%); margin: 0"
      >
        @if (!sidebarVisible) {
          <ng-container
            *ngTemplateOutlet="cardSwitch; context: { $implicit: { title: 'Missing Config' } }"
          ></ng-container>
        }
        <img [src]="'assets/layout/images/no-view-available.png'" style="height: 85%; object-fit: contain" />
        <div class="absolute top-0 right-0 flex flex-row-reverse gap-1 pt-3 pr-3">
          <!-- @if(!sidebarVisible){
                <app-button [action]="maximizeButtonAction"></app-button>
                } -->
          @if (stickyHeaderTemplate && !sidebarVisible) {
            <ng-container *ngTemplateOutlet="stickyHeaderTemplate"></ng-container>
          }
          <ng-container *ngTemplateOutlet="refreshTemplate"></ng-container>
        </div>
      </div>
    </div>
  }
</ng-template>
<ng-template let-config #cardSwitch>
  @if (editNameEnabled) {
    @if (activeItem) {
      <input
        type="text"
        placeholder="Title"
        (keydown.enter)="editName()"
        pInputText
        pAutoFocus
        [autofocus]="true"
        [(ngModel)]="activeItem.name"
      />
    }
  } @else {
    @if (widgetSwitchItems?.length > 1) {
      <app-drop-down-input
        [label]="''"
        [placeholder]="'Other Views'"
        [name]="'type'"
        [optionLabel]="'name'"
        [items]="widgetSwitchItems"
        [optionValue]="undefined"
        [dataKey]="'contentViewCode'"
        [control]="chartOptionsForm?.controls?.contentViews"
        [viewMode]="'create'"
        [showClear]="false"
        [badgeView]="false"
        [appendTo]="'body'"
        (onChanges)="swapViewByItem($event)"
        style="max-width: calc(50% - 36px); width: fit-content"
        class="field-m-0"
      >
        <ng-template let-value #selectedItemTemplate>
          <span class="">
            @if (value?.contentViewObject?.icon) {
              <i [class]="value?.contentViewObject?.icon + ' mr-1'"></i>
            }
            {{ value?.name }}
          </span>
        </ng-template>
        <ng-template let-option #itemTemplate>
          <!-- {{option | json}} -->
          <span class="">
            @if (option?.contentViewObject?.icon) {
              <i [class]="option?.contentViewObject?.icon + ' mr-1'"></i>
            }
            {{ option?.name }}
          </span>
        </ng-template>
      </app-drop-down-input>
    } @else if (widgetSwitchItems?.length > 0) {
      <span class="mb-2 text-xl line-clamp-1-ellipsis" style="max-width: calc(50% - 34px);">
        @if (widgetSwitchItems?.[0]?.contentViewObject?.icon) {
          <i [class]="widgetSwitchItems?.[0]?.contentViewObject?.icon + ' mr-1'"></i>
        }
        {{ config?.title || widgetSwitchItems?.[0]?.name || widgetSwitchItems?.[0]?.contentViewObject?.name }}
      </span>
    }
  }
  @if (showEditControls) {
    <app-button [action]="editNameAction"></app-button>
  }
</ng-template>
<ng-template let-options #topRightHeaderTemplate>
  <div class="absolute top-0 right-0 flex flex-row-reverse gap-1 pt-3 pr-3">
    @if (stickyHeaderTemplate && !sidebarVisible) {
      <ng-container *ngTemplateOutlet="stickyHeaderTemplate"></ng-container>
    }
    @if (!sidebarVisible) {
      <app-button [action]="maximizeButtonAction"></app-button>
    }
    <ng-container *ngTemplateOutlet="refreshTemplate"></ng-container>
  </div>
</ng-template>
<ng-template let-options #refreshTemplate>
  <app-button [action]="refreshWidgetAction"></app-button>
  <div
    [pTooltip]="'Last Fetch: ' + (widgetLastRenderTime | date: 'yyyy-MM-dd HH:mm:ss')"
    [tooltipPosition]="'top'"
    class="flex flex-row gap-1 align-items-center justify-content-end"
    style="align-self: center"
  >
    <span
      class="text-500 text-sm"
      timeago
      [date]="widgetLastRenderTime | date: 'yyyy-MM-dd HH:mm:ss'"
      style="font-variant-numeric: lining-nums"
    ></span>
    <!-- <i class="text-500 text-lg fas fa-clock-rotate-left"></i> -->
  </div>
</ng-template>
