<app-input-view-switch
  [showLabelInViewMode]="showLabelInViewMode"
  [mode]="viewMode"
  [data]="data || control?.value"
  [dataType]="dataType"
  [label]="label"
  [keepValueCallback]="setFieldResetable.bind(this)"
  [resetFieldCheckVisable]="resetFieldCheckVisable"
  [resetableChecked]="resetable"
>
  <div class="field" [class.mb-1]="control?.dirty && control?.hasError('required')">
    <span class="p-float-label" [class.p-input-icon-left]="leftIcon" [class.p-input-icon-right]="rightIcon">
      @if (leftIcon) {
        <i [class]="' pi ' + leftIcon"></i>
      }
      @if (rightIcon) {
        <i [class]="' pi ' + rightIcon"></i>
      }
      <p-inputMask class="w-full" [formControl]="fControl" [placeholder]="placeholder" [mask]="inputMask"></p-inputMask>
      <label
        >{{ label }}
        @if (isControlRequired) {
          <span class="text-red-500"> *</span>
        }
        @if (resetFieldCheckVisable) {
          <br />
          <app-reset-switch-input
            [checked]="!resetable"
            (onChange)="setFieldResetable($event)"
          ></app-reset-switch-input>
        }
      </label>
    </span>
    @if (control?.dirty) {
      @if (control?.hasError('required')) {
        <small class="p-error p-invalid">{{ label }} {{ 'is Required' }}</small>
      }
      @if (control?.hasError('pattern')) {
        <small class="p-error p-invalid">{{ label }} {{ patternError }}</small>
      }
      @if (control?.hasError('minlength')) {
        <small class="p-error p-invalid">
          {{ label }} must have a minimum length of {{ control?.errors?.minlength?.requiredLength }}
        </small>
      }
      @if (control?.hasError('maxlength')) {
        <small class="p-error p-invalid">
          {{ label }} must have a maximum length of {{ control?.errors?.maxlength?.requiredLength }}
        </small>
      }
      @if (control?.hasError('min')) {
        <small class="p-error p-invalid">
          {{ label }} must be greater than or equal to {{ control?.errors?.min?.min }}
        </small>
      }
      @if (control?.hasError('max')) {
        <small class="p-error p-invalid">
          {{ label }} must be less than or equal to {{ control?.errors?.max?.max }}
        </small>
      }
      @if (customError && control?.invalid) {
        <small class="p-error p-invalid">{{ customErrorLabel }}</small>
      }
    }
  </div>
</app-input-view-switch>
