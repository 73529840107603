import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import {
  AppPermissions,
  Base,
  DataTypeEnum,
  EntityTypeFieldDto,
  ITablePageable,
  IViewMode,
  SearchBody,
  getEnumOptions,
} from '@shared/classes';
import { AppDialogService } from '@shared/services/app-dialog.service';
import { RoleDataService } from 'app/modules/users/services/data/role-data.service';
import { uniqBy } from 'lodash-es';
import { NgxPermissionsService } from 'ngx-permissions';
import { PickList } from 'primeng/picklist';
import { debounceTime, distinctUntilChanged, forkJoin, takeUntil } from 'rxjs';
import { EntityFilterPopupComponent } from '../risk-entities-selector/entity-filter-popup/entity-filter-popup.component';

@Component({
  selector: 'app-role-picklist-selector',
  templateUrl: './role-picklist-selector.component.html',
  styleUrls: ['./role-picklist-selector.component.scss'],
})
export class RolePicklistSelectorComponent extends Base implements OnInit {
  customProjectionFields: string[] = ['id', 'name', 'name', 'owner', 'recordStatus'];
  pageInfo: ITablePageable = new ITablePageable();

  loading: boolean = false;
  originalElements: any[] = [];
  sourceElements: any[] = [];

  @Input()
  targetElements: any[] = [];

  @ViewChild('pickList', { static: false }) pickList: PickList;

  @Output()
  resultlements: EventEmitter<any[]> = new EventEmitter();
  @Output()
  initialSetElements: EventEmitter<any[]> = new EventEmitter();
  @Input() viewMode?: IViewMode = IViewMode.edit;
  @Input() showCategoriesSelector?: boolean = false;
  @Input() categoryList: EntityTypeFieldDto.CategoryEnum[] = [];
  @Input() excludeRoles: string[] = [];
  @Input() showTargetFilter?: boolean = false;
  dataType: DataTypeEnum = DataTypeEnum.Text;
  sourceFilterValue = '';
  appliedSearchBody: SearchBody = {
    projectionFields: ['id', 'name', 'name', 'owner', 'recordStatus'],
    filters: [{ property: 'category', operation: 'IN', value: this.categoryList }],
  };
  listOfCategories = getEnumOptions(EntityTypeFieldDto.CategoryEnum);
  categoriesControl = new FormControl([]);
  readonly readRolePermission = AppPermissions.ReadRole;
  constructor(
    private service: RoleDataService,
    private _dialogService: AppDialogService,
    private permissionService: NgxPermissionsService
  ) {
    super();
  }

  ngOnInit(): void {
    if (!this.targetElements) this.targetElements = [];
    if (!this.sourceElements) this.sourceElements = [];
    this.appliedSearchBody = {
      projectionFields: ['id', 'name', 'name', 'owner', 'recordStatus'],
      filters: [{ property: 'category', operation: 'IN', value: this.categoryList }],
    };
    // this.fetchData();
    this.categoriesControl.valueChanges
      .pipe(debounceTime(500), distinctUntilChanged(), takeUntil(this.destroy$))
      .subscribe((data) => {
        this.categoryList = data;
        this.fetchData();
      });
    this.categoriesControl.patchValue(this.categoryList);
  }
  fetchData() {
    this.permissionService.hasPermission(this.readRolePermission).then((isAllowed) => {
      if (isAllowed) {
        this.loading = true;
        forkJoin({
          users: this.service.getClientRoleList({ showLoading: false, showMsg: false, params: { size: 650 } }),
        })
          .pipe(takeUntil(this.destroy$))
          .subscribe({
            next: (data) => {
              this.loading = false;
              let list = [];

              list = ((data?.users as any)?.content as any[]).filter((x) => !this.excludeRoles.includes(x.name));

              this.originalElements = [...list];
              this.sourceElements = [...list];

              if (this.targetElements && this.targetElements.length > 0) {
                this.targetElements = this.sourceElements.filter(
                  (item) =>
                    !!this.targetElements.find(
                      (ell) =>
                        (ell?.name != undefined && item?.name != undefined && ell.name == item.name) ||
                        ell?.id == item?.id ||
                        (item?.name != undefined && ell == item?.name)
                    )
                );
                this.sourceElements = this.sourceElements.filter(
                  (item) =>
                    !this.targetElements.find(
                      (ell) =>
                        (ell?.name != undefined && item?.name != undefined && ell.name == item.name) ||
                        ell?.id == item?.id ||
                        (item?.name != undefined && ell == item?.name)
                    )
                );
              }

              this.initialSetElements.emit(this.targetElements);
              this.resultlements.emit(this.targetElements);
            },
            error: (err) => {
              this.loading = false;
            },
          });
      }
    });
  }
  onChange() {
    this.resultlements.emit(this.targetElements);
  }

  onCreateReq(): void {
    this._dialogService.showDialog(
      EntityFilterPopupComponent,
      'Apply Filter',
      (e) => {
        this._addRecord(e);
        this.resetPickListFilter();
      },
      {
        data: {
          categoryList: this.categoryList,
        },
      }
    );
    // this._dialogService.open({
    //     title: 'Apply Filter',
    //     dialogType: DialogTypeEnum.form,
    //     componentType: EntityFilterPopup,
    //     submitCallBack: (data: any) => {
    //         this._addRecord(data);
    //     }
    // });
  }

  private _addRecord(entity): void {
    if (entity && entity?.entities?.length) {
      this.targetElements = this.originalElements?.filter((el) => !!entity?.entities.find((el2) => el2 == el?.name));

      this.sourceElements = this.originalElements?.filter((el) => !entity?.entities.find((el2) => el2 == el?.name));

      // this.targetElements.push(...entity?.entities)

      this.sourceElements = uniqBy(this.sourceElements, 'name');
      this.targetElements = uniqBy(this.targetElements, 'name');

      this.resultlements.emit(this.targetElements);
    }

    // this._dialogService.ref.close();
  }
  setCategories(cats) {
    // this.categoryList = cats;
    // this.fetchData();
  }

  resetPickListFilter() {
    if (this.pickList) {
      this.pickList.filterValueSource = '';

      this.sourceFilterValue = ''; // Set sourceFilterValue to an empty string
    }
  }
}
