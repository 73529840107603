<app-input-view-switch
  [showLabelInViewMode]="showLabelInViewMode"
  [mode]="viewMode"
  [data]="data || control?.value"
  [dataType]="dataType"
  [label]="label"
  [keepValueCallback]="setFieldResetable.bind(this)"
  [resetFieldCheckVisable]="resetFieldCheckVisable"
  [resetableChecked]="resetable"
>
  <div class="field w-full" [class.mb-1]="control?.dirty && control?.hasError('required')">
    <span class="mt-1 w-full">
      <!-- <ngx-slider [(value)]="value" [options]="options"></ngx-slider> -->
    </span>

    @if (control?.dirty) {
      @if (control?.hasError('required')) {
        <small class="p-error p-invalid">{{ label }} {{ 'is Required' }}</small>
      }
    }
    @if (resetFieldCheckVisable) {
      <br />
      <app-reset-switch-input [checked]="!resetable" (onChange)="setFieldResetable($event)"></app-reset-switch-input>
    }
  </div>
</app-input-view-switch>
