import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { BaseForm } from '@shared/classes';
import { ViewModeService } from '@shared/services/view-mode.service';

@Component({
  selector: 'app-add-watchers-form',
  templateUrl: './add-watchers-form.component.html',
  styleUrls: ['./add-watchers-form.component.scss'],
})
export class AddWatchersFormComponent extends BaseForm<any> implements OnInit {
  constructor(public viewModeService: ViewModeService) {
    super(viewModeService);
  }

  ngOnInit(): void {}

  getData() {
    return this.formGroup.getRawValue();
  }
  setData(data: any) {
    this.formGroup.patchValue(data);
    this.data = data;
  }

  initFormStructure(): void {
    this.formGroup = new FormGroup({
      principle: new FormControl(null),
    });
  }
}
