<form [formGroup]="formGroup" (ngSubmit)="onSubmitForm()">
  <div class="formgrid grid p-fluid">
    <div class="col-12 md:col-4">
      <app-basic-input
        [label]="'Text Field'"
        [placeholder]="'Enter Data'"
        [control]="formGroup?.controls?.text"
      ></app-basic-input>
    </div>
    <div class="col-12 md:col-4">
      <app-basic-input
        [label]="'Email Field'"
        [placeholder]="'Enter Email'"
        [control]="formGroup?.controls?.email"
        [type]="'email'"
      ></app-basic-input>
    </div>
    <div class="col-12 md:col-4">
      <app-number-input
        [label]="'Number Field'"
        [placeholder]="'1234567890'"
        [name]="'number'"
        [showButtons]="true"
        [control]="formGroup?.controls?.number"
        [type]="'number'"
      ></app-number-input>
    </div>
    <div class="col-12 md:col-4">
      <app-date-input
        [label]="'Date Field'"
        [placeholder]="'11/22/2022'"
        [name]="'date'"
        [showIcon]="true"
        [control]="formGroup?.controls?.date"
      ></app-date-input>
    </div>
    <div class="col-12 md:col-4">
      <app-drop-down-input
        [label]="'Dropdown Field'"
        [placeholder]="'Select Item'"
        [name]="'dropdown'"
        [optionLabel]="'name'"
        [items]="[
          { name: 'Option 1', value: 'op1', id: 1 },
          { name: 'Option 2', value: 'op2', id: 2 },
        ]"
        [optionValue]="'value'"
        [control]="formGroup?.controls?.dropdown"
      ></app-drop-down-input>
    </div>
    <div class="col-12 md:col-4">
      <app-drop-down-input
        [label]="'Multi Dropdown Field'"
        [placeholder]="'Select Items'"
        [name]="'multi-dropdown'"
        [optionLabel]="'name'"
        [multi]="true"
        [items]="[
          { name: 'Option 1', value: 'op1', id: 1 },
          { name: 'Option 2', value: 'op2', id: 2 },
        ]"
        [optionValue]="'value'"
        [control]="formGroup?.controls?.multiDropdown"
      ></app-drop-down-input>
    </div>
    <div class="col-12 md:col-4">
      <app-check-box [label]="'Checkbox'" [name]="'checkbox'" [control]="formGroup?.controls?.checkbox">
      </app-check-box>
    </div>
    <div class="col-12 md:col-4">
      <app-switch-input [label]="'Switch Slider'" [name]="'switch'" [control]="formGroup?.controls?.slider">
      </app-switch-input>
    </div>
    <div class="col-12 md:col-4">
      <app-radio-group
        [name]="'radio'"
        [control]="formGroup?.controls?.radioGroup"
        [groupItems]="[
          { label: 'Option 1', value: 1 },
          { label: 'Option 2', value: 'option 2' },
          { label: 'Option 3', value: 'op 3' },
        ]"
      >
      </app-radio-group>
    </div>
    <div class="col-12 md:col-4">
      <app-text-input
        [rows]="7"
        [name]="'textarea'"
        [label]="'Text Area'"
        [placeholder]="'Enter Text...'"
        [control]="formGroup?.controls?.textArea"
      ></app-text-input>
    </div>
    <div class="col-12 md:col-8">
      <app-text-editor
        [height]="'100px'"
        [advanced]="true"
        [name]="'textarea'"
        [label]="'Text Editor'"
        [placeholder]="'Enter Text...'"
        [control]="formGroup?.controls?.textEditor"
      ></app-text-editor>
    </div>
  </div>
  <div class="flex flex-row-reverse gap-2">
    @if (fieldViewMode != 'view') {
      <app-button [action]="submitButtonAction"></app-button>
    }
    @if (showSaveButton) {
      <app-button [action]="submitSaveButtonAction"></app-button>
    }
  </div>
</form>
