import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import {
  DataTypeEnum,
  DynamicComponentBase,
  FilterItem,
  ITablePageable,
  IViewMode,
  ModuleIcons,
  TargetTypeEnum,
} from '@shared/classes';
import { PathResolverService } from '@shared/services/path-resolver.service';
import { EntityDataService } from 'app/modules/entity-module/entity/entity-data.service';
import { AssetsDataService } from 'app/modules/entity-module/services/data/assets-data.service';
import { EntityTypesDataService } from 'app/modules/entity-module/services/data/entity-types-data.service';
import { EventsDataService } from 'app/modules/entity-module/services/data/events-data.service';
import { GeographiesDataService } from 'app/modules/entity-module/services/data/geographies-data.service';
import { ObjectivesDataService } from 'app/modules/entity-module/services/data/objectives-data.service';
import { ProcessesDataService } from 'app/modules/entity-module/services/data/processes-data.service';
import { ResponsibilitiesDataService } from 'app/modules/entity-module/services/data/responsibilities-data.service';
import { groupBy, uniqBy } from 'lodash-es';
import { TreeNode } from 'primeng/api';

@Component({
  selector: 'app-target-code-tree-selector',
  templateUrl: './target-code-tree-selector.component.html',
  styleUrls: ['./target-code-tree-selector.component.scss'],
})
export class TargetCodeTreeSelectorComponent extends DynamicComponentBase implements OnInit {
  @Input() multi = false;
  @Input() items: any[] = [];
  @Input() returnObject: boolean = false;
  @Input() excludeId: string = null;
  @Input() dataKey: string = 'code';
  @Input() customProjectionFields: string[] = ['id', 'recordStatus', 'code', 'name', 'parent', 'label'];
  @Input() appendTo: string = null;

  @Input() dropDown: boolean = true;
  optionsLoaded: boolean = false;
  pageInfo: ITablePageable = new ITablePageable();
  uiChange: boolean = false;
  @Input() set selectedItems(items: String[] | any[]) {
    this.setSelectedItems(items);
  }
  @Input() excludeCodes: string[] = [];
  @Input() set extraFilters(filters: FilterItem[]) {
    this._extraFilters = filters;
    this.getOptions();
  }
  private _extraFilters: FilterItem[] = [];
  get extraFilters() {
    return this._extraFilters;
  }
  @Input() set targetType(type: TargetTypeEnum) {
    this._targetType = type;
    this.getOptions();
  }
  private _targetType: TargetTypeEnum;
  get targetType() {
    return this._targetType;
  }

  isLoading: boolean = false;

  selectedNodes: any = null;
  groupedItems: { [index: string]: any[] };
  formattedItems: TreeNode[];

  visited: any = {};
  treeNodes: any = {};
  editModalVisible: boolean = false;
  editModalControl: FormControl = new FormControl(null);

  constructor(
    private service: EntityDataService,
    private respService: ResponsibilitiesDataService,
    private eventService: EventsDataService,
    private assetService: AssetsDataService,
    private geoService: GeographiesDataService,
    private proService: ProcessesDataService,
    private objService: ObjectivesDataService,
    private entityTypeService: EntityTypesDataService,
    private pathResolverService: PathResolverService
  ) {
    super();
    this.dataType = DataTypeEnum.CodeLink;
  }

  ngOnInit() {
    // if(this.viewMode != 'view')
    //     this.getOptions();
    if (this.fControl) {
      this.editModalControl.patchValue(this.control?.getRawValue());
      this.subs.sink = this.fControl.valueChanges.subscribe((changes) => {
        if (!this.uiChange) {
          this.selectedItems = this.multi ? changes : [changes];
        } else {
          this.uiChange = false;
        }
        this.editModalControl.patchValue(this.control?.getRawValue());
      });
    }
  }
  // onSetViewMode(){
  //     if(this.viewMode == 'edit')
  //         this.getOptions();
  // }
  setSelectedItems(list: any[]) {
    let newSelections = null;
    Object.keys(this.treeNodes).forEach((nodeId) => {
      let nodeIdx = this.checkIfIncluded(nodeId, list);
      if (nodeIdx != -1) {
        if (this.multi) {
          if (!newSelections) newSelections = [];
          newSelections.push(this.treeNodes[nodeId]);
        } else {
          newSelections = this.treeNodes[nodeId];
        }
      }
    });
    this.selectedNodes = newSelections;
  }
  getOptions(filters: any = []) {
    this.isLoading = true;
    if (!this.targetType) return;
    if (this.excludeCodes?.length > 0) {
      filters.push({
        property: 'code',
        operation: this.excludeCodes?.length == 1 ? 'NOT_EQUAL' : 'NOT_IN',
        value: this.excludeCodes?.length == 1 ? this.excludeCodes[0] : this.excludeCodes,
      });
    }
    filters.push(...this.extraFilters);
    filters.push({ property: 'recordStatus', operation: 'IN', value: ['LOCKED', 'ACTIVE'] });

    this.isLoading = true;
    this.subs.sink = this.pathResolverService
      .getDataServiceByTargetType(this.targetType)
      .search<any>(
        { ...this.pageInfo.pagination, all: true },
        { filters: filters, projectionFields: this.customProjectionFields },
        { showLoading: false, showMsg: false }
      )
      .subscribe({
        next: (res: any) => {
          // this.items = res?.content?.map(x=> {return {...x,label:x.label +' '+x.code}});
          // this.pageInfo.totalElements = res?.totalElements;
          this.pageInfo.totalElements = res?.length;

          let list = (res as any[]) || [];

          // list.forEach(element => {
          //     element.category='RESPONSIBILITIES';
          // });
          this.formattedItems = [];

          // this.groupedItems1 = groupBy(list, 'category');

          this.selectedNodes = null;

          let i = 0;
          this.formattedItems.push({
            label: this.targetType + '', //toPascalCase(category),
            expandedIcon: ModuleIcons[this.targetType],
            collapsedIcon: ModuleIcons[this.targetType],
            children: [],
            expanded: true,
            selectable: false,
          });

          this.groupedItems = groupBy(list, 'parent');

          this.visited = {};
          this.formatDataForTreeView(list, i);
          this.items = uniqBy(list, 'code');
        },
        complete: () => {
          this.isLoading = false;
        },
      });
  }
  setInputOptions() {
    this.multi = this.inputOptions?.dropDownInput?.multi ?? this.multi;
    this.items = this.inputOptions?.dropDownInput?.items ?? this.items;
    this.dropDown = this.inputOptions?.dropDownInput?.treeDropDown ?? this.dropDown;
    this.appendTo = this.inputOptions?.dropDownInput?.appendTo ?? this.appendTo;
    // this.categoryList = this.inputOptions?.extra?.categoryList ?? this.categoryList;
  }
  onValueChanges(value: any) {
    // this.data = value;
    this.uiChange = true;
    if (this.multi) {
      if (this.returnObject) {
        let values: any[] = value?.map((item) => item?.data);
        this.fControl.patchValue(values);
      } else {
        let values: string[] = value?.map((item) => item?.data[this.dataKey]);
        this.fControl.patchValue(values);
      }
    } else {
      if (this.returnObject) {
        this.fControl.patchValue(value?.data);
      } else {
        this.fControl.patchValue(value?.data[this.dataKey]);
      }
    }
  }
  onNodeUnselect(event) {
    event.node.data.mode = undefined;
  }
  formatDataForTreeView(data: any[], index) {
    this.formatDataForTreeViewRecursive(data, index);
  }
  formatDataForTreeViewRecursive(data: any[], index1) {
    this.formattedItems[index1].data = data;
    this.formattedItems[index1].type = 'root';

    this.groupedItems['null']?.forEach((el, index) => {
      if (!!!this.visited[el[this.dataKey]]) {
        this.visited[el[this.dataKey]] = true;
        if (!(this.excludeId && el[this.dataKey] == this.excludeId))
          this.formattedItems[index1].children.push(
            this.getFormattedChildren(el[this.dataKey], el, this.formattedItems[index1].expandedIcon)
          );
      }
    });
  }
  getFormattedChildren(code, data: any, parentIcon): TreeNode {
    let node: TreeNode = {
      label: data.label || data.name,
      data: data,
      expandedIcon: parentIcon, //this.entityTypeMap[data?.type]?.icon ?? parentIcon,
      collapsedIcon: parentIcon, // this.entityTypeMap[data?.type]?.icon ?? parentIcon,
      icon: parentIcon, //this.entityTypeMap[data?.type]?.icon ?? parentIcon,
      children: [],
      expanded: true,
      selectable: this.viewMode != 'view',
      key: data[this.dataKey],
    };
    this.treeNodes[code] = node;
    if (this.checkIfIncludedInValue(code)) {
      if (this.multi) {
        if (!this.selectedNodes) this.selectedNodes = [];
        this.selectedNodes.push(node);
      } else {
        this.selectedNodes = node;
      }
    }
    if (!!this.groupedItems[code]) {
      this.groupedItems[code].forEach((el, index) => {
        if (!!!this.visited[el[this.dataKey]]) {
          this.visited[el[this.dataKey]] = true;
          let ret = this.getFormattedChildren(el[this.dataKey], el, node.icon);
          if (!(this.excludeId && el[this.dataKey] == this.excludeId)) node.children.push(ret);
        } else {
        }
      });
    } else {
      return node;
    }
    return node;
  }
  checkIfIncludedInValue(code) {
    if (!this.fControl?.value) return false;
    if (!this.multi)
      return this.returnObject
        ? (this.fControl.value[this.dataKey] as String) == code
        : (this.fControl.value as String) == code;
    return (
      (this.fControl?.value as String[])?.findIndex((x) => (this.returnObject ? x[this.dataKey] == code : x == code)) !=
      -1
    );
  }
  getMatchingValue(code) {
    if (this.multi) {
      return (this.fControl?.value as String[])?.find((x) => (this.returnObject ? x[this.dataKey] == code : x == code));
    } else {
      return this.fControl.value;
    }
  }
  // checkIfIncludedInSelectedNodes(code) {
  //     if (!this.selectedNodes) return false;
  //     return ((this.selectedNodes)?.findIndex(x => x.data[this.dataKey] == code) != -1)
  // }
  checkIfIncluded(code, data: any[]) {
    if (!data) return -1;
    return data?.findIndex((x) => (this.returnObject ? (x[this.dataKey] as String) == code : (x as String) == code));
  }
  onChangeRespMode(mode: { originalEvent?: any; value?: IViewMode }, node) {
    mode?.originalEvent?.stopPropagation();
    this.uiChange = true;
    if (this.multi) {
      let values = [...this.fControl.getRawValue()];
      let ind = values?.findIndex((x) => x?.code === node?.data?.code);
      if (ind != -1) {
        values[ind] = { code: values[ind].code, mode: mode?.value };
        this.fControl.patchValue(values);
      }
    } else {
      let val = { ...this.fControl.getRawValue() };
      if (val.code === node?.data?.code) {
        val = { ...val, mode: mode?.value };
        this.fControl.patchValue(val);
      }
    }
  }
  onOptionClick(event: { originalEvent?: any; option?: any; index?: any }) {
    event?.originalEvent?.stopPropagation();
  }
  openEditModal() {
    if (!!this.linkedControl?.invalid || this.linkedControl?.disabled || this.fControl.disabled) return;
    this.editModalControl.patchValue(this.control?.getRawValue());
    this.editModalVisible = true;
  }
  onFocusChange(event) {
    this.openEditModal();
  }
}
