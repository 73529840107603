<app-dashlet class="widget-country-graph" [config]="{ title: title }">
  <ul class="list-none p-0 m-0">
    @for (item of notifications; track item; let last = $last) {
      <li>
        <app-notification-mini-item [notification]="item"></app-notification-mini-item>
      </li>
    }
  </ul>
  @if (isLoading) {
    <div>
      <div class="mt-3">
        <ul class="m-0 p-0 list-none">
          <li class="mb-3">
            <div class="flex">
              <p-skeleton shape="circle" size="4rem" styleClass="mr-2"></p-skeleton>
              <div style="flex: 1">
                <p-skeleton width="100%" styleClass="mb-2"></p-skeleton>
                <p-skeleton width="75%"></p-skeleton>
              </div>
            </div>
          </li>
          <li class="mb-3">
            <div class="flex">
              <p-skeleton shape="circle" size="4rem" styleClass="mr-2"></p-skeleton>
              <div style="flex: 1">
                <p-skeleton width="100%" styleClass="mb-2"></p-skeleton>
                <p-skeleton width="75%"></p-skeleton>
              </div>
            </div>
          </li>
          <li class="mb-3">
            <div class="flex">
              <p-skeleton shape="circle" size="4rem" styleClass="mr-2"></p-skeleton>
              <div style="flex: 1">
                <p-skeleton width="100%" styleClass="mb-2"></p-skeleton>
                <p-skeleton width="75%"></p-skeleton>
              </div>
            </div>
          </li>
          <li class="mb-3">
            <div class="flex">
              <p-skeleton shape="circle" size="4rem" styleClass="mr-2"></p-skeleton>
              <div style="flex: 1">
                <p-skeleton width="100%" styleClass="mb-2"></p-skeleton>
                <p-skeleton width="75%"></p-skeleton>
              </div>
            </div>
          </li>
          <li>
            <div class="flex">
              <p-skeleton shape="circle" size="4rem" styleClass="mr-2"></p-skeleton>
              <div style="flex: 1">
                <p-skeleton width="100%" styleClass="mb-2"></p-skeleton>
                <p-skeleton width="75%"></p-skeleton>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  }
  <div class="w-full p-2">
    @if (this.notificationPage?.number + 1 < this.notificationPage?.totalPages) {
      <app-button [action]="loadMoreAction"></app-button>
    }
    @if (!isLoading && !(this.notificationPage?.number + 1 < this.notificationPage?.totalPages)) {
      <p class="w-full text-center text-primary-400">Reached Last Notification</p>
    }
  </div>
</app-dashlet>
