import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import {
  DataTypeEnum,
  DynamicComponentBase,
  DynamicFieldConfig,
  FieldDto,
  IDynamicComponent,
  getFieldValidators,
} from '@shared/classes';
import { TargetCodeSelectorComponent } from '@shared/components/selectors/target-code-selector/target-code-selector.component';
import { Subject, takeUntil } from 'rxjs';
import { AttachmentInputComponent } from '../attachment-input/attachment-input.component';
import { BasicInputComponent } from '../basic-input/basic-input.component';
import { IpInputComponent } from '../customised-fields/ip-input/ip-input.component';
import { IP6InputComponent } from '../customised-fields/ip6-input/ip6-input.component';
import { MacInputComponent } from '../customised-fields/mac-input/mac-input.component';
import { DateInputComponent } from '../date-input/date-input.component';
import { DropDownInputComponent } from '../drop-down-input/drop-down-input.component';
import { MCQComponent } from '../mcq/mcq.component';
import { NumberInputComponent } from '../number-input/number-input.component';
import { RatingInputComponent } from '../rating-input/rating-input.component';
import { SwitchInputComponent } from '../switch-input/switch-input.component';
import { TextEditorComponent } from '../text-editor/text-editor.component';
import { Ipv4MaskInputComponent } from '../customised-fields/ipv4-mask-input/ipv4-mask-input.component';
import { GvlSelectorComponent } from '@shared/components/selectors/gvl-selector/gvl-selector.component';

@Component({
  selector: 'app-dynamic-field-list-array-input',
  templateUrl: './dynamic-field-list-array-input.component.html',
  styleUrls: ['./dynamic-field-list-array-input.component.scss'],
  providers: [{ provide: DynamicComponentBase, useExisting: DynamicFieldListArrayInputComponent }],
})
export class DynamicFieldListArrayInputComponent extends DynamicComponentBase implements OnInit {
  dataType: DataTypeEnum = DataTypeEnum.Text;
  dynamicFields: IDynamicComponent[] = [];
  private formValid = new Subject<boolean>();
  private _config;
  @Input() set config(conf: {
    dynamicFields: { [key: string]: FieldDto };
    dynamicFieldValues: { [key: string]: any };
  }) {
    this._config = conf;
    this.initInputFields();
  }
  get config() {
    return this._config;
  }

  constructor(private fb: FormBuilder) {
    super();
  }

  ngOnInit(): void {
    // this.initInputFields();
  }
  setInputOptions() {}
  initInputFields() {
    let fields: IDynamicComponent[] = [];

    this.fControl.statusChanges.pipe(takeUntil(this.destroy$)).subscribe((res) => {
      this.formValid.next(res == 'VALID');
    });
    (this.fControl as any as FormArray)?.clear();
    // if (this.config.dynamicFieldValues) {
    //     fields = this.config.dynamicFieldValues?.map((field, i) => {
    //         let fb: FormGroup = new FormGroup({
    //             name:       new FormControl(field.name),
    //             label:      new FormControl(field.label),
    //             type:       new FormControl(field.type),
    //             options:    new FormControl(field?.options),
    //             required:   new FormControl(field.required),
    //             value:      new FormControl(field.value, getFieldValidators(field?.options))
    //         });
    //         if((field as any).question) fb.addControl('question', new FormControl((field as any).question));
    //         ((this.fControl as any) as FormArray).push(fb);
    //         return {
    //             componentType: DynamicFieldMap[field.type],
    //             options: {
    //                 label: field.label,
    //                 name: field.name,
    //                 control: fb.controls.value,
    //                 inputOptions: {
    //                     numberInput: {
    //                         maxFractionDigits: field.type == 'INTEGER' ? 0 : (field?.options?.[DynamicFieldConfig.NUMBER_MAX_FRACTIONS] ?? 5),
    //                         showButtons: true,
    //                         min: (field?.options?.[DynamicFieldConfig.GENERAL_MIN] ?? undefined),
    //                         max: (field?.options?.[DynamicFieldConfig.GENERAL_MAX] ?? undefined)
    //                     },
    //                     attachmentInput:{
    //                         bucketId:(field?.options?.[DynamicFieldConfig.ATTACHMENT_FOLDER] ?? 'root'),
    //                         mode :('basic'),
    //                         multiple : (field?.options?.[DynamicFieldConfig.ATTACHMENT_MULTIPLE] ?? false),
    //                         accept : (field?.options?.[DynamicFieldConfig.ATTACHMENT_ACCEPT] ?? null),
    //                         maxFileSize : (field?.options?.[DynamicFieldConfig.ATTACHMENT_MAX_FILE_SIZE] ?? null),
    //                         fileLimit : (field?.options?.[DynamicFieldConfig.ATTACHMENT_FILE_LIMIT] ?? null),
    //                     },
    //                     dropDownInput:{
    //                         items: (field?.options?.[DynamicFieldConfig.LIST_OPTIONS]? ( (field?.options?.[DynamicFieldConfig.LIST_OPTIONS] as string[]).map(x=>{return {label:x,value:x}}) ) : []),
    //                         multi:field.type == DynamicFiledValue.TypeEnum.Multiselect ?  true : false,
    //                         optionLabel:'label',
    //                         optionValue:'value',
    //                     },
    //                     dateInput:{
    //                         minDate : (field?.options?.[DynamicFieldConfig.MIN_DATE] ?? null),
    //                         maxDate : (field?.options?.[DynamicFieldConfig.MAX_DATE] ?? null),
    //                         showTime : field.type == DynamicFiledValue.TypeEnum.Datetime ? true : false,
    //                         selectionMode : (field?.options?.[DynamicFieldConfig.DATE_SELECTION_MODE] ?? 'single'),
    //                         mode : (field?.options?.[DynamicFieldConfig.DATE_VIEW_MODE] ?? 'date')
    //                     },
    //                     ratingInput:{
    //                         maxNumber: (field?.options?.[DynamicFieldConfig.RATING_MAX_NUMBER] ?? 5)
    //                     },
    //                     extra: field,
    //                 },
    //                 inputMask:(field?.options?.[DynamicFieldConfig.INPUT_MASK] ?? null),
    //                 viewMode: this.viewMode
    //             }
    //         }
    //     })
    // } else {
    fields = Object.keys(this.config.dynamicFields)?.map((key, i) => {
      const field = this.config.dynamicFields[key];
      let fb: FormGroup = new FormGroup({
        name: new FormControl(field.name),
        label: new FormControl(field.label),
        type: new FormControl(field.type),
        options: new FormControl(field?.options),
        // required:   new FormControl(field?.required),
        value: new FormControl(
          this.config?.dynamicFieldValues?.[field.name] ?? null,
          getFieldValidators(field?.options)
        ),
      });
      if ((field as any).question) fb.addControl('question', new FormControl((field as any).question));
      (this.fControl as any as FormArray).push(fb);
      return {
        componentType: DynamicFieldMap[field.type],
        options: {
          label: field.label,
          name: field.name,
          control: fb.controls.value,
          inputOptions: {
            numberInput: {
              maxFractionDigits:
                field.type == 'INTEGER' ? 0 : (field?.options?.[DynamicFieldConfig.NUMBER_MAX_FRACTIONS] ?? 5),
              showButtons: true,
              min: field?.options?.[DynamicFieldConfig.GENERAL_MIN] ?? undefined,
              max: field?.options?.[DynamicFieldConfig.GENERAL_MAX] ?? undefined,
            },
            attachmentInput: {
              bucketId: field?.options?.[DynamicFieldConfig.ATTACHMENT_FOLDER] ?? 'root',
              mode: 'basic',
              multiple: field?.options?.[DynamicFieldConfig.ATTACHMENT_MULTIPLE] ?? false,
              accept: field?.options?.[DynamicFieldConfig.ATTACHMENT_ACCEPT] ?? null,
              maxFileSize: field?.options?.[DynamicFieldConfig.ATTACHMENT_MAX_FILE_SIZE] ?? null,
              fileLimit: field?.options?.[DynamicFieldConfig.ATTACHMENT_FILE_LIMIT] ?? null,
            },
            dropDownInput: {
              items: field?.options?.[DynamicFieldConfig.LIST_OPTIONS]
                ? (field?.options?.[DynamicFieldConfig.LIST_OPTIONS] as string[]).map((x) => {
                    return { label: x, value: x };
                  })
                : [],
              multi: field.type == FieldDto.TypeEnum.Multiselect ? true : false,
              optionLabel: 'label',
              optionValue: 'value',
            },
            dateInput: {
              minDate: field?.options?.[DynamicFieldConfig.MIN_DATE]
                ? new Date(field?.options?.[DynamicFieldConfig.MIN_DATE])
                : null,
              maxDate: field?.options?.[DynamicFieldConfig.MAX_DATE]
                ? new Date(field?.options?.[DynamicFieldConfig.MAX_DATE])
                : null,
              showTime: field.type == FieldDto.TypeEnum.Datetime ? true : false,
              selectionMode: field?.options?.[DynamicFieldConfig.DATE_SELECTION_MODE] ?? 'single',
              mode: field?.options?.[DynamicFieldConfig.DATE_VIEW_MODE] ?? 'date',
            },
            ratingInput: {
              maxNumber: field?.options?.[DynamicFieldConfig.RATING_MAX_NUMBER] ?? 5,
            },
            radioInput: {
              groupItems: field?.options?.[DynamicFieldConfig.LIST_OPTIONS]
                ? (field?.options?.[DynamicFieldConfig.LIST_OPTIONS] as string[]).map((x) => {
                    return { label: x, value: x };
                  })
                : [],
              selectionMode: field?.options?.[DynamicFieldConfig.SINGLE_SELECTION_MODE] ?? 'radio',
            },
            codeSelectorInput: {
              targetTypes: field?.options?.[DynamicFieldConfig.RELATION_TARGET_TYPE]
                ? [field?.options?.[DynamicFieldConfig.RELATION_TARGET_TYPE]]
                : null,
              // relationType: (field?.options?.[DynamicFieldConfig.RELATION_TYPE] ? [field?.options?.[DynamicFieldConfig.RELATION_TYPE]] : null)
            },
            extra: field,
          },
          inputMask: field?.options?.[DynamicFieldConfig.INPUT_MASK] ?? null,
          viewMode: this.viewMode,
        },
      };
    });
    // }
    if (fields) this.dynamicFields = [...fields];
    setTimeout(() => {
      this.formValid.next((this.fControl as any as FormGroup).valid);
    }, 1);
  }
}
export const DynamicFieldMap = {
  ['INTEGER']: NumberInputComponent,
  ['DOUBLE']: NumberInputComponent,
  ['BOOLEAN']: SwitchInputComponent,
  ['STRING']: BasicInputComponent,
  ['NUMBER']: NumberInputComponent,
  ['SINGLE_SELECTION']: MCQComponent,
  ['ATTACHMENT']: AttachmentInputComponent,
  ['IP']: IpInputComponent,
  ['IP6']: IP6InputComponent,
  ['MAC']: MacInputComponent,
  ['IPV4_MASK']: Ipv4MaskInputComponent,
  ['DATE']: DateInputComponent,
  ['DATETIME']: DateInputComponent,
  ['MULTISELECT']: DropDownInputComponent,
  ['RATING']: RatingInputComponent,
  ['LONG_TEXT']: TextEditorComponent,
  ['RELATION']: TargetCodeSelectorComponent,
  ['GROUP_VALUE_LIST']: GvlSelectorComponent,
};
