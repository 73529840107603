<ng-container *ngFor="let item of actions">
  <ng-container [ngSwitch]="item.state">
    <div class="my-2">
      <ng-container *ngSwitchCase="viewType.Updated">
        <div class="flex flex-row align-items-center gap-3 w-full">
          <app-text-view [data]="item.oldValue.fieldName"></app-text-view>
          <div class="flex flex-row align-items-center justify-content-between w-full">
            <app-text-view [data]="item.oldValue.value"></app-text-view>
            <i class="pi pi-arrow-right"></i>
            <app-text-view [data]="item.newValue.value"></app-text-view>
          </div>
        </div>
      </ng-container>
      <ng-container *ngSwitchCase="viewType.Deleted">
        <div class="flex flex-row align-items-center gap-3 w-full">
          <app-text-view [data]="item.oldValue.fieldName"></app-text-view>
          <div class="flex flex-row align-items-center justify-content-between w-full">
            <app-text-view [data]="item.oldValue.value"></app-text-view>
            <i class="pi pi-arrow-right"></i>
            <app-text-view [data]="''"></app-text-view>
          </div>
        </div>
      </ng-container>
      <ng-container *ngSwitchCase="viewType.Created">
        <div class="flex flex-row align-items-center gap-3 w-full">
          <app-text-view [data]="item.newValue.fieldName"></app-text-view>
          <div class="flex flex-row align-items-center justify-content-between w-full">
            <app-text-view [data]="''"></app-text-view>
            <i class="pi pi-arrow-right"></i>
            <app-text-view [data]="item.newValue.value"></app-text-view>
          </div>
        </div>
      </ng-container>
    </div>
  </ng-container>
</ng-container>
