import { ChartViewConfig } from '@shared/classes/model';
import { EchartVariationBase } from '../../../echart-variation-base';
import { LineChart } from '../../base/line-chart';

export class CurvedLineChart extends LineChart implements EchartVariationBase {
  smooth = true;
  variation: ChartViewConfig.ChartVariationEnum = 'CURVED';
  // chartOptions: EChartsOption = {
  //   tooltip: {
  //     trigger: 'axis',
  //     appendTo: 'body',
  //   },
  //   xAxis: [
  //     {
  //       type: 'category',
  //       data: [],
  //     },
  //   ],
  //   yAxis: {
  //     type: 'value',
  //   },
  //   series: [
  //     {
  //       data: [],
  //       type: 'line',
  //       smooth: this.smooth,
  //     },
  //   ],
  // };
  checkVariationCondition(variation: ChartViewConfig.ChartVariationEnum): boolean {
    return true;
  }
}
