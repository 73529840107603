import { DOCUMENT } from '@angular/common';
import {
  AfterContentInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  forwardRef,
  Inject,
  Injector,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { ControlValueAccessor, FormControl, FormGroup, NG_VALUE_ACCESSOR, NgControl, Validators } from '@angular/forms';
import { DynamicComponentBase, isNullObj } from '@shared/classes';

export const INPUT_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => MailToInputFormControlComponent),
  multi: true,
};

@Component({
  selector: 'app-mail-to-input-form-control',
  templateUrl: './mail-to-input-form-control.component.html',
  styleUrl: './mail-to-input-form-control.component.scss',
  providers: [INPUT_VALUE_ACCESSOR],
})
export class MailToInputFormControlComponent
  extends DynamicComponentBase
  implements OnInit, AfterContentInit, OnChanges, ControlValueAccessor
{
  private ngControl: NgControl | null = null; // assign the current formControl to have access to all options ex: errors, dirty, hasValidator ...etc
  value: any; // end value of the control component
  public changed: (value: any) => void;
  public touched: () => void;
  public isDisabled: boolean;
  public formGroup = new FormGroup({
    toList: new FormControl(null),
    ccList: new FormControl(null),
    bccList: new FormControl(null),
  });
  constructor(
    @Inject(DOCUMENT) private document: Document,
    public el: ElementRef,
    private cd: ChangeDetectorRef,
    private readonly injector: Injector
  ) {
    super();
  }

  setInputOptions(): void {
    // for dynamic generation of the input
  }
  ngOnInit(): void {
    this.ngControl = this.injector.get(NgControl, null, { optional: true });
  }
  ngAfterContentInit(): void {
    // for custom templates assignments, EX: pre-item-template
  }
  ngOnChanges(changes: SimpleChanges): void {
    //for checks on @Input() items changes
  }
  writeValue(value: any): void {
    // On control.patchValue
    this.value = value;
    if (!isNullObj(value)) {
      this.formGroup.patchValue(value);
    } else {
      this.formGroup.reset();
    }
    // this.cd.markForCheck(); // if ChangeDetectionStrategy is OnPush then it's needed
  }
  onInputValueChange(value: any) {
    this.value = { ...value };
    this.changed({ ...value });
  }
  registerOnChange(fn: any): void {
    this.changed = fn;
    // this.formGroup.valueChanges.subscribe(values=>{
    //     this.onInputValueChange(values);
    // })
  }
  registerOnTouched(fn: any): void {
    this.touched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
    // this.cd.markForCheck(); // if ChangeDetectionStrategy is OnPush then it's needed
  }
  onAfterViewInit(): void {
    this.afterViewInit();
  }
  afterViewInit(): void {
    this.formGroup.valueChanges.subscribe((values) => {
      this.onInputValueChange(values);
    });
  }
  validateAndAdd(event: any, controlName: string) {
    event?.originalEvent?.preventDefault();
    event?.originalEvent?.stopPropagation();
    const inputValue = event.value.trim();
    const control = new FormControl(event.value.trim(), Validators.email);
    if (control.errors?.email) {
      const a = [...this.formGroup.value?.[controlName]];
      a.pop();
      this.formGroup.controls?.[controlName].patchValue(a);
    }
    // if (this.isValidEmail(inputValue)) {
    //   // this.formGroup.controls.toList.setValue([...this.formGroup.value.toList, inputValue]);
    // } else {
    //   // Show an error message or handle it as you prefer
    //   console.warn('Invalid email format. Chips rejected.');

    // }
  }

  isValidEmail(email: string): boolean {
    // Implement your custom email validation logic here
    // You can use regex or any other method to validate email addresses
    // For simplicity, let's assume a basic regex check:
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  }
}
