@if (!loadingFields) {
  <div class="w-full">
    <div class="grid p-fluid">
      <div class="sm:col-12 md:col-12 lg:col-3">
        <div class="grid p-fluid">
          @for (field of notImageFields; track field; let i1 = $index) {
            <div
              class="w-96 card h-auto text-center relative mb-1"
              (mouseenter)="onMouseOver((i1 + 5) * 100)"
              (mouseleave)="onMouseOut((i1 + 5) * 100)"
            >
              <div class="col-11 left_top">
                {{ field?.options?.label | humanize }}
              </div>
              <div class="h-4rem flex align-items-end justify-content-between">
                <input
                  id="disabled-input"
                  class="w-9"
                  type="text"
                  pInputText
                  [disabled]="true"
                  [(ngModel)]="field.options.data"
                />
              </div>
              @if (showPopupEditButton && isHovered[(i1 + 5) * 100]) {
                <div class="col-2 flex flex-column align-items-end justify-content-end px-2 right_bottom">
                  <app-button [action]="showNotImageEditPopupAction" [commandData]="i1"></app-button>
                </div>
              }
            </div>
          }
        </div>
      </div>
      <div class="sm:col-12 md:col-12 lg:col-9">
        <div class="grid p-fluid gap-1">
          @for (field of dynamicFields; track field; let i1 = $index) {
            <div
              class="w-30 sm:col-12 card h-auto text-center relative mb-0"
              (mouseenter)="onMouseOver(i1)"
              (mouseleave)="onMouseOut(i1)"
            >
              <div class="col-11 left_top">
                {{ field?.options?.label | humanize }}
              </div>
              @if (field?.componentType?.name == 'ImageInputComponent') {
                <img [src]="field?.options?.data" class="mt-6 mb-3 img_container" />
              }
              @if (showPopupEditButton && isHovered[i1]) {
                <div class="col-2 flex flex-column align-items-end justify-content-end px-2 right_bottom">
                  <app-button [action]="showEditPopupAction" [commandData]="i1"></app-button>
                </div>
              }
            </div>
          }
        </div>
      </div>
    </div>
  </div>
}
@if (loadingFields) {
  <div class="custom-skeleton">
    <!-- <div class="flex mb-3">
      <p-skeleton shape="circle" size="4rem" styleClass="mr-2"></p-skeleton>
      <div class="w-full mb-2">
        <p-skeleton styleClass="mb-2"></p-skeleton>
        <p-skeleton width="50%" styleClass="mb-2"></p-skeleton>
        <p-skeleton width="20%" styleClass="mb-2"></p-skeleton>
        <p-skeleton width="80%" height="2rem" styleClass="mb-2"></p-skeleton>
        <p-skeleton width="65%" height="4rem"></p-skeleton>
      </div>
    </div> -->
    <div class="w-full flex flex-column gap-3">
      <p-skeleton width="100%" height="2rem"></p-skeleton>
      <p-skeleton width="100%" height="2rem"></p-skeleton>
      <p-skeleton width="100%" height="2rem"></p-skeleton>
    </div>
  </div>
}
<p-dialog
  [header]="dynamicFields?.[popupActiveDynamicFieldIndex]?.options?.label | humanize"
  [(visible)]="displayDialog"
  [modal]="true"
  [style]="{ width: '80vw' }"
  [maximizable]="true"
  [draggable]="false"
  [resizable]="false"
>
  <div class="col-12">
    @if (displayDialog) {
      <ng-template
        appDynamicComponent
        [mode]="'edit'"
        [dynamicComponentInfo]="dynamicFields?.[popupActiveDynamicFieldIndex]"
      ></ng-template>
    }
  </div>
  <ng-template pTemplate="footer">
    <!-- <p-button icon="pi pi-check" (click)="displayDialog=false" label="Ok" styleClass="p-button-text"></p-button> -->
    @if (viewMode != 'view') {
      <div class="w-full flex align-items-end justify-content-end gap-2 py-0 px-3">
        <!-- <button pButton type="button" label="Select a file" (click)="fileInput.click()"></button> -->
        <app-button
          [action]="getFieldValueAction"
          [commandData]="dynamicFields?.[popupActiveDynamicFieldIndex]"
        ></app-button>
      </div>
    }
  </ng-template>
</p-dialog>

<p-dialog
  [header]="notImageFields?.[popupActiveNotImageDynamicFieldIndex]?.options?.label | humanize"
  [(visible)]="displayNotImageDialog"
  [modal]="true"
  [style]="{ width: '80vw' }"
  [maximizable]="true"
  [draggable]="false"
  [resizable]="false"
>
  <div class="col-12">
    @if (displayNotImageDialog) {
      <ng-template
        appDynamicComponent
        [mode]="'edit'"
        [dynamicComponentInfo]="notImageFields?.[popupActiveNotImageDynamicFieldIndex]"
      ></ng-template>
    }
  </div>
  <ng-template pTemplate="footer">
    @if (viewMode != 'view') {
      <div class="w-full flex align-items-end justify-content-end gap-2 py-0 px-3">
        <app-button
          [action]="getFieldValueAction"
          [commandData]="notImageFields?.[popupActiveNotImageDynamicFieldIndex]"
        ></app-button>
      </div>
    }
  </ng-template>
</p-dialog>
