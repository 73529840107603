<!-- <div class="w-full h-full flex flex-column gap-2">
<div class="flex justify-content-between align-items-center">
  <h4 class="mb-0">{{config?.title}}</h4>
  <app-button *ngIf="editMode" [action]="mainAction"></app-button>
</div>
<ng-content></ng-content>
</div> -->
<div [class]="'border-noround-top h-full flex-column justify-content-between flex ' + styleClass">
  <div class="w-full h-full flex flex-column">
    <div class="w-full flex align-items-center justify-content-between px-1 mb-2">
      <div class="w-full flex align-items-center gap-1">
        @if (config?.icon) {
          <span
            [class]="
              'inline-flex border-circle align-items-center justify-content-center ' +
              ' bg-' +
              config?.color +
              '-100 ' +
              ' mr-3'
            "
            style="width: 2rem; height: 2rem; min-width: 2rem; min-height: 2rem"
          >
            <i [class]="'pi ' + config?.icon + ' text-xl text-' + config?.color + '-600'"></i>
          </span>
        }
        @if (titleTemplate) {
          <ng-container *ngTemplateOutlet="titleTemplate; context: { $implicit: config }"></ng-container>
        } @else {
          <span
            [pTooltip]="config?.title"
            class="text-900 font-medium text-2xl"
            style="
              line-clamp: 1;
              -webkit-line-clamp: 1;
              overflow: hidden;
              text-overflow: ellipsis;
              text-wrap: nowrap;
              min-width: 0;
            "
            >{{ config?.title }}</span
          >
        }
      </div>
      @if (editMode) {
        <app-button [action]="mainAction"></app-button>
      }
    </div>
    <!-- <div class="text-900 my-3 text-xl font-medium"></div> -->
    @if (config?.description) {
      <p class="mt-0 mb-3 text-700 line-height-3 px-1">
        {{ config?.description }}
      </p>
    }
    <div class="overflow-auto flex flex-column px-1 h-full">
      <ng-content></ng-content>
    </div>
  </div>
  @if (config?.action) {
    <div class="px-4 py-3 surface-100 text-right">
      <app-button [action]="config?.action" [commandData]="config?.actionData"></app-button>
    </div>
  }
</div>
