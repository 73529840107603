import { Component, Input, OnInit } from '@angular/core';
import { DataTypeEnum, DynamicComponentBase, ITablePageable } from '@shared/classes';
import { PolicyBodyDataService } from 'app/modules/org-framework/service/data/policy-body-data.service';
// import { CheckItemDataService } from 'app/modules/authority-module/services/data/check-item-data.service';

@Component({
  selector: 'app-check-items-selector',
  templateUrl: './check-items-selector.component.html',
  styleUrls: ['./check-items-selector.component.scss'],
  providers: [{ provide: DynamicComponentBase, useExisting: CheckItemsSelectorComponent }],
})
export class CheckItemsSelectorComponent extends DynamicComponentBase implements OnInit {
  @Input() multi: boolean = true;
  @Input() optionValue: string = undefined;
  @Input() dataKey: string = 'id';
  constructor(private service: PolicyBodyDataService) {
    super();
  }
  items: any[] = [];
  pageInfo: ITablePageable = new ITablePageable();
  isLoading: boolean = false;
  dataType: DataTypeEnum = DataTypeEnum.Text;

  ngOnInit(): void {
    this.getOptions();
  }

  setInputOptions() {
    this.multi = this.inputOptions?.dropDownInput?.multi ?? this.multi;
  }
  getOptions(filters: any = []) {
    this.isLoading = true;
    this.subs.sink = this.service
      .search<any>(this.pageInfo.pagination, { filters: filters }, { showLoading: false, showMsg: false })
      .subscribe({
        next: (res) => {
          this.items = res.content;
          this.pageInfo.totalElements = res.totalElements;
        },
        complete: () => {
          this.isLoading = false;
        },
      });
  }
  onFilter(event) {
    this.getOptions([{ property: 'name', operation: 'CONTAINS', value: event }]);
  }
}
