import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DynamicComponentBase, IColumn } from '@shared/classes';

@Component({
  selector: 'app-checkbox-list',
  templateUrl: './checkbox-list.component.html',
  styleUrls: ['./checkbox-list.component.scss'],
})
export class CheckboxListComponent extends DynamicComponentBase implements OnInit {
  @Output() onFocus: EventEmitter<any> = new EventEmitter();
  @Output() onChange: EventEmitter<any> = new EventEmitter();
  @Input() key: string = '';
  @Input() checkOptionValue = 'checked';
  @Input() disableCheckOptionKey = '_disableCheck';
  @Input() cols: IColumn[] = [];
  @Input() disableSelectAll: boolean = false;
  allChecked: boolean = false;

  @Input() set list(value) {
    this.data = value;
    this.isAllChecked();
  }

  constructor() {
    super();
  }

  ngOnInit(): void {}
  setInputOptions() {}
  onFocusChange(event) {
    this.onFocus.emit(event);
  }

  checkAll(event) {
    this.allChecked = event;
    this.data = this.data.map((x) => ({
      ...x,
      [this.checkOptionValue]: this.allChecked,
    }));
    this.onChange.emit(this.data);
  }

  onCheckboxChange(event, index) {
    this.data[index][this.checkOptionValue] = event.checked;
    this.onChange.emit(this.data);
  }

  isAllChecked() {
    setTimeout(() => {
      this.allChecked = this.data.every((item) => item[this.checkOptionValue]);
    }, 50);
  }

  onSelectionChange(event) {
    event;
  }

  getItemEntries(item) {
    return Object.entries(item);
  }
}
