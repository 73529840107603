<app-dashlet [styleClass]="styleClass" [config]="{ title: title }">
  <ng-content></ng-content>
  @if (headerTemplate) {
    <ng-template let-config #titleTemplate>
      <ng-container *ngTemplateOutlet="headerTemplate; context: { $implicit: config }"></ng-container>
    </ng-template>
  }
  <div class="formgrid grid p-fluid w-full">
    @for (field of fields; track field) {
      <div
        class="col-12"
        [ngClass]="{
          'md:col-6': field?.options?.colSpan != 12,
        }"
      >
        <!-- <app-view-switch [viewType]="field?.dataType"
            [data]="field?.passRowAsData ? data : getElementValue(field?.key, data)"
            [options]="field?.options">
          </app-view-switch> -->
        <app-input-view-switch
          [showLabelInViewMode]="true"
          [mode]="'view'"
          [data]="field?.passRowAsData ? data : getElementValue(field?.key, data)"
          [dataType]="field?.dataType"
          [label]="field?.name"
          [options]="field?.options"
        >
        </app-input-view-switch>
      </div>
    }
  </div>
</app-dashlet>
