import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { JWTTokenService } from '@core/services/JWT-token/jwttoken.service';
import { IAction, PermissionActions, SubscriptionItem, UserOperationPrinciple } from '@shared/classes';
import { AppDialogService } from '@shared/services/app-dialog.service';
import { PathResolverService } from '@shared/services/path-resolver.service';
import { ToastService } from '@shared/services/toast.service';
import { MySubscriptionDataService } from 'app/modules/activity-feed/services/data/my-subscription-data.service';
import { SubscriptionDataService } from 'app/modules/activity-feed/services/data/subscription-data.service';
import { AddWatchersFormComponent } from '../add-watchers-form/add-watchers-form.component';
import { AddWatchersPopupFormComponent } from '../add-watchers-popup-form/add-watchers-popup-form.component';

@Component({
  selector: 'app-watching-tray',
  templateUrl: './watching-tray.component.html',
  styleUrls: ['./watching-tray.component.scss'],
})
export class WatchingTrayComponent implements OnInit {
  subscribers: SubscriptionItem[] = [];
  userList: string[] = [];
  principleList: UserOperationPrinciple[] = [];
  loading: boolean = true;
  isSubscribed: boolean = false;
  subscribeAction: IAction = {
    id: 1,
    label: 'Subscribe',
    buttonType: 'button',
    command: this.subscribe.bind(this),
    icon: 'pi pi-eye',
    color: 'success',
    buttonStyle: 'text',
    buttonClass: 'w-full',
  };
  unsubscribeAction: IAction = {
    id: 2,
    label: 'Unsubscribe',
    buttonType: 'button',
    command: this.unsubscribe.bind(this),
    icon: 'pi pi-eye-slash',
    color: 'danger',
    buttonStyle: 'text',
    buttonClass: 'w-full',
  };
  addSubscribers: IAction = {
    id: 3,
    label: 'Manage Watchers',
    buttonType: 'button',
    command: this.onAddSubscribers.bind(this),
    icon: 'pi pi-cog',
    color: 'primary',
    buttonStyle: 'text',
    buttonClass: 'w-full',
    // permission: `${PermissionActions.Delete}${this.moduleKeyword}`,
  };
  private _itemId: string;
  dialogComponent = AddWatchersFormComponent;
  @Input() set itemId(itemId: string) {
    this.addSubscribers = itemId
      ? {
          ...this.addSubscribers,
          permission: `${PermissionActions.Delete}${this.pathResolver?.getModuleKeywordByCode(itemId)}`,
        }
      : null;
    this._itemId = itemId;
  }
  get itemId() {
    return this._itemId;
  }
  constructor(
    private requestService: SubscriptionDataService,
    private myRequestService: MySubscriptionDataService,
    private toastService: ToastService,
    private jwtTokenService: JWTTokenService,
    private route: ActivatedRoute,
    private pathResolver: PathResolverService,
    private appDialogService: AppDialogService
  ) {
    // this.route.params.subscribe({
    //     next: (params) => {
    //         this.itemId = params["id"];
    //         this.getSubscribers();
    //     },
    // });
  }

  ngOnInit(): void {
    this.getSubscribers();
  }
  getSubscribers() {
    if (!this.itemId) return;
    this.loading = true;
    this.myRequestService.mySubscription(this.itemId).subscribe({
      next: (res) => {
        this.subscribers = res.data;
        this.onSetSubscribers();
        this.loading = false;
      },
      error: (err) => {
        this.loading = false;
      },
    });
  }
  subscribe() {
    this.loading = true;
    this.myRequestService.subscribe(this.itemId).subscribe((res) => {
      // this.toastService.success("Success","You will receive notifications from this feed",null,primengToastPlacement['top-left']);
      this.isSubscribed = true;
      this.userList.push(this.jwtTokenService.getPreferredUsername());
      this.userList = [...this.userList];
      this.loading = false;
    });
  }
  unsubscribe() {
    this.loading = true;
    this.myRequestService.unSubscribe(this.itemId).subscribe((res) => {
      // this.toastService.success("Unsubscribed from this feed",'',null,primengToastPlacement['top-left']);
      this.isSubscribed = false;
      let ind = this.userList.findIndex((x) => x == this.jwtTokenService.getPreferredUsername());
      if (ind != -1) {
        this.userList.splice(ind, 1);
        this.userList = [...this.userList];
      }
      this.loading = false;
    });
  }
  onSetSubscribers() {
    this.userList = [];
    this.principleList = [];
    this.isSubscribed = false;
    this.subscribers.forEach((sub) => {
      this.principleList.push(sub?.principle);
      this.userList.push(sub?.principle?.name);
      if (sub?.principle?.name == this.jwtTokenService.getPreferredUsername()) {
        this.isSubscribed = true;
      }
    });
  }
  onAddSubscribers() {
    this.appDialogService.showDialog(
      AddWatchersPopupFormComponent,
      'Manage Watchers',
      (e) => {
        if (e) {
          // this.requestService.addWatchers(this.itemId,e.people).subscribe(res=>{

          // })
          this.toastService.success('Success', 'Watchers changed successfully');
        }
      },
      {
        data: {
          dynamicViewComponent: this.dialogComponent,
          dataService: this.requestService,
          submitType: 'update',
          data: { principle: this.principleList },
          subscribers: this.subscribers,
          itemId: this.itemId,
        },
      }
    );
  }
}
