<app-dashlet class="widget-country-graph" [config]="{ title: title }">
  @if (chartTitleTemplate) {
    <ng-template let-config #titleTemplate>
      <ng-container *ngTemplateOutlet="chartTitleTemplate; context: { $implicit: config }"></ng-container>
    </ng-template>
  }
  @if (isLoading) {
    <div class="w-full">
      <p-skeleton shape="circle" size="10rem" styleClass="m-auto"></p-skeleton>
      <div style="flex: 1; gap: 0.75rem">
        <p-skeleton width="100%" height="2rem" styleClass="mt-3"></p-skeleton>
        <p-skeleton width="100%" height="2rem" styleClass="mt-3"></p-skeleton>
        <p-skeleton width="100%" height="2rem" styleClass="mt-3"></p-skeleton>
        <p-skeleton width="100%" height="2rem" styleClass="mt-3"></p-skeleton>
        <p-skeleton width="100%" height="2rem" styleClass="mt-3"></p-skeleton>
      </div>
    </div>
  }
  <ng-content></ng-content>
  <div
    class="w-full mt-3 flex flex-wrap"
    [class.flex-column-reverse]="!horizontalMode"
    [class.gap-3]="!horizontalMode"
    [class.flex-row]="horizontalMode"
    [class.justify-content-between]="horizontalMode"
  >
    @if (!isLoading && showList) {
      <div class="country-content" [class.w-6]="horizontalMode">
        <ul>
          @for (listItem of listData | keyvalue; track listItem) {
            <li class="flex justify-content-between align-items-center">
              <div class="flex justify-content-between align-items-center">
                <div
                  [ngClass]="
                    listColors ? 'color shadow-1' : 'bg-' + badgeColors[$any(listItem?.key)] + '-500 color shadow-1'
                  "
                  [ngStyle]="listColors ? { 'background-color': listColors[$any(listItem?.key)] } : {}"
                ></div>
                <span>{{ $any(listItem.key) | humanize }}</span>
              </div>
              <span [pTooltip]="'Amount: ' + listItem.value" tooltipPosition="top"
                >{{
                  totalAmount ? (($any(listItem.value) / totalAmount) * 100 | parseToFixedNumber: 'int') : '0'
                }}%</span
              >
            </li>
          }
        </ul>
      </div>
    }
    @if (drillDownPreviousOptions?.length > 0) {
      <div class="w-full">
        <div class="layout-breadcrumb-container">
          <p-breadcrumb
            class="max-w-full layout-breadcrumb"
            [model]="drillDownPreviousOptions"
            [home]="home"
            (onItemClick)="onDrillDownItemClick($event)"
          />
        </div>
        @if (drillDownPreviousOptions?.length == this.drillDownList?.fields?.length) {
          <app-widget-table
            class=""
            [title]="tableCardContent?.name"
            [cardContent]="tableCardContent"
            [styleClass]="'relative m-0 '"
          >
            <ng-template let-config #headerTemplate> </ng-template>
          </app-widget-table>
        }
      </div>
    }
    <div [ngStyle]="{ maxWidth: '100%', width: horizontalMode ? '40%' : 'auto' }">
      @if (
        !isLoading &&
        !useD3 &&
        showChart &&
        !(
          drillDownPreviousOptions?.length > 0 && drillDownPreviousOptions?.length == this.drillDownList?.fields?.length
        )
      ) {
        <p-chart
          [type]="chartType"
          [data]="drillDownPreviousOptions?.length > 0 ? drillDownDataSet : _chartData"
          [options]="chartOptions"
          [plugins]="[plugin]"
          (onDataSelect)="onDataSelect($event)"
          style="width: '75%'; align-self: 'center'; justify-self: center"
        ></p-chart>
      }
      <div #my_dataviz id="my_dataviz"></div>
    </div>
  </div>
</app-dashlet>
