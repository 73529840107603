import { ChartViewConfig } from '@shared/classes/model';
import { EChartsOption } from 'echarts';
import { AggregateFunctionOptions, AssignChartDataOptions } from '../../../echart-base';
import { EchartVariationBase } from '../../../echart-variation-base';
import { HorizontalBarChart } from '../../base/horizontal-bar-chart';

export class DrilldownHorizontalBarChart extends HorizontalBarChart implements EchartVariationBase {
  variation: ChartViewConfig.ChartVariationEnum = 'DRILL_DOWN';
  //   chartOptions: EChartsOption = {
  //     xAxis: {
  //       type: 'category',
  //       data: [],
  //     },
  //     yAxis: {
  //       type: 'value',
  //     },
  //     series: [
  //       {
  //         data: [],
  //         type: 'line',
  //         smooth: true,
  //       },
  //     ],
  //   };
  checkVariationCondition(variation: ChartViewConfig.ChartVariationEnum): boolean {
    return true;
  }
  assignDataToChartOptions(options: AssignChartDataOptions): EChartsOption {
    let optionsCopy = { ...options?.chartOptions };
    optionsCopy.series[0].name = options?.name;
    optionsCopy.series[0].data = options?.data.map((x, i) => {
      return { ...x };
    });
    optionsCopy.yAxis[0].data = options?.data.map((x, i) => {
      return x.name;
    });
    // optionsCopy.series = options?.data?.map((x, i) => {
    //   return { type: 'bar', name: x?.name, data: [x?.value] };
    // });
    // (optionsCopy.legend as LegendComponentOption).data = options?.data?.map((x, i) => x.name);
    // optionsCopy.xAxis[0] = {
    //   type: 'category',
    //   data: [options?.name],
    // };
    return optionsCopy;
  }
  formatAggregateData(options: AggregateFunctionOptions) {
    return this.aggregateToSingleDimension(options);
  }
}
