import { ChartViewConfig } from '@shared/classes/model';
import { EChartsOption } from 'echarts';
import { AggregateFunctionOptions, AssignChartDataOptions, EchartBase } from '../../echart-base';
import { groupData } from '../../helpers/general-functions';

export class BubbleChart extends EchartBase {
  smooth = false;
  chartOptions: EChartsOption = {
    tooltip: {
      trigger: 'item',
      appendTo: 'body',
      valueFormatter: (value) => value[2],
    },
    xAxis: [
      {
        type: 'category',
        data: [],
        splitLine: {
          lineStyle: {
            type: 'dashed',
          },
        },
      },
    ],
    yAxis: [
      {
        type: 'category',
        data: [],
        splitLine: {
          lineStyle: {
            type: 'dashed',
          },
        },
        // scale: true,
      },
    ],
    series: [
      {
        data: [],
        type: 'scatter',
        symbolSize: function (data) {
          return data[2] * 2;
        },
        emphasis: {
          focus: 'series',
          label: {
            show: true,
            formatter: function (param) {
              return param.data[3];
            },
            position: 'top',
          },
        },
      },
    ],
  };
  chartType: ChartViewConfig.ChartTypeEnum = 'BUBBLE';
  // assignDataToChartOptions(options: AssignChartDataOptions): EChartsOption {
  //   let optionsCopy = { ...options?.chartOptions };
  //   const seriesObj = options?.data?.find((x) => x?.['series']);
  //   // const xAxisObj = options?.data?.find((x) => x?.['xAxis']);
  //   if (seriesObj) {
  //     optionsCopy.series = seriesObj.series;
  //     // optionsCopy.xAxis[0].data = xAxisObj.xAxis;
  //   } else {
  //     optionsCopy.series[0].name = options?.name;
  //     optionsCopy.series[0].data = options?.data.map((x, i) => {
  //       return { ...x };
  //     });
  //     optionsCopy.xAxis[0].data = options?.data.map((x, i) => {
  //       return x.name;
  //     });
  //   }
  //   return optionsCopy;
  // }
  // formatAggregateData(options: AggregateFunctionOptions) {
  //   if (options?.payload?.groupByFields?.length > 1) {
  //     const data = groupData(options?.response?.aggregation_value, options?.payload?.groupByFields, options?.drillDownAggregationField);
  //     let secondGroupValues = {};
  //     options?.response?.aggregation_value?.forEach((val) => {
  //       secondGroupValues[val?.group_id?.[getGroupFieldIdKey(options?.payload?.groupByFields?.[1])] || 'EMPTY_VALUE'] = secondGroupValues[
  //         val?.group_id?.[getGroupFieldIdKey(options?.payload?.groupByFields?.[1])] || 'EMPTY_VALUE'
  //       ]
  //         ? {
  //             ...secondGroupValues[val?.group_id?.[getGroupFieldIdKey(options?.payload?.groupByFields?.[1])] || 'EMPTY_VALUE'],
  //             [val?.group_id?.[getGroupFieldIdKey(options?.payload?.groupByFields?.[0])] || 'EMPTY_VALUE']:
  //               val?.[options?.drillDownAggregationField],
  //           }
  //         : {
  //             [val?.group_id?.[getGroupFieldIdKey(options?.payload?.groupByFields?.[0])] || 'EMPTY_VALUE']:
  //               val?.[options?.drillDownAggregationField],
  //           };
  //       // secondGroupValues[val?.group_id?.[getGroupFieldIdKey(options?.payload?.groupByFields?.[1])]] ? [...secondGroupValues[val?.group_id?.[getGroupFieldIdKey(options?.payload?.groupByFields?.[1])]],{[val?.group_id?.[getGroupFieldIdKey(options?.payload?.groupByFields?.[0])]]:val?.[getGroupFieldIdKey(options?.payload?.groupByFields?.[1])+'_count']}] : [{[val?.group_id?.[getGroupFieldIdKey(options?.payload?.groupByFields?.[0])]]:val?.[getGroupFieldIdKey(options?.payload?.groupByFields?.[1])+'_count']}];
  //     });
  //     let ret = [];
  //     data.forEach((item) => {
  //       let series = {
  //         data: Object.entries(secondGroupValues).map(([key, value]) => {
  //           return value?.[item?.name] || 0;
  //         }),
  //         type: 'scatter',
  //         symbolSize: function (data) {
  //           return data * 5;
  //         },
  //         emphasis: {
  //           focus: 'series',
  //           label: {
  //             show: true,
  //             formatter: function (param) {
  //               return param?.data?.[3];
  //             },
  //             position: 'top',
  //           },
  //         },
  //         name: item.name,
  //       };
  //       // const a ={
  //       //   data: Object.entries(secondGroupValues).map(([key, value]) => {
  //       //     return value?.[item?.name] || 0;
  //       //   }),
  //       //   type: 'line',
  //       //   smooth: this.smooth,
  //       //   name: item.name,
  //       //   lineStyle: {
  //       //     width: 0,
  //       //   },
  //       //   symbolSize: 10,
  //       // };
  //       ret.push(series);
  //     });
  //     return {
  //       data: [{ series: ret }, { xAxis: Object.keys(secondGroupValues) }],
  //       chartTitle: null,
  //     };
  //   } else {
  //     return this.aggregateToSingleDimension(options);
  //   }
  // }
  assignDataToChartOptions(options: AssignChartDataOptions): EChartsOption {
    let optionsCopy = { ...options?.chartOptions };
    optionsCopy.series[0].name = null; //options?.name;
    if (options.initOptions.aggregationResult.payload.groupByFields.length > 2) {
      const heatMaps = [];
      let minValue = Infinity;
      let maxValue = -Infinity;
      options?.data?.forEach((treeNode, idx) => {
        heatMaps.push(
          this.generateHeatmapData(
            treeNode.children,
            idx != 0 ? heatMaps[idx - 1].xAxis : null,
            idx != 0 ? heatMaps[idx - 1].yAxis : null
          )
        );
        if (heatMaps[idx].minValue < minValue) minValue = heatMaps[idx].minValue;
        if (heatMaps[idx].maxValue > maxValue) maxValue = heatMaps[idx].maxValue;
      });
      heatMaps.forEach((heatMap, idx) => {
        optionsCopy.xAxis[0].data = heatMap?.xAxis;
        optionsCopy.yAxis[0].data = heatMap?.yAxis;
        if (idx == 0) {
          optionsCopy.series[0].name = options?.data?.[idx]?.name;
          optionsCopy.series[0].data = heatMap?.heatmap;
          optionsCopy.series[0].symbolSize = (data) => {
            // First, settle on some min/max bubble sizes that work well for your chart
            const BUBBLE_MIN_SIZE = 10;
            const BUBBLE_MAX_SIZE = 75;

            // Square them because we'll eventually use `Math.sqrt`
            // See https://chartio.com/learn/charts/bubble-chart-complete-guide/#scale-bubble-area-by-value
            const squaredMax = Math.pow(BUBBLE_MAX_SIZE, 2);
            const squaredMin = Math.pow(BUBBLE_MIN_SIZE, 2);

            // Index of the field in your data that represents the bubble size
            const bubbleSizeIdx = 2;

            // Find min and max values in your data for the column
            // const minValue = minValue;
            // const maxValue = maxValue;

            // Scales each bubble so it's sized relative to the mix and max values in the dataset
            const scale = (data[bubbleSizeIdx] - minValue) / (maxValue - minValue);
            const scaledSize = scale * (squaredMax - squaredMin) + squaredMin;
            return Math.sqrt(scaledSize);
          };
        } else {
          (optionsCopy.series as any[]).push({
            ...optionsCopy.series[0],
            data: heatMap?.heatmap,
            name: options?.data?.[idx]?.name,
          });
        }
      });
    } else {
      const heatMap = this.generateHeatmapData(options?.data);
      optionsCopy.series[0].data = heatMap?.heatmap;
      optionsCopy.xAxis[0].data = heatMap?.xAxis;
      optionsCopy.yAxis[0].data = heatMap?.yAxis;
      optionsCopy.series[0].symbolSize = (data) => {
        // First, settle on some min/max bubble sizes that work well for your chart
        const BUBBLE_MIN_SIZE = 10;
        const BUBBLE_MAX_SIZE = 75;

        // Square them because we'll eventually use `Math.sqrt`
        // See https://chartio.com/learn/charts/bubble-chart-complete-guide/#scale-bubble-area-by-value
        const squaredMax = Math.pow(BUBBLE_MAX_SIZE, 2);
        const squaredMin = Math.pow(BUBBLE_MIN_SIZE, 2);

        // Index of the field in your data that represents the bubble size
        const bubbleSizeIdx = 2;

        // Find min and max values in your data for the column
        const minValue = heatMap?.minValue;
        const maxValue = heatMap?.maxValue;

        // Scales each bubble so it's sized relative to the mix and max values in the dataset
        const scale = (data[bubbleSizeIdx] - minValue) / (maxValue - minValue);
        const scaledSize = scale * (squaredMax - squaredMin) + squaredMin;
        return Math.sqrt(scaledSize);
      };
    }
    // optionsCopy.visualMap[0].min = heatMap?.minValue;
    // optionsCopy.visualMap[0].max = heatMap?.maxValue;
    //@TODO: children format original data
    return optionsCopy;
  }
  formatAggregateData(options: AggregateFunctionOptions) {
    return {
      data: groupData(
        options?.response?.aggregation_value,
        options?.payload?.groupByFields,
        options?.drillDownAggregationField
      ),
      chartTitle: null,
    };
  }
  generateHeatmapData(
    tree: any[],
    previousXAxis = null,
    previousYAxis = null
  ): {
    xAxis: string[];
    yAxis: string[];
    heatmap: number[][];
    minValue: number;
    maxValue: number;
  } {
    const xAxis: string[] = previousXAxis || [];
    const yAxis: string[] = previousYAxis || [];
    const heatmap: number[][] = [];
    let minValue = Infinity;
    let maxValue = -Infinity;

    tree.forEach((node, xIndex) => {
      if (!xAxis.includes(node.name)) {
        xAxis.push(node.name);
      }
      const xAxisIndex = xAxis.indexOf(node.name);
      if (node?.children) {
        node?.children?.forEach((child, yIndex) => {
          if (!yAxis.includes(child.name)) {
            yAxis.push(child.name);
          }
          const value = child.value;
          heatmap.push([xAxisIndex, yAxis.indexOf(child.name), value]);
          if (value < minValue) minValue = value;
          if (value > maxValue) maxValue = value;
        });
      }
    });

    return { xAxis, yAxis, heatmap, minValue, maxValue };
  }
}
