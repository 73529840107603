import { Component, OnInit } from '@angular/core';
import { BaseViewItem } from '@shared/classes';

@Component({
  selector: 'app-image-view',
  templateUrl: './image-view.component.html',
  styleUrls: ['./image-view.component.scss'],
})
export class ImageViewComponent extends BaseViewItem implements OnInit {
  constructor() {
    super();
  }

  ngOnInit(): void {}
}
