<div class="flex" [class.gap-2]="instanceInFormRepeater" [class.flex-wrap]="!instanceInFormRepeater">
  <div class="w-6" [class.pr-2]="!instanceInFormRepeater">
    <app-drop-down-input
      label="Impact Factor"
      [placeholder]="'Select Impact Factor'"
      [name]="'in-risk-imp'"
      [optionLabel]="'label'"
      [items]="filteredItems"
      [control]="impactFactorControl"
      [viewMode]="instanceInFormRepeater ? 'view' : viewMode"
      [showStatusBadge]="false"
      [badgeView]="false"
      [appendTo]="'body'"
      [showLabelInViewMode]="showLabelInViewMode"
    ></app-drop-down-input>
  </div>
  <div class="w-6">
    <app-drop-down-input
      [label]="instanceInFormRepeater ? '' : 'Impact Factor Value'"
      [placeholder]="'Factor Value'"
      [name]="'in-risk-imp'"
      [optionLabel]="'label'"
      [items]="valueItems"
      [control]="impactFactorValueControl"
      [viewMode]="viewMode"
      [showStatusBadge]="false"
      [badgeView]="false"
      [showClear]="false"
      [appendTo]="'body'"
      [showLabelInViewMode]="showLabelInViewMode"
      [dataKey]="'code'"
      [floatLabel]="instanceInFormRepeater ? false : false"
    ></app-drop-down-input>
  </div>
  @if (instanceInFormRepeater || true) {
    <div class="w-6">
      <app-drop-down-input
        [label]="'Impact Value'"
        [placeholder]="'Risk Impact Value'"
        [name]="'in-risk-imp'"
        [optionLabel]="'label'"
        [items]="valueItems"
        [control]="impactFactorNameControl"
        [viewMode]="'view'"
        [showStatusBadge]="false"
        [badgeView]="false"
        [showClear]="false"
        [appendTo]="'body'"
        [showLabelInViewMode]="instanceInFormRepeater ? false : true"
        [dataKey]="'code'"
        [floatLabel]="instanceInFormRepeater ? false : false"
      ></app-drop-down-input>
    </div>
  }
</div>
