import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  AccessRecord,
  AcknowledgmentDto,
  AcknowledgmentRequest,
  BaseForm,
  IAction,
  PermissionActions,
  TypesWithName,
  getEnumOptions,
  humanizeCasedString,
  isNullObj
} from '@shared/classes';

import { BulkOperation } from '@shared/classes/view/BulkOperation';
import { ViewModeService } from '@shared/services';
import { BulkOperationService } from '@shared/services/bulk-operation-service/bulk-operation.service';
import { cloneDeep } from 'lodash-es';
import { NgxPermissionsService } from 'ngx-permissions';

@Component({
  selector: 'app-bulk-operation-details-step-form',
  templateUrl: './bulk-operation-details-step-form.component.html',
  styleUrls: ['./bulk-operation-details-step-form.component.scss'],
})
export class BulkOperationDetailsStepFormComponent extends BaseForm<any> implements OnInit {
  @Input() stepperService: BulkOperationService;

  bulkOperations = BulkOperation;

  //Update
  fields;

  //Add Relations
  tagType = AcknowledgmentRequest.TargetTypeEnum.RelationType;
  excludedCodes: string[] = [];
  listOfTypes = [];
  selectedType;

  //Access/Watchers
  get excludedAccesses() {
    return this.accessControl.value;
  }
  userActionsMap: {
    [x: string]: { actions: any[]; accessLevel?: AccessRecord.AccessLevelEnum | 'Add' | 'Delete'; principleType: 'GROUP' | 'USER' };
  } = {};
  accessControl = new FormControl(null);
  baseAccessAction: IAction = {
    id: 1,
    label: 'Save',
    buttonType: 'button',
    color: 'secondary',
    buttonClass: 'w-fit',
  };

  //Status
  listOfStatuses = [];
  constructor(
    public viewModeService: ViewModeService,
    private permissionService: NgxPermissionsService
  ) {
    super(viewModeService);
  }

  ngOnInit(): void {
    if (this.stepperService.operation == this.bulkOperations.Status) {
      this.initializeStatusOperation();
      return;
    }
    if (this.stepperService.operation == this.bulkOperations.Watchers) {
      this.initializeWatchersOperation();
      return;
    }
    if (this.stepperService.operation == this.bulkOperations.Access) {
      this.initializeAccessOperation();
      return;
    }
    if (this.stepperService.operation == this.bulkOperations.Delete || this.stepperService.operation == this.bulkOperations.PermenantDelete) {
      this.initializeDeleteOperation();
      return;
    }
    if (this.stepperService.operation == this.bulkOperations.AddRelations) {
      this.initializeAddRelationsOperation();
      return;
    }
    this.initializeUpdateOperation();
  }

  initializeStatusOperation() {
    this.listOfStatuses = getEnumOptions(AcknowledgmentDto.RecordStatusEnum);
    this.formGroup = new FormGroup({
      status: new FormControl(null)
    });
    this.stepperService.stepperItem = { status: AcknowledgmentDto.RecordStatusEnum.Active };
    this.formGroup.controls.status.patchValue(AcknowledgmentDto.RecordStatusEnum.Active)
  }

  initializeAddRelationsOperation() {
    this.formGroup = new FormGroup({
      targetType: new FormControl(null, Validators.required),
      notes: new FormControl(null),
      targetCode: new FormControl(null, Validators.required),
      relationType: new FormControl(null, Validators.required),
    });
    this.excludedCodes = this.stepperService.items.map((x) => x.code + '');

    this.formGroup?.controls?.targetType?.valueChanges.subscribe((res) => {
      this.selectedType = res ?? null;
      this.formGroup?.patchValue({
        targetCode: null,
      });
    });
    const perms = this.permissionService.getPermissions();

    const allowedTypeList = [];
    getEnumOptions(TypesWithName).forEach((element) => {
      if (!isNullObj(perms?.[`${PermissionActions.Read}${element.value}`])) {
        allowedTypeList.push(element);
      }
    });
    this.listOfTypes = [...allowedTypeList];
  }

  initializeDeleteOperation() {
    this.formGroup = new FormGroup([]);
  }

  initializeWatchersOperation() {
    this.formGroup = new FormGroup({
      access: new FormControl(this.stepperService.stepperItem.access ?? null)
    });
    this.userActionsMap = this.stepperService.stepperItem.access ?? {};
    this.accessControl = new FormControl(this.stepperService.tempAccesses);
    if (this.userActionsMap) {
      Object.keys(this.userActionsMap).forEach(x => {
        this.userActionsMap[x] = {
          actions: this.getWatchersActionList(x, this.userActionsMap[x].accessLevel),
          accessLevel: this.userActionsMap[x].accessLevel,
          principleType: this.userActionsMap[x].principleType,
        }
      });
    }
  }

  initializeAccessOperation() {
    this.formGroup = new FormGroup({
      access: new FormControl(this.stepperService.stepperItem.access ?? null)
    });
    this.userActionsMap = this.stepperService.stepperItem.access ?? {};
    this.accessControl = new FormControl(this.stepperService.tempAccesses);
    if (this.userActionsMap) {
      Object.keys(this.userActionsMap).forEach(x => {
        this.userActionsMap[x] = {
          actions: this.getActionList(x, this.userActionsMap[x].accessLevel),
          accessLevel: this.userActionsMap[x].accessLevel,
          principleType: this.userActionsMap[x].principleType,
        }
      });
    }
  }

  initializeUpdateOperation() {
    this.fields = [...this.stepperService.mappingService.recordFields?.filter(f => f.useInBulk).map(x => {
      return {
        ...x.formField,
        options: {
          ...x.formField?.options,
          label: x.name,
          name: x.key,
        }

      }
    })];
    this.stepperService.checkControls = this.stepperService.checkControls.map((x) => new FormControl(x.value));
    this.formGroup = new FormGroup(
      this.stepperService.mappingService.recordFields?.filter((x, idx) => x.useInBulk)?.filter((x, idx) => this.stepperService.checkControls[idx].value)
        .reduce((acc, x) => {
          acc[x?.key] = x?.formField?.options.control;
          return acc;
        }, {})
    );
  }

  changeUserWatcherLevel(user, level) {
    this.userActionsMap[user].accessLevel = level;
    this.formGroup.controls.access.patchValue(this.userActionsMap);
    this.stepperService.tempAccesses = this.accessControl.value;
  }

  changeUserAccessLevel(user, level) {
    this.userActionsMap[user].accessLevel = level;
    this.formGroup.controls.access.patchValue(this.userActionsMap);
    this.stepperService.tempAccesses = this.accessControl.value;
  }

  getWatchersActionList(principle: string, first?: AccessRecord.AccessLevelEnum | 'Delete' | 'Add') {
    if (first == 'Delete')
      return [{
        ...this.baseAccessAction,
        icon: null,
        id: 1,
        label: 'Delete',
        command: this.changeUserWatcherLevel.bind(this, principle, 'Delete'),
      }, {
        ...this.baseAccessAction,
        icon: null,
        id: 2,
        label: 'Add',
        command: this.changeUserWatcherLevel.bind(this, principle, 'Add'),
      }];

    return [{
      ...this.baseAccessAction,
      icon: null,
      id: 2,
      label: 'Add',
      command: this.changeUserWatcherLevel.bind(this, principle, 'Add'),
    }, {
      ...this.baseAccessAction,
      icon: null,
      id: 6,
      label: 'Delete',
      command: this.changeUserAccessLevel.bind(this, principle, 'Delete'),
    }];
  }

  getActionList(principle: string, first?: AccessRecord.AccessLevelEnum | 'Delete' | 'Add') {
    if (first)
      return [first == 'Delete' ? {
        ...this.baseAccessAction,
        icon: null,
        id: 1,
        label: 'Delete',
        command: this.changeUserAccessLevel.bind(this, principle, 'Delete'),
      } : {
        ...this.baseAccessAction,
        icon: null,
        id: 1,
        label: first,
        command: this.changeUserAccessLevel.bind(this, principle, AccessRecord.AccessLevelEnum[first]),
      }, ...Object.keys(AccessRecord.AccessLevelEnum).filter((x, idx) => x != humanizeCasedString(first)).map((level, idx) => {
        return {
          ...this.baseAccessAction,
          icon: null,
          id: idx + 2,
          label: level,
          command: this.changeUserAccessLevel.bind(this, principle, AccessRecord.AccessLevelEnum[level]),
        };
      })];

    return [...Object.keys(AccessRecord.AccessLevelEnum).map((level, idx) => {
      return {
        ...this.baseAccessAction,
        icon: null,
        id: idx + 2,
        label: level,
        command: this.changeUserAccessLevel.bind(this, principle, AccessRecord.AccessLevelEnum[level]),
      };
    }), {
      ...this.baseAccessAction,
      icon: null,
      id: 6,
      label: 'Delete',
      command: this.changeUserAccessLevel.bind(this, principle, 'Delete'),
    }];
  }
  removeDuplicates(objects: any[]): any[] {
    const seen = new Set<string>();
    const uniqueObjects: any[] = [];

    for (const obj of objects) {
      const serialized = JSON.stringify(obj);
      if (!seen.has(serialized)) {
        seen.add(serialized);
        uniqueObjects.push(obj);
      }
    }

    return uniqueObjects;
  }

  setWatcherUserListActions(dataList) {
    const newDataList = this.removeDuplicates(dataList);

    let tempList: {
      [x: string]: { actions: any[]; accessLevel?: AccessRecord.AccessLevelEnum | 'Add' | 'Delete'; principleType: 'GROUP' | 'USER' };
    } = {};
    newDataList.forEach((user) => {
      // if(!this?.subscribers?.find(x => x?.name === user)){
      tempList[user.name] = this.userActionsMap[user.name] || {
        actions: this.getWatchersActionList(user.name),
        accessLevel: 'Add',
        principleType: user.type,
      };
      // }
    });
    this.stepperService.tempAccesses = this.accessControl.value;
    this.userActionsMap = cloneDeep(tempList);
    this.formGroup.controls.access.patchValue(this.userActionsMap);
  }

  setUserListActions(dataList) {
    const newDataList = this.removeDuplicates(dataList);

    let tempList: {
      [x: string]: { actions: any[]; accessLevel?: AccessRecord.AccessLevelEnum | 'Add' | 'Delete'; principleType: 'GROUP' | 'USER' };
    } = {};
    newDataList.forEach((user) => {
      // if(!this?.subscribers?.find(x => x?.name === user)){
      tempList[user.name] = this.userActionsMap[user.name] || {
        actions: this.getActionList(user.name),
        accessLevel: 'READ',
        principleType: user.type,
      };
      // }
    });
    this.stepperService.tempAccesses = this.accessControl.value;
    this.userActionsMap = cloneDeep(tempList);
    this.formGroup.controls.access.patchValue(this.userActionsMap);
  }

  getData() {
    return this.getChangedFormValues(this.data);
  }

  setData(data: any) {
    // if (this.formGroup)
    //   this.formGroup.patchValue({ ...data });
    this.data = data;
  }

  initFormStructure(): void { }

  formChecked(controlName: string, control: FormControl, event) {
    if (controlName) {
      if (this.formGroup.contains(controlName)) {
        this.formGroup.removeControl(controlName);
      } else {
        this.formGroup.addControl(controlName, control);
      }
    }
  }
}
