@switch (viewType) {
  @case (dashletTypes.Empty) {
    <app-dashlet [config]="config.title"> </app-dashlet>
  }
  @case (dashletTypes.Text) {
    <app-dashlet-text
      [title]="config.title"
      [icon]="config.icon"
      [color]="config.color"
      [value]="config.value"
      [imageSrc]="config.imageSrc ? config.imageSrc() : null"
      [isLoading]="isLoading"
      (onInit)="onInitEvent()"
    >
    </app-dashlet-text>
  }
  @case (dashletTypes.Table) {
    <app-dashlet-table
      [title]="config.title"
      [cols]="config.cols"
      [tableData]="config.tableData()"
      [tableEventData]="config.tableEventData()"
      (onTableFilterChange)="onTableFilterChangeEvent($event)"
    >
    </app-dashlet-table>
  }
  @case (dashletTypes.Doughnut) {
    <app-dashlet-doughnut-chart
      [title]="config.title"
      [chartData]="config.chartData()"
      [listData]="config.listData()"
      [totalAmount]="config.totalAmount()"
      [listColors]="config.listColors ? config.listColors() : null"
      [chartOptions]="config.chartOptions"
      [plugin]="config.plugin"
      [showList]="config.showList"
      [showChart]="config.showChart"
      [isLoading]="isLoading"
      (onInit)="onInitEvent()"
      [horizontalMode]="config.horizontalMode"
      [useD3]="useD3"
    >
      <ng-content></ng-content>
      @if (dashletTitleTemplate) {
        <ng-template let-config #chartTitleTemplate>
          <ng-container *ngTemplateOutlet="dashletTitleTemplate; context: { $implicit: config }"></ng-container>
        </ng-template>
      }
    </app-dashlet-doughnut-chart>
  }
  @case (dashletTypes.Bar) {
    <app-dashlet-doughnut-chart
      [title]="config.title"
      [chartData]="config.chartData()"
      [listData]="config.listData()"
      [totalAmount]="config.totalAmount()"
      [listColors]="config.listColors ? config.listColors() : null"
      [chartOptions]="config.chartOptions"
      [plugin]="config.plugin"
      [showList]="config.showList"
      [showChart]="config.showChart"
      [isLoading]="isLoading"
      (onInit)="onInitEvent()"
      [horizontalMode]="config.horizontalMode"
      [chartType]="'bar'"
    >
      <ng-content></ng-content>
      @if (dashletTitleTemplate) {
        <ng-template let-config #chartTitleTemplate>
          <ng-container *ngTemplateOutlet="dashletTitleTemplate; context: { $implicit: config }"></ng-container>
        </ng-template>
      }
    </app-dashlet-doughnut-chart>
  }
  @case (dashletTypes.Radar) {
    <app-dashlet-doughnut-chart
      [title]="config.title"
      [chartData]="config.chartData()"
      [listData]="config.listData()"
      [totalAmount]="config.totalAmount()"
      [listColors]="config.listColors ? config.listColors() : null"
      [chartOptions]="config.chartOptions"
      [plugin]="config.plugin"
      [showList]="config.showList"
      [showChart]="config.showChart"
      [isLoading]="isLoading"
      (onInit)="onInitEvent()"
      [horizontalMode]="config.horizontalMode"
      [chartType]="'radar'"
    >
      <ng-content></ng-content>
      @if (dashletTitleTemplate) {
        <ng-template let-config #chartTitleTemplate>
          <ng-container *ngTemplateOutlet="dashletTitleTemplate; context: { $implicit: config }"></ng-container>
        </ng-template>
      }
    </app-dashlet-doughnut-chart>
  }
  @case (dashletTypes.PolarArea) {
    <app-dashlet-doughnut-chart
      [title]="config.title"
      [chartData]="config.chartData()"
      [listData]="config.listData()"
      [totalAmount]="config.totalAmount()"
      [listColors]="config.listColors ? config.listColors() : null"
      [chartOptions]="config.chartOptions"
      [plugin]="config.plugin"
      [showList]="config.showList"
      [showChart]="config.showChart"
      [isLoading]="isLoading"
      (onInit)="onInitEvent()"
      [horizontalMode]="config.horizontalMode"
      [chartType]="'polarArea'"
    >
      <ng-content></ng-content>
      @if (dashletTitleTemplate) {
        <ng-template let-config #chartTitleTemplate>
          <ng-container *ngTemplateOutlet="dashletTitleTemplate; context: { $implicit: config }"></ng-container>
        </ng-template>
      }
    </app-dashlet-doughnut-chart>
  }
  @case (dashletTypes.Line) {
    <app-dashlet-doughnut-chart
      [title]="config.title"
      [chartData]="config.chartData()"
      [listData]="config.listData()"
      [totalAmount]="config.totalAmount()"
      [listColors]="config.listColors ? config.listColors() : null"
      [chartOptions]="config.chartOptions"
      [plugin]="config.plugin"
      [showList]="config.showList"
      [showChart]="config.showChart"
      [isLoading]="isLoading"
      (onInit)="onInitEvent()"
      [horizontalMode]="config.horizontalMode"
      [chartType]="'line'"
    >
      <ng-content></ng-content>
      @if (dashletTitleTemplate) {
        <ng-template let-config #chartTitleTemplate>
          <ng-container *ngTemplateOutlet="dashletTitleTemplate; context: { $implicit: config }"></ng-container>
        </ng-template>
      }
    </app-dashlet-doughnut-chart>
  }
  @case (dashletTypes.Pie) {
    <app-dashlet-doughnut-chart
      [title]="config.title"
      [chartData]="config.chartData()"
      [listData]="config.listData()"
      [totalAmount]="config.totalAmount()"
      [listColors]="config.listColors ? config.listColors() : null"
      [chartOptions]="config.chartOptions"
      [plugin]="config.plugin"
      [showList]="config.showList"
      [showChart]="config.showChart"
      [isLoading]="isLoading"
      (onInit)="onInitEvent()"
      [horizontalMode]="config.horizontalMode"
      [chartType]="'pie'"
      [drillDownList]="drillDownList"
      [currentCardContent]="currentCardContent"
    >
      <ng-content></ng-content>
      @if (dashletTitleTemplate) {
        <ng-template let-config #chartTitleTemplate>
          <ng-container *ngTemplateOutlet="dashletTitleTemplate; context: { $implicit: config }"></ng-container>
        </ng-template>
      }
    </app-dashlet-doughnut-chart>
  }
  @case (dashletTypes.RiskMatrix) {
    <app-dashlet-risk-methodology [title]="config.title" [riskItems]="config.riskData()" (onInit)="onInitEvent()">
    </app-dashlet-risk-methodology>
  }
  @case (dashletTypes.Notification) {
    <app-dashlet-notification
      [title]="config.title"
      (onLoadMore)="config.onLoadMore($event)"
      [notifications]="config.notifications()"
      [notificationPage]="config.notificationPage()"
      [isLoading]="isLoading"
    >
    </app-dashlet-notification>
  }
  @case (dashletTypes.Timeline) {
    <app-dashlet-timeline
      (fetchMore)="config.onLoadMore($event)"
      [data]="config.data()"
      [auditTrailPage]="config.timelinePage()"
      [isLoading]="isLoading"
    >
    </app-dashlet-timeline>
  }
}
