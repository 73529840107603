import { ChartViewConfig } from '@shared/classes/model';
import { EChartsOption } from 'echarts';
import { AssignChartDataOptions, EchartBase } from '../../echart-base';

export class RoseChart extends EchartBase {
  chartOptions: EChartsOption = {
    animationDurationUpdate: (idx) => idx * 600,
    series: [
      {
        name: '',
        type: 'pie',
        radius: ['20%', '60%'],
        center: ['50%', '65%'],
        label: {
          show: true,
          formatter: '{c}',
          position: 'inner',
        },
        roseType: 'area',
        itemStyle: {
          borderRadius: 8,
        },
        // label: {
        //   show: false,
        //   position: 'center',
        // },
        // emphasis: {
        //   label: {
        //     show: true,
        //     fontSize: 20,
        //     fontWeight: 'bold',
        //   },
        // },
        // labelLine: {
        //   show: false,
        // },
        data: [],
      },
    ],
  };
  chartType: ChartViewConfig.ChartTypeEnum = 'ROSE';
  assignDataToChartOptions(options: AssignChartDataOptions): EChartsOption {
    let optionsCopy = { ...options?.chartOptions };
    optionsCopy.series[0].name = options?.name;
    optionsCopy.series[0].data = options?.data?.sort((a, b) => b?.value - a?.value);
    return optionsCopy;
  }
}
