import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-folder-selector',
  templateUrl: './folder-selector.component.html',
  styleUrls: ['./folder-selector.component.scss'],
})
export class FolderSelectorComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
