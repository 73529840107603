<div class="formgrid grid p-fluid">
  <div class="col-12 md:col-12">
    <span class="p-input-icon-right flex gap-2 w-full">
      <textarea pInputTextarea [rows]="10" [(ngModel)]="bulkItems" [ngModelOptions]="{ standalone: true }"></textarea>
      <!-- <input (keyup)="search(getSearchValue($event))" class="w-full" pInputText type="text" placeholder="Search Questions..." /> -->
    </span>
  </div>
</div>
<p-table
  [value]="products"
  editMode="row"
  [(selection)]="selectedProducts3"
  dataKey="id.id"
  [tableStyle]="{ 'min-width': '50rem' }"
  (onRowSelect)="onRowSelect($event)"
>
  <ng-template pTemplate="caption">
    <div class="table-header">
      <div class="flex-grow5 flex flex-row-reverse justify-content-between w-full">
        <app-button [action]="showAddQuestionActionBulk"></app-button>

        <!-- <app-button *ngIf="showSaveButton" [action]="submitSaveButtonAction"></app-button> -->
        <app-button *ngIf="products?.length > 0" [action]="clearTableAction"></app-button>
      </div>
    </div>
  </ng-template>
  <ng-template pTemplate="header">
    <tr>
      <th style="width: 4rem">select</th>
      <!-- <th>search Score</th> -->
      <th>Name</th>
      <!-- <th>Iso2013</th>
      <th>Iso2022</th>
      <th>Csf</th>
      <th>CscTop20</th>
      <th>Nesa</th> -->
      <th style="width: 20%"></th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-product let-editing="editing" let-ri="rowIndex">
    <tr [pEditableRow]="product">
      <td>
        <p-tableCheckbox [value]="product"></p-tableCheckbox>
      </td>

      <!-- <td [style.background-color]="product?.searchScore?.color ?? ''">
        <p-cellEditor>
          <ng-template pTemplate="output">
            {{ product.searchScore.searchScore }}
          </ng-template>
        </p-cellEditor>
      </td> -->
      <td>
        <p-cellEditor>
          <ng-template pTemplate="input">
            <input pInputText type="text" [(ngModel)]="product.name.name" />
          </ng-template>
          <ng-template pTemplate="output">
            {{ product.name.name }}
          </ng-template>
        </p-cellEditor>
      </td>
      <td *ngIf="selectedProduct(product)">
        <div class="flex align-items-center justify-content-center gap-2">
          <button
            *ngIf="!editing"
            pButton
            pRipple
            type="button"
            pInitEditableRow
            icon="pi pi-pencil"
            (click)="onRowEditInit(product)"
            class="p-button-rounded p-button-text"
          ></button>
          <button
            *ngIf="!editing"
            pButton
            pRipple
            type="button"
            icon="pi pi-trash"
            (click)="onRowDelete(product, $event)"
            class="p-button-rounded p-button-text p-button-danger"
          ></button>
          <button
            *ngIf="editing"
            pButton
            pRipple
            type="button"
            pSaveEditableRow
            icon="pi pi-check"
            (click)="onRowEditSave(product)"
            class="p-button-rounded p-button-text p-button-success mr-2"
          ></button>
          <button
            *ngIf="editing"
            pButton
            pRipple
            type="button"
            pCancelEditableRow
            icon="pi pi-times"
            (click)="onRowEditCancel(product, ri)"
            class="p-button-rounded p-button-text p-button-danger"
          ></button>
        </div>
      </td>
    </tr>
  </ng-template>
</p-table>
<div class="flex align-items-center mt-2 p-2 justify-content-end w-full gap-2">
  <app-button [action]="confirmAddLinkAction"></app-button>
</div>
