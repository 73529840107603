<div>
  <!-- <p-colorPicker
        [(ngModel)]="data"
        [appendTo]="'body'"
    ></p-colorPicker> -->

  <div
    [pTooltip]="data"
    tooltipPosition="top"
    [escape]="false"
    [style]="colorSize + ' border-radius:var(--border-radius); background:' + data + ';'"
    (click)="copyToClipboard(data)"
  ></div>
</div>
