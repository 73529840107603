<form [formGroup]="formGroup" (ngSubmit)="onSubmitForm()">
  <div class="formgrid grid p-fluid">
    <div class="col-12 md:col-6">
      <app-number-input
        label="Days to Keep"
        [placeholder]="'Enter Number of Days'"
        [control]="formGroup?.controls?.daysToKeep"
        [viewMode]="fieldViewMode"
      ></app-number-input>
    </div>
  </div>
  <div class="flex w-full flex-column align-items-end">
    <app-button class="w-fit" [action]="submitSaveButtonAction"></app-button>
  </div>
  <ng-content></ng-content>
</form>
