<app-base-page [breadCrumb]="breadCrumb">
  <div class="flex md:flex-row flex-column w-full gap-2 justify-content-between parent-container">
    <div class="filter-container flex flex-column gap-3 md:h-full h-20rem">
      <div class="formgrid grid p-fluid">
        <div class="col-12">
          <app-basic-input
            label=""
            [placeholder]="'Enter name to search...'"
            [control]="formGroup?.controls?.query"
            [viewMode]="'create'"
          ></app-basic-input>
        </div>
        <div class="col-12">
          <app-tag-search-input
            [viewMode]="'create'"
            [placeholder]="'Search Tags...'"
            [controls]="
              tagsSet
                ? { tagStringsControl: tagsStringControl, tagCodesControl: formGroup?.controls?.tags, emitEvent: false }
                : null
            "
          ></app-tag-search-input>
        </div>
      </div>
      <div class="flex flex-column overflow-y-auto h-full gap-2">
        <div class="flex flex-row gap-2">
          <p-checkbox
            [binary]="true"
            [(ngModel)]="allSelected"
            (onChange)="toggleAll($event)"
            inputId="all"
          ></p-checkbox>
          <span>All</span>
        </div>
        @for (item of targetTypesList; track item) {
          <div class="flex flex-row gap-2">
            <p-checkbox
              [binary]="true"
              [(ngModel)]="selectedMap[item.value]"
              (onChange)="filterChecked($event, item.value)"
              [inputId]="item.value"
            ></p-checkbox>
            <span>
              {{ item.label }}
            </span>
          </div>
        }
      </div>
    </div>
    <div class="search-container">
      <div class="flex flex-column md:h-full h-auto">
        <div class="md:h-full h-auto overflow-y-auto flex flex-column gap-2">
          @if (searchable) {
            @if (loading) {
              <div class="mb-4 w-full text-center">
                <i class="pi pi-spin pi-spinner text-400"></i>
              </div>
            } @else {
              @if (items?.length) {
                @for (item of items; track item) {
                  <div class="w-full">
                    <app-code-navigation [data]="item"></app-code-navigation>
                  </div>
                }
              } @else {
                <div class="flex justify-content-center align-items-center w-full">
                  <span class="font-medium text-base text-center w-full"> No items found </span>
                </div>
              }
            }
          } @else {
            <div class="flex justify-content-center align-items-center w-full">
              <span class="font-medium text-base text-center w-full">No Tags Selected</span>
            </div>
          }
        </div>
        <p-paginator
          #p
          (onPageChange)="onPageChange($event)"
          [first]="0"
          [rows]="paginator.size"
          [totalRecords]="totalElements"
          [rowsPerPageOptions]="[10, 20, 30]"
        ></p-paginator>
      </div>
    </div>
  </div>
</app-base-page>
