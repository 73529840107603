<div class="w-full">
  <div class="w-full">
    <!-- <h4 class="mb-4">Risk Project Basic Information</h4> -->
    <app-bulk-operation-choice-step-form
      (formSubmit)="onSubmit($event)"
      [formData]="stepperService.stepperItem"
      (formStatusChanged)="formStatusChanged($event)"
      (formChanges)="formChanges($event)"
    >
      <div class="flex flex-wrap m-0 justify-content-end">
        <app-button [customStatus$]="formValid" [action]="nextButton"></app-button>
      </div>
    </app-bulk-operation-choice-step-form>
  </div>
</div>
