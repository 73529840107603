import { DataTypeEnum } from '@shared/classes';

export interface InputPrefix {
  type: 'icon' | 'text';
  enabled: boolean;
  value: string;
}
export interface Ifield {
  key: string;
  label: string;
  ariaLabel?: string;
  placeholder?: string;
  options?: FieldOptions;
  dataType: DataTypeEnum;
  styleClass?: string | string[];
  validations?: FieldValidations;
  renderComponent?: RenderComponent;
  prefix?: InputPrefix;
}

/**
 * @TODO This class could be an abstract class
 * And extends it with the specific provided dataType
 */
export class Field {
  key: string;
  prefix: {
    type: 'icon' | 'text';
    enabled: boolean;
    value: string;
  };
  label: string;
  ariaLabel: string;
  placeholder: string;
  options: FieldOptions;
  dataType: DataTypeEnum;
  validations: FieldValidations;
  styleClass?: string | string[];
  renderComponent: RenderComponent;
  constructor(field: Ifield) {
    this.key = field.key;
    this.prefix = field?.prefix;
    this.options = field?.options;
    this.label = field?.label || 'Label';
    this.dataType = field.dataType;
    this.ariaLabel = field?.label || 'Input';
    this.styleClass = field?.styleClass || [];
    this.validations = field?.validations;
    this.placeholder = field?.placeholder;
  }

  get isRequired(): boolean {
    return !!this.validations?.required?.enabled;
  }

  get hasMinValidator(): boolean {
    return !!this.validations?.min?.enabled;
  }

  get minValue(): number {
    return this.validations?.min?.value || 0;
  }

  get hasMaxValidator(): boolean {
    return !!this.validations?.max?.enabled;
  }

  get maxValue(): number {
    return this.validations?.max?.value || 0;
  }

  get hasMinLengthValidator(): boolean {
    return !!this.validations?.minLength?.enabled;
  }

  get minLengthValue(): number {
    return this.validations?.minLength.value || 0;
  }

  get hasMaxLengthValidator(): boolean {
    return !!this.validations?.maxLength?.enabled;
  }

  get maxLengthValue(): number {
    return this.validations?.maxLength?.value || 0;
  }
}

// export interface ItextField extends Ifield {
//     validations?: TextFieldValidations;
//     options?: FieldOptions;
// }

// export abstract class Field {
//     label: string;
//     ariaLabel: string;
//     styleClass?: string | string[];
//     renderComponent: RenderComponent;

//     abstract validations: FieldValidations;
//     constructor(field: any) {
//         this.label = field?.label || 'Label';
//         this.ariaLabel = field?.label || 'Input';
//         this.styleClass = field?.styleClass || [];
//     }
// }

// export class NumberField extends Field {
//     validations: NumberFieldValidations = {} as NumberFieldValidations;
//     constructor(field: any) {
//         super(field);
//         this.validations.required = field.validations?.required || {
//             enabled: false,
//         };
//         this.validations.min = field.validations?.min || { enabled: false };
//         this.validations.max = field.validations?.max || { enabled: false };
//         this.renderComponent = {
//             editComponentSelector: 'app-number-input',
//             viewComponentSelector: 'app-view-number',
//         };
//     }

//     get isRequired(): boolean {
//         return !!this.validations?.required?.enabled;
//     }

//     get hasMinValidator(): boolean {
//         return !!this.validations?.min?.enabled;
//     }

//     get minValue(): number {
//         return this.validations?.min?.value || 0;
//     }

//     get hasMaxValidator(): boolean {
//         return !!this.validations?.max?.enabled;
//     }

//     get maxValue(): number {
//         return this.validations?.max?.value || 0;
//     }
// }

// export class TextField extends Field {
//     validations: TextFieldValidations = {} as TextFieldValidations;
//     constructor(field: ItextField) {
//         super(field);
//         this.validations.required = field.validations?.required || {
//             enabled: false,
//         };
//         this.validations.minLength = field.validations?.minLength || null;
//         this.validations.maxLength = field.validations?.maxLength || null;
//         this.renderComponent = {
//             editComponentSelector: 'app-number-input',
//             viewComponentSelector: 'app-view-number',
//         };
//     }

//     get isRequired(): boolean {
//         return !!this.validations?.required?.enabled;
//     }

//     get hasMinLengthValidator(): boolean {
//         return !!this.validations?.minLength?.enabled;
//     }

//     get minLengthValue(): number {
//         return this.validations?.minLength.value || 0;
//     }

//     get hasMaxLengthValidator(): boolean {
//         return !!this.validations?.maxLength?.enabled;
//     }

//     get maxLengthValue(): number {
//         return this.validations?.maxLength?.value || 0;
//     }
// }

export interface FieldOptions {
  [prop: string]: any;
}

export interface FieldValidations {
  required: RequiredValidator;
  min?: MinValidator;
  max?: MaxValidator;
  minLength?: MinLengthValidator;
  maxLength?: MaxLengthValidator;
  [prop: string]: { [prop: string]: any };
}

export interface NumberFieldValidations extends FieldValidations {
  min: MinValidator;
  max: MaxValidator;
}

export interface TextFieldValidations extends FieldValidations {
  minLength: MinLengthValidator;
  maxLength: MaxLengthValidator;
}

export interface RenderComponent {
  editComponentSelector: string;
  viewComponentSelector: string;
}

export interface RequiredValidator {
  enabled: boolean;
}

export interface MinValidator {
  enabled: boolean;
  value: number;
}

export interface MaxValidator {
  enabled: boolean;
  value: number;
}

export interface MinLengthValidator {
  enabled: boolean;
  value: number;
}

export interface MaxLengthValidator {
  enabled: boolean;
  value: number;
}
