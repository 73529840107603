import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AuditTrailDto } from '@shared/classes';
import { PaginationModel } from '@shared/classes/model/backend/base-responses';
import { BaseViewItem } from '@shared/classes/view/BaseViewItem';

@Component({
  selector: 'app-dashlet-timeline',
  templateUrl: './dashlet-timeline.component.html',
  styleUrls: ['./dashlet-timeline.component.scss'],
})
export class DashletTimelineComponent extends BaseViewItem<AuditTrailDto[]> implements OnInit {
  @Input() isLoading: boolean = true;

  @Input() auditTrailPage: PaginationModel<AuditTrailDto>;

  @Output() fetchMore: EventEmitter<number> = new EventEmitter();

  @Output() onInit: EventEmitter<any> = new EventEmitter();

  ngOnInit(): void {
    if (this.onInit) {
      this.onInit.emit();
    }
  }

  loadMore(page) {
    if (this.fetchMore) {
      this.fetchMore.emit(page);
    }
  }
}
