import { ChartViewConfig } from '@shared/classes/model';
import { EChartsOption } from 'echarts';
import { AggregateFunctionOptions, AssignChartDataOptions, EchartBase, InitOptions } from '../../echart-base';
import { groupData } from '../../helpers/general-functions';

export class HeatmapChart extends EchartBase {
  chartOptions: EChartsOption = {
    animationDurationUpdate: (idx) => idx * 600,
    grid: {
      top: 60,
      bottom: 80,
    },
    xAxis: [
      {
        type: 'category',
        data: [],
        splitArea: {
          show: true,
        },
      },
    ],
    yAxis: [
      {
        type: 'category',
        data: [],
        splitArea: {
          show: true,
        },
      },
    ],
    visualMap: [
      {
        min: 0,
        max: 10,
        calculable: true,
        orient: 'horizontal',
        left: 'center',
        bottom: 10,
      },
    ],
    series: [
      {
        name: '',
        type: 'heatmap',
        data: [],
        label: {
          show: true,
        },
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowColor: 'rgba(0, 0, 0, 0.5)',
          },
        },
      },
    ],
  };
  chartType: ChartViewConfig.ChartTypeEnum = 'HEATMAP';
  assignDataToChartOptions(options: AssignChartDataOptions): EChartsOption {
    let optionsCopy = { ...options?.chartOptions };
    optionsCopy.series[0].name = null; //options?.name;
    const heatMap = this.generateHeatmapData(options?.data);
    optionsCopy.series[0].data = heatMap?.heatmap;
    optionsCopy.xAxis[0].data = heatMap?.xAxis;
    optionsCopy.yAxis[0].data = heatMap?.yAxis;
    optionsCopy.visualMap[0].min = heatMap?.minValue;
    optionsCopy.visualMap[0].max = heatMap?.maxValue;
    //@TODO: children format original data
    return optionsCopy;
  }
  formatAggregateData(options: AggregateFunctionOptions) {
    return {
      data: groupData(options?.response?.aggregation_value, options?.payload?.groupByFields, options?.drillDownAggregationField),
      chartTitle: null,
    };
  }
  generateHeatmapData(tree: any[]): {
    xAxis: string[];
    yAxis: string[];
    heatmap: [number, number, number][];
    minValue: number;
    maxValue: number;
  } {
    const xAxis: string[] = [];
    const yAxis: string[] = [];
    const heatmap: [number, number, number][] = [];
    let minValue = Infinity;
    let maxValue = -Infinity;

    tree.forEach((node, xIndex) => {
      xAxis.push(node.name);
      if (node.children) {
        node.children.forEach((child, yIndex) => {
          if (!yAxis.includes(child.name)) {
            yAxis.push(child.name);
          }
          const value = child.value;
          heatmap.push([xIndex, yAxis.indexOf(child.name), value]);
          if (value < minValue) minValue = value;
          if (value > maxValue) maxValue = value;
        });
      }
    });

    return { xAxis, yAxis, heatmap, minValue, maxValue };
  }
  assignColors(echartOptions: EChartsOption,initOptions:InitOptions): EChartsOption{
    if (
      initOptions?.viewCardItem?.contentViewConfig?.colorPalette?.length > 0 ||
      this.fallbackColorPalete?.length > 0
    ) {
      echartOptions.color =
        initOptions?.viewCardItem?.contentViewConfig?.colorPalette || this.fallbackColorPalete;
        echartOptions.visualMap[0].color = (initOptions?.viewCardItem?.contentViewConfig?.colorPalette || this.fallbackColorPalete).slice(0,2);
    }
    return {...echartOptions}
  }
}
