<span
  [pTooltip]="tooltipContent"
  tooltipPosition="top"
  [autoHide]="false"
  [innerHtml]="data | humanize | highlight: filter"
  [class.max-w-18rem]="useStaticMaxWidth"
  class="line-clamp-1-ellipsis"
>
</span>
<ng-template #tooltipContent>
  <div class="max-h-30rem max-w-30rem overflow-auto">
    <span [innerHtml]="data | humanize | highlight: filter"></span>
  </div>
</ng-template>
