<div class="flex flex-column w-full h-full relative" [class.card]="displayCard">
  @if (item) {
    <app-dashlet-view-switch
      [config]="item.config"
      [isLoading]="item.isLoading()"
      [viewType]="item.type"
      (onInit)="item.onInit()"
      [useD3]="false"
      [drillDownList]="aggregationResult"
      [currentCardContent]="cardContent"
    >
      @if (headerTemplate) {
        <ng-template let-config #dashletTitleTemplate>
          <div class="flex flex-column w-full field-m-0">
            <ng-container *ngTemplateOutlet="headerTemplate; context: { $implicit: config }"></ng-container>
            @if (showChartViewControls) {
              <div class="gridster-item-content flex flex-row flex-wrap gap-3 field-m-0 mt-2">
                <app-drop-down-input
                  [label]="''"
                  [placeholder]="'Chart Type'"
                  [name]="'type'"
                  [optionLabel]="'label'"
                  [items]="chartTypeItems"
                  [optionValue]="'value'"
                  [control]="formGroup?.controls?.chartType"
                  [viewMode]="'create'"
                  [showClear]="false"
                  [badgeView]="true"
                  [appendTo]="'body'"
                ></app-drop-down-input>
                <app-drop-down-input
                  [label]="''"
                  [placeholder]="'Chart View Type'"
                  [name]="'type'"
                  [optionLabel]="'label'"
                  [items]="chartViewTypeItems"
                  [optionValue]="'value'"
                  [control]="formGroup?.controls?.viewType"
                  [viewMode]="'create'"
                  [showClear]="false"
                  [badgeView]="true"
                  [appendTo]="'body'"
                ></app-drop-down-input>
              </div>
            }
          </div>
        </ng-template>
      }
    </app-dashlet-view-switch>
  }
  <ng-content></ng-content>
</div>
