import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-avatar-info-card-tray',
  templateUrl: './avatar-info-card-tray.component.html',
  styleUrls: ['./avatar-info-card-tray.component.scss'],
})
export class AvatarInfoCardTrayComponent implements OnInit {
  @Input() userList: any[] = [];
  constructor() {}

  ngOnInit(): void {}
}
