import { Component, Input, OnInit } from '@angular/core';
import { BaseViewItem } from '@shared/classes';

@Component({
  selector: 'app-humanized-text-view',
  templateUrl: './humanized-text-view.component.html',
  styleUrls: ['./humanized-text-view.component.scss'],
})
export class HumanizedTextViewComponent extends BaseViewItem implements OnInit {
  @Input() filter: string;
  @Input() useStaticMaxWidth: boolean = true;
  constructor() {
    super();
  }

  ngOnInit(): void {}
}
