import { AggregationBody, AggregationField, ChartViewConfig, ViewCardItem } from '@shared/classes/model';
import { EChartsOption } from 'echarts';
import {
  getAggregationFieldIdKeyList,
  getGroupFieldIdKey,
  humanizeCasedString,
  isNullObj,
  isValidCode,
} from './helpers/general-functions';

export abstract class EchartBase {
  defaultOptions: EChartsOption = {
    backgroundColor: 'transparent',
    animationDurationUpdate: (idx) => idx * 600,
    // title: {
    //     text: '',
    //     subtext: '',
    //     left: 'center'
    // },
    tooltip: {
      trigger: 'item',
      appendTo: 'body',
    },
    legend: {
      top: 0,
      left: 'center',
      type: 'scroll',
      orient: 'horizontal',
      padding: [50, 10, 20, 50],
      pageIconColor: '#9d9ba3',
      pageTextStyle: {
        color: '#9d9ba3',
      },
    },
    grid: {
      top: 80,
    },
    toolbox: {
      feature: {
        // dataZoom: {
        //   yAxisIndex: 'none',
        // },
        // restore: {},
        saveAsImage: {},
      },
    },
  };
  abstract chartOptions: EChartsOption;
  abstract chartType: ChartViewConfig.ChartTypeEnum;
  get activeChartOptions() {
    return { ...this.defaultOptions, ...this.chartOptions };
  }
  fallbackColorPalete: string[] = null;
  overrideTooltipObject = null;
  abstract assignDataToChartOptions(options: AssignChartDataOptions): EChartsOption;

  getEChartOptions(initOptions: InitOptions): EChartsOption {
    // get assigned options from the class
    let chartOptions: EChartsOption = this.activeChartOptions;
    //Formate the aggregation result into chart acceptable format
    const availableAggregationFields = getAggregationFieldIdKeyList(
      initOptions?.viewCardItem?.contentViewObject?.type == 'STATISTICS' ? (initOptions?.aggregationResult?.collectedStatistics?.[0]?.payload?.aggregationFields || []) : initOptions?.viewCardItem?.contentViewObject?.filterView?.aggregationBody?.aggregationFields
    );
    const aggregateData = this.formatAggregateData({
      ...initOptions?.aggregationResult,
      drillDownAggregationField:
        availableAggregationFields.find(
          (x) => x == initOptions?.viewCardItem?.contentViewConfig?.drillDownAggregationField
        ) ||
        availableAggregationFields.find(
          (x) => x == initOptions?.viewCardItem?.contentViewObject?.chartViewConfig?.drillDownAggregationField
        ) ||
        availableAggregationFields?.[0],
    });
    //assign the formatted data to the chart options
    let chartOptionsWithData = this.assignDataToChartOptions({
      chartOptions: chartOptions,
      name:
        aggregateData?.chartTitle ||
        initOptions?.viewCardItem?.contentViewObject?.name ||
        initOptions?.viewCardItem?.contentViewCode,
      data: aggregateData.data,
      initOptions: initOptions,
    });
    //handle overrides
    //color palette override
    chartOptionsWithData = this.assignColors(chartOptionsWithData, initOptions);
    //tooltip override
    chartOptionsWithData.tooltip = this.overrideTooltipObject ?? chartOptionsWithData.tooltip;
    const finalChartOptions = this.handleOptionsOverride(chartOptionsWithData, initOptions.renderOptions);
    finalChartOptions.title = {
      text:
        humanizeCasedString(getGroupFieldIdKey(initOptions?.aggregationResult?.payload?.groupByFields?.[0])) ||
        aggregateData?.chartTitle ||
        initOptions?.viewCardItem?.contentViewObject?.name ||
        initOptions?.viewCardItem?.contentViewCode,
      subtext: humanizeCasedString(
        availableAggregationFields.find(
          (x) => x == initOptions?.viewCardItem?.contentViewConfig?.drillDownAggregationField
        ) ||
          availableAggregationFields.find(
            (x) => x == initOptions?.viewCardItem?.contentViewObject?.chartViewConfig?.drillDownAggregationField
          ) ||
          availableAggregationFields?.[0]
      ),
      left: 'center',
    };
    return { ...finalChartOptions };
    // this.defaultOptions = { ...option };
    // this.chartId = uuidv4(); //@TODO use an id generator (not really important)
  }
  assignColors(echartOptions: EChartsOption, initOptions: InitOptions): EChartsOption {
    if (
      initOptions?.viewCardItem?.contentViewConfig?.colorPalette?.length > 0 ||
      this.fallbackColorPalete?.length > 0
    ) {
      echartOptions.color = initOptions?.viewCardItem?.contentViewConfig?.colorPalette || this.fallbackColorPalete;
    }
    return { ...echartOptions };
  }
  handleOptionsOverride(echartOptions: EChartsOption, renderOptions: RenderOptions): EChartsOption {
    let optionsCopy = { ...echartOptions };
    if (renderOptions && renderOptions.renderType) {
      if (renderOptions.renderType == 'STATIC') {
        Object.entries(StaticRenderModeOverrides).forEach(([key, value], index) => {
          optionsCopy[key] = value;
        });
      } else if (renderOptions.renderType == 'LIVE') {
        Object.entries(LiveRenderModeOverrides).forEach(([key, value], index) => {
          optionsCopy[key] = value;
        });
      }
    }
    return optionsCopy;
  }
  formatAggregateData(options: AggregateFunctionOptions) {
    return this.aggregateToSingleDimension(options);
  }
  aggregateToSingleDimension(options: AggregateFunctionOptions) {
    // const aggregateField = getGroupFieldIdKey(options?.payload?.groupByFields?.[0]);
    // const aggregationFieldOperation: any =
    //   options?.payload?.aggregationFields?.find((x) => x?.fieldName == aggregateField)?.operation?.toLowerCase() ||
    //   'count';
    let chartDataList: { [x: string]: any } = {};
    const groupByKey = getGroupFieldIdKey(options?.payload?.groupByFields?.[0]) ?? null; // the group by key to have data split by it
    options?.payload?.aggregationFields?.forEach((field) => {
      chartDataList[field.fieldName + '_' + field?.operation?.toLowerCase()] = this.parseAggregateData(
        options?.response?.aggregation_value,
        field?.fieldName, // the aggregation field i want to have counts/sums ..etc for it
        field?.operation?.toLowerCase(),
        null,
        groupByKey
      );
    });
    const aggregationResult = {
      fields: options?.payload?.aggregationFields,
      value: options?.response?.aggregation_value,
      formattedValues: chartDataList,
      drillDownAggregationField: options?.drillDownAggregationField, // the aggregation field that the chart should show counts for
      groupByKey: groupByKey,
    };
    return this.initialChartFormatData(aggregationResult);
  }
  parseAggregateData(items: any[], key: string, type: any, enumContainer: any = null, idKey: any = null) {
    let ret = {};
    if (enumContainer) {
      Object.keys(enumContainer).forEach((key) => {
        ret[enumContainer[key]] = 0;
      });
    }
    if (items) {
      if (idKey) {
        items.forEach((item) => {
          const retKey =
            item.group_id[idKey] != null && item.group_id[idKey] != undefined
              ? typeof item.group_id[idKey] === 'object'
                ? JSON.stringify(item.group_id[idKey])
                : item.group_id[idKey]
              : null;
          if (item[key + '_' + type] && retKey != null && retKey != undefined) {
            ret[retKey] = ret[retKey] ? ret[retKey] + item[key + '_' + type] : item[key + '_' + type];
          } else if (item[key + '_' + type]) {
            //HANDLE NULL GROUP BY VALUE (the field has nulls)
            ret['EMPTY_VALUE'] = ret['EMPTY_VALUE']
              ? ret['EMPTY_VALUE'] + item[key + '_' + type]
              : item[key + '_' + type];
          }
        });
      } else {
        //wont ever reach here, legacy code usage
        items.forEach((item) => {
          if (item[key + '_' + type] && item.group_id != null && item.group_id != undefined)
            ret[item.group_id] = item[key + '_' + type];
        });
      }
    }
    return ret;
  }
  initialChartFormatData(aggregationResult: IAggregationResult) {
    if (
      isNullObj(aggregationResult?.value) ||
      isNullObj(aggregationResult?.fields) ||
      isNullObj(aggregationResult?.formattedValues)
    )
      return null;
    const currentField = aggregationResult?.drillDownAggregationField; //this.activeDataIndex FIRST TIME ALWAYS 0 other handling will be initiated by the charts calling formatAggregateData directly
    if (isNullObj(currentField)) return null;

    return this.finalizeAggregateData(aggregationResult?.formattedValues?.[currentField], aggregationResult.groupByKey);
  }
  finalizeAggregateData(dataObject, title) {
    // const humanizeKeys = false; //!!(this.mappingService?.mappedFields?.[title]?.tableDefinition?.filter?.enumClass || null);
    const formattedData = [];
    Object.entries(dataObject).forEach(([key, val], index) => {
      formattedData.push({ name: isValidCode(key) ? key : humanizeCasedString(key), value: val });
    });
    const data = [...formattedData];
    const chartTitle = humanizeCasedString(title);
    // const mergeOptions = this.assignDataToChartOptions(
    //     {chartOptions:this.activeChartOptions,
    //     chartType:this.chartType,
    //     name:chartTitle || title,
    //     data:data}
    // );
    return { data, chartTitle };
  }
  fixAggregateId(groupByFields: string[], response) {
    // if (isNullObj(groupByFields)) {
    //@TODO: discuss this case and come up with appropriate handling for it
    return { ...response };
    // }
    // return groupByFields?.length > 1
    //   ? { ...response }
    //   : {
    //       ...response,
    //       aggregation_value: response?.aggregation_value?.map((x) => {
    //         return { ...x, group_id: isString(x.group_id) ? { [groupByFields?.[0]]: x.group_id } : x.group_id };
    //       }),
    //     };
  }
  //implemented in variation
  getOptions():EchartFieldDefinition[]{ //return defined() values
    return [];
  }
  //shall be implemented in base
  getOptionValue(optionName:string){//uses getOptionsValues to return value for an item
    //check if in defined variations
    // return value from the getOptionsValues
    return
  }
  //implement in base
  getOptionsValues(){
    // search for keys and return formatted map based on the variation formatter  (values from form)
    return null;
  }
}
export interface EchartFieldDefinition{

}
export interface AggregationValue {
  group_id: { [key: string]: any };
  [key: string]: any;
}
export interface AggregateDataOptions {
  response?: { aggregation_value: AggregationValue[] };
  payload?: AggregationBody;
  collectedStatistics?: AggregateStatistics[];
}
export interface AggregateFunctionOptions extends AggregateDataOptions {
  drillDownAggregationField?: string;
}
export interface IAggregationResult {
  fields: AggregationField[];
  value: AggregationValue[];
  formattedValues: { [x: string]: any };
  drillDownAggregationField: string;
  groupByKey: string;
}
export interface AggregateStatistics {
  collectionDate?: string;
  response?: { aggregation_value: AggregationValue[] };
  payload?: AggregationBody;
}
export interface InitOptions {
  viewCardItem: ViewCardItem;
  aggregationResult: AggregateDataOptions;
  renderOptions?: RenderOptions;
}
export interface AssignChartDataOptions {
  chartOptions: EChartsOption;
  name?: string;
  data?: { value: number; name: string; [x: string]: any }[];
  initOptions?: InitOptions;
}
export interface RenderOptions {
  renderType?: 'STATIC' | 'LIVE';
}
export const StaticRenderModeOverrides: EChartsOption = {
  animation: false,
  toolbox: null,
};
export const LiveRenderModeOverrides: EChartsOption = {};
