import { AfterViewInit, Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { BaseForm, FieldDto, IAction } from '@shared/classes';
import { ViewModeService } from '@shared/services/view-mode.service';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-dynamic-type-options-form-popup',
  templateUrl: './dynamic-type-options-form-popup.component.html',
  styleUrls: ['./dynamic-type-options-form-popup.component.scss'],
})
export class DynamicTypeOptionsFormPopupComponent extends BaseForm<FieldDto> implements OnInit, AfterViewInit {
  submitButtonAction: IAction = {
    id: 1,
    label: 'Ok',
    buttonType: 'button',
    command: this.onSubmitForm.bind(this),
    icon: 'pi pi-save',
    status$: this.formValid$,
  };
  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    public viewModeService: ViewModeService
  ) {
    super(viewModeService);
  }

  ngOnInit(): void {}
  ngAfterViewInit(): void {
    setTimeout(() => {
      this.setData(this.config.data);
    }, 100);
    this._setupSubscriptions();
  }
  onSubmitForm() {
    this.ref.close(this.getData());
  }
  getData() {
    return {
      options: { ...this.formGroup.getRawValue() }.options,
    };
  }

  setData(data: any) {
    this.formGroup.patchValue({ ...data });
    this.data = data;
  }

  initFormStructure(): void {
    this.formGroup = new FormGroup({
      type: new FormControl(null),
      options: new FormControl(null),
      // questionType: new FormControl(null, Validators.required),
      // options: new FormControl(null),
    });
  }
}
