<div class="w-full">
  <div class="w-full">
    <!-- <h4 class="mb-4">Risk Project Basic Information</h4> -->
    <app-bulk-operation-results-step-form
      (formSubmit)="onSubmit($event)"
      (formChanges)="formChanges($event)"
      [stepperService]="stepperService"
      [completedCounterS]="stepperService?.completedCounterS"
      [completedCounterF]="stepperService?.completedCounterF"
      [operationStatuses]="stepperService?.operationStatuses"
      [totalCount]="stepperService?.totalCount"
    >
      <div class="flex flex-wrap m-0 justify-content-end">
        <app-button [action]="nextButton"></app-button>
      </div>
    </app-bulk-operation-results-step-form>
  </div>
</div>
