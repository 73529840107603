import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DynamicComponentBase, IDynamicComponent, ITableLoadEvent, ITablePageable, OfflineConfig } from '@shared/classes';

@Component({
  selector: 'app-lazy-form-repeater',
  templateUrl: './lazy-form-repeater.component.html',
  styleUrl: './lazy-form-repeater.component.scss'
})
export class LazyFormRepeaterComponent extends DynamicComponentBase implements OnInit {

  @Input() cols: IDynamicComponent[];
  @Input() showOptionsAction: boolean = false;
  @Input() addOrderFieldToOptions: boolean = true;
  @Input() showDeleteButton: boolean = true;
  @Input() showEditButton: boolean = true;
  @Input() showAddForm: boolean = true;
  @Input() showReorder: boolean = true;
  @Input() flyMode: boolean = false;
  @Input() showSelection: boolean = false;
  @Input() loading: boolean = true;
  @Input() offlineConfig: OfflineConfig = new OfflineConfig();
  @Input() pageInfo: ITablePageable = new ITablePageable();
  @Input() rowsPerPageOptions: any[] = [5, 10, 25, 50, 100, 200];
  @Input() cellsStyle: string = null;
  @Input() title: string = 'Form Repeater';
  @Input() selectionMode: 'single' | 'multiple' | undefined | null = 'multiple';
  @Input() floatActions: boolean = false;
  @Input() actionBodyClass = null;
  @Input() actionHeaderClass = null;
  @Input() headerStyle = null;
  @Input() tableStyle = null;
  @Output() normalMode: EventEmitter<boolean> = new EventEmitter();
  @Output() inlineEdit: EventEmitter<any> = new EventEmitter();
  @Output() filtersChanged: EventEmitter<ITableLoadEvent> = new EventEmitter();
  @Output() lazyLoad: EventEmitter<any> = new EventEmitter();
  @Output() onSelectedItemsChange: EventEmitter<any[]> = new EventEmitter();
  onSelectedItemsChangeEvent(event) {
    this.onSelectedItemsChange.emit(event);
  }
  normalModeEvent(event) {
    this.normalMode.emit(event);
  }
  inlineEditEvent(event) {
    this.inlineEdit.emit(event);
  }
  onLazyLoad(event) {
    this.lazyLoad.emit(event);
  }
  onFiltersChanged(event) {
    this.filtersChanged.emit(event);
  }
  ngOnInit() {
  }

  setInputOptions(): void {
  }
}
