<app-dashlet [styleClass]="styleClass" [config]="{ title: title }">
  <ng-content></ng-content>
  @if (headerTemplate) {
    <ng-template let-config #titleTemplate>
      <ng-container *ngTemplateOutlet="headerTemplate; context: { $implicit: config }"></ng-container>
    </ng-template>
  }
  @switch (cardContent.customViewConfig.componentType) {
    @case ('RISK_HEATMAP') {
      <app-widget-risk-map></app-widget-risk-map>
    }
    @case ('NOTIFICATIONS') {
      <app-widget-notifications></app-widget-notifications>
    }
    @case ('SYSTEM_TIMELINE') {
      <app-widget-timeline></app-widget-timeline>
    }
    @default {}
  }
</app-dashlet>
