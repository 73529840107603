import { Component, ContentChild, Input, OnInit, TemplateRef } from '@angular/core';
import { IAction } from '@shared/classes';

@Component({
  selector: 'app-dashlet',
  templateUrl: './dashlet.component.html',
  styleUrls: ['./dashlet.component.scss'],
})
export class DashletComponent implements OnInit {
  @ContentChild('titleTemplate') titleTemplate: TemplateRef<any>;
  @Input() config: any = {};
  @Input() editMode: boolean = false;
  @Input() styleClass: string = null;
  mainAction: IAction = {
    id: 1,
    label: '',
    buttonType: 'button',
    buttonStyle: 'text',
    color: 'secondary',
    passEvent: true,
    icon: 'pi pi-ellipsis-v',
  };
  constructor() {}

  ngOnInit(): void {}
}
