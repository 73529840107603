import { ChartViewConfig } from '@shared/classes/model';
import { EChartsOption } from 'echarts';
import { AssignChartDataOptions, EchartBase } from '../../echart-base';

export class SemiCircleDoughnutChart extends EchartBase {
  chartOptions: EChartsOption = {
    backgroundColor: 'transparent',
    series: [
      {
        name: '',
        type: 'pie',
        radius: ['40%', '70%'],
        center: ['50%', '90%'],
        // adjust the start and end angle
        startAngle: 180,
        endAngle: 360,
        data: [],
        label: {
          formatter: '{c}',
          position: 'inner',
        },
      },
    ],
  };
  chartType: ChartViewConfig.ChartTypeEnum = 'SEMI_CIRCLE_DOUGHNUT';
  assignDataToChartOptions(options: AssignChartDataOptions): EChartsOption {
    let optionsCopy = { ...options?.chartOptions };
    optionsCopy.series[0].name = options?.name;
    optionsCopy.series[0].data = options?.data;
    return optionsCopy;
  }
}
