import { ChartViewConfig } from '@shared/classes/model';
import { EChartsOption } from 'echarts';
import { EchartVariationBase } from '../../../echart-variation-base';
import { RadarChart } from '../../base/radar-chart';

export class LineRadarChart extends RadarChart implements EchartVariationBase {
  variation: ChartViewConfig.ChartVariationEnum = 'LINE';
  chartOptions: EChartsOption = {
    radar: {
      //   shape: 'circle',
      indicator: [],
      center: ['50%', '58%'],
      radius: '60%',
    },
    series: [
      {
        name: '',
        type: 'radar',
        data: [
          {
            value: [],
            name: '',
          },
        ],
      },
    ],
  };
  checkVariationCondition(variation: ChartViewConfig.ChartVariationEnum): boolean {
    return true;
  }
}
