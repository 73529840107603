import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BaseViewItem } from '@shared/classes';

@Component({
  selector: 'app-dashlet-text',
  templateUrl: './dashlet-text.component.html',
  styleUrls: ['./dashlet-text.component.scss'],
})
export class DashletTextComponent extends BaseViewItem implements OnInit {
  @Input() title: string = '';

  @Input() value: any = {};

  @Input() icon: any = {};

  @Input() color: any = {};

  @Input() imageSrc?: string;

  @Input() isLoading: boolean = true;

  @Output() onInit: EventEmitter<any> = new EventEmitter();

  ngOnInit(): void {
    if (this.onInit) {
      this.onInit.emit();
    }
  }
}
