<ng-container *ngFor="let item of actions">
  <ng-container [ngSwitch]="item.state">
    <div class="my-2">
      <ng-container *ngSwitchCase="viewType.Updated">
        <div class="flex flex-row align-items-center gap-3 w-full">
          <app-avatar-list [users]="item.newValue.principle"></app-avatar-list>
          <div class="flex flex-row align-items-center justify-content-between w-full">
            <div class="access-column">
              <div class="empty-circle" pTooltip="Old Value" tooltipPosition="top"></div>
              <app-badge-view [data]="item.oldValue.accessLevel"></app-badge-view>
            </div>
            <i class="pi pi-arrow-right"></i>
            <div class="access-column">
              <div class="filled-circle" pTooltip="New Value" tooltipPosition="top"></div>
              <app-badge-view [data]="item.newValue.accessLevel"></app-badge-view>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-container *ngSwitchCase="viewType.Deleted">
        <div class="flex flex-row align-items-center gap-3 w-full">
          <app-avatar-list [users]="item.oldValue.principle"></app-avatar-list>
          <div class="flex flex-row align-items-center justify-content-between w-full">
            <div class="access-column">
              <div class="empty-circle" pTooltip="Old Value" tooltipPosition="top"></div>
              <app-badge-view [data]="item.oldValue.accessLevel"></app-badge-view>
            </div>
            <i class="pi pi-arrow-right"></i>
            <div class="access-column">
              <div class="filled-circle" pTooltip="New Value" tooltipPosition="top"></div>
              <app-badge-view [data]="'NO_ACCESS'"></app-badge-view>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-container *ngSwitchCase="viewType.Created">
        <div class="flex flex-row align-items-center gap-3 w-full">
          <app-avatar-list [users]="item.newValue.principle"></app-avatar-list>
          <div class="flex flex-row align-items-center justify-content-between w-full">
            <div class="access-column">
              <div class="empty-circle" pTooltip="Old Value" tooltipPosition="top"></div>
              <app-badge-view [data]="'NO_ACCESS'"></app-badge-view>
            </div>
            <i class="pi pi-arrow-right"></i>
            <div class="access-column">
              <div class="filled-circle" pTooltip="New Value" tooltipPosition="top"></div>
              <app-badge-view [data]="item.newValue.accessLevel"></app-badge-view>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </ng-container>
</ng-container>
