<p-selectButton
  [options]="treeSwitchOptions"
  [(ngModel)]="pageViewMode"
  optionLabel="label"
  optionValue="value"
  (onChange)="onChangeViewMode($event)"
  styleClass="view-switch-buttons"
>
  <ng-template let-item>
    <div
      class="flex flex-row gap-2 align-items-center w-full align-self-center justify-content-center"
      [pTooltip]="item.tooltip"
      tooltipPosition="top"
      style="padding: 0.629rem 0"
    >
      <i [class]="item.icon"></i>
      @if (item.label) {
        <div>
          {{ item.label }}
        </div>
      }
    </div>
  </ng-template>
</p-selectButton>
