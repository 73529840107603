import { Component, OnInit } from '@angular/core';
import { DataItem } from '@shared/classes/model/backend/task/model/models';
import { BaseViewItem } from '@shared/classes/view/BaseViewItem';
import { DataTypeEnum } from '@shared/classes/view/view-enums';

enum ViewType {
  Created,
  Updated,
  Deleted,
}

@Component({
  selector: 'app-dynamics-view',
  templateUrl: './dynamics-view.component.html',
  styleUrls: ['./dynamics-view.component.scss'],
})
export class DynamicsViewComponent extends BaseViewItem<DataItem> implements OnInit {
  actions: Array<any> = [];

  viewType = ViewType;

  dataTypes = DataTypeEnum;

  constructor() {
    super();
  }

  ngOnInit(): void {}
  onSetData(): void {
    // this.data;
    this.processAclData();
  }

  processAclData(): void {
    try {
      const dynamicsNew = this.data.newValue ? JSON.parse(this.data.newValue) : [];
      const dynamicsOld = this.data.oldValue ? JSON.parse(this.data.oldValue) : [];
      let dNewEntries = Object.entries(dynamicsNew);
      let dOldEntries = Object.entries(dynamicsOld);
      let newMap: Map<any, any> = new Map<any, any>();
      let oldMap: Map<any, any> = new Map<any, any>();

      // Setting up the maps
      dNewEntries.forEach((val) => {
        newMap.set(val[0], val);
      });

      dOldEntries.forEach((val) => {
        oldMap.set(val[0], val);
      });

      // Construct created
      dNewEntries.forEach((val) => {
        const obj: any = val[1];

        if (!oldMap.has(val[0])) {
          this.actions.push({
            state: ViewType.Created,
            oldValue: '',
            newValue: {
              fieldName: val[0],
              value: obj?.value ?? '',
            },
          });
        }
      });

      // Construct Updated
      dOldEntries.forEach((val) => {
        const obj: any = val[1];
        if (
          newMap.has(val[0]) &&
          newMap.get(val[0])[1].value &&
          obj.value &&
          newMap.get(val[0])[1].value != obj.value
        ) {
          this.actions.push({
            state: ViewType.Updated,
            oldValue: {
              fieldName: val[0],
              value: obj?.value ?? '',
            },
            newValue: {
              fieldName: newMap.get(val[0])[0],
              value: newMap.get(val[0])[1].value ?? '',
            },
          });
        } else if (!newMap.has(val[0])) {
          this.actions.push({
            state: ViewType.Deleted,
            oldValue: {
              fieldName: val[0],
              value: obj?.value ?? '',
            },
            newValue: '',
          });
        }
      });
    } catch (e) {}
  }
}
