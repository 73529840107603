import { ChartViewConfig } from '@shared/classes/model';
import { EChartsOption } from 'echarts';
import { AssignChartDataOptions, EchartBase } from '../../echart-base';

export class FunnelChart extends EchartBase {
  chartOptions: EChartsOption = {
    tooltip: {
      trigger: 'item',
      formatter: '{a} <br/>{b} : {c}%',
    },
    series: [
      {
        name: '',
        type: 'funnel',
        left: '10%',
        top: 75,
        bottom: '6%',
        width: '80%',
        min: 0,
        max: 100,
        minSize: '0%',
        maxSize: '100%',
        sort: 'descending',
        gap: 2,
        label: {
          show: true,
          formatter: (params) => (params.data as any).realValue.toString(),
          //   position: 'inside'
        },
        labelLine: {
          length: 10,
          lineStyle: {
            width: 1,
            type: 'solid',
          },
        },
        itemStyle: {
          borderColor: '#fff',
          borderWidth: 0,
        },
        emphasis: {
          label: {
            fontSize: 20,
          },
        },
        data: [],
      },
    ],
  };
  chartType: ChartViewConfig.ChartTypeEnum = 'FUNNEL';
  assignDataToChartOptions(options: AssignChartDataOptions): EChartsOption {
    let optionsCopy = { ...options?.chartOptions };
    optionsCopy.series[0].name = options?.name;
    const dataSum = options?.data?.reduce((sum, item) => sum + item.value, 0);
    optionsCopy.series[0].data = options?.data.map((x, i) => {
      return { name: x.name, realValue: x.value, value: x.value ? Math.round((x.value * 100) / dataSum) : 0 };
    });
    return optionsCopy;
  }
}
