import { Component, Input, OnInit } from '@angular/core';
import { BaseViewItem } from '@shared/classes';

@Component({
  selector: 'app-htmlTextShortenWithHoverViewComponent',
  templateUrl: './htmlTextShortenWithHoverViewComponent.component.html',
  styleUrls: ['./htmlTextShortenWithHoverViewComponent.component.scss'],
})
export class HtmlTextShortenViewComponent extends BaseViewItem implements OnInit {
  @Input() filter: string;

  constructor() {
    super();
  }

  ngOnInit(): void {}
}
