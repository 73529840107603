import { ChartViewConfig } from '@shared/classes/model';
import { EChartsOption } from 'echarts';
import { EchartVariationBase } from '../../../echart-variation-base';
import { AreaChart } from '../../base/area-chart';
export class LogarithmicAreaChart extends AreaChart implements EchartVariationBase {
  variation: ChartViewConfig.ChartVariationEnum = 'LOGARITHMIC';
  smooth = false;
  stack = null;
  useNullInsteadOfZero = true;
  chartOptions: EChartsOption = {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross',
        label: {
          backgroundColor: '#6a7985',
        },
      },
      appendTo: 'body',
    },
    xAxis: [
      {
        type: 'category',
        data: [],
      },
    ],
    yAxis: {
      type: 'log',
      logBase: 10,
      min: 0.1, // Set the minimum value to 0.1
      minorSplitLine: {
        show: true, // Show minor grid lines between major ticks
        lineStyle: {
          color: '#86898f',
          width: 0.2,
        },
      },
      // min: 'dataMin', // Automatically adjust minimum value
      // max: 'dataMax',
    },
    series: [
      {
        data: [],
        type: 'line',
        areaStyle: {},
        // smooth: true,
      },
    ],
  };
  checkVariationCondition(variation: ChartViewConfig.ChartVariationEnum): boolean {
    return true;
  }
}
