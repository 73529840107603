<a [href]="href" (click)="navigate($event)" [target]="isExternal ? '_blank' : '_self'">
  <span class="flex flex-row gap-1 flex-nowrap align-items-center">
    @if (icon) {
      <i [class]="icon"></i>
    }
    <span class="line-clamp-1-ellipsis" style="min-width: 0">
      <ng-content></ng-content>
    </span>
    @if (isExternal) {
      <i class="pi pi-external-link"></i>
    }
  </span>
</a>
