<app-base-page [breadCrumb]="breadCrumb">
  <ng-container page-header>
    <h4>Example Dynamic Form usage</h4>
  </ng-container>
  <form [formGroup]="formGroup" (ngSubmit)="onSubmitForm()">
    <div class="formgrid grid p-fluid">
      @for (field of dynamicFields; track field) {
        <div class="col-12 md:col-6">
          <ng-template
            appDynamicComponent
            [mode]="viewModeService.viewMode"
            [dynamicComponentInfo]="field"
          ></ng-template>
        </div>
      }
    </div>
    <div class="flex flex-row-reverse gap-2">
      @if (viewModeService.viewMode != 'view') {
        <app-button [action]="submitButtonAction"></app-button>
      }
      <app-button [action]="addMoreFieldsAction"></app-button>
    </div>
  </form>
</app-base-page>
