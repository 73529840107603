import { Component, Input, OnInit } from '@angular/core';
import { AppPermissions, ITableLoadEvent, UnsubscribeOnDestroyAdapter } from '@shared/classes';
import { AuditTrailService, PathResolverService } from '@shared/services';
import { NgxPermissionsService } from 'ngx-permissions';

@Component({
  selector: 'app-widget-timeline',
  templateUrl: './widget-timeline.component.html',
  styleUrl: './widget-timeline.component.scss',
})
export class WidgetTimelineComponent extends UnsubscribeOnDestroyAdapter implements OnInit {
  @Input() title: string = '';
  appPermissions = AppPermissions;
  data = () => this.auditTrailData;
  onLoadMore = this.fetchMore.bind(this);
  timelinePage = () => this.auditTrailPage;
  constructor(
    private auditTrailDataService: AuditTrailService,
    private permissionService: NgxPermissionsService,
    private pathResolverService: PathResolverService
  ) {
    super();
  }
  ngOnInit(): void {
    this.fetchMore(null);
  }
  onInitEvent() {}
  loading = true;
  auditTrailData = [];
  auditTrailTableEvent: ITableLoadEvent = new ITableLoadEvent();
  auditTrailPage;
  fetchMore(page) {
    this.auditTrailTableEvent.pageInfo.pagination.size = 20;
    if (page) {
      this.auditTrailTableEvent.pageInfo.pagination.page = page;
    }
    this.loading = true;
    this.subs.sink = this.auditTrailDataService
      .search<any>(
        {
          ...this.auditTrailTableEvent.pageInfo.pagination,
          sort: ['lastModificationDate,desc'],
        },
        {
          projectionFields: [
            'extraData',
            'type',
            'targetType',
            'targetCode',
            'creationDate',
            'actionTitle',
            'createdUser',
          ],
        }
      )
      .subscribe({
        next: (res: any) => {
          this.loading = false;

          this.auditTrailPage = res;
          const parsedData = this.parseData([...res.content]);
          this.auditTrailData = [...this.auditTrailData, ...parsedData];
          this.auditTrailTableEvent.pageInfo.totalElements = res.totalElements;
        },
        error: (error) => {},
        complete: () => {},
      });
  }

  parseData(data) {
    let newParsedData = [];
    for (let i = 0; i < data.length; i++) {
      let mappedFields;
      const element = data[i];
      try {
        mappedFields = this.pathResolverService.getMappingServiceByModuleKeyword(
          this.pathResolverService.getModuleKeywordByCode(element.targetCode)
        ).mappedFields;
      } catch (e) {
        console.error(element, 'Doenst have mapper service');
      }
      if (mappedFields) {
        newParsedData = [...newParsedData, element];
      }
    }
    return newParsedData;
  }
}
