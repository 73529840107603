import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import {
  BaseForm,
  FieldDto,
  IAction,
  QuestionFieldsDto,
  QuestionnaireAnswerDto,
  QuestionnaireQuestionsDto,
  RequestHandlerOptions,
} from '@shared/classes';
import { ViewModeService } from '@shared/services/view-mode.service';
import { RiskAssessmentStepperService } from 'app/modules/risk/risk-assessment-request/stepper/risk-assessment-stepper.service';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-assessment-field-popup',
  templateUrl: './assessment-field-popup.component.html',
  styleUrls: ['./assessment-field-popup.component.scss'],
})
export class AssessmentFieldPopupComponent extends BaseForm<QuestionnaireAnswerDto> implements OnInit {
  id: string;

  @Input()
  questions: QuestionFieldsDto[] = [];

  @Input()
  questionnaire: QuestionnaireQuestionsDto;

  questionnaireValue: QuestionnaireAnswerDto;

  dynamicFields: { [key: string]: FieldDto } = {};

  @Output() incompliantSubmit: EventEmitter<QuestionnaireAnswerDto> = new EventEmitter();
  @Output() compliantSubmits: EventEmitter<QuestionnaireAnswerDto> = new EventEmitter();

  @Input() incompliantButtonAction: IAction = {
    id: 1,
    label: 'Non-Compliant',
    buttonType: 'button',
    command: this.onIncompliantSubmit.bind(this),
    icon: 'pi pi-times',
    status$: this.formValid$,
    color: 'danger',
  };

  @Input() compliantButtonAction: IAction = {
    id: 2,
    label: 'Save',
    buttonType: 'button',
    command: this.onCompliantSubmits.bind(this),
    icon: 'pi pi-check',
    status$: this.formValid$,
    color: 'success',
  };
  public loading: Boolean = false;
  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    public viewModeService: ViewModeService,
    private riskAssessmentStepper: RiskAssessmentStepperService
  ) {
    super(viewModeService);
    this.questions = this.config?.data?.questions;
    this.questionnaire = this.config?.data?.questionnaire;
    if (!this.data) this.data = {};
    this.data.answers = this.config?.data?.questionnaireValue;
    this.viewModeOnly = this.config?.data?.viewModeOnly;
    this.formData = this.data;
  }

  apiOptions: RequestHandlerOptions = {
    showLoading: true,
    showMsg: true,
  };

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.initDynamicFields();
    this._setupSubscriptions();
  }

  onCompliantSubmits() {
    this.compliantSubmits.emit(this.getData());
    this.riskAssessmentStepper.questionnaireAnswer = this.getData();
    this.riskAssessmentStepper.saveAnswers();
    this.ref.close(this.getData());
  }

  onIncompliantSubmit() {
    this.incompliantSubmit.emit(this.getData());
    this.ref.close(this.getData());
  }

  initDynamicFields() {
    if (!this.questions) return;
    // let exampleDynamicFieldNames = this.questions.map((q) => q.question);

    // exampleDynamicFieldNames.forEach((cName) => {
    //     this.formGroup.addControl(
    //         cName,
    //         new FormControl(null, Validators.required)
    //     );
    // });

    //@NOTE you can do custom logic to handle how to get the names and
    // how to instantiate the components based and what data you have
    // this is just a simple example
    // this.dynamicFields = {};
    // Object.keys(this.questions).forEach((q, i) => {
    //     this.questions[q].fields.forEach(field=>{
    //         this.dynamicFields[q] = field;
    //     });
    // });

    // this.dynamicFields = this.questions.map((q, i) => {
    //     switch (q.questionType) {
    //         case DynamicField.TypeEnum.String: {
    //             return {
    //                 componentType: BasicInputComponent,
    //                 options: {
    //                     label: q.question,
    //                     name: "dynamic-basic-input" + i,
    //                     control:
    //                         this.formGroup.controls[
    //                             exampleDynamicFieldNames[i]
    //                         ],
    //                 },
    //             };
    //         }

    //         case DynamicField.TypeEnum.Number: {
    //             return {
    //                 componentType: NumberInputComponent,
    //                 options: {
    //                     label: q.question,
    //                     name: "dynamic-basic-input" + i,
    //                     control:
    //                         this.formGroup.controls[
    //                             exampleDynamicFieldNames[i]
    //                         ],
    //                     inputOptions: {
    //                         numberInput: {
    //                             showButtons: true,
    //                         },
    //                     },
    //                 },
    //             };
    //         }

    //         case DynamicField.TypeEnum.Mcq: {
    //             return {
    //                 componentType: MCQComponent,
    //                 options: {
    //                     label: q.question,
    //                     name: "dynamic-basic-input" + i,
    //                     control:
    //                         this.formGroup.controls[
    //                             exampleDynamicFieldNames[i]
    //                         ],
    //                     inputOptions: {
    //                         radioInput: {
    //                             groupItems: q?.options?.map((o) => {
    //                                 return {
    //                                     label: o,
    //                                     value: o,
    //                                 };
    //                             }),
    //                         },
    //                     },
    //                 },
    //             };
    //         }

    //         case DynamicField.TypeEnum.Boolean: {
    //             return {
    //                 componentType: SwitchInputComponent,
    //                 options: {
    //                     label: q.question,
    //                     name: "dynamic-basic-input" + i,
    //                     control:
    //                         this.formGroup.controls[
    //                             exampleDynamicFieldNames[i]
    //                         ],
    //                     inputOptions: {},
    //                 },
    //             };
    //         }
    //     }
    // });
  }

  getData() {
    let qs = this.questions;
    let formData = this.formGroup.getRawValue();

    // let answers: DynamicFiledValue[] = qs.map((q) => {
    //     return {
    //         question: q.question,
    //         questionType: q.questionType,
    //         options: q.options,
    //         answer: formData[q.question],
    //     };
    // });

    let data: QuestionnaireAnswerDto = {
      answers: formData.answers,
      // code: null,
      // id: this.questionnaireValue?.id,
      questionnaire: this.questionnaire?.code,
      // questionnaireCode: this.questionnaire?.code,
      questionnaireType: this.questionnaire?.type,
      // questionnaireName: this.questionnaire?.name,
    };

    return data;
  }

  setData(data: QuestionnaireAnswerDto) {
    this.formGroup.patchValue(data);
  }

  initFormStructure(): void {
    this.formGroup = new FormGroup({
      answers: new FormArray([]),
    });
  }
}
