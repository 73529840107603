import { AfterViewInit, Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { BaseForm, IAction } from '@shared/classes';
import { AppDialogService } from '@shared/services/app-dialog.service';
import { ViewModeService } from '@shared/services/view-mode.service';
import { cloneDeep, unionBy } from 'lodash-es';
import { Observable, Subject, debounceTime, distinctUntilChanged, of, switchMap } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';
import { CheckItemDataService } from '../../service/data/check-item-data.service';

@Component({
  selector: 'app-check-item-form',
  templateUrl: './check-item-form.component.html',
  styleUrls: ['./check-item-form.component.scss'],
})
export class CheckItemFormComponent extends BaseForm<any> implements OnInit, AfterViewInit {
  memoryMap = {};
  docList = [
    { name: 'CIS CSC Top 20', value: 'CIS CSC Top 20' },
    { name: 'ISO/IEC 27001:2022', value: 'ISO/IEC 27001:2022' },
    { name: 'NESA', value: 'NESA' },
    { name: 'ISO/IEC 27001:2013', value: 'ISO/IEC 27001:2013' },
    { name: 'NIST CSF', value: 'NIST CSF' },
  ];

  selectedProducts3: any;
  bulkItems: any = '';

  clonedProducts: any = {};

  products: any[] = [];

  showAddQuestion: Boolean = false;
  searchQuestions$: Observable<any[]>;

  private searchText$ = new Subject<string>();
  searchQ;
  searchKeywords;
  showAddQuestionAction: IAction = {
    id: 1,
    label: 'Add',
    buttonType: 'button',
    command: this.onSearchQuestionClick.bind(this),
    icon: 'pi pi-plus',
    color: 'info',
  };
  showAddQuestionActionBulk: IAction = {
    id: 1,
    label: 'Add all',
    buttonType: 'button',
    command: this.onAddBulkQuestionClick.bind(this),
    icon: 'pi pi-plus',
    color: 'info',
  };
  clearTableAction: IAction = {
    id: 1,
    label: 'Clear',
    buttonType: 'button',
    command: this.clearTableAndSelection.bind(this),
    icon: 'pi pi-filter-slash',
    color: 'danger',
    buttonStyle: 'outlined',
  };
  isSearching: boolean;

  onSearchQuestionClick() {
    this.addNewElement({ ...this.formGroup.getRawValue(), name: this.searchKeywords, id: uuidv4() }, '#e91e63', true);
  }
  onAddBulkQuestionClick() {
    console.dir(this.bulkItems.split('\n'));
    this.bulkItems.split('\n').forEach((element) => {
      this.addNewElement({ ...this.formGroup.getRawValue(), name: element, id: uuidv4() }, '#e91e63', true);
    });

    // this.addNewElement({...this.formGroup.getRawValue(),name:this.searchKeywords,id:uuidv4()},"#e91e63",true);
  }
  constructor(
    public viewModeService: ViewModeService,
    private appDialogService: AppDialogService,
    private requestService: CheckItemDataService
  ) {
    super(viewModeService);
  }

  ngOnInit(): void {}
  ngAfterViewInit(): void {
    this._setupSubscriptions();
    this.onFilterInputChange();
  }
  getData() {
    // return this.viewModeService.viewMode == 'create' ? {
    let data = {
      ...this.formGroup.getRawValue(),
      searchItems: this.selectedProducts3?.map((item) => {
        let unFlatItem = {
          id: item?.id?.id,
          code: item?.code?.code,
          name: item?.name?.name,
          iso2013: item?.iso2013?.iso2013,
          iso2022: item?.iso2022?.iso2022,
          csf: item?.csf?.csf,
          cscTop20: item?.cscTop20?.cscTop20,
          nesa: item?.nesa?.nesa,
        };

        return unFlatItem;
      }),
      searchKeyword: this.searchKeywords,
    };

    return data;
    // } : this.getChangedFormValues().updateItems
  }

  setData(data: any) {
    this.formGroup.patchValue({ ...data });
    this.data = data;
    this.products = data?.products ? data?.products : [];
    this.selectedProducts3 = data?.selectedProducts3 ? data?.selectedProducts3 : [];
    this.memoryMap = data?.memoryMap ? data?.memoryMap : {};
    this.searchQ = data?.searchQ ? data.searchQ : '';
  }

  initFormStructure(): void {
    this.formGroup = new FormGroup({
      docName: new FormControl(null),
      iso2013: new FormControl(null),
      name: new FormControl(null),
      iso2022: new FormControl(null),
      csf: new FormControl(null),
      cscTop20: new FormControl(null),
      nesa: new FormControl(null),
    });
  }

  onFilterInputChange() {
    this.searchText$
      .pipe(
        debounceTime(10),
        distinctUntilChanged(),
        switchMap((keywords) => {
          this.isSearching = true;
          this.searchKeywords = keywords;
          return this.requestService.search(
            { page: 0, size: 20, sort: ['searchScore desc'] },
            {
              projectionFields: ['name', 'code', 'id', 'iso2013', 'iso2022', 'csf', 'cscTop20', 'nesa', 'searchScore'],
              filters: [],
              textSearch: {
                search: keywords,
              },
            }
          );
        })
      )
      .subscribe((res) => {
        this.isSearching = false;
        if (res.content.length <= 0) {
          this.showAddQuestion = true;
        } else {
          this.showAddQuestion = false;
        }

        this.searchQuestions$ = of([]);
        let newPr = res.content.map((p) => {
          let newP: any = {};

          newP['id'] = {
            id: p['id'],
            color: '',
          };

          newP['code'] = {
            code: p['code'],
            color: '',
          };

          newP['name'] = {
            name: p['name'],
            color: '',
          };

          newP['iso2013'] = {
            iso2013: p['iso2013'],
            color: '',
          };

          newP['iso2022'] = {
            iso2022: p['iso2022'],
            color: '',
          };

          newP['csf'] = {
            csf: p['csf'],
            color: '',
          };

          newP['cscTop20'] = {
            cscTop20: p['cscTop20'],
            color: '',
          };

          newP['nesa'] = {
            nesa: p['nesa'],
            color: '',
          };
          newP['searchScore'] = {
            searchScore: p['searchScore'],
            color: p['searchScore'] > 15 ? '#8BC34A' : p['searchScore'] > 8 ? '#FFC107' : '#F44336',
          };

          this.memoryMap[newP?.id?.id] = cloneDeep(newP);

          return newP;
        });
        this.products = unionBy(this.products, newPr, 'id.id');
      });
  }

  getSearchValue(event: Event): string {
    return (event.target as HTMLInputElement).value;
  }

  search(keywords: string) {
    this.searchText$.next(keywords);
  }

  onSelectItem(event) {
    // this.tableQuestions.push(event);
    // this.searchQ = "";
  }

  onRowSelect(event) {
    let selectedItem = event?.data;
    let selectedItemId = selectedItem?.id.id;

    let targetItem = this.selectedProducts3.find((el) => el.id.id == selectedItemId);

    if (targetItem) {
      let iso2013 = this.formGroup.controls.iso2013.value;
      if (iso2013) {
        targetItem.iso2013.iso2013 = iso2013;
        targetItem.iso2013.color = '#e91e63';
      }

      let iso2022 = this.formGroup.controls.iso2022.value;
      if (iso2022) {
        targetItem.iso2022.iso2022 = iso2022;
        targetItem.iso2022.color = '#e91e63';
      }

      let csf = this.formGroup.controls.csf.value;
      if (csf) {
        targetItem.csf.csf = csf;
        targetItem.csf.color = '#e91e63';
      }

      let cscTop20 = this.formGroup.controls.cscTop20.value;
      if (cscTop20) {
        targetItem.cscTop20.cscTop20 = cscTop20;
        targetItem.cscTop20.color = '#e91e63';
      }

      let nesa = this.formGroup.controls.nesa.value;
      if (nesa) {
        targetItem.nesa.nesa = nesa;
        targetItem.nesa.color = '#e91e63';
      }

      this.memoryMap[targetItem.id.id] = cloneDeep(targetItem);
    }
  }

  onRowEditInit(product: any) {
    this.clonedProducts[product.id] = cloneDeep(product);
  }
  onRowDelete(product: any, event: any) {
    this.appDialogService.confirmPopup(
      {
        accept: () => {
          let selectedItemId = product?.id.id;
          let targetIndex = this.products.findIndex((el) => el.id.id == selectedItemId);

          let targetSelectionIndex = this.selectedProducts3.findIndex((el) => el.id.id == selectedItemId);
          if (targetSelectionIndex != -1) {
            (this.selectedProducts3 as any[]).splice(targetSelectionIndex, 1);
          }
          if (targetIndex != -1) {
            let targetItem = this.products[targetIndex];
            delete this.memoryMap[targetItem.id.id];
            (this.products as any[]).splice(targetIndex, 1);
          }
        },
      },
      event.target
    );
  }

  onRowEditSave(product: any) {
    let selectedItemId = product?.id.id;
    let targetItem = this.products.find((el) => el.id.id == selectedItemId);

    let oldValue = this.memoryMap[targetItem.id.id];

    if (product.iso2013.iso2013 != oldValue.iso2013.iso2013) {
      targetItem.iso2013.iso2013 = product.iso2013.iso2013;
      targetItem.iso2013.color = '#4caf50';
    }

    if (product.iso2022.iso2022 != oldValue.iso2022.iso2022) {
      targetItem.iso2022.iso2022 = product.iso2022.iso2022;
      targetItem.iso2022.color = '#4caf50';
    }

    if (product.csf.csf != oldValue.csf.csf) {
      targetItem.csf.csf = product.csf.csf;
      targetItem.csf.color = '#4caf50';
    }

    if (product.cscTop20.cscTop20 != oldValue.cscTop20.cscTop20) {
      targetItem.cscTop20.cscTop20 = product.cscTop20.cscTop20;
      targetItem.cscTop20.color = '#4caf50';
    }

    if (product.nesa.nesa != oldValue.nesa.nesa) {
      targetItem.nesa.nesa = product.nesa.nesa;
      targetItem.nesa.color = '#4caf50';
    }

    this.memoryMap[selectedItemId] = cloneDeep(targetItem);

    delete this.clonedProducts[product.id];
  }

  onRowEditCancel(product: any, index: number) {
    this.products[index] = this.clonedProducts[product.id];
    delete this.clonedProducts[product.id];
  }

  selectedProduct(product) {
    let selectedItemId = product?.id;

    if (this.selectedProducts3) {
      let targetItem = this.selectedProducts3.find((el) => el.id == selectedItemId);
      if (targetItem) return true;
    }

    return false;
  }
  addNewElement(p: any, color: string = '', addToSelectedItems: boolean = false) {
    //@TODO apply DRY code principle using this function
    this.products = this.products ? this.products : [];
    let newP: any = {};

    newP['id'] = {
      id: p['id'] || '',
      color: color,
    };

    newP['code'] = {
      code: p['code'] || '',
      color: color,
    };

    newP['name'] = {
      name: p['name'] || '',
      color: '',
    };

    newP['iso2013'] = {
      iso2013: p['iso2013'] || '',
      color: p['iso2013'] ? color : '',
    };

    newP['iso2022'] = {
      iso2022: p['iso2022'] || '',
      color: p['iso2022'] ? color : '',
    };

    newP['csf'] = {
      csf: p['csf'] || '',
      color: p['csf'] ? color : '',
    };

    newP['cscTop20'] = {
      cscTop20: p['cscTop20'] || '',
      color: p['cscTop20'] ? color : '',
    };

    newP['nesa'] = {
      nesa: p['nesa'] || '',
      color: p['nesa'] ? color : '',
    };
    newP['searchScore'] = {
      searchScore: 0,
      color: '#81D4FA',
    };

    this.memoryMap[newP?.id?.id] = cloneDeep(newP);

    this.products.unshift(newP);
    if (addToSelectedItems) {
      if (!this.selectedProducts3) {
        this.selectedProducts3 = [];
      }
      if (this.products.length - 1 >= 0) this.selectedProducts3.push(this.products[0]);
      this.selectedProducts3 = [...this.selectedProducts3];
    }
  }
  clearTableAndSelection() {
    this.products = [];
    this.selectedProducts3 = [];
    this.memoryMap = {};
  }
}
