import { ChartViewConfig } from '@shared/classes/model';
import { EChartsOption } from 'echarts';
import { EchartVariationBase } from '../../../echart-variation-base';
import { LineChart } from '../../base/line-chart';

export class RoundedLogarithmicLineChart extends LineChart implements EchartVariationBase {
  variation: ChartViewConfig.ChartVariationEnum = 'CURVED_LOGARITHMIC';
  smooth = true;
  useNullInsteadOfZero = true;
  chartOptions: EChartsOption = {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross',
        label: {
          backgroundColor: '#6a7985',
        },
      },
      appendTo: 'body',
    },
    xAxis: [
      {
        type: 'category',
        data: [],
      },
    ],
    yAxis: {
      type: 'log',
      logBase: 10,
      min: 0.1, // Set the minimum value to 0.1
      minorSplitLine: {
        show: true, // Show minor grid lines between major ticks
        lineStyle: {
          color: '#86898f',
          width: 0.2,
        },
      },
    },
    series: [
      {
        data: [],
        type: 'line',
        smooth: this.smooth,
      },
    ],
  };
  checkVariationCondition(variation: ChartViewConfig.ChartVariationEnum): boolean {
    return true;
  }
}
