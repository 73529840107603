@if (!isLoading) {
  <ng-template
    appDynamicFormComponent
    [dynamicComponent]="viewForm"
    (formSubmit)="onSubmit($event)"
    (formSubmitSave)="onSubmit($event, 'PAGE')"
    [formData]="data"
    (formChanges)="onFormChange($event)"
    [showSaveAndClose]="false"
  ></ng-template>
}

@if (!(liveData?.principle?.length > 0)) {
  <div>
    <h6 class="mb-0">Existing Watchers</h6>
    <app-table
      [cols]="cols"
      [tableActionsListType]="'list'"
      [tableActions]="tableActions"
      [inputData]="subscribers"
      [offlineConfig]="offlineConfig"
      [passIndexWithRowDataToAction]="true"
      [sessionStorageKey]="undefined"
      [selectionMode]="null"
      [isLoading]="isLoading"
      class="w-full"
    >
    </app-table>
  </div>
}
