import { Component, Input, OnInit } from '@angular/core';
import { hexToRgba, isNullObj } from '@shared/classes/helpers/general-functions';
import { BadgeColor, BadgeIcon, BadgeType } from '@shared/classes/view/view-enums';

@Component({
  selector: 'app-circle-badge-item',
  templateUrl: './circle-badge-item.component.html',
  styleUrls: ['./circle-badge-item.component.scss'],
})
export class CircleBadgeItemComponent implements OnInit {
  badgeIcons = BadgeIcon;
  badgeColors = BadgeColor;
  badgeTypes = BadgeType;
  @Input() status: string = 'Valid';
  @Input() badgeType: 'solid' | 'outline' | 'custom' = 'custom';
  @Input() text: string = null;
  @Input() displayText: boolean = true;
  @Input() styleClass: string = '';
  @Input() showIcon: boolean = false;
  @Input() set customColor(customColor: string) {
    if (!isNullObj(customColor)) {
      this._customColor = hexToRgba(customColor, 1);
      this.customColorBg = hexToRgba(customColor, 0.5);
    }
  }
  @Input() customIcon: string = null;
  @Input() flicker: boolean = false;

  customColorBg: string = null;
  private _customColor: string = null;
  get customColor(): string {
    return this._customColor;
  }
  constructor() {}

  ngOnInit(): void {}
}
