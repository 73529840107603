<app-dashlet [config]="{ title: title }">
  <app-table
    [cols]="cols"
    [inputData]="tableData"
    [pageInfo]="tableEventData.pageInfo"
    [selectionMode]="null"
    [rowsPerPageOptions]="null"
    [useAutoUpdate]="false"
    [lazyLoadOnInit]="true"
    [offlineConfig]="{
      lazy: true,
      paginator: true,
      showActionBar: false,
    }"
    (filtersChanged)="onTableFilterChangeEvent($event)"
    class="h-full"
  ></app-table>
</app-dashlet>
