<p-knob
  [(ngModel)]="value"
  [ngModelOptions]="{ standalone: true }"
  (ngModelChange)="onValueChanges($event)"
  [min]="min"
  [max]="max"
  [size]="size"
  [strokeWidth]="strokeWidth"
  [valueColor]="valueColor"
  [rangeColor]="rangeColor"
  [textColor]="textColor"
  [showValue]="showValue"
  [valueTemplate]="valueTemplate"
  [disabled]="disabled"
  [readonly]="readonly"
></p-knob>
