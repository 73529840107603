import { Component, Input, OnInit } from '@angular/core';
import { DataTypeEnum, RelationItemDto } from '@shared/classes';
import { RelationDto } from '@shared/classes/model/backend/hub/model/relationDto';
import { BaseViewItem } from '@shared/classes/view/BaseViewItem';

@Component({
  selector: 'app-relation-nicemode-view',
  templateUrl: './relation-nicemode-view.component.html',
  styleUrls: ['./relation-nicemode-view.component.scss'],
})
export class RelationNicemodeViewComponent extends BaseViewItem<RelationItemDto> implements OnInit {
  dataTypes = DataTypeEnum;
  @Input()
  relationTypes;

  constructor() {
    super();
  }
  ngOnInit(): void {}

  checkSwitchCodes() {
    // const f = this.data.fieldName.toLowerCase();
    // const t = this.data.targetType;
    // const s = this.data.sourceType;
    const d = this.data.relationDirection;
    return d == RelationItemDto.RelationDirectionEnum.Reference;
  }
}
