<div class="w-12 flex flex-row flex-wrap gap-1 mt-3">
  @for (item of uploadRequestList | keyvalue; track item) {
    <app-attachment-item-view
      [progressBg]="item?.value?.inProgress ? 'bg-green-400' : ''"
      [extraText]="item?.value?.inProgress ? 'Uploading...' : ''"
      [data]="item?.value?.requestResult"
      [externalCode]="externalCode"
      [progress]="item?.value?.progress"
      [inProgress]="item?.value?.inProgress"
      (onCancelRequest)="onCancelUpload(item?.key)"
    ></app-attachment-item-view>
  }
</div>
