import { Component, Input, OnInit } from '@angular/core';
import { DataTypeEnum, DynamicComponentBase, EntityTypeFieldDto, ITablePageable } from '@shared/classes';
import { EntityTypesDataService } from 'app/modules/entity-module/services/data/entity-types-data.service';

@Component({
  selector: 'app-entity-type-selector',
  templateUrl: './entity-type-selector.component.html',
  styleUrls: ['./entity-type-selector.component.scss'],
})
export class EntityTypeSelectorComponent extends DynamicComponentBase implements OnInit {
  @Input() dataKey: string = undefined;
  @Input() multi: boolean = true;
  @Input() optionValue: string = 'id';
  @Input() categoryList: EntityTypeFieldDto.CategoryEnum[] = null;
  @Input() appendTo: string = null;
  constructor(private service: EntityTypesDataService) {
    super();
  }
  items: any[] = [];
  pageInfo: ITablePageable = new ITablePageable();
  isLoading: boolean = false;
  dataType: DataTypeEnum = DataTypeEnum.Text;

  ngOnInit(): void {
    this.getOptions();
  }

  setInputOptions() {
    this.multi = this.inputOptions?.dropDownInput?.multi ?? this.multi;
  }
  getOptions(filters: any = []) {
    this.isLoading = true;
    if (!!this.categoryList) {
      filters.push({ property: 'category', operation: 'IN', value: this.categoryList });
    }
    this.subs.sink = this.service
      .search<EntityTypeFieldDto>(
        this.pageInfo.pagination,
        { filters: filters },
        { showLoading: false, showMsg: false }
      )
      .subscribe({
        next: (res) => {
          this.items = res.content;
          this.pageInfo.totalElements = res.totalElements;
        },
        complete: () => {
          this.isLoading = false;
        },
      });
  }
  onFilter(event) {
    this.getOptions([{ property: 'name', operation: 'CONTAINS', value: event }]);
  }
}
