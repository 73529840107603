import { ChartViewConfig } from '@shared/classes/model';
import { EchartVariationBase } from '../../../echart-variation-base';
import { AreaChart } from '../../base/area-chart';

export class CurvedAreaChart extends AreaChart implements EchartVariationBase {
  variation: ChartViewConfig.ChartVariationEnum = 'CURVED';
  smooth = true;
  // chartOptions: EChartsOption = {
  //   tooltip: {
  //     trigger: 'axis',
  //     appendTo: 'body',
  //   },
  //   xAxis: [
  //     {
  //       type: 'category',
  //       data: [],
  //     },
  //   ],
  //   yAxis: {
  //     type: 'value',
  //   },
  //   series: [
  //     {
  //       data: [],
  //       type: 'line',
  //       areaStyle: {},
  //       smooth: true,
  //     },
  //   ],
  // };
  checkVariationCondition(variation: ChartViewConfig.ChartVariationEnum): boolean {
    return true;
  }
}
