<div class="w-full">
  <div class="w-full">
    <!-- <h4 class="mb-4">Risk Project Basic Information</h4> -->
    <app-bulk-operation-confirmation-step-form
      (formSubmit)="onSubmit($event)"
      (formChanges)="formChanges($event)"
      [stepperService]="stepperService"
    >
      <div class="flex flex-row-reverse flex-wrap m-0 justify-content-between">
        <app-button [action]="nextButton"></app-button>
        <app-button [action]="prevButton"></app-button>
      </div>
    </app-bulk-operation-confirmation-step-form>
  </div>
</div>
