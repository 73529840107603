import {
  AfterViewInit,
  Component,
  ContentChild,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import {
  DynamicComponentBase,
  FilterItem,
  IAction,
  ITableLoadEvent,
  ITablePageable,
  OfflineConfig,
  RequestHandlerOptions,
  TargetTypeEnum,
  getModuleKeywordByCode,
  humanizeCasedString,
  isNullObj
} from '@shared/classes';
import { tableComponent } from '@shared/pages/table/table.component';
import { PathResolverService } from '@shared/services';
import { cloneDeep } from 'lodash-es';
import { NgxPermissionsService } from 'ngx-permissions';
import { MenuItem } from 'primeng/api';
/*
=================================================================
IMPORTANT NOTE
when using option value that is not code, only use 1 target type 
because it cannot fetch the module service/keyword without
code which is important to set selected rows for each target type
=================================================================
*/
@Component({
  selector: 'app-table-code-selector',
  templateUrl: './table-code-selector.component.html',
  styleUrl: './table-code-selector.component.scss',
})
export class TableCodeSelectorComponent extends DynamicComponentBase implements OnInit, AfterViewInit {
  tablesMap = {};
  menuItems: MenuItem[] = [];
  @ViewChild(tableComponent) tableComponent!: tableComponent;
  showSearchField = true;
  inited = false;
  _currentItem: MenuItem;
  searchKeywords = {};
  set currentItem(value: MenuItem) {
    // this.showSearchField = false;
    // setTimeout(() => { this.showSearchField = true; })
    if (this._currentItem)
      this.searchKeywords[this._currentItem?.id] = this.tableComponent?.globalFilter?.nativeElement?.value ?? '';

    if (this.tableComponent?.globalFilter)
      this.tableComponent.globalFilter.nativeElement.value = this.searchKeywords[value?.id] ?? '';
    this._currentItem = value;
    if (
      this.currentItem?.id &&
      !this.tablesMap?.[this.currentItem?.id]?.loading &&
      (this.forcedRefresh || !this.tablesMap?.[this.currentItem.id]?.data?.length)
    ) {
      this.fetchTableData(this.currentItem?.id, true, new ITableLoadEvent());
    }
    this.initSelectedItems = isNullObj(this.initSelectedItems) ? [] : [...this.initSelectedItems];
  }
  get currentItem() {
    return this._currentItem;
  }
  pageInfo: ITablePageable = new ITablePageable();

  _inlineControl: FormControl<any>;
  @Input() set inlineControl(control: FormControl<any>) {
    this._inlineControl = control;
  }
  get inlineControl() {
    return this._inlineControl;
  }
  _initValue: any;
  @Input() set initValue(value: any) {
    this._initValue = value;
  }
  get initValue() {
    return this._initValue;
  }

  @Input() optionValue: any;
  @Input() dataKey: any;
  @Input() filters: FilterItem[] = [];
  @Input() searchUrl: string = null;
  @Input() customSort: string[] = [];
  @Input() forcedRefresh;
  @Input() dialogVisable;
  @Input() customObjectFields;
  @Input() additionalProjectionFields: string[] = [];

  @Input() set targetTypes(types: TargetTypeEnum[]) {
    this._targetTypes = types;

    this.getOptions();
  }
  @Input() inlineMode = false;
  @Input() listBoxMode;
  @Output() onInlineChange: EventEmitter<any> = new EventEmitter();
  @ContentChild('okButtonTemplateRef') okButtonTemplate: TemplateRef<any>;

  valueChangedFlag = false;
  private _targetTypes: TargetTypeEnum[];
  get targetTypes() {
    return this._targetTypes;
  }

  offlineConfig: OfflineConfig = new OfflineConfig();

  initSelectedItems = [];
  allSelectedItems = [];

  clearAction: IAction = {
    id: 1,
    label: 'Clear',
    buttonType: 'button',
    command: this.clear.bind(this),
    icon: 'pi pi-times',
  };
  readonly defaultSort = ['creationDate,desc'];
  constructor(
    private pathResolverService: PathResolverService,
    private permissionService: NgxPermissionsService
  ) {
    super();
    // this.getOptions = this.getOptions.bind(this);

    // this.offlineConfig.showActionBar = false;
  }
  ngOnInit(): void {
    this.dataKey = this.dataKey ?? 'code';

    this.initValue = this.inlineControl?.value;
    this.setValue();
    // if (!this.inlineControl?.value?.length) {
    //   this.clear(false);
    //   if (!this.dialogVisable && this.forcedRefresh && this.currentItem?.id) {
    //     this.fetchTableData(this.currentItem.id, this.tablesMap[this.currentItem.id]?.tableEvent);
    //   }
    // }
    this.subs.sink = this.inlineControl.valueChanges.subscribe((x) => {
      this.initValue = x;
      if (!this.valueChangedFlag) {
        this.valueChangedFlag = false;
        return;
      }
      this.setValue();
      if (!x?.length) {
        this.clear(false);
        if (!this.dialogVisable && this.forcedRefresh && this.currentItem?.id) {
          this.fetchTableData(this.currentItem.id, true, this.tablesMap[this.currentItem.id]?.tableEvent);
        }
      }
    });
    // this.setControl();
  }

  ngAfterViewInit(): void { }

  setInputOptions(): void { }

  async getOptions(filters: FilterItem[] = []) {
    this.setTargetTypes();
    // if (this._initValue)
    //   this.setValue();
  }

  setTargetTypes() {
    this.tablesMap = {};
    this.menuItems = [];
    this.clear();
    this.targetTypes.forEach((typeItem) => {
      const service = this.pathResolverService.getDataServiceByTargetType(typeItem);
      const mappingService = this.pathResolverService.getMappingServiceByTargetType(typeItem);
      this.tablesMap[typeItem.toString()] = {
        name: typeItem,
        selected: 0,
        service: service,
        mappingService: mappingService,
        cols: [...mappingService.tableFields],
        tableEvent: new ITableLoadEvent(),
      };
      this.menuItems.push({
        label: humanizeCasedString(typeItem.toString()),
        id: typeItem.toString(),
        command: () => { },
      });
    });
  }

  setValue() {
    const key = this.optionValue == undefined ? this.dataKey : this.optionValue;
    Object.entries(this.tablesMap).forEach((x) => {
      this.tablesMap[x[0]].selected = 0;
      this.tablesMap[x[0]].selectedItems = [];
    });
    if (this._initValue) {
      this.initSelectedItems = this._initValue?.length
        ? this._initValue?.map((item) => {
          if (key != 'code' && this.targetTypes?.length) {
            this.tablesMap[this.targetTypes[0]].selected = (this.tablesMap[this.targetTypes[0]]?.selected ?? 0) + 1;
            this.tablesMap[this.targetTypes[0]].selectedItems = [
              ...(this.tablesMap[this.targetTypes[0]]?.selectedItems ?? []),
              this.optionValue == undefined ? item : { [key]: item },
            ];
          } else if (this.dataKey == 'code') {
            const module = getModuleKeywordByCode(this.optionValue == undefined ? item[this.dataKey] : item);
            if (module) {
              this.tablesMap[module].selected = (this.tablesMap[module]?.selected ?? 0) + 1;
              this.tablesMap[module].selectedItems = [
                ...(this.tablesMap[module]?.selectedItems ?? []),
                this.optionValue == undefined ? item : { [key]: item },
              ];
            }
          }
          return this.optionValue == undefined ? item : { [this.dataKey]: item };
        })
        : { [this.dataKey]: this._initValue };
      this.allSelectedItems = cloneDeep(this.initSelectedItems);
      this.clearAction.label = 'Clear (' + (this.allSelectedItems?.length ?? 0) + ')';
    }
  }

  fetchTableData(currentId, inited, tableEvent: ITableLoadEvent) {
    if (!inited) {
      return;
    }
    const service = this.tablesMap[currentId.toString()].service;
    this.tablesMap[currentId].tableEvent = { ...tableEvent };
    this.tablesMap[currentId].loading = true;

    const sort = [...(this.customSort ?? []), ...(this.tablesMap?.[currentId].tableEvent?.pageInfo?.sort ?? [])];
    const filters = [...(this.filters ?? []), ...(this.tablesMap?.[currentId]?.tableEvent?.filters ?? [])];

    const exec = {
      next: (res: any) => {
        this.inited = true;
        this.tablesMap[currentId].data = res.content;
        this.tablesMap[currentId].tableEvent.pageInfo.totalElements = res?.totalElements ?? 0;
        this.tablesMap[currentId].loading = false;
      },
      error: (error) => {
        this.tablesMap[currentId].loading = false;

        // this.isLoading = false;
      },
      complete: () => {
        this.tablesMap[currentId].loading = false;
      },
    };
    const search =
      tableEvent?.textSearch; /*? { ...tableEvent?.textSearch, search: trim(tableEvent?.textSearch?.search, ' ') } : undefined*/
    if (this.searchUrl) {
      service
        .search(
          { ...tableEvent.pageInfo.pagination, sort: sort?.length > 0 ? sort : this.defaultSort },
          {
            projectionFields: isNullObj(this.additionalProjectionFields)
              ? service.projectionFields
              : [...service.projectionFields, ...this.additionalProjectionFields],
            filters: filters?.length ? filters : null,
            textSearch: search,
          },
          new RequestHandlerOptions(),
          this.searchUrl
        )
        .subscribe(exec);
    } else {
      service
        .search(
          { ...tableEvent.pageInfo.pagination, sort: sort?.length > 0 ? sort : this.defaultSort },
          {
            projectionFields: isNullObj(this.additionalProjectionFields)
              ? service.projectionFields
              : [...service.projectionFields, ...this.additionalProjectionFields],
            filters: filters?.length ? filters : null,
            textSearch: search,
          }
        )
        .subscribe(exec);
    }
  }

  onRowSelected(items) {
    let newItems = [];
    let remainingItems = [];
    const key = this.optionValue == undefined ? this.dataKey : this.optionValue;

    items.forEach((element) => {
      const module = getModuleKeywordByCode(key ? element[key] : element);
      if (key != 'code' && this.currentItem?.id) {
        newItems.push(element);
      } else if (module == this.currentItem.id) {
        newItems.push(element);
      } else {
        remainingItems.push(element);
      }
    });
    this.tablesMap[this.currentItem.id].selected = newItems?.length ?? 0;
    this.tablesMap[this.currentItem.id].selectedItems = newItems;
    let listOfSlectedItems = [];
    Object.values(this.tablesMap).forEach((x) => {
      const item = x as any;
      listOfSlectedItems = [...listOfSlectedItems, ...(item.selectedItems ?? [])];
    });
    this.allSelectedItems = [...listOfSlectedItems];
    this.initSelectedItems = [...newItems, ...remainingItems];
    this.clearAction.label = 'Clear (' + (this.allSelectedItems?.length ?? 0) + ')';
    if (this.inlineControl) {
      this.valueChangedFlag = true;
      this.inlineControl.patchValue(
        listOfSlectedItems.map((x) =>
          this.optionValue
            ? x[key]
            : this.customObjectFields
              ? this.customObjectFields.reduce((acc, key) => {
                if (x.hasOwnProperty(key)) {
                  acc[key] = x[key];
                }
                return acc;
              }, {})
              : x
        )
      );
    }
    // if (this.inlineMode) {
    //   this.onInlineChange.emit();
    // }
  }

  onClearSelection(event) {
    this.onRowSelected([]);
  }

  clear(withPatching = true) {
    Object.entries(this.tablesMap).forEach((x) => {
      this.tablesMap[x[0]].selected = 0;
      this.tablesMap[x[0]].selectedItems = [];
    });
    this.initSelectedItems = [];
    this.allSelectedItems = [];
    this.clearAction.label = 'Clear (' + (this.allSelectedItems?.length ?? 0) + ')';
    if (withPatching) {
      this.valueChangedFlag = true;
      this.inlineControl?.patchValue([]);
      // if (this.inlineMode) {
      //   this.onInlineChange.emit();
      // }
    }
  }
}
