import { Component, Input, OnInit } from '@angular/core';
import { DataTypeEnum, DynamicComponentBase } from '@shared/classes';

@Component({
  selector: 'app-toggle-input',
  templateUrl: './toggle-input.component.html',
  styleUrl: './toggle-input.component.scss',
})
export class ToggleInputComponent extends DynamicComponentBase implements OnInit {
  dataType: DataTypeEnum = DataTypeEnum.Badge;
  @Input() styleClass: string = '';
  @Input() labelLeft;
  @Input() onLabel: string = '';
  @Input() offLabel: string = '';
  @Input() onIcon: string = '';
  @Input() offIcon: string = '';
  @Input() labelWidthFit: boolean = false;
  constructor() {
    super();
  }

  ngOnInit(): void { }

  setInputOptions() { }
}
