import { Component, Input, OnInit } from '@angular/core';
import { NotificationDto } from '@shared/classes';

@Component({
  selector: 'app-notifications-list',
  templateUrl: './notifications-list.component.html',
  styleUrls: ['./notifications-list.component.scss'],
})
export class NotificationsListComponent implements OnInit {
  @Input() notifications: NotificationDto[] = [];
  @Input() styleClass: string = '';
  constructor() {}

  ngOnInit(): void {}
}
