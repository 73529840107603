import { ChartViewConfig } from '@shared/classes/model';
import { EChartsOption } from 'echarts';
import { AssignChartDataOptions, EchartBase } from '../../echart-base';

export class TreeMapChart extends EchartBase {
  chartOptions: EChartsOption = {
    legend: {
      show: false,
      top: '1%',
      left: 'center',
    },
    series: [
      {
        breadcrumb:{
          show:false
        },
        type: 'treemap',
        data: [],
        top: 55,
      },
    ],
  };
  chartType: ChartViewConfig.ChartTypeEnum = 'TREE_MAP';
  assignDataToChartOptions(options: AssignChartDataOptions): EChartsOption {
    let optionsCopy = { ...options?.chartOptions };
    optionsCopy.series[0].name = null; //options?.name;
    optionsCopy.series[0].data = options?.data.map((x, i) => {
      return { ...x };
    });
    return optionsCopy;
  }
}
