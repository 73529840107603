<div [class]="styleClass">
  <ul class="list-none p-0 m-0">
    @for (item of userList; track item) {
      <li>
        <app-avatar-info-card-item
          [user]="item.user ?? item.username"
          [date]="item.approvedDate ?? item.actionDate"
        ></app-avatar-info-card-item>
      </li>
    }
  </ul>
</div>
