import { ChartViewConfig } from '@shared/classes/model';
import { EChartsOption } from 'echarts';
import { AssignChartDataOptions } from '../../../echart-base';
import { EchartVariationBase } from '../../../echart-variation-base';
import { HeatmapChart } from '../../base/heatmap-chart';

export class PolarHeatmapChart extends HeatmapChart implements EchartVariationBase {
  variation: ChartViewConfig.ChartVariationEnum = 'POLAR';
  chartOptions: EChartsOption = {
    animationDurationUpdate: (idx) => idx * 600,
    grid: {
      top: 60,
      bottom: 80,
    },
    tooltip: {
      trigger: 'item',
      appendTo: 'body',
      valueFormatter: (value) => value[2],
    },
    angleAxis: [
      {
        type: 'category',
        data: [],
        boundaryGap: false,
        splitLine: {
          show: true,
          lineStyle: {
            color: '#ddd',
            type: 'dashed',
          },
        },
        axisLine: {
          show: false,
        },
      },
    ],
    radiusAxis: [
      {
        type: 'category',
        data: [],
        z: 100,
        axisLabel:{
          backgroundColor:"rgba(30,30,30,0.75)",
          padding:3,
          color:"#FFFFF7"
        }
      },
    ],
    polar: { radius: '60%', center: ['50%', '60%'] },
    visualMap: [
      {
        type: 'continuous',
        min: 0,
        max: 10,
        top: 'middle',
        dimension: 2,
        calculable: true,
      },
    ],
    series: [
      {
        name: '',
        type: 'custom',
        coordinateSystem: 'polar',
        renderItem: function (params, api) {
          var values = [api.value(0), api.value(1)];
          var coord = api.coord(values);
          var size = api.size([1, 1], values);
          return {
            type: 'sector',
            shape: {
              cx: (params.coordSys as any).cx,
              cy: (params.coordSys as any).cy,
              r0: coord[2] - size[0] / 2,
              r: coord[2] + size[0] / 2,
              startAngle: -(coord[3] + size[1] / 2),
              endAngle: -(coord[3] - size[1] / 2),
            },
            style: api.style({
              fill: api.visual('color'),
            }),
          };
        },
        data: [],
      },
    ],
  };
  checkVariationCondition(variation: ChartViewConfig.ChartVariationEnum): boolean {
    return true;
  }
  assignDataToChartOptions(options: AssignChartDataOptions): EChartsOption {
    let optionsCopy = { ...options?.chartOptions };
    optionsCopy.series[0].name = null; //options?.name;
    const heatMap = this.generateHeatmapData(options?.data);
    optionsCopy.series[0].data = this.populateHeatmap(heatMap?.heatmap, heatMap?.xAxis?.length, heatMap?.yAxis?.length);
    optionsCopy.angleAxis[0].data = heatMap?.yAxis;
    optionsCopy.radiusAxis[0].data = heatMap?.xAxis;
    optionsCopy.visualMap[0].min = 0;
    optionsCopy.visualMap[0].max = heatMap?.maxValue;
    //@TODO: children format original data
    return optionsCopy;
  }
  populateHeatmap(
    heatmapData: [number, number, number][],
    maxIndex1: number,
    maxIndex2: number
  ): [number, number, number][] {
    const existingEntriesMap = new Map<string, [number, number, number]>();

    // Populate the map with existing entries
    for (const entry of heatmapData) {
      const key = `${entry[0]},${entry[1]}`;
      existingEntriesMap.set(key, entry);
    }

    const populatedHeatmap: [number, number, number][] = [];

    for (let i = 0; i < maxIndex1; i++) {
      for (let j = 0; j < maxIndex2; j++) {
        const key = `${i},${j}`;
        const existingEntry = existingEntriesMap.get(key);

        if (existingEntry) {
          // Entry already exists, keep it as is
          populatedHeatmap.push(existingEntry);
        } else {
          // Entry doesn't exist, add [index1, index2, 0]
          populatedHeatmap.push([i, j, 0]);
        }
      }
    }

    return populatedHeatmap;
  }
}
