@if (isLoading) {
  <div class="w-full">
    <span [class]="isLoading ? 'ml-2 pi pi-spin pi-spinner' : ''"></span>
  </div>
}
<p-organizationChart
  styleClass="entity-org-chart"
  (selectionChange)="onValueChanges($event)"
  [value]="formattedEntities"
  [selectionMode]="allowSelection ? (multi ? 'multiple' : 'single') : null"
  [(selection)]="selectedNodes"
>
  <ng-template let-node pTemplate="default">
    <div class="" style="width: fit-content; max-width: 180px; word-break: break-word">
      <div class="p-3 border-round shadow-2 flex align-items-center surface-card">
        <div
          [class]="'bg-' + color + '-100 inline-flex align-items-center justify-content-center mr-2'"
          style="min-width: 24px; min-height: 24px; border-radius: var(--border-radius)"
        >
          <i [class]="node?.icon + ' text-' + color + '-600 text-lg'"></i>
        </div>
        <div style="min-width: 4rem">
          <span class="text-700 text-base font-medium">{{ node.label }}</span>
          <!-- <p class="mt-1 mb-0 text-600 font-medium text-sm">{{node?.data?.type | json}}</p> -->
        </div>
        @if (node?.selectable) {
          <div class="ml-auto w-2rem h-2rem" style="min-width: 2rem">
            <app-button-list
              class="p-speeddial-placement w-2rem h-2rem"
              [actionList]="actionList"
              [listType]="'speed'"
              [commandData]="node"
              (onSpeedDial)="setCommandData($event)"
            ></app-button-list>
          </div>
        }
      </div>
    </div>
  </ng-template>
  <ng-template let-node pTemplate="collapse">
    <div class="" style="width: fit-content; max-width: 256px">
      <div class="p-3 border-round shadow-2 flex align-items-center surface-card">
        <div
          [class]="'bg-' + color + '-100 inline-flex align-items-center justify-content-center mr-2'"
          style="min-width: 24px; min-height: 24px; border-radius: var(--border-radius)"
          pBadge
          [value]="node?.data?.total"
        >
          <i [class]="node?.icon + ' text-' + color + '-600 text-lg'"></i>
        </div>
        <div class="flex gap-2">
          <span class="text-700 text-base font-medium">{{ node.label }} </span>
          <!-- <p class="mt-1 mb-0 text-600 font-medium text-sm">{{node?.data?.type | json}}</p> -->
        </div>
        @if (node?.selectable) {
          <div class="ml-2 w-fit h-2rem" style="min-width: 2rem">
            <app-button
              pTooltip="Browse Entities"
              tooltipPosition="top"
              (onClickAction)="op.toggle($event)"
              [action]="{
                id: 2,
                label: '',
                icon: 'pi pi-ellipsis-v',
                iconPos: 'left',
                buttonStyle: 'text',
                color: 'secondary',
              }"
            ></app-button>
            <p-overlayPanel #op [showCloseIcon]="true">
              <ng-template pTemplate>
                <p-listbox
                  [options]="node?.data?.options"
                  [group]="true"
                  [checkbox]="false"
                  [filter]="true"
                  [multiple]="false"
                  [listStyle]="{ 'max-height': '250px' }"
                  [style]="{ width: '15rem' }"
                  optionLabel="name"
                  (onClick)="onBrowseEntityClick($event)"
                >
                  <ng-template let-group pTemplate="group">
                    <div class="flex align-items-center">
                      <div
                        [class]="'bg-' + color + '-100 inline-flex align-items-center justify-content-center mr-2'"
                        style="min-width: 24px; min-height: 24px; border-radius: var(--border-radius)"
                        pBadge
                        [value]="group?.items?.length"
                      >
                        <i [class]="group?.icon + ' text-' + color + '-600 text-lg'"></i>
                      </div>
                      <span>{{ group.name }}</span>
                    </div>
                  </ng-template>
                </p-listbox>
                <!-- <p-listbox
            [options]="node.data"
            [checkbox]="true"
            [filter]="true"
            [multiple]="false"
            optionLabel="code"
            [style]="{ width: '15rem' }"
            (onClick)="onBrowseEntityClick($event)"
            >
          </p-listbox> -->
              </ng-template>
            </p-overlayPanel>
            <!-- <app-drop-down-input
      [label]="'Entities'"
      [placeholder]="'Select Item'"
      [name]="'multi-dropdown'"
      [optionLabel]="'code'"
      [multi]="false"
      [items]="node.data"
      [optionValue]="'code'"
      [control]="formControl"
      [badgeView]="false"
      [viewMode]="'edit'"
    ></app-drop-down-input> -->
            <!-- <app-button-list class="p-speeddial-placement w-2rem h-2rem"
    [actionList]="actionList" [listType]="'speed'"
    [commandData]="node" (onSpeedDial)="setCommandData($event)"
  ></app-button-list> -->
          </div>
        }
      </div>
    </div>
  </ng-template>
</p-organizationChart>
