<app-dashlet [styleClass]="styleClass" [config]="{ title: title }">
  <ng-content></ng-content>
  @if (headerTemplate) {
    <ng-template let-config #titleTemplate>
      <ng-container *ngTemplateOutlet="headerTemplate; context: { $implicit: config }"></ng-container>
    </ng-template>
  }
  <!-- <div class="flex flex-column gap-1 flex-wrap">
        @for(link of cardContent?.linkFields; track link){
            <app-link-view class="max-w-full min-w-0" [href]="link.link">{{link.title}}</app-link-view>
        }
    </div> -->
  @if (showChartViewControls) {
    <div #chartViewControlsDiv class="gridster-item-content">
      <div class="flex flex-row flex-wrap gap-2 field-m-0 mb-2">
        <app-drop-down-input
          [label]="''"
          [placeholder]="'Chart Type'"
          [name]="'type'"
          [optionLabel]="'label'"
          [items]="chartTypeOptions"
          [optionValue]="'value'"
          [control]="formGroup?.controls?.chartType"
          [viewMode]="'create'"
          [showClear]="false"
          [badgeView]="true"
          [appendTo]="'body'"
        ></app-drop-down-input>
        <!-- @if (chartVariationOptions?.length > 1) { -->
        <app-drop-down-input
          [label]="''"
          [placeholder]="'Variations'"
          [name]="'type'"
          [optionLabel]="'label'"
          [items]="chartVariationOptions"
          [optionValue]="'value'"
          [control]="formGroup?.controls?.chartVariation"
          [viewMode]="'create'"
          [showClear]="false"
          [badgeView]="true"
          [appendTo]="'body'"
        ></app-drop-down-input>
        <!-- } -->
        <app-drop-down-input
          [label]="''"
          [placeholder]="'Chart View Type'"
          [name]="'type'"
          [optionLabel]="'label'"
          [items]="chartViewTypeItems"
          [optionValue]="'value'"
          [control]="formGroup?.controls?.viewType"
          [viewMode]="'create'"
          [showClear]="false"
          [badgeView]="true"
          [appendTo]="'body'"
        ></app-drop-down-input>
        @if (formGroup?.controls?.viewType?.value != 'DATA' && drillDownAggregationFieldItems?.length > 0) {
          <app-drop-down-input
            [label]="''"
            [placeholder]="'Aggregation Field'"
            [name]="'type'"
            [optionLabel]="'label'"
            [items]="drillDownAggregationFieldItems"
            [optionValue]="'value'"
            [control]="formGroup?.controls?.drillDownAggregationField"
            [viewMode]="'create'"
            [showClear]="false"
            [appendTo]="'body'"
          ></app-drop-down-input>
        }
        @if (formGroup?.controls?.viewType?.value == 'CHART_AND_DATA') {
          <app-drop-down-input
            [label]="''"
            [placeholder]="'Data Position'"
            [name]="'type'"
            [optionLabel]="'label'"
            [items]="tablePostionItems"
            [optionValue]="'value'"
            [control]="formGroup?.controls?.dataPosition"
            [viewMode]="'create'"
            [showClear]="false"
            [badgeView]="true"
            [appendTo]="'body'"
          ></app-drop-down-input>
        }
        @if (formGroup?.controls?.viewType?.value != 'DATA') {
          <app-palette-picker-input
            [label]="''"
            [placeholder]="'Color Palette'"
            [name]="'colorPalette'"
            [control]="formGroup?.controls?.colorPalette"
            [viewMode]="'create'"
            [appendTo]="'body'"
          ></app-palette-picker-input>
        }
        @if (!isProductionEnv()) {
          <app-button [action]="editOptionsAction"></app-button>
        }
      </div>
    </div>
  }
  <!-- <div #eChartContainer id="eChartContainer" style="position: relative; height: 100vh; overflow: hidden; width:100%;">
    </div> -->
  <div [class]="'flex gap-1 w-full ' + flexDirection" [style]="chartContainerHeight">
    @if (
      aggregateDataOptions &&
      formGroup?.controls?.dataPosition?.value &&
      formGroup?.controls?.viewType?.value != 'CHART'
    ) {
      <app-statistics-table [style]="tableHeight" [options]="aggregateDataOptions"> </app-statistics-table>
    }
    @if (directConfig || formGroup?.controls?.viewType?.value != 'DATA') {
      <div class="flex flex-column w-full h-full min-h-0 min-w-0">
        @if (this.aggregationResult?.groupByFields?.length > 0 && !disabledDrillDownCharts[chartType]) {
          <div class="w-full" style="display: contents">
            <div class="layout-breadcrumb-container p-0">
              <p-breadcrumb
                class="max-w-full layout-breadcrumb p-0"
                [model]="drillDownPreviousOptions?.length > 0 ? drillDownPreviousOptions : null"
                [home]="home"
                (onItemClick)="onDrillDownItemClick($event)"
                [styleClass]="'pl-1 pb-1 pt-1'"
              />
            </div>
            @if (
              drillDownPreviousOptions?.length == this.aggregationResult?.groupByFields?.length || instantShowTable
            ) {
              <app-widget-table
                class=""
                [title]="tableCardContent?.name"
                [cardContent]="tableCardContent"
                [styleClass]="'relative m-0 '"
                style="height: calc(100% - 25px)"
              >
                <ng-template let-config #headerTemplate> </ng-template>
              </app-widget-table>
            }
          </div>
        }
        @if (
          chartId &&
          !(
            drillDownPreviousOptions?.length > 0 &&
            drillDownPreviousOptions?.length == this.aggregationResult?.groupByFields?.length
          ) &&
          !instantShowTable
        ) {
          @if (directConfig) {
            @if (isLoadingImport) {
              <div class="flex flex-1 justify-content-center align-items-center">
                <p-skeleton shape="circle" size="12rem" />
              </div>
            } @else {
              <div
                [id]="chartId"
                echarts
                [options]="directConfigOptions"
                [theme]="app.colorScheme == 'dark' ? 'dark' : undefined"
                [merge]="mergeOptions"
                [autoResize]="true"
                [style]="{ height: height }"
                style="position: relative; overflow: hidden; max-width: 100%; width: 100%; max-height: 100%"
                class="echart-container"
                (chartClick)="onChartEvent($event, 'chartClick')"
              ></div>
            }
          } @else {
            @if (isLoadingImport || isLoading) {
              <div class="flex flex-1 justify-content-center align-items-center">
                <p-skeleton shape="circle" size="12rem" />
              </div>
            } @else {
              <div
                [id]="chartId + '_12'"
                echarts
                [options]="overrideOptions || factorOptions"
                [theme]="app.colorScheme == 'dark' ? 'dark' : undefined"
                [merge]="overrideOptions || factorOptions"
                [autoResize]="true"
                [style]="{ height: height }"
                style="position: relative; overflow: hidden; max-width: 100%; width: 100%; max-height: 100%"
                class="echart-container"
                (chartClick)="onChartEvent($event, 'chartClick')"
              ></div>
            }
          }
        }
      </div>
    }
  </div>
</app-dashlet>
<p-dialog
  header="Edit Options"
  [modal]="true"
  [maximizable]="true"
  [(visible)]="showOptionsEditor"
  [style]="{ width: '60vw' }"
  [appendTo]="'body'"
>
  @if (tempOptionsFormControl?.value) {
    <app-monaco-editor-input
      [editorLanguage]="'json'"
      class="w-full"
      [control]="tempOptionsFormControl"
      [height]="'400px'"
      [style]="{ height: '400px', width: '100%' }"
    ></app-monaco-editor-input>
  }
  <ng-template pTemplate="footer">
    <p-button (click)="assignNewOptions()" label="Assign" styleClass="p-button"></p-button>
  </ng-template>
</p-dialog>
