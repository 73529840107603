import { Component, ContentChild, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import { BaseViewItem, ContentViewDto, DashletTypeEnum, ITableLoadEvent } from '@shared/classes';

@Component({
  selector: 'app-dashlet-view-switch',
  templateUrl: './dashlet-view-switch.component.html',
  styleUrls: ['./dashlet-view-switch.component.scss'],
})
export class DashletViewSwitchComponent extends BaseViewItem {
  @ContentChild('dashletTitleTemplate') dashletTitleTemplate: TemplateRef<any>;
  @Input() viewType: DashletTypeEnum = DashletTypeEnum.Empty;
  @Input() useD3: boolean = false;

  @Input() config: any = {};

  @Input() isLoading: boolean = true;

  @Output() onInit: EventEmitter<any> = new EventEmitter();

  @Output() onTableFilterChange: EventEmitter<ITableLoadEvent> = new EventEmitter();

  // @Input() onTableFilterChange: (tableEvent, permission) => Promise<any>;

  dashletTypes = DashletTypeEnum;
  @Input() drillDownList: any = {};
  @Input() currentCardContent: ContentViewDto;

  constructor() {
    super();
  }

  onTableFilterChangeEvent(tableEvent) {
    this.onTableFilterChange.emit(tableEvent);
  }

  onInitEvent() {
    this.onInit.emit();
  }
}
