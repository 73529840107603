import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { DataTypeEnum, DynamicComponentBase } from '@shared/classes';

@Component({
  selector: 'app-chips-input',
  templateUrl: './chips-input.component.html',
  styleUrl: './chips-input.component.scss',
  providers: [{ provide: DynamicComponentBase, useExisting: ChipsInputComponent }],
})
export class ChipsInputComponent  extends DynamicComponentBase implements OnInit {
  dataType: DataTypeEnum = DataTypeEnum.Text;
  @Output() onFocus: EventEmitter<any> = new EventEmitter();
  @Output() onAdd: EventEmitter<any> = new EventEmitter();

  constructor() {
    super();
  }

  ngOnInit(): void {}
  setInputOptions() {}
  onFocusChange(event) {
    this.onFocus.emit(event);
  }
  onAddChange(event){
    this.onAdd.emit(event)
  }
}
