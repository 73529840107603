import { Component, Input, OnInit } from '@angular/core';
import { BaseViewItem } from '@shared/classes/view/BaseViewItem';

@Component({
  selector: 'app-number-view',
  templateUrl: './number-view.component.html',
  styleUrls: ['./number-view.component.scss'],
})
export class NumberViewComponent extends BaseViewItem implements OnInit {
  @Input() parse: Boolean = false;
  constructor() {
    super();
  }

  ngOnInit(): void {}
}
