<div (click)="op.toggle($event, actualTarget)" class="w-full cursor-pointer">
  <app-avatar-list [users]="userList.length + ''" [customTooltip]="'Click for details'"></app-avatar-list>
</div>
<div #actualTarget></div>

<p-overlayPanel #op [dismissable]="true" [showCloseIcon]="true">
  <ng-template pTemplate>
    <div style="max-height: 320px; overflow: auto">
      <app-avatar-info-card-list [userList]="userList"></app-avatar-info-card-list>
    </div>
  </ng-template>
</p-overlayPanel>
