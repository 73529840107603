import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  BaseForm,
  DataTypeEnum,
  IAction,
  IDynamicComponent,
  ImpactValue,
  LikelihoodValue,
  RiskItem,
  RiskMethodology,
  getEnumOptions,
} from '@shared/classes';
import { ImpactFactorWithValueSelectorComponent } from '@shared/components/selectors/impact-factor-with-value-selector/impact-factor-with-value-selector.component';
import { ViewModeService } from '@shared/services/view-mode.service';
import { EntityDataService } from 'app/modules/entity-module/entity/entity-data.service';
import { find, maxBy } from 'lodash-es';
import { combineLatest, startWith, takeUntil } from 'rxjs';

@Component({
  selector: 'app-inherited-risk-value-form',
  templateUrl: './inherited-risk-value-form.component.html',
  styleUrls: ['./inherited-risk-value-form.component.scss'],
})
export class InheritedRiskValueFormComponent extends BaseForm<RiskItem> implements OnInit {
  private _riskMeth: RiskMethodology = null;
  items: any;
  impactList: ImpactValue[] = [];
  likelihoodList: LikelihoodValue[] = [];
  optionsFields: IDynamicComponent[] = [];
  dataTypes = DataTypeEnum;

  strategyList: any[] = getEnumOptions(RiskItem.StrategyEnum);
  inheritedRiskAcceptanceList: any[] = getEnumOptions(RiskItem.InheritedRiskAcceptanceEnum);
  filteredItems: any[];
  selectedFactor;
  triggerRowEditIndex: number;
  testControl = new FormControl(null);
  addFormButtonAction: IAction = {
    id: 1,
    buttonStyle: 'raised',
    label: 'Add Item',
    buttonType: 'button',
    command: this.onSelectItem.bind(this),
    icon: 'pi pi-plus',
  };
  @Input() set riskMeth(riskMeth: RiskMethodology) {
    if (riskMeth) {
      this._riskMeth = riskMeth;
      this.impactList = riskMeth.impactValues;
      this.likelihoodList = riskMeth.likelihoodValues;
      // this. = riskMeth.impactFactors.map(item => {
      //     return {
      //         label: item.name,
      //         selectable:false,
      //         children: Object.keys(item.options).map(key => {
      //             const option: any = item.options[key];
      //             return {
      //                 label: key,
      //                 value: option
      //             }
      //         })
      //     }
      // })
      this.items = riskMeth.impactFactors.map((item) => {
        return {
          label: item.name,
          items: Object.keys(item.options).map((key) => {
            const option: any = item.options[key];
            return {
              label: key,
              value: option,
            };
          }),
          code: item.code,
        };
      });
      // this.items = riskMeth.impactFactors;
      this.optionsFields = [
        // {
        //     componentType: DropDownInputComponent,
        //     options: {
        //         label: 'Impact Factor',
        //         name: 'factor',
        //         control: new FormControl(null, Validators.required),
        //         inputOptions: {
        //             dropDownInput: {
        //                 multi: false,
        //                 showClear: true,
        //                 optionLabel: 'label',
        //                 optionValue: undefined,
        //                 items: this.items,
        //             }
        //         },
        //         dataType: DataTypeEnum.Text,
        //         showLabelInViewMode: false,
        //     },
        //     viewModeOnly:true
        // },
        {
          componentType: ImpactFactorWithValueSelectorComponent,
          options: {
            label: 'Impact Factor',
            name: 'factorVal',
            control: new FormControl(null, Validators.required),
            inputOptions: {
              dropDownInput: {
                multi: false,
                showClear: true,
                optionLabel: 'label',
                optionValue: undefined,
                items: riskMeth?.impactFactors,
                badgeView: false,
              },
            },
            dataType: DataTypeEnum.Text,
            showLabelInViewMode: false,
          },
          // viewModeOnly:true
        },
        // {
        //     componentType: DropDownInputComponent,
        //     options: {
        //         label: 'Factor Value',
        //         name: 'value',
        //         control: new FormControl(null, Validators.required),
        //         inputOptions: {
        //             linkedControlName: 'factor',
        //             dropDownInput: {
        //                 multi: false,
        //                 showClear: true,
        //                 optionLabel: 'label',
        //                 optionValue: undefined,
        //                 items: this.items,
        //                 dataKey:'code',
        //                 formatGetItems: (items: any[],linkedControlValue:any) => {
        //                     let value = items.find(item => item.code === linkedControlValue?.code);

        //                     if(value){
        //                         return value.items.map(item => ({
        //                             label: item.label,
        //                             value: item.value,
        //                             code: item?.code || item?.value?.code
        //                         }))
        //                     }else{
        //                         return [];
        //                     }
        //                 },
        //             }
        //         },
        //         dataType: DataTypeEnum.Text,
        //         showLabelInViewMode: false,
        //     },
        // },
      ];
    }
  }
  get riskMeth() {
    return this._riskMeth;
  }
  // factorsRepeaterControl:FormControl = new FormControl([]);
  @Input() readOnlyName: boolean = false;
  constructor(
    public viewModeService: ViewModeService,
    private entityService: EntityDataService
  ) {
    super(viewModeService);
  }

  ngOnInit(): void {
    this.riskValueSubscriber();
    this.formGroup.controls.factors.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((values) => {
      let a: any = maxBy(values, 'factorVal.value.value.value');
      a = { ...a?.factorVal };

      (this.formGroup.controls?.inheritedRiskImpact).patchValue(
        a?.value?.value?.code
          ? {
              value: a?.value?.value?.value ?? null,
              code: a?.value?.value?.code ?? null,
              name: a?.value?.value?.name ?? null,
              description: a?.value?.value?.description ?? null,
              factors:
                values?.map((item) => {
                  let options = {};
                  item?.factorVal?.factor?.items?.forEach((option) => {
                    options[option?.label] = {
                      ...option?.value,
                      selected: option?.value?.code == item?.factorVal?.value?.value?.code,
                    };
                  });
                  return {
                    name: item?.factorVal?.factor?.label,
                    code: item?.factorVal?.factor?.code,
                    // description:   item?.factor?.description,
                    options: options,
                  };
                }) ?? null,
            }
          : null
      );
      // this.formGroup.patchValue({
      //     inheritedRiskImpact:a?.value?.value
      // })
    });
  }

  getData() {
    return this.viewModeService.viewMode == 'create'
      ? this.getDataKeyValueFormat({
          ...this.formGroup.getRawValue(),
          entityId: this.formGroup?.getRawValue()?.entityId?.code,
          project: this.formGroup?.getRawValue()?.project?.code,
          factors: (this.formGroup?.getRawValue()?.factors as { factorVal: any }[])?.map((x) => x.factorVal),
          // inheritedRisk:{...this.formGroup.getRawValue().inheritedRisk,factors:this.factorsRepeaterControl.value},
          // residualRisk:{...this.formGroup.getRawValue().residualRisk,factors:this.factorsRepeaterControl.value},
        })
      : this.getChangedFormValues({
          ...this.formGroup.getRawValue(),
          entityId: this.formGroup?.getRawValue()?.entityId?.code,
          project: this.formGroup?.getRawValue()?.project?.code,
          factors: (this.formGroup?.getRawValue()?.factors as { factorVal: any }[])?.map((x) => x.factorVal),
          // inheritedRisk:{...this.formGroup.getRawValue().inheritedRisk,factors:this.factorsRepeaterControl.value},
          // residualRisk:{...this.formGroup.getRawValue().residualRisk,factors:this.factorsRepeaterControl.value},
        }).updateItems;
  }

  setData(data: any) {
    let factors = data?.inheritedRiskImpact?.factors?.map((item) => {
      let selectedValue;
      Object.keys(item.options).forEach((key) => {
        if (item?.options?.[key]?.selected) {
          selectedValue = { key: key, value: item?.options?.[key] };
        }
      });
      return {
        factorVal: {
          factor: {
            label: item.name,
            items: Object.keys(item.options).map((key) => {
              const option: any = item.options[key];
              return {
                label: key,
                value: option,
              };
            }),
            code: item?.code || item?.value?.code,
          },
          value: {
            label: selectedValue?.key,
            value: selectedValue?.value,
            code: selectedValue?.value?.code,
          },
        },
      };
    });

    this.formGroup.patchValue({ ...data, factors: factors || [] });
    this.data = { ...data };
    // this.factorsRepeaterControl.patchValue([...data?.factors]);
    // this.factorsRepeaterControl.patchValue(this.data.residualImpact.factors);
    // if (data?.entityId) {
    //     this.entityService.getById<any>(data?.entityId).pipe(take(1)).subscribe(res => {
    //         this.formGroup.patchValue({ entityId: { ...res.data } })
    //     })
    // }
  }

  initFormStructure(): void {
    this.formGroup = new FormGroup({
      name: new FormControl({ value: '', disabled: false }),
      description: new FormControl({ value: '', disabled: false }),

      inheritedRiskImpact: new FormControl({ value: null, disabled: true }),
      // new FormGroup({
      //     value: new FormControl(null),
      //     code: new FormControl(null),
      //     name: new FormControl(null),
      //     description: new FormControl(null),
      //     factors: new FormControl([]),
      // }) ,//new FormControl({ value: null, disabled: true }),
      inheritedLikelihood: new FormControl({ value: null, disabled: false }),
      inheritedRisk: new FormControl({ value: null, disabled: true }),
      inheritedRiskAcceptance: new FormControl({ value: null, disabled: true }),
      inheritedClassification: new FormControl({ value: null, disabled: true }),
      // strategy: new FormControl(null),
      factors: new FormControl([]),
      // factors2: new FormControl([]),
    });
  }

  riskValueSubscriber() {
    combineLatest([
      this.formGroup.controls.inheritedLikelihood.valueChanges.pipe(
        startWith(this.formGroup.controls.inheritedLikelihood.value)
      ),
      this.formGroup.controls.inheritedRiskImpact.valueChanges.pipe(
        startWith(this.formGroup.controls.inheritedRiskImpact.value)
      ),
    ])
      .pipe(
        takeUntil(this.destroy$)
        // ,filter(([inheritedLikelihood, inheritedRiskImpact]) => inheritedLikelihood && inheritedRiskImpact)
      )
      .subscribe(([inheritedLikelihood, inheritedRiskImpact]) => {
        // let a:any = maxBy(inheritedRiskImpact, "value.value");

        this.formGroup
          .get('inheritedRisk')
          .setValue(
            inheritedLikelihood && inheritedRiskImpact ? inheritedLikelihood?.value * inheritedRiskImpact?.value : null
          );
      });
    this.formGroup?.controls?.inheritedRisk?.valueChanges.subscribe((res) => {
      if (res) {
        this.formGroup?.controls?.inheritedRiskAcceptance?.patchValue(
          res > this.riskMeth?.acceptableRisk
            ? RiskItem.InheritedRiskAcceptanceEnum.NotAcceptable
            : RiskItem.InheritedRiskAcceptanceEnum.Acceptable
        );
        this.formGroup?.controls?.inheritedClassification?.patchValue(this.getCellThreshold(this.riskMeth, res));
      } else {
        this.formGroup?.controls?.inheritedRiskAcceptance?.patchValue(null);
        this.formGroup?.controls?.inheritedClassification?.patchValue(null);
      }
    });
  }

  filterItems(event) {
    //in a real application, make a request to a remote url with the query and return filtered results, for demo we filter at client side
    let filtered: any[] = [];
    let query = event.query;

    for (let i = 0; i < this.items.length; i++) {
      let item = this.items[i];
      if (item.label.toLowerCase().indexOf(query.toLowerCase()) == 0) {
        if ((this.formGroup.controls.factors.value as any[]).find((x) => x?.factor?.code == item?.code)) {
        } else {
          filtered.push(item);
        }
      }
    }

    this.filteredItems = filtered;
  }
  onSelectItem(event) {
    this.selectedFactor = '';
    this.formGroup.controls.factors.patchValue([
      { factor: event, value: null },
      ...this.formGroup.controls.factors.value,
    ]);
    this.triggerRowEditIndex = 0;
    setTimeout(() => {
      this.triggerRowEditIndex = undefined;
    }, 10);
  }
  getCellThreshold(meth: RiskMethodology, cellValue) {
    let threshold = find(meth?.thresholdValues, function (o) {
      return o.value >= cellValue;
    });

    if (threshold != undefined) return threshold.name;
    else return '';
  }
}
