<div
  class="py-3 border-2 border-dashed border-round surface-border max-h-full h-full flex flex-column gap-3 justify-content-center align-items-center cursor-pointer text-center hover:bg-black-alpha-10 relative"
  (click)="onCardClickAction($event)"
>
  <i [class]="icon + ' text-7xl pointer-events-disabled select-none text-400'"></i>
  @if (data) {
    <p class="text-2xl pointer-events-disabled select-none text-400">
      {{ data }}
      <br />
      <span class="hover:underline text-primary">{{ buttonText }}</span>
    </p>
  }
</div>
