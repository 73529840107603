@if (mode == 'Shrank') {
  @if (!isLoading) {
    <app-avatar-overlay-list
      [users]="avatarList"
      [maxLength]="3"
      (avatarClicked)="show($event, actualTarget)"
    ></app-avatar-overlay-list>
  } @else {
    <p><i class="pi pi-spin pi-spinner"></i></p>
  }
  <div #actualTarget></div>
  <p-overlayPanel #op [dismissable]="true" [showCloseIcon]="true">
    <ng-template pTemplate>
      <div class="flex justify-content-between">
        <h4 class="mb-0">Responsibilities</h4>
      </div>
      <p-divider class="my-2"></p-divider>
      @for (item of avatarStretchList; track item; let last = $last) {
        <div class="flex flex-wrap flex-row p-fluid w-full">
          <!-- <i
            class="flex justify-content-center align-items-center col-2 pt-1"
            [ngClass]="item?.entityType?.icon"
          ></i> -->
          <span class="w-6 flex justify-content-start align-items-center">{{ item?.name }}</span>
          @if (item?.members?.length > 0) {
            <app-user-list-view class="w-6" [data]="item?.members"></app-user-list-view>
          }
          @if (!last) {
            <p-divider styleClass="my-2" class="w-12"></p-divider>
          }
        </div>
      }
    </ng-template>
  </p-overlayPanel>
} @else {
  @if (!isLoading) {
    <div class="flex justify-content-between">
      <h4 class="mb-0">Responsibilities</h4>
    </div>
    <p-divider class="mb-3"></p-divider>
    @for (item of avatarStretchList; track item; let last = $last) {
      <div class="grid p-fluid w-full">
        <i class="flex justify-content-center align-items-center col-2 pt-1 pi pi-heart"></i>
        <span class="col-6 flex justify-content-start align-items-center">{{ item?.name }}</span>
        @if (item?.members?.length > 0) {
          <app-user-list-view class="col-4" [data]="item?.members"></app-user-list-view>
        }
        @if (!last) {
          <p-divider styleClass="my-0 py-0" class="col-12"></p-divider>
        }
      </div>
    }
  } @else {
    <p><i class="pi pi-spin pi-spinner"></i></p>
  }
}
