import { Component, ContentChild, Input, OnInit, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import {
  UnsubscribeOnDestroyAdapter,
  ContentViewDto,
  IDynamicComponent,
  IColumn,
  getTableColumnDefinitionFromField,
  getProp,
} from '@shared/classes';
import {
  BaseFieldDefinitionsService,
  AppDialogService,
  PathResolverService,
  BaseRequestControllerService,
  ToastService,
} from '@shared/services';

@Component({
  selector: 'app-widget-dynamic-form',
  templateUrl: './widget-dynamic-form.component.html',
  styleUrl: './widget-dynamic-form.component.scss',
})
export class WidgetDynamicFormComponent extends UnsubscribeOnDestroyAdapter implements OnInit {
  @ContentChild('headerTemplate') headerTemplate: TemplateRef<any>;
  @Input() styleClass: string = 'relative card m-0';
  @Input() title: string = 'some title';
  private _cardContent: ContentViewDto = null;
  @Input() set cardContent(cardContent: ContentViewDto) {
    this._cardContent = cardContent;
    this.initData();
  }
  get cardContent() {
    return this._cardContent;
  }
  mappingService: BaseFieldDefinitionsService = null;
  dataService: BaseRequestControllerService<any> = null;
  fields: IColumn[] = [];
  data: any = null;
  constructor(
    public appDialogService: AppDialogService,
    public router: Router,
    private pathResolverService: PathResolverService,
    private toastService: ToastService
  ) {
    super();
  }
  ngOnInit(): void {}
  initData() {
    if (this.cardContent && this.cardContent?.targetType && this.cardContent?.recordField) {
      this.mappingService = this.pathResolverService.getMappingServiceByTargetType(this.cardContent?.targetType as any);
      this.fields = this.cardContent?.recordField?.map((x) => {
        return {
          ...getTableColumnDefinitionFromField(this.mappingService.mappedFields?.[x.key]),
          options: {
            ...getTableColumnDefinitionFromField(this.mappingService.mappedFields?.[x.key])?.options,
            colSpan: x.colSpan,
          },
        };
      });
      this.dataService = this.pathResolverService.getDataServiceByTargetType(this.cardContent?.targetType as any);
      this.subs.sink = this.dataService
        .search(
          { page: 0, size: 1 },
          {
            projectionFields: this.cardContent?.recordField?.map((x) => x.key),
            filters: [{ property: 'code', operation: 'EQUAL', value: this.cardContent.recordCode }],
          }
        )
        .subscribe({
          next: (res) => {
            if (res?.content?.length > 0) {
              this.data = res?.content?.[0];
            } else {
              this.toastService.error('Not Found!', `Record With Code ${this.cardContent.recordCode} Not Found`);
            }
          },
          error: (err) => {
            // this.toastService.error('Not Found!',`Record With Code ${this.cardContent.recordCode} Not Found`);
          },
        });
    }
  }
  getElementValue(key: string, data: any) {
    let value = data?.[key];
    value = getProp(data, key);
    return value;
  }
}
