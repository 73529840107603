import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-periodical-widget',
  templateUrl: './periodical-widget.component.html',
  styleUrls: ['./periodical-widget.component.scss'],
})
export class PeriodicalWidgetComponent implements OnInit {
  widgetColors = {
    blue: 'background-color: #00ACC1; box-shadow: 0px 6px 20px rgba(0, 208, 222, 0.3)',
    green: 'background-color: #43A047; box-shadow: 0px 6px 20px rgba(11, 209, 138, 0.3)',
    red: 'background-color: #E53935; box-shadow: 0px 6px 20px rgba(252, 97, 97, 0.3);',
  };
  iconMap = {
    blue: 'pi pi-minus',
    green: 'pi pi-arrow-up',
    red: 'pi pi-arrow-down',
  };
  @Input() widgetColor: 'red' | 'green' | 'blue' = 'blue';
  private _config: { oldValue: number; currentValue: number };
  get config() {
    return this._config;
  }
  @Input() set config(config: { oldValue: number; currentValue: number }) {
    this._config = config;
    if (config) this.calcNumbers();
  }
  oldPercent: string = '0.00';
  currentNumber: string = '0';

  constructor() {}

  ngOnInit(): void {}
  calcNumbers() {
    let change =
      this.config.oldValue == 0
        ? 100
        : ((this.config.currentValue - this.config.oldValue) / this.config.oldValue) * 100;
    this.oldPercent = change.toFixed(1);
    this.currentNumber = this.config.currentValue.toFixed(0);
    if (change > 10) this.widgetColor = 'green';
    else if (change < 0) this.widgetColor = 'red';
    else this.widgetColor = 'blue';
  }
}
