<app-input-view-switch
  [showLabelInViewMode]="showLabelInViewMode"
  [mode]="viewMode"
  [data]="data?.name || control?.value?.name"
  [dataType]="dataType"
  [label]="label"
>
  <app-drop-down-input
    [label]="label ?? 'Risk Project'"
    [placeholder]="'Search Risk Projects'"
    [name]="'dropdown'"
    [optionLabel]="'name'"
    [items]="items"
    [optionValue]="optionValue"
    [control]="fControl"
    [multi]="multi"
    [dataKey]="dataKey"
    [lazy]="true"
    [loading]="isLoading"
    [onFilterCommand]="onFilter.bind(this)"
    [floatLabel]="floatLabel"
    [customFilter]="true"
  ></app-drop-down-input>
</app-input-view-switch>
