<form [formGroup]="formGroup" (ngSubmit)="onSubmitForm()">
  <div class="formgrid grid p-fluid">
    <div class="col-12">
      <app-drop-down-input
        [label]="'Operation'"
        [placeholder]="'Select Item'"
        [name]="'dropdown'"
        [optionLabel]="'label'"
        [multi]="false"
        [items]="operations"
        [optionValue]="'value'"
        [control]="formGroup?.controls?.operation"
        [badgeView]="true"
        [viewMode]="'create'"
      ></app-drop-down-input>
    </div>
  </div>
  <ng-content></ng-content>
</form>
