<p-stepper [linear]="true" [(activeStep)]="stepperService.activeStep">
  <p-stepperPanel header="Operation">
    <ng-template pTemplate="content">
      <app-bulk-operation-choice-step></app-bulk-operation-choice-step>
    </ng-template>
  </p-stepperPanel>
  <p-stepperPanel header="Operation Details">
    <ng-template pTemplate="content">
      <app-bulk-operation-details-step></app-bulk-operation-details-step>
    </ng-template>
  </p-stepperPanel>
  <p-stepperPanel header="Confirmation">
    <ng-template pTemplate="content">
      <app-bulk-operation-confirmation-step></app-bulk-operation-confirmation-step>
    </ng-template>
  </p-stepperPanel>
  <p-stepperPanel header="Results">
    <ng-template pTemplate="content">
      <app-bulk-operation-results-step></app-bulk-operation-results-step>
    </ng-template>
  </p-stepperPanel>
</p-stepper>
