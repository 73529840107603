import { Component, Input, OnInit } from '@angular/core';
import { BaseViewItem } from '@shared/classes/view/BaseViewItem';

@Component({
  selector: 'app-gvl-item-view',
  templateUrl: './gvl-item-view.component.html',
  styleUrls: ['./gvl-item-view.component.scss'],
})
export class GvlItemViewComponent extends BaseViewItem implements OnInit {
  @Input() showNumeric: boolean = false;
  @Input() showDescription: boolean = false;

  constructor() {
    super();
  }

  ngOnInit(): void {}
}
