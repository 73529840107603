<div class="custom-timeline mt-4">
  <p-timeline
    [value]="data"
    [class.p-timeline-event-content]="isLargeScreen"
    [class.hide-event-content]="!isLargeScreen"
    [align]="isLargeScreen ? 'alternate' : 'right'"
  >
    <ng-template pTemplate="marker" let-event>
      <span [class]="'custom-marker shadow-2 ' + 'bg-' + colorMap['TRAIL_' + event.type] + '-500 '">
        <i [ngClass]="iconMap[event.type]"></i>
      </span>
    </ng-template>
    <ng-template pTemplate="opposite" let-event>
      <app-audit-trail-card [data]="event"></app-audit-trail-card>
    </ng-template>
  </p-timeline>
  @if (isLoading) {
    <p-timeline
      [value]="events"
      [class.p-timeline-event-content]="isLargeScreen"
      [class.hide-event-content]="!isLargeScreen"
      [align]="isLargeScreen ? 'alternate' : 'right'"
    >
      <ng-template pTemplate="marker" let-event>
        <span [class]="'custom-marker shadow-2 loadingBg'"> </span>
      </ng-template>
      <ng-template pTemplate="opposite" let-event>
        <app-loading-card></app-loading-card>
      </ng-template>
    </p-timeline>
  }
  <div class="w-full p-2">
    @if (this.pageData?.number + 1 < this.pageData?.totalPages) {
      <app-button [action]="loadMoreAction"></app-button>
    }
    @if (!isLoading && !(this.pageData?.number + 1 < this.pageData?.totalPages)) {
      <p class="w-full text-center text-primary-400">Reached Last Audit</p>
    }
  </div>
</div>
