@if (!isListLoading && listData?.length <= 0) {
  <div class="mb-4 mt-4 w-full text-center">
    <app-text-view [data]="'No Items in need of linking'"></app-text-view>
  </div>
} @else {
  <p-splitter
    [layout]="isVertical ? 'vertical' : 'horizontal'"
    [panelSizes]="[80, 20]"
    [minSizes]="[10, 10]"
    [class]="isVertical ? '' : 'splitter-height'"
    [styleClass]="isVertical ? 'mb-3' : 'mb-3 h-full'"
  >
    <ng-template pTemplate>
      @if (isVertical) {
        <ng-container [ngTemplateOutlet]="listTemplate"></ng-container>
      } @else {
        <ng-container [ngTemplateOutlet]="matcherTemplate"></ng-container>
      }
    </ng-template>
    <ng-template pTemplate>
      @if (isVertical) {
        <ng-container [ngTemplateOutlet]="matcherTemplate"></ng-container>
      } @else {
        <ng-container [ngTemplateOutlet]="listTemplate"></ng-container>
      }
    </ng-template>
  </p-splitter>
}
<ng-template #matcherTemplate>
  @if (isDataLoading) {
    <div class="flex flex-column w-full gap-2 overflow-y-auto">
      <div class="card w-full h-19rem"></div>
      <div class="card w-full h-19rem"></div>
    </div>
  } @else {
    <div class="flex flex-column w-full gap-2 overflow-y-auto">
      <app-dynamic-form-view [config]="{ data: currentData, module: moduleKeyword }"></app-dynamic-form-view>
      <app-control-objective-matcher
        [searchData]="isDataLoading ? null : { name: currentData?.[nameKey], description: currentData?.description }"
        [linkedObjectives]="currentData?.controlObjectives"
        (onProcessLinking)="processLinking($event)"
      ></app-control-objective-matcher>
    </div>
  }
</ng-template>

<ng-template #listTemplate>
  @if (isListLoading) {
    <div class="w-full flex flex-column gap-1">
      @for (item of [].constructor(15); track item) {
        <p-skeleton shape="square" height="2rem" styleClass="w-full"></p-skeleton>
      }
    </div>
  } @else {
    <p-listbox
      [options]="listData"
      [virtualScroll]="true"
      [virtualScrollItemSize]="38"
      [filter]="true"
      [(ngModel)]="listCurrentData"
      (onClick)="itemSelected($event)"
      [filterFields]="['code', 'label']"
      [scrollHeight]="isVertical ? '20rem' : 'calc(100vh - 150px - 48px)'"
      [style]="{ width: '100%', height: '100%' }"
    >
      <ng-template let-item pTemplate="item">
        <div
          class="w-full p-2 align-items-center cursor-pointer hover:bg-gray-600 transition-duration-100"
          [class]="currentData?.code == item.code ? 'bg-gray-600' : ''"
        >
          <app-code-navigation
            [pTooltip]="item.label"
            [badgeMode]="true"
            [showRecordStatus]="true"
            [showLabel]="true"
            [data]="item.code"
          ></app-code-navigation>
        </div>
      </ng-template>
    </p-listbox>
  }
</ng-template>
