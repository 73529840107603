<div class="w-full">
  <div class="card">
    <div class="flex justify-content-between">
      <ng-content select="[page-header]"></ng-content>
      <h4 class="mb-0">{{ title }}</h4>
    </div>
    <div class="w-full h-full">
      <p-table
        [value]="permissionList"
        [styleClass]="'justify-content-between flex flex-column h-full p-datatable-striped '"
      >
        <ng-template pTemplate="header">
          <tr>
            <th>View Permission</th>
            <th>Required Permissions</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-item>
          <tr>
            <td>{{ item.viewPermission | humanize }}</td>
            <td>{{ item.permissions.join(', ') }}</td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</div>
