<div
  class="py-3 border-bottom-1 w-full gap-0 surface-border flex md:align-items-start md:justify-content-between flex-column md:flex-row"
>
  <div class="flex align-items-center gap-3 w-full">
    <app-avatar-list [users]="user"></app-avatar-list>
    <div class="w-full">
      <div class="flex flex-column">
        <span class="text-900 font-medium block no-underline">{{ user }}</span>
        <span
          class="white-space-nowrap block text-500 font-medium no-underline"
          timeago
          [date]="date | date: 'yyyy-MM-dd HH:mm:ss'"
          style="font-variant-numeric: lining-nums"
        ></span>
      </div>
    </div>
  </div>
</div>
