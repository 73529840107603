import { ChartViewConfig } from '@shared/classes/model';
import { EChartsOption } from 'echarts';
import { EchartVariationBase } from '../../../echart-variation-base';
import { ScatterChart } from '../../base/scatter-chart';

export class RoundedLogarithmicScatterChart extends ScatterChart implements EchartVariationBase {
  variation: ChartViewConfig.ChartVariationEnum = 'CURVED_LOGARITHMIC';
  smooth = true;
  useNullInsteadOfZero = true;
  chartOptions: EChartsOption = {
    tooltip: {
      trigger: 'axis',
      appendTo: 'body',
    },
    xAxis: [
      {
        type: 'category',
        data: [],
      },
    ],
    yAxis: {
      type: 'log',
      logBase: 10,
    },
    series: [
      {
        data: [],
        type: 'line',
        lineStyle: {
          width: 0,
        },
        symbolSize: 10,
        smooth: true,
      },
    ],
  };
  checkVariationCondition(variation: ChartViewConfig.ChartVariationEnum): boolean {
    return true;
  }
}
