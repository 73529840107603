import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BaseForm, DataTypeEnum, IProgressBar } from '@shared/classes';
import { BulkOperation } from '@shared/classes/view/BulkOperation';
import { ViewModeService } from '@shared/services';
import { toSafeInteger } from 'lodash-es';

@Component({
  selector: 'app-bulk-operation-results-step-form',
  templateUrl: './bulk-operation-results-step-form.component.html',
  styleUrls: ['./bulk-operation-results-step-form.component.scss'],
})
export class BulkOperationResultsStepFormComponent extends BaseForm<any> implements OnInit {
  _completedCounterS = 0;
  cols = [{
    name: 'Id',
    key: 'label',
    dataType: DataTypeEnum.CodeLink,
  },
  {
    name: 'Status',
    key: 'value',
    dataType: DataTypeEnum.Badge,
  },
  {
    name: 'Details',
    key: 'details',
    dataType: DataTypeEnum.LongText,
  }
  ];
  statusesControl = new FormControl(null);
  @Input() set stepperService(val) {
    if (val.operation == BulkOperation.Access || val.operation == BulkOperation.Watchers) {
      this.cols = [{
        name: 'Id',
        key: 'label',
        dataType: DataTypeEnum.CodeLink,
      },
      {
        name: 'Access',
        key: 'access',
        dataType: DataTypeEnum.Badge,
      },
      {
        name: 'Status',
        key: 'value',
        dataType: DataTypeEnum.Badge,
      },
      {
        name: 'Details',
        key: 'details',
        dataType: DataTypeEnum.LongText,
      }
      ]
    }
  };

  @Input() set completedCounterS(cnt: number) {
    this._completedCounterS = cnt;
    this.setProgress();
  }
  get completedCounterS() {
    return this._completedCounterS;
  }

  _completedCounterF = 0;
  @Input() set completedCounterF(cnt: number) {
    this._completedCounterF = cnt;
    this.setProgress();
  }
  get completedCounterF() {
    return this._completedCounterF;
  }

  _totalCount = 0;
  @Input() set totalCount(cnt: number) {
    this._totalCount = cnt;
  }
  get totalCount() {
    return this._totalCount;
  }

  _operationStatuses = {};
  operationStatusesList = [];
  @Input() set operationStatuses(status) {
    this._operationStatuses = status;
    this.operationStatusesList = Object.values(this._operationStatuses);
  }
  get operationStatuses() {

    return this._operationStatuses;

  }

  progressBarList: IProgressBar[] = [];

  constructor(public viewModeService: ViewModeService) {
    super(viewModeService);

  }

  ngOnInit(): void {

  }

  getData() {
    return {
      ...this.data,
    };
  }

  setProgress() {
    this.progressBarList = [
      {
        value: this._totalCount > 0 ? (this._completedCounterS / (this._totalCount ?? 1)) * 100 : 0,
        bgColor: 'bg-green-400',
        tooltipHint:
          (this._totalCount > 0 ? toSafeInteger((this._completedCounterS / (this._totalCount ?? 1)) * 100) : 0) +
          '% FINISHED',
      },
      {
        value: this._totalCount > 0 ? (this._completedCounterF / (this._totalCount ?? 1)) * 100 : 0,
        bgColor: 'bg-red-400',
        tooltipHint:
          (this._totalCount > 0 ? toSafeInteger((this._completedCounterF / (this._totalCount ?? 1)) * 100) : 0) +
          '% FAILED',
      },
    ];
    if (this._operationStatuses)
      this.operationStatusesList = Object.values(this._operationStatuses);

    this.formGroup.controls.progress.patchValue(
      toSafeInteger(((this._completedCounterF + this.completedCounterS) / (this._totalCount ?? 1)) * 100)
    );
  }

  setData(data: any) {
    this.data = data;
  }

  initFormStructure(): void {
    this.formGroup = new FormGroup({ progress: new FormControl(0, Validators.min(100)) });
    // this.formRepeaterFields = [
    //   {
    //     componentType: CodeNavigationComponent,
    //     options: {
    //       label: 'Id',
    //       name: 'label',
    //       control: new FormControl('', Validators.required),
    //       dataType: DataTypeEnum.Text,
    //       showLabelInViewMode: false,
    //     },
    //   },
    //   {
    //     componentType: HtmlTextViewComponent,
    //     options: {
    //       label: 'Status',
    //       name: 'value',
    //       control: new FormControl('', Validators.required),
    //       dataType: DataTypeEnum.Text,
    //       showLabelInViewMode: false,
    //     },
    //   }
    // ];
  }
}
