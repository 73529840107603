import {
  AfterContentInit,
  Component,
  Injector,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  forwardRef,
} from '@angular/core';
import { ControlValueAccessor, FormControl, FormGroup, NG_VALUE_ACCESSOR, NgControl, Validators } from '@angular/forms';
import {
  DataTypeEnum,
  DataTypeToFieldTypeMapper,
  DynamicComponentBase,
  FilterBuilderTreeNode,
  FilterItem,
  GroupingField,
  IAction,
  IColumn,
  IDynamicComponent,
  IFieldDefinition,
  ITableLoadEvent,
  ModuleKeywords,
  OfflineConfig,
  SearchFilterDto,
  SortField,
  TargetTypeEnum,
  formatFilterBuilderTree,
  getEnumOptions,
  getTableColumnDefinitionFromField,
  isNullObj,
} from '@shared/classes';
import { DropDownInputComponent } from '@shared/components/ui/input-fields/drop-down-input/drop-down-input.component';
import {
  BaseFieldDefinitionsService,
  BaseRequestControllerService,
  PathResolverService,
  RelationFilterField,
} from '@shared/services';
import { SearchFilterDataService } from 'app/modules/data-structure-module/services/data/search-filter-data.service';
import { omit } from 'lodash-es';
import { TreeNode } from 'primeng/api';
import { v4 as uuidV4 } from 'uuid';

export const INPUT_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => FilterBuilderComponent),
  multi: true,
};
@Component({
  selector: 'app-filter-builder',
  templateUrl: './filter-builder.component.html',
  styleUrl: './filter-builder.component.scss',
  providers: [INPUT_VALUE_ACCESSOR],
})
export class FilterBuilderComponent
  extends DynamicComponentBase
  implements OnInit, AfterContentInit, OnChanges, ControlValueAccessor
{
  dataTypeMapper = DataTypeToFieldTypeMapper;
  private _mappingService: BaseFieldDefinitionsService;
  colsByKey: any = {};
  public get mappingService(): BaseFieldDefinitionsService {
    return this._mappingService;
  }
  public set mappingService(value: BaseFieldDefinitionsService) {
    this._mappingService = value;
    this.onSetMappingService();
  }
  private _dataService: BaseRequestControllerService<any>;
  public get dataService(): BaseRequestControllerService<any> {
    return this._dataService;
  }
  public set dataService(value: BaseRequestControllerService<any>) {
    this._dataService = value;
    this.onSetDataService();
  }
  private _moduleKeyword: ModuleKeywords;
  get moduleKeyword() {
    return this._moduleKeyword;
  }
  @Input() set moduleKeyword(moduleKeyword: ModuleKeywords) {
    this._moduleKeyword = moduleKeyword;
    this.onSetModuleKeyword();
    this.formGroup?.controls?.moduleControl?.patchValue(moduleKeyword, { onlySelf: true, emitEvent: false });
  }
  onChangeModuleControl(moduleKeyword) {
    this._moduleKeyword = moduleKeyword;
    this.onSetModuleKeyword();
    this.onInputValueChange(moduleKeyword);
  }
  tableFields: IColumn[] = [];
  mappedFields: { [x: string]: IFieldDefinition } = {};
  formattedTableFields: {
    label: string;
    fieldName: string;
    operation: FilterBuilder.OperationEnum;
    dateTimePart: GroupingField.DateTimePartEnum;
  }[] = [];
  filter: SearchFilterDto; // just for testing
  operationEnumOptions = getEnumOptions(FilterBuilder.OperationEnumNormal);
  numberOperationEnumOptions = getEnumOptions(FilterBuilder.OperationEnumNumber);
  dateOperationEnumOptions = getEnumOptions(FilterBuilder.OperationEnumDate);
  typeOptions = getEnumOptions(SearchFilterDto.TypeEnum);
  filterTypeOptions = getEnumOptions(SearchFilterDto.FilterTypeEnum);
  dateTimePartOptions = getEnumOptions(GroupingField.DateTimePartEnum);
  numberDataType = DataTypeEnum.Number;
  offlineConfig: OfflineConfig = {
    lazy: true,
    paginator: false,
    showActionBar: false,
  };
  savedFilters: FilterItem[] = [];
  savedRelationFilters: FilterItem[] = [];
  submitAction: IAction = {
    id: 1,
    label: 'Save',
    buttonType: 'button',
    command: this.onSubmit.bind(this),
    icon: 'pi pi-save',
    // status$: this.formValid$,
    // loading$: this.loadingState$,
    // buttonStyle: 'outlined'
  };
  targetTypes = getEnumOptions(TargetTypeEnum);
  formGroup = new FormGroup({
    nameControl: new FormControl(null, Validators.required),
    moduleControl: new FormControl(null, Validators.required),
    // statisticsModeSwitch: new FormControl(null),
    projectionFieldsControl: new FormControl(null),
    sortControl: new FormControl(null),
    savedFilters: new FormControl(null),
    filterTree: new FormControl(null),
    savedRelationFilters: new FormControl(null),
    relationTree: new FormControl(null),
    typeControl: new FormControl(SearchFilterDto.TypeEnum.Search),
    filterTypeControl: new FormControl(SearchFilterDto.FilterTypeEnum.Normal),
  });
  private ngControl: NgControl | null = null; // assign the current formControl to have access to all options ex: errors, dirty, hasValidator ...etc
  value: any; // end value of the control component
  public changed: (value: any) => void;
  public touched: () => void;
  public isDisabled: boolean;
  private _availableFields: any[] = [];
  get availableFields() {
    return this._availableFields;
  }
  set availableFields(availableFields: any[]) {
    this._availableFields = availableFields;
    if (!isNullObj(availableFields))
      this.formRepeaterFields = [
        {
          componentType: DropDownInputComponent,
          options: {
            label: 'Field',
            name: 'fieldName',
            control: new FormControl(null, Validators.required),
            inputOptions: {
              dropDownInput: {
                multi: false,
                showClear: false,
                optionLabel: 'label',
                optionValue: 'value',
                items: this.availableFields,
                badgeView: false,
                appendTo: 'body',
              },
            },
            dataType: DataTypeEnum.Text,
            showLabelInViewMode: false,
          },
        },
        {
          componentType: DropDownInputComponent,
          options: {
            label: 'Sort Direction',
            name: 'sortOperation',
            control: new FormControl(null, Validators.required),
            inputOptions: {
              dropDownInput: {
                multi: false,
                showClear: false,
                optionLabel: 'label',
                optionValue: 'value',
                items: [
                  { label: 'Ascending', value: 'ASC' },
                  { label: 'Descending', value: 'DESC' },
                ],
                badgeView: false,
                appendTo: 'body',
              },
            },
            dataType: DataTypeEnum.Text,
            showLabelInViewMode: false,
          },
        },
        {
          componentType: DropDownInputComponent,
          options: {
            label: 'Null Variants',
            name: 'nulls',
            control: new FormControl(SortField.NullsEnum.Native, Validators.required),
            inputOptions: {
              dropDownInput: {
                multi: false,
                showClear: false,
                optionLabel: 'label',
                optionValue: 'value',
                items: [
                  { label: 'Native', value: SortField.NullsEnum.Native },
                  { label: 'First', value: SortField.NullsEnum.First },
                  { label: 'Last', value: SortField.NullsEnum.Last },
                ],
                badgeView: false,
                appendTo: 'body',
              },
            },
            dataType: DataTypeEnum.Text,
            showLabelInViewMode: false,
          },
        },
      ];
  }
  formRepeaterFields: IDynamicComponent[] = [];
  constructor(
    private pathResolverService: PathResolverService,
    private filterService: SearchFilterDataService,
    private readonly injector: Injector
  ) {
    super();
  }

  onSetModuleKeyword() {
    if (this.pathResolverService && this.moduleKeyword) {
      this.mappingService = this.pathResolverService.getMappingServiceByModuleKeyword(this.moduleKeyword);
      this.dataService = this.pathResolverService.getDataServiceByModuleKeyword(this.moduleKeyword);
    }
  }
  onSetMappingService() {
    this.tableFields = this.mappingService.tableFields
      .filter((x) => !!x.filter)
      .map((x) => {
        return { ...x, sortDisabled: true, matchMode: 'and' };
      });
    this.initSourceTreeItems();
    this.initRelationSourceTreeItems();
    this.mappedFields = { ...this.mappingService.mappedFields };
    this.colsByKey = {};
    const fields = [];
    this.tableFields.forEach((col) => {
      this.colsByKey[col.key] = col;
      fields.push({ label: col.name, value: col.key });
    });
    this.availableFields = [...fields];
    this.formattedTableFields = this.tableFields.map((x) => {
      return {
        label: x.name,
        fieldName: x.key,
        operation: this.isDateType(x.dataType)
          ? FilterBuilder.OperationEnum.GroupByInterval
          : FilterBuilder.OperationEnum.GroupBy,
        dateTimePart: this.isDateType(x.dataType) ? GroupingField.DateTimePartEnum.Month : null,
      };
    });
  }
  onSetDataService() {}

  onFilterChange(node, tableEvent: ITableLoadEvent) {
    node.tableEvent = tableEvent;
    // let colsByKey = {};
    // this.tableFields.forEach((col) => {
    //   colsByKey[col.key] = col;
    // });
    const res = formatFilterBuilderTree(this.emptyTree, 'AND', this.colsByKey);
    this.savedFilters = [res];
    this.formGroup.controls.savedFilters.patchValue(res ? [res] : null);
    this.updateFilterTreeControl();
    this.onInputValueChange(this.savedFilters);
  }
  onRelationFilterChange(node, tableEvent: ITableLoadEvent) {
    node.tableEvent = tableEvent;
    // let colsByKey = {};
    // this.tableFields.forEach((col) => {
    //   colsByKey[col.key] = col;
    // });
    //@TODO: change colseByKey
    const res = formatFilterBuilderTree(this.relationTree, 'AND', this.colsByKey);
    this.savedRelationFilters = [res];
    this.formGroup.controls.savedRelationFilters.patchValue(res ? [res] : null);
    this.updateRelationFilterTreeControl();
    this.onInputValueChange(this.savedRelationFilters);
  }
  updateFilterTreeControl() {
    const copiedTree = [];
    this.emptyTree.forEach((item) => {
      copiedTree.push(this.removeParent(item));
    });
    this.formGroup.controls.filterTree.patchValue(copiedTree);
    this.onInputValueChange(copiedTree);
  }
  updateRelationFilterTreeControl() {
    const copiedTree = [];
    this.relationTree.forEach((item) => {
      copiedTree.push(this.removeParent(item));
    });
    this.formGroup.controls.relationTree.patchValue(copiedTree);
    this.onInputValueChange(copiedTree);
  }
  removeParent(node) {
    const newNode = omit(node, 'parent', 'children');
    const newChildren = [];
    if (node?.children?.length > 0) {
      node?.children?.forEach((item) => {
        newChildren.push(this.removeParent(item));
      });
    }
    return { ...newNode, children: newChildren };
  }
  onSetFormData(value: SearchFilterDto) {
    this.moduleKeyword = value?.targetType as any;
    const formattedGroupingFields =
      value?.aggregationBody?.groupByFields?.map((x) => {
        return {
          operation: this.getReverseGroupingValue(x.typeShape),
          fieldName: x.fieldName,
          dateTimePart: x.typeShape == 'DATETIME_PART' ? x?.dateTimePart : null,
        };
      }) || [];
    const formattedAggregationFields =
      value?.aggregationBody?.aggregationFields?.map((x) => {
        return { operation: x.operation, fieldName: x.fieldName, dateTimePart: null };
      }) || [];
    const patchValue = {
      nameControl: value?.name,
      moduleControl: value?.targetType,
      savedFilters: value?.filter?.filters,
      typeControl: value?.type || SearchFilterDto.TypeEnum.Search,
      filterTypeControl: value?.filterType || SearchFilterDto.FilterTypeEnum.Normal,
      projectionFieldsControl: [...formattedGroupingFields, ...formattedAggregationFields]?.map((x) => {
        return {
          ...x,
          label: this.mappedFields?.[x.fieldName]?.name,
          uid: uuidV4(),
          _pickListTargetItem: true,
        };
      }),
      sortControl: value?.aggregationBody?.sortFields,
      filterTree: value?.filterTree,
      relationTree: value?.relationFilterTree,
      savedRelationFilters: value?.filter?.relationFilters,
    };
    this.emptyTree = value?.filterTree || [];
    this.relationTree = value?.relationFilterTree || [];
    // setTimeout(() => {
    //   if (patchValue?.savedFilters?.[0]) {
    //     const res = this.transformFilterItem(patchValue?.savedFilters?.[0]);

    //     this.emptyTree = [res];
    //   }
    // }, 3000);
    this.formGroup?.patchValue(patchValue);
    if (this.isViewInit) this.onInputValueChange(patchValue);
    this.initSourceTreeItems();
    this.initRelationSourceTreeItems();
  }
  formatFilterTreeIntoPrimeTree(filter: FilterItem) {
    if (filter.left) {
    }
    if (filter.right) {
    }
    if (!filter.left && !filter.right) {
    }
  }
  // Function to transform FilterItem to FilterBuilderTreeNode
  transformFilterItem(filterItem: FilterItem): FilterBuilderTreeNode {
    if (!filterItem.left && !filterItem.right) {
      // If the filter item has property, operation, and value, it's a leaf node
      if (filterItem.property && filterItem.operation && filterItem.value !== undefined) {
        const tableEvent = new ITableLoadEvent();
        tableEvent.filters?.push({
          property: filterItem.property,
          operation: filterItem.operation,
          value: filterItem.value,
          typeShape: filterItem.typeShape || 'NORMAL',
        });
        return {
          ...omit(this.colsByKey[filterItem.property]),
          type: 'node',
          tableEvent: tableEvent,
        };
      }
    }

    // Otherwise, it's an operator node
    const children: FilterBuilderTreeNode[] = [];
    if (filterItem.left) {
      children.push(this.transformFilterItem(filterItem.left));
    }
    if (filterItem.right) {
      children.push(this.transformFilterItem(filterItem.right));
    }

    return this.minimizeTree(
      {
        type: 'operator',
        matchMode: filterItem.operand == 'AND' ? 'and' : 'or',
        children: this.groupChildrenByProperty(children),
      },
      true
    );
    // return {
    //     type: 'operator',
    //     matchMode: filterItem.operand == 'AND' ? 'and' : 'or',
    //     children: this.groupChildrenByProperty(children)
    // };
  }

  // Function to group filters by property in the children nodes
  groupChildrenByProperty(children: FilterBuilderTreeNode[]): FilterBuilderTreeNode[] {
    const groupedNodes: { [key: string]: FilterBuilderTreeNode } = {};

    children.forEach((child) => {
      if (child.type === 'node' && child.tableEvent) {
        const filter = child.tableEvent.filters?.[0];
        if (filter) {
          if (!groupedNodes[filter.property]) {
            groupedNodes[filter.property] = {
              ...omit(this.colsByKey[filter.property]),
              type: 'node',
              tableEvent: new ITableLoadEvent(),
            };
          }
          groupedNodes[filter.property].tableEvent?.filters?.push(filter);
        }
      } else {
        // For operator nodes, we just add them directly
        groupedNodes[`${Object.keys(groupedNodes).length}`] = child;
      }
    });

    return Object.values(groupedNodes);
  }
  minimizeTree(node: FilterBuilderTreeNode, isRoot: boolean = false): FilterBuilderTreeNode {
    if (node.type === 'operator' && node.children) {
      let i = 0;
      while (i < node.children.length) {
        const child = node.children[i];
        if (child.type === 'operator' && child.matchMode === node.matchMode) {
          // Merge children of the same operand
          node.children.splice(i, 1, ...(child.children || []));
        } else {
          node.children[i] = this.minimizeTree(child);
          i++;
        }
      }
      // Elevate nodes with only one child to the parent level if not the root node
      if (node.children.length === 1 && !isRoot) {
        return node.children[0];
      }
    }
    return node;
  }
  //     // Function to transform FilterItem to FilterBuilderTreeNode
  // transformFilterItem(filterItem: FilterItem) {
  //     // If the filter item has property, operation, and value, it's a leaf node
  //     if (filterItem.property && filterItem.operation && filterItem.value !== undefined) {
  //         return {
  //             type: null,
  //             tableEvent: {
  //                 property: filterItem.property,
  //                 operation: filterItem.operation,
  //                 value: filterItem.value,
  //                 typeShape: filterItem.typeShape || 'NORMAL'
  //             }
  //         };
  //     }

  //     // Otherwise, it's an operator node
  //     const children: any[] = [];
  //     if (filterItem.left) {
  //         children.push(this.transformFilterItem(filterItem.left));
  //     }
  //     if (filterItem.right) {
  //         children.push(this.transformFilterItem(filterItem.right));
  //     }

  //     return {
  //         type: 'operator',
  //         matchMode: filterItem.operand == 'AND' ? 'and' : 'or',
  //         children: children
  //     };
  // }
  onSubmit() {
    this.filter = this.formatGetData();
    // this.filterService.create(getDataKeyValueFormat(this.filter)).subscribe(res=>{

    //     window.history.back();
    // })
  }
  relationTree = [];
  relationTreeMatchMode = 'and';
  emptyTree = [];
  emptyTreeMatchMode = 'and';
  filterMatchingOptions = [
    { label: 'Match All', value: 'and' },
    { label: 'Match Any', value: 'or' },
  ];
  sourceTreeItems = [];
  relationSourceTreeItems = [];
  dragOver(event) {
    event?.stopPropagation();
    event?.preventDefault();
  }
  changeMatchMode(node) {
    node.matchMode = node?.matchMode == 'and' ? 'or' : 'and';
  }
  onNodeDrop(event: any) {
    let draggedNode = event.dragNode;
    let dropNode = event.dropNode;
    let index = event.index;
    draggedNode.id = uuidV4();
    event?.accept();
    this.initSourceTreeItems(true);
    this.updateFilterTreeControl();
    // if (target === 'target') {
    //   // Clone the dragged node
    //   let newNode = { ...draggedNode, children: [...(draggedNode.children || [])] };

    //   // Insert the cloned node in the target tree
    //   if (dropNode) {
    //     if (!dropNode.children) {
    //       dropNode.children = [];
    //     }
    //     dropNode.children.splice(index, 0, newNode);
    //   } else {
    //     this.emptyTree.splice(index, 0, newNode);
    //   }
    // }
  }
  onRemoveTargetNode(node) {
    const foundNode = this.recursiveFind(this.emptyTree, node?.id);
    if (foundNode) {
      (foundNode.parent as any[]).splice(foundNode.index, 1);
      this.initSourceTreeItems();
      this.updateFilterTreeControl();
    }
  }
  initSourceTreeItems(enableDragging = false) {
    if (this.mappingService) {
      if (this.emptyTree?.length > 0 || enableDragging) {
        this.sourceTreeItems = [
          { label: 'Match All', value: 'and', type: 'operator', matchMode: 'and' },
          { label: 'Match Any', value: 'or', type: 'operator', matchMode: 'or' },
          { type: 'line', draggable: false },
          ...this.mappingService.tableFields
            .filter((x) => !!x.filter)
            .map((x) => {
              return { ...x, type: 'node', sortDisabled: true, droppable: false };
            }),
        ];
      } else {
        this.sourceTreeItems = [
          { label: 'Match All', value: 'and', type: 'operator', matchMode: 'and' },
          { label: 'Match Any', value: 'or', type: 'operator', matchMode: 'or' },
          { type: 'line', draggable: false },
          ...this.mappingService.tableFields
            .filter((x) => !!x.filter)
            .map((x) => {
              return { ...x, type: 'node', sortDisabled: true, droppable: false, draggable: false };
            }),
        ];
      }
    }
  }
  recursiveFind(tree: TreeNode[], id: string) {
    for (let index = 0; index < tree?.length; index++) {
      const element: any = tree?.[index];
      if (element?.id && element?.id == id) {
        return { parent: tree, node: element, index: index };
      }
      if (element?.children?.length > 0) {
        const ret = this.recursiveFind(element?.children, id);
        if (ret) {
          return ret;
        }
      }
    }
    return null;
  }
  onFilter(event: string) {
    this.formattedTableFields = this.tableFields
      .filter((x) => x.name.toLowerCase().includes(event.toLowerCase()))
      .map((x) => {
        return {
          label: x.name,
          fieldName: x.key,
          operation: this.isDateType(x.dataType)
            ? FilterBuilder.OperationEnum.GroupByInterval
            : FilterBuilder.OperationEnum.GroupBy,
          dateTimePart: this.isDateType(x.dataType) ? GroupingField.DateTimePartEnum.Month : null,
        };
      });
  }
  getNoFilterProperty(item) {
    return { ...item, filter: null };
  }
  onRelationNodeDrop(event: any) {
    let draggedNode = event.dragNode;
    let dropNode = event.dropNode;
    let index = event.index;
    draggedNode.id = uuidV4();
    event?.accept();
    this.initRelationSourceTreeItems(true);
    this.updateRelationFilterTreeControl();
  }
  onRemoveRelationTargetNode(node) {
    const foundNode = this.recursiveFind(this.relationTree, node?.id);
    if (foundNode) {
      (foundNode.parent as any[]).splice(foundNode.index, 1);
      this.initRelationSourceTreeItems();
      this.updateRelationFilterTreeControl();
    }
  }
  initRelationSourceTreeItems(enableDragging = false) {
    this.relationSourceTreeItems = [
      { label: 'Match All', value: 'and', type: 'operator', matchMode: 'and' },
      { label: 'Match Any', value: 'or', type: 'operator', matchMode: 'or' },
      { type: 'line', draggable: false },
      {
        ...getTableColumnDefinitionFromField(RelationFilterField),
        type: 'node',
        sortDisabled: true,
        droppable: true,
        draggable: true,
      },
    ];
  }
  setInputOptions(): void {
    // for dynamic generation of the input
  }
  ngOnInit(): void {
    this.ngControl = this.injector.get(NgControl, null, { optional: true });
  }
  ngAfterContentInit(): void {
    // for custom templates assignments, EX: pre-item-template
  }
  ngOnChanges(changes: SimpleChanges): void {
    //for checks on @Input() items changes
  }
  writeValue(value: any): void {
    // On control.patchValue
    this.value = value;
    if (!isNullObj(value)) {
      this.onSetFormData(value);
    } else {
      this.formGroup.reset();
    }
    // this.cd.markForCheck(); // if ChangeDetectionStrategy is OnPush then it's needed
  }
  formatGetData() {
    const ret: SearchFilterDto = {
      name: this.formGroup?.controls?.nameControl?.value,
      filter: {
        filters: this.formGroup.controls.savedFilters.value,
        projectionFields: this.formGroup?.controls?.projectionFieldsControl?.getRawValue()?.map((x) => x.fieldName),
        relationFilters: this.formGroup?.controls?.savedRelationFilters?.value,
      },
      filterTree: this.formGroup.controls.filterTree.getRawValue(),
      relationFilterTree: this.formGroup.controls.relationTree.getRawValue(),
      aggregationBody: {
        filters: this.formGroup.controls.savedFilters.value,
        relationFilters: this.formGroup?.controls?.savedRelationFilters?.value,
        // sortFields
        groupByFields: this.formGroup?.controls?.projectionFieldsControl
          ?.getRawValue()
          ?.filter((x) => this.isGroupingField(x)) //store grouping fields as grouping
          ?.map((x) => {
            const ret: GroupingField = {
              fieldName: x?.fieldName,
              typeShape: this.getGroupingValue(x.operation),
              dateTimePart:
                this.getGroupingValue(x.operation) == 'DATETIME_PART' ? (x?.dateTimePart ?? undefined) : undefined,
            };
            return ret;
          }),
        aggregationFields: this.formGroup?.controls?.projectionFieldsControl
          ?.getRawValue()
          ?.filter((x) => !this.isGroupingField(x)) //store non grouping fields as aggregating functions
          ?.map((x) => {
            return {
              fieldName: x.fieldName,
              operation: x.operation,
            };
          }),
        // projectionFieldsNames: this.formGroup?.controls?.projectionFieldsControl //Not used in BE but adding it either way
        //   ?.getRawValue()
        //   ?.map((x) => x.fieldName),
        sortFields: this.formGroup?.controls?.sortControl?.getRawValue(),
      },
      type: this.formGroup?.controls?.typeControl.value,
      targetType: this.moduleKeyword as any,
      filterType: this.formGroup?.controls?.filterTypeControl.value,
      all: false,
      size: 10,
      page: 0,
    };
    return ret;
  }
  onAggregationFieldChanges(event) {}
  onInputValueChange(value: any) {
    this.value = { ...this.formatGetData() };
    this.changed({ ...this.formatGetData() });
  }
  registerOnChange(fn: any): void {
    this.changed = fn;
    this.isViewInit = true;
    // this.formGroup.valueChanges.subscribe(values=>{
    //     this.onInputValueChange(values);
    // })
  }
  registerOnTouched(fn: any): void {
    this.touched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
    // this.cd.markForCheck(); // if ChangeDetectionStrategy is OnPush then it's needed
  }
  isViewInit: boolean = false;
  afterViewInit(): void {
    this.formGroup.valueChanges.subscribe((values) => {
      this.onInputValueChange(values);
    });
    this.isViewInit = true;
  }
  getAggregationOperationOptions(type) {
    if (type == DataTypeEnum.Number) {
      return this.numberOperationEnumOptions;
    } else if (this.isDateType(type)) {
      return this.dateOperationEnumOptions;
    } else {
      return this.operationEnumOptions;
    }
  }
  isDateType(type) {
    return type == DataTypeEnum.Date || type == DataTypeEnum.DateLong || type == DataTypeEnum.DateShort;
  }
  isGroupingField(field: { fieldName: string; operation: FilterBuilder.OperationEnum }) {
    return FilterBuilder.GroupingEnumSet.has(field.operation as FilterBuilder.GroupingEnum);
  }
  getGroupingValue(operation: FilterBuilder.GroupingEnum) {
    return FilterBuilder.GroupingMapper[operation];
  }
  getReverseGroupingValue(operation: GroupingField.TypeShapeEnum) {
    return FilterBuilder.GroupingMapperReverse[operation];
  }
}
export namespace FilterBuilder {
  export type GroupingEnum = 'GROUP_BY' | 'GROUP_BY_DATE' | 'GROUP_BY_INTERVAL';
  export const GroupingEnum = {
    GroupBy: 'GROUP_BY' as GroupingEnum,
    GroupByDate: 'GROUP_BY_DATE' as GroupingEnum,
    GroupByInterval: 'GROUP_BY_INTERVAL' as GroupingEnum,
  };
  export const GroupingEnumSet = new Set(Object.values(FilterBuilder.GroupingEnum));
  export const GroupingMapper: { [key in GroupingEnum]: GroupingField.TypeShapeEnum } = {
    GROUP_BY: 'NORMAL',
    GROUP_BY_DATE: 'DATETIME',
    GROUP_BY_INTERVAL: 'DATETIME_PART',
  };
  export const GroupingMapperReverse: { [key in GroupingField.TypeShapeEnum]: GroupingEnum } = {
    NORMAL: 'GROUP_BY',
    DATETIME: 'GROUP_BY_DATE',
    DATETIME_PART: 'GROUP_BY_INTERVAL',
  };
  export type OperationEnum =
    | 'GROUP_BY'
    | 'GROUP_BY_DATE'
    | 'GROUP_BY_INTERVAL'
    | 'AVG'
    | 'SUM'
    | 'MAX'
    | 'MIN'
    | 'FIRST'
    | 'LAST'
    | 'COUNT';
  export const OperationEnum = {
    GroupBy: 'GROUP_BY' as OperationEnum,
    GroupByDate: 'GROUP_BY_DATE' as OperationEnum,
    GroupByInterval: 'GROUP_BY_INTERVAL' as OperationEnum,
    Avg: 'AVG' as OperationEnum,
    Sum: 'SUM' as OperationEnum,
    Max: 'MAX' as OperationEnum,
    Min: 'MIN' as OperationEnum,
    First: 'FIRST' as OperationEnum,
    Last: 'LAST' as OperationEnum,
    Count: 'COUNT' as OperationEnum,
  };
  export type OperationEnumNormal = 'GROUP_BY' | 'FIRST' | 'LAST' | 'COUNT';
  export const OperationEnumNormal = {
    GroupBy: 'GROUP_BY' as OperationEnumNormal,
    First: 'FIRST' as OperationEnumNormal,
    Last: 'LAST' as OperationEnumNormal,
    Count: 'COUNT' as OperationEnumNormal,
  };
  export type OperationEnumDate = 'GROUP_BY_DATE' | 'GROUP_BY_INTERVAL' | 'MAX' | 'MIN' | 'FIRST' | 'LAST' | 'COUNT';
  export const OperationEnumDate = {
    GroupByDate: 'GROUP_BY_DATE' as OperationEnum,
    GroupByInterval: 'GROUP_BY_INTERVAL' as OperationEnum,
    Count: 'COUNT' as OperationEnumDate,
    Max: 'MAX' as OperationEnumDate,
    Min: 'MIN' as OperationEnumDate,
    First: 'FIRST' as OperationEnumDate,
    Last: 'LAST' as OperationEnumDate,
  };
  export type OperationEnumNumber = 'GROUP_BY' | 'AVG' | 'SUM' | 'MAX' | 'MIN' | 'FIRST' | 'LAST' | 'COUNT';
  export const OperationEnumNumber = {
    GroupBy: 'GROUP_BY' as OperationEnumNumber,
    Avg: 'AVG' as OperationEnumNumber,
    Sum: 'SUM' as OperationEnumNumber,
    Max: 'MAX' as OperationEnumNumber,
    Min: 'MIN' as OperationEnumNumber,
    First: 'FIRST' as OperationEnumNumber,
    Last: 'LAST' as OperationEnumNumber,
    Count: 'COUNT' as OperationEnumNumber,
  };
}
