import { Component, Input, OnInit } from '@angular/core';
import { BaseViewItem, IProgressBar } from '@shared/classes';
import { isNumber } from 'lodash-es';

@Component({
  selector: 'app-planned-progress-bar-view',
  templateUrl: './planned-progress-bar-view.component.html',
  styleUrls: ['./planned-progress-bar-view.component.scss'],
})
export class PlannedProgressBarViewComponent extends BaseViewItem<any> implements OnInit {
  percentage1 = 0;
  percentage2 = 0;
  value1 = null;
  value2 = null;
  value3 = null;
  viewValue;
  viewValue2 = null;
  viewValue3 = null;

  @Input() viewDataType;
  @Input() viewOptions;
  @Input() optionValue;
  @Input() progressColor;
  @Input() useRawValue: boolean = false;

  progressBarList: IProgressBar[] = [];

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.setData();
  }

  onSetData(): void {
    this.setData();
  }

  setData() {
    if (this.data?.value1 && this.data?.value2 && this.data?.value3) {
      this.value1 = this.optionValue ? this.data.value1[this.optionValue] : this.data.value1;
      this.value2 = this.optionValue ? this.data.value2[this.optionValue] : this.data.value2;
      this.value3 = this.optionValue ? Math.abs(this.data.value3[this.optionValue]) : isNumber(this.data?.value3) ? Math.abs(this.data?.value3) : this.data.value3;

      if (this.value2 != undefined || this.value2 != null) {
        this.setProgressBarValues();
      } else {
        this.progressBarList = [];
        this.viewValue2 = null;
      }
    } else {
      this.progressBarList = [];
      this.viewValue2 = null;
    }
  }

  private setProgressBarValues() {
    const div = this.value2 == 0 ? 0 : this.value1 / this.value2;
    this.percentage1 = div * 100;
    if (this.value1 > this.value2) {
      this.viewValue = this.optionValue
        ? this.useRawValue
          ? this.data.value1
          : this.data.value1[this.optionValue]
        : this.data.value1;
      this.viewValue2 = this.optionValue
        ? this.useRawValue
          ? this.data.value2
          : this.data.value2[this.optionValue]
        : this.data.value2;
      this.viewValue3 = this.optionValue
        ? this.useRawValue
          ? { ...this.viewValue2, [this.optionValue]: this.value3 }
          : this.value3
        : this.value3;

      const p1 = 1 / div;
      const p2 = 1 - p1;
      this.percentage1 = p1 * 100;
      this.percentage2 = p2 * 100;
      this.progressBarList = [
        this.createProgressBar(this.percentage1, 'bg-blue-400'),
        this.createProgressBar(this.percentage2, 'bg-orange-400'),
      ];
    } else {
      this.viewValue = this.optionValue
        ? this.useRawValue
          ? this.data.value1
          : this.data.value1[this.optionValue]
        : this.data.value1;
      if (this.value1 < this.value2) {
        this.viewValue2 = this.optionValue
          ? this.useRawValue
            ? { ...this.viewValue, [this.optionValue]: this.value3 }
            : this.value3
          : this.value3;
      } else {
        this.viewValue2 = null;
      }
      this.viewValue3 = null;
      this.progressBarList = [this.createProgressBar(this.percentage1, 'bg-blue-400')];
    }
  }

  private createProgressBar(value: number, bgColor: string): IProgressBar {
    return {
      value: value,
      bgColor: bgColor,
      tooltipHint: '%' + value.toFixed(2),
      animated: true,
    };
  }
}
