import { Component, Input, OnInit } from '@angular/core';
import { DataTypeEnum, DynamicComponentBase } from '@shared/classes';
import { EntityDataService } from 'app/modules/entity-module/entity/entity-data.service';

@Component({
  selector: 'app-entity-selector-by-level',
  templateUrl: './entity-selector-by-level.component.html',
  styleUrls: ['./entity-selector-by-level.component.scss'],
})
export class EntitySelectorByLevelComponent extends DynamicComponentBase implements OnInit {
  @Input() multi: boolean = true;
  @Input() set level(level: string) {
    this._level = level;
    this.getOptions();
  }
  private _level: string = '';
  get level() {
    return this._level;
  }
  items: any[] = [];
  dataType: DataTypeEnum = DataTypeEnum.Text;

  constructor(private service: EntityDataService) {
    super();
  }
  ngOnInit(): void {
    // this.getOptions();
    if (this.control) {
      this.subs.sink = this.fControl.valueChanges.subscribe((changes) => {
        this.onChanges.emit(changes);
      });
    }
  }

  setInputOptions() {
    this.multi = this.inputOptions?.dropDownInput?.multi ?? this.multi;
    this.level = this.inputOptions?.extra?.level ?? this.level;
  }
  getOptions() {
    if (this.level)
      this.subs.sink = this.service.getEntitiesByLevel(this.level, { showLoading: false, showMsg: false }).subscribe({
        next: (res) => {
          this.items = res.data;
        },
      });
  }
}
