<div class="relative h-5rem border-round-xl pt-2">
  <span class="text-sm font-medium line-height-1"></span>
  <div class="flex justify-content-between">
    <div class="flex justify-content-between align-items-center">
      <div
        [tooltipPosition]="'top'"
        [pTooltip]="'Old Value : ' + config?.oldValue"
        class="flex justify-content-center align-items-center h-2rem border-round p-2 mr-3 gap-1"
        [style]="widgetColors[widgetColor] + ' width:5.5rem !important;'"
      >
        <i [class]="' w-2rem ' + iconMap[widgetColor]"></i>
        <span class="line-height-2">{{ oldPercent }}%</span>
      </div>
      <div class="line-height-4 text-4xl">{{ currentNumber }}</div>
    </div>
  </div>
  <img [src]="'assets/demo/images/' + widgetColor + '.svg'" class="absolute" style="bottom: 14px; right: 12px" />
</div>
