import { Component, Input, OnDestroy } from '@angular/core';
import { BulkOperationService } from '@shared/services/bulk-operation-service/bulk-operation.service';

@Component({
  selector: 'app-bulk-operation-stepper',
  templateUrl: './bulk-operation-stepper.component.html',
  styleUrl: './bulk-operation-stepper.component.scss',
})
export class BulkOperationStepperComponent implements OnDestroy {
  @Input() stepperService: BulkOperationService;

  ngOnDestroy(): void {
    this.stepperService?.goToStep1();
  }
}
