import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-avatar-info-card-list',
  templateUrl: './avatar-info-card-list.component.html',
  styleUrls: ['./avatar-info-card-list.component.scss'],
})
export class AvatarInfoCardListComponent implements OnInit {
  @Input() userList: any[] = [];
  @Input() styleClass: string = '';

  constructor() {}

  ngOnInit(): void {}
}
