<div
  [class]="
    'flex gap-2 select-none align-items-center justify-content-center badge ' +
    badgeTypes[badgeType] +
    (badgeColors[status] || badgeColors['Valid']) +
    ' ' +
    (flicker ? ' badge-shining ' : '') +
    styleClass
  "
  [style]="
    (customColor ? 'color:' + customColor + '; ' : '') +
    (customColorBg ? 'background-color:' + customColorBg + '; ' : '') +
    ' min-height: 2.5rem; min-width: 2.5rem; text-align: center;  border-radius: 50%; font-size: 20px;'
  "
>
  @if ((displayText || showIcon) && (badgeIcons[status] || customIcon)) {
    <i [class]="customIcon || badgeIcons[status]"></i>
  }
  {{ displayText ? text || status : null }}
  <ng-content></ng-content>
</div>
