import { Component, Input, OnInit } from '@angular/core';
import { DataTypeEnum } from '@shared/classes';
import { BaseViewItem } from '@shared/classes/view/BaseViewItem';

@Component({
  selector: 'app-gvl-item-list-view',
  templateUrl: './gvl-item-list-view.component.html',
  styleUrls: ['./gvl-item-list-view.component.scss'],
})
export class GvlItemListViewComponent extends BaseViewItem implements OnInit {
  @Input() showNumeric: boolean = false;
  @Input() showDescription: boolean = false;

  gvlDataType = DataTypeEnum.gvlItem;

  constructor() {
    super();
  }

  ngOnInit(): void {}

  checkType(item) {
    return typeof item == 'string';
  }
}
