import { Component, Input, OnInit } from '@angular/core';
import { DataTypeEnum, DynamicComponentBase } from '@shared/classes';
import { RoleDataService } from 'app/modules/users/services/data/role-data.service';

@Component({
  selector: 'app-role-selector',
  templateUrl: './role-selector.component.html',
  styleUrls: ['./role-selector.component.scss'],
})
export class RoleSelectorComponent extends DynamicComponentBase implements OnInit {
  @Input() multi: boolean = true;
  @Input() optionValue: string = 'name';
  @Input() appendTo: string = null;

  items: any[] = [];
  isLoading: boolean = false;
  dataType: DataTypeEnum = DataTypeEnum.Text;
  constructor(private service: RoleDataService) {
    super();
  }

  ngOnInit(): void {
    this.getOptions();
  }

  setInputOptions() {
    this.multi = this.inputOptions?.dropDownInput?.multi ?? this.multi;
    this.appendTo = this.inputOptions?.dropDownInput?.appendTo ?? this.appendTo;
  }
  getOptions(roleName = null) {
    this.isLoading = true;
    this.subs.sink = this.service
      .getClientRoleList({ showLoading: false, showMsg: false, params: roleName ? { roleName: roleName } : {} })
      .subscribe({
        next: (res: any) => {
          this.items = res.content.map((x) => x.name);
        },
        complete: () => {
          this.isLoading = false;
        },
      });
  }
  onFilter(event) {
    this.getOptions(event);
  }
}
