import { Component, Type } from '@angular/core';
import {
  BasePage,
  DataTypeEnum,
  IAction,
  IColumn,
  OfflineConfig,
  RequestHandlerOptions,
  SubscriptionItem,
} from '@shared/classes';
import { SubscriptionDataService } from 'app/modules/activity-feed/services/data/subscription-data.service';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { forkJoin, of } from 'rxjs';

@Component({
  selector: 'app-add-watchers-popup-form',
  templateUrl: './add-watchers-popup-form.component.html',
  styleUrls: ['./add-watchers-popup-form.component.scss'],
})
export class AddWatchersPopupFormComponent extends BasePage {
  apiOptions: RequestHandlerOptions = {
    showLoading: true,
    showMsg: true,
  };
  viewForm: Type<any>;
  // requestService:BaseRequestControllerService<any>;
  submitType: 'create' | 'update' | 'local' = 'local';
  itemId = null;
  data: any = null;
  subscribers: any = null;
  isLoading: boolean = false;
  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private requestService: SubscriptionDataService
  ) {
    super();
    this.viewForm = this.config?.data?.dynamicViewComponent;
    // this.requestService = this.config?.data?.requestService;
    this.submitType = this.config?.data?.submitType ?? this.submitType;
    this.itemId = this.config?.data?.itemId ?? this.itemId;
    // this.data = this.config?.data?.data ?? this.data;
    this.subscribers = this.config?.data?.subscribers ?? this.subscribers;

    this.setCols();
    this.setTableActions();
  }

  onSubmit(data, redirectState: 'BACK' | 'PAGE' | 'NONE' = 'BACK') {
    switch (this.submitType) {
      case 'create':
        this.requestService.create(data, this.apiOptions).subscribe((res) => {
          this.ref.close(res.data);
        });
        break;
      case 'update':
        this.changeWatchers(data);
        break;
      default:
        this.ref.close(data);
        break;
    }
  }
  changeWatchers(data) {
    let subscriptionUsers: [SubscriptionItem] = data.principle.map((user) => {
      let subscriptionUser: SubscriptionItem = {};
      // subscriptionUser.membershipType = SubscriptionItem.MembershipTypeEnum.User;
      subscriptionUser.principle = { name: user.name, principleType: user.principleType };
      subscriptionUser.type = SubscriptionItem.TypeEnum.Watching;
      return subscriptionUser;
    });

    const usersToAdd = [];
    const usersToRemove = [];
    subscriptionUsers.forEach((item) => {
      if (this.subscribers?.find((x) => x?.principle?.name == item?.principle?.name)) {
      } else {
        usersToAdd.push(item);
      }
    });
    // this.data.principle.forEach(item => {
    //     if(subscriptionUsers?.find(x=> x?.principle?.name == item?.name)){

    //     }else{
    //         let subscriptionUser: SubscriptionItem = {};
    //         subscriptionUser.principle = { name: item.name, principleType: item.principleType};
    //         subscriptionUser.type = SubscriptionItem.TypeEnum.Watching;
    //         usersToRemove.push(subscriptionUser);
    //     }
    // })
    forkJoin({
      addWatchers: usersToAdd.length > 0 ? this.requestService.addWatchers(this.itemId, usersToAdd) : of([]),
      removeWatchers:
        usersToRemove.length > 0 ? this.requestService.removeWatchers(this.itemId, usersToRemove) : of([]),
    }).subscribe((res) => {
      // this.ref.close(res);
      this.data = null;
      this.liveData = { principle: [] };
      this.isLoading = true;
      this.requestService.allSubscription(this.itemId).subscribe({
        next: (res) => {
          this.subscribers = res.data;
          this.isLoading = false;
        },
        error: (err) => {
          this.isLoading = false;
        },
      });
    });
  }
  removeWatcher(item) {
    let subscriptionUser: SubscriptionItem = {};
    subscriptionUser.principle = { name: item.data.principle.name, principleType: item.data.principle.principleType };
    subscriptionUser.type = SubscriptionItem.TypeEnum.Watching;
    this.isLoading = true;
    this.requestService.removeWatchers(this.itemId, [subscriptionUser]).subscribe({
      next: (res) => {
        (this.subscribers as any[]).splice(item.index, 1);
        this.subscribers = [...this.subscribers];
        this.isLoading = false;
      },
      error: (err) => {
        this.isLoading = false;
      },
    });
  }
  cols: IColumn[] = [];
  offlineConfig: OfflineConfig = {
    lazy: false,
    paginator: true,
    showActionBar: false,
  };
  tableActions: IAction[] = [];
  setTableActions() {
    this.tableActions = [
      {
        id: 1,
        icon: 'pi pi-trash',
        color: 'secondary',
        command: this.removeWatcher.bind(this),
        tooltipText: 'Remove Item',
        // displayCommand: (row)=> row?.type != 'DIRECT'
      },
    ];
  }
  setCols() {
    this.cols = [
      {
        name: 'Principle',
        key: 'principle.name',
        dataType: DataTypeEnum.UserListView,
        // filter: {
        //     type: "text",
        //     matchMode: "startsWith",
        // },
        // frozen: true,
        // alignFrozen: "left",
        sortDisabled: true,
      },
      // {
      //     name: "Reference Name",
      //     key: "referenceName",
      //     dataType: DataTypeEnum.Text,
      //     // filter: {
      //     //     type: "text",
      //     //     display: "menu",
      //     //     matchMode: "startsWith",
      //     //     showMatchModes: true,
      //     //     showAddButton: true,
      //     //     showOperator: true,
      //     // },
      //     sortDisabled: true,
      // },
      // {
      //     name: "Reference Value",
      //     key: "referenceValue",
      //     dataType: DataTypeEnum.CodeLink,
      //     // filter: {
      //     //     type: "text",
      //     //     display: "menu",
      //     //     matchMode: "startsWith",
      //     //     showMatchModes: true,
      //     //     showAddButton: true,
      //     //     showOperator: true,
      //     // },
      //     sortDisabled: true,
      // },
      {
        name: 'Type',
        key: 'type',
        dataType: DataTypeEnum.Badge,
        // filter: {
        //     type: "text",
        //     display: "menu",
        //     matchMode: "startsWith",
        //     showMatchModes: true,
        //     showAddButton: true,
        //     showOperator: true,
        // },
        sortDisabled: true,
      },
      // {
      //     name: "Enabled",
      //     key: "enable",
      //     dataType: DataTypeEnum.Text,
      //     // description: "Is this user enabled?",
      // },
      // {
      //     name: "System Admin",
      //     key: "sfSysAdmin",
      //     dataType: DataTypeEnum.Badge,
      //     // description: "Is this user enabled?",
      //     filter: {
      //         type: "boolean",
      //         display: "menu",
      //         matchMode: "equals",
      //     },
      // },
      // {
      //     name: "Creator Name",
      //     key: "creatorName",
      //     dataType: DataTypeEnum.UserListView,
      //     sortDisabled: true,
      // },
      {
        name: 'Date',
        key: 'date',
        dataType: DataTypeEnum.DateLong,
        sortDisabled: true,
      },
      // {
      //     name: "Last Modifier",
      //     key: "lastModifierName",
      //     dataType: DataTypeEnum.UserListView,
      //     filter: {
      //         type: "multiDropdown",
      //         matchMode: 'in',
      //         showMatchModes: false,
      //         showAddButton: false,
      //         showOperator: false,
      //         dynamicInput: {
      //             componentType: UserSelectorComponent,
      //             options: {
      //                 label: '',
      //                 name: '',
      //                 control: new FormControl(null),
      //             }
      //         }
      //     },
      // },
    ];
  }
  liveData = { principle: [] };
  onFormChange(event) {
    this.liveData = event;
  }
}
