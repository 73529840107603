import { Component, Input, OnInit } from '@angular/core';
import { DynamicComponentBase } from '@shared/classes';
import { TreeNode } from 'primeng/api';

@Component({
  selector: 'app-single-child-tree-select-input',
  templateUrl: './single-child-tree-select-input.component.html',
  styleUrls: ['./single-child-tree-select-input.component.scss'],
  providers: [{ provide: DynamicComponentBase, useExisting: SingleChildTreeSelectInputComponent }],
})
export class SingleChildTreeSelectInputComponent extends DynamicComponentBase implements OnInit {
  constructor() {
    super();
  }

  ngOnInit(): void {}
  @Input() items: TreeNode[] = [];

  // Define the selectedNodes array
  selectedNodes: TreeNode[] = [];

  // Define the onNodeSelect event handler
  onNodeSelect(event: any) {
    const selectedNode = event.node;
    const parentNode = selectedNode.parent;

    // Check if the selected node is a child of a parent node
    if (parentNode) {
      // Check if there is already a selected child node
      const selectedChildNode = parentNode.children.find(
        (childNode) => childNode !== selectedNode && this.selectedNodes.includes(childNode)
      );
      if (selectedChildNode) {
        // Deselect the previously selected child node
        setTimeout(() => {
          this.selectedNodes = this.selectedNodes.filter((node) => node !== selectedChildNode);
        }, 1);
        // Select the new child node
        //   this.selectedNodes.push(selectedNode);
      } else {
        // Select the new child node
        // this.selectedNodes.push(selectedNode);
      }
    }
  }
  onModelChange(value) {
    this.fControl.patchValue(value);
  }
  // Define the onNodeUnSelect event handler
  onNodeUnselect(event: any) {
    // const selectedNode = event.node;
    // this.selectedNodes = this.selectedNodes.filter(node => node !== selectedNode);
  }

  setInputOptions(): void {
    this.items = this.inputOptions?.dropDownInput?.items ?? this.items;
  }
}
