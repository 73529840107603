import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { JWTTokenService } from '@core/services/JWT-token/jwttoken.service';
import {
  BadgeIcon,
  DataTypeEnum,
  DynamicComponentBase,
  EntityTypeFieldDto,
  FilterItem,
  IViewMode,
  ModuleIcons,
  ModuleKeywords,
  PermissionActions,
  toPascalCase,
} from '@shared/classes';
import { EntityDataService } from 'app/modules/entity-module/entity/entity-data.service';
import { AssetsDataService } from 'app/modules/entity-module/services/data/assets-data.service';
import { EntityTypesDataService } from 'app/modules/entity-module/services/data/entity-types-data.service';
import { EventsDataService } from 'app/modules/entity-module/services/data/events-data.service';
import { GeographiesDataService } from 'app/modules/entity-module/services/data/geographies-data.service';
import { ObjectivesDataService } from 'app/modules/entity-module/services/data/objectives-data.service';
import { OrganizationsDataService } from 'app/modules/entity-module/services/data/organizations-data.service';
import { ProcessesDataService } from 'app/modules/entity-module/services/data/processes-data.service';
import { ResponsibilitiesDataService } from 'app/modules/entity-module/services/data/responsibilities-data.service';
import { groupBy, uniqBy } from 'lodash-es';
import { NgxPermissionsService } from 'ngx-permissions';
import { TreeNode } from 'primeng/api';
import { forkJoin, of } from 'rxjs';

@Component({
  selector: 'app-entity-category-tree-selector',
  templateUrl: './entity-category-tree-selector.component.html',
  styleUrls: ['./entity-category-tree-selector.component.scss'],
})
export class EntityCategoryTreeSelectorComponent extends DynamicComponentBase implements OnInit {
  badgeIcons = BadgeIcon;
  @Input() showClear = true;
  @Input() multi = false;
  @Input() items: any[] = [];
  @Input() returnObject: boolean = false;
  @Input() useResponsibilityMode: boolean = false;
  @Input() excludeId: string = null;
  @Input() dataKey: string = 'code';
  @Input() customProjectionFields: string[] = [
    'id',
    'recordStatus',
    'code',
    'name',
    'owner',
    'type',
    'parent',
    'members',
    'category',
    'icon',
    'groupName',
    'responsibility',
    'entityTypeInfo',
  ];
  @Input() categoryList: EntityTypeFieldDto.CategoryEnum[] = [];
  @Input() appendTo: string = null;

  @Input() dropDown: boolean = true;
  optionsLoaded: boolean = false;
  entityTypeMap: { [x: string]: EntityTypeFieldDto };
  @Input() set selectedEntities(entities: String[] | any[]) {
    this.setSelectedItems(entities);
  }

  @Input() set extraFilters(filters: FilterItem[]) {
    this._extraFilters = filters;
    this.getOptions();
  }
  private _extraFilters: FilterItem[] = [];
  get extraFilters() {
    return this._extraFilters;
  }

  isLoading: boolean = false;

  @Input()
  tempMode: boolean = false;

  selectedNodes: any = null;
  groupedEntities: { [index: string]: any[] };
  groupedEntities1: { [index: string]: any[] };
  formattedEntities: TreeNode[];

  visited: any = {};
  treeNodes: any = {};
  responsibilityModeOptions = [
    { label: '', value: 'ONE', icon: 'pi pi-user', tooltip: 'One Person' },
    { label: '', value: 'ALL', icon: 'pi pi-users', tooltip: 'All People' },
  ];
  editModalVisible: boolean = false;
  editModalControl: FormControl = new FormControl(null);
  @Output() onChangesObject: EventEmitter<any> = new EventEmitter();
  currentUsername: string;
  constructor(
    private service: EntityDataService,
    private respService: ResponsibilitiesDataService,
    private eventService: EventsDataService,
    private assetService: AssetsDataService,
    private geoService: GeographiesDataService,
    private proService: ProcessesDataService,
    private objService: ObjectivesDataService,
    private orgService: OrganizationsDataService,
    private entityTypeService: EntityTypesDataService,
    private permissionService: NgxPermissionsService,
    private jwtService: JWTTokenService
  ) {
    super();
    this.dataType = DataTypeEnum.CodeLink;
  }

  ngOnInit() {
    this.currentUsername = this.jwtService.getPreferredUsername();
    if (this.viewMode != 'view') this.getOptions();

    if (this.control) {
      this.subs.sink = this.fControl.valueChanges.subscribe((changes) => {
        if (this.tempMode) {
          if (this.multi) {
            this.selectedNodes = [...changes];
          } else {
            this.selectedNodes = changes;
          }
        }

        if (changes === null) {
          this.selectedEntities = [];
        }
        this.onChanges.emit(changes);
        this.onChangesObject.emit(this.selectedNodes?.data);
        this.editModalControl.patchValue(
          this.returnObject != true
            ? this.control?.getRawValue()
            : this.control?.getRawValue()?.['label'] || this.control?.getRawValue()?.['code']
        );
      });
    }
  }
  // onSetViewMode(){
  //     if(this.viewMode == 'edit')
  //         this.getOptions();
  // }
  setSelectedItems(list: any[]) {
    let newSelections = null;
    Object.keys(this.treeNodes).forEach((nodeId) => {
      let nodeIdx = this.checkIfIncluded(nodeId, list);
      if (nodeIdx != -1) {
        if (this.treeNodes[nodeId]?.data && this.useResponsibilityMode)
          this.treeNodes[nodeId].data.mode = list[nodeIdx].mode;

        if (this.multi) {
          if (!newSelections) newSelections = [];
          newSelections.push(this.treeNodes[nodeId]);
        } else {
          newSelections = this.treeNodes[nodeId];
        }
      }
    });
    this.selectedNodes = newSelections;
  }
  getOptions(filters: any = []) {
    this.isLoading = true;
    this.subs.sink = forkJoin({
      [EntityTypeFieldDto.CategoryEnum.Asset]: this.permissionService.hasPermission(
        PermissionActions.Read + ModuleKeywords.Asset
      ),
      [EntityTypeFieldDto.CategoryEnum.Event]: this.permissionService.hasPermission(
        PermissionActions.Read + ModuleKeywords.Event
      ),
      [EntityTypeFieldDto.CategoryEnum.Geography]: this.permissionService.hasPermission(
        PermissionActions.Read + ModuleKeywords.Geography
      ),
      [EntityTypeFieldDto.CategoryEnum.Objective]: this.permissionService.hasPermission(
        PermissionActions.Read + ModuleKeywords.Objective
      ),
      [EntityTypeFieldDto.CategoryEnum.Process]: this.permissionService.hasPermission(
        PermissionActions.Read + ModuleKeywords.Process
      ),
      [EntityTypeFieldDto.CategoryEnum.Responsibility]: this.permissionService.hasPermission(
        PermissionActions.Read + ModuleKeywords.Responsibility
      ),
      [EntityTypeFieldDto.CategoryEnum.Organization]: this.permissionService.hasPermission(
        PermissionActions.Read + ModuleKeywords.Organization
      ),
    }).subscribe((perms) => {
      this.subs.sink = forkJoin({
        // entities : this.service.search<any>(
        //     { all: true },
        //     { projectionFields: this.customProjectionFields, filters: [
        //         // { "property": "category", "operation": "in", "value": this.categoryList }
        //     ] },
        //     { showLoading: false, showMsg: false }
        // ),
        [EntityTypeFieldDto.CategoryEnum.Asset]:
          perms[EntityTypeFieldDto.CategoryEnum.Asset] &&
          this.categoryList.find((x) => x == EntityTypeFieldDto.CategoryEnum.Asset)
            ? this.assetService.search(
                { all: true },
                {
                  projectionFields: this.customProjectionFields,
                  filters: [...this.extraFilters],
                },
                { showLoading: false, showMsg: false }
              )
            : of([]),
        [EntityTypeFieldDto.CategoryEnum.Event]:
          perms[EntityTypeFieldDto.CategoryEnum.Event] &&
          this.categoryList.find((x) => x == EntityTypeFieldDto.CategoryEnum.Event)
            ? this.eventService.search(
                { all: true },
                {
                  projectionFields: this.customProjectionFields,
                  filters: [...this.extraFilters],
                },
                { showLoading: false, showMsg: false }
              )
            : of([]),
        [EntityTypeFieldDto.CategoryEnum.Geography]:
          perms[EntityTypeFieldDto.CategoryEnum.Geography] &&
          this.categoryList.find((x) => x == EntityTypeFieldDto.CategoryEnum.Geography)
            ? this.geoService.search(
                { all: true },
                {
                  projectionFields: this.customProjectionFields,
                  filters: [...this.extraFilters],
                },
                { showLoading: false, showMsg: false }
              )
            : of([]),
        [EntityTypeFieldDto.CategoryEnum.Objective]:
          perms[EntityTypeFieldDto.CategoryEnum.Objective] &&
          this.categoryList.find((x) => x == EntityTypeFieldDto.CategoryEnum.Objective)
            ? this.objService.search(
                { all: true },
                {
                  projectionFields: this.customProjectionFields,
                  filters: [...this.extraFilters],
                },
                { showLoading: false, showMsg: false }
              )
            : of([]),
        [EntityTypeFieldDto.CategoryEnum.Process]:
          perms[EntityTypeFieldDto.CategoryEnum.Process] &&
          this.categoryList.find((x) => x == EntityTypeFieldDto.CategoryEnum.Process)
            ? this.proService.search(
                { all: true },
                {
                  projectionFields: this.customProjectionFields,
                  filters: [...this.extraFilters],
                },
                { showLoading: false, showMsg: false }
              )
            : of([]),
        [EntityTypeFieldDto.CategoryEnum.Responsibility]:
          perms[EntityTypeFieldDto.CategoryEnum.Responsibility] &&
          this.categoryList.find((x) => x == EntityTypeFieldDto.CategoryEnum.Responsibility)
            ? this.respService.search(
                { all: true },
                {
                  projectionFields: this.customProjectionFields,
                  filters: [...this.extraFilters],
                },
                { showLoading: false, showMsg: false }
              )
            : of([]),
        [EntityTypeFieldDto.CategoryEnum.Organization]:
          perms[EntityTypeFieldDto.CategoryEnum.Organization] &&
          this.categoryList.find((x) => x == EntityTypeFieldDto.CategoryEnum.Organization)
            ? this.orgService.search(
                { all: true },
                {
                  projectionFields: this.customProjectionFields,
                  filters: [...this.extraFilters],
                },
                { showLoading: false, showMsg: false }
              )
            : of([]),
        // types:this.entityTypeService.search<EntityTypeFieldDto[]>(
        //     { all: true },
        //     { projectionFields: this.customProjectionFields, filters: [...this.extraFilters, { "property": "category", "operation": "in", "value": this.categoryList }] },
        //     { showLoading: false, showMsg: false }
        // )
      }).subscribe({
        next: (data) => {
          this.groupedEntities1 = {};
          let list = [];
          this.categoryList.forEach((element) => {
            this.groupedEntities1[element] = data[element] as any[];
            list = list.concat(data[element] as any[]);
          });
          // list.forEach(element => {
          //     element.category='RESPONSIBILITIES';
          // });
          this.entityTypeMap = {};
          // (data.types as EntityTypeFieldDto[]).forEach(element => {
          //     this.entityTypeMap[element.code] = element;
          // });
          this.formattedEntities = [];

          // this.groupedEntities1 = groupBy(list, 'category');

          this.selectedNodes = null;

          let i = 0;
          Object.keys(this.groupedEntities1).forEach((category: EntityTypeFieldDto.CategoryEnum, index) => {
            if (this.categoryList.includes(category)) {
              this.formattedEntities.push({
                label: toPascalCase(category),
                expandedIcon: ModuleIcons[category],
                collapsedIcon: ModuleIcons[category],
                children: [],
                expanded: true,
                selectable: false,
              });

              this.groupedEntities = groupBy(this.groupedEntities1[category], 'parent');

              this.visited = {};
              this.formatDataForTreeView(list, i);
              this.items = uniqBy(list, 'code');
              i++;
            }
          });
        },
        complete: () => {
          this.isLoading = false;
        },
      });
    });
  }
  setInputOptions() {
    this.multi = this.inputOptions?.dropDownInput?.multi ?? this.multi;
    this.items = this.inputOptions?.dropDownInput?.items ?? this.items;
    this.dropDown = this.inputOptions?.dropDownInput?.treeDropDown ?? this.dropDown;
    this.appendTo = this.inputOptions?.dropDownInput?.appendTo ?? this.appendTo;
    this.showClear = this.inputOptions?.dropDownInput?.showClear ?? this.showClear;
    this.categoryList = this.inputOptions?.extra?.categoryList ?? this.categoryList;
  }

  onValueChanges(value: any) {
    // this.data = value;

    if (this.multi) {
      if (this.returnObject) {
        let values: any[] = value?.map((item) => item?.data);
        this.fControl.patchValue(values);
      } else if (this.useResponsibilityMode) {
        let values: any[] = [];
        value?.forEach((item) => {
          if (item?.data?.code) {
            item.data.mode = item?.data?.mode || 'ONE';
            values.push({ code: item?.data?.code, mode: item?.data?.mode });
          }
        });
        // let values: ResponsibilityItemFieldDto[] = value?.map(item => {return {code:item?.data?.code,mode:item?.data?.mode}});
        this.fControl.patchValue(values);
      } else {
        let values: string[] = value?.map((item) => item?.data[this.dataKey]);
        this.fControl.patchValue(values);
      }
    } else {
      if (this.returnObject) {
        this.fControl.patchValue(value?.data);
      } else if (this.useResponsibilityMode) {
        let val = null;
        if (value?.data?.code) {
          value.data.mode = value?.data?.mode || 'ONE';
          val = { code: value?.data?.code, mode: value?.data?.mode };
        }
        this.fControl.patchValue(val);
      } else {
        this.fControl.patchValue(value?.data[this.dataKey]);
      }
    }
  }
  onNodeUnselect(event) {
    event.node.data.mode = undefined;
  }
  formatDataForTreeView(data: any[], index) {
    this.formatDataForTreeViewRecursive(data, index);
  }
  formatDataForTreeViewRecursive(data: any[], index1) {
    this.formattedEntities[index1].data = data;
    this.formattedEntities[index1].type = 'root';

    this.groupedEntities['null']?.forEach((el, index) => {
      if (!!!this.visited[el[this.dataKey]]) {
        this.visited[el[this.dataKey]] = true;
        if (!(this.excludeId && el[this.dataKey] == this.excludeId))
          this.formattedEntities[index1].children.push(
            this.getFormattedChildren(el[this.dataKey], el, this.formattedEntities[index1].expandedIcon)
          );
      }
    });
  }
  getFormattedChildren(code, data: any, parentIcon): TreeNode {
    const currentUsername = this.currentUsername;
    let node: TreeNode = {
      label: data.name,
      data: {
        ...data,
        isMember:
          data?.code?.startsWith('RES') &&
          (data?.members ? (data?.members as string[]).includes(currentUsername) : false),
      },
      expandedIcon: data?.entityTypeInfo?.icon ?? parentIcon,
      collapsedIcon: data?.entityTypeInfo?.icon ?? parentIcon,
      icon: data?.entityTypeInfo?.icon ?? parentIcon,
      children: [],
      expanded: true,
      selectable: this.viewMode != 'view',
      key: data[this.dataKey],
    };
    this.treeNodes[code] = node;
    if (this.checkIfIncludedInValue(code)) {
      if (this.useResponsibilityMode) {
        let val = this.getMatchingValue(code);
        node.data.mode = val.mode;
        this.treeNodes[code] = node;
      }
      if (this.multi) {
        if (!this.selectedNodes) this.selectedNodes = [];
        this.selectedNodes.push(node);
      } else {
        this.selectedNodes = node;
      }
    }
    if (!!this.groupedEntities[code]) {
      this.groupedEntities[code].forEach((el, index) => {
        if (!!!this.visited[el[this.dataKey]]) {
          this.visited[el[this.dataKey]] = true;
          let ret = this.getFormattedChildren(el[this.dataKey], el, node.icon);
          if (!(this.excludeId && el[this.dataKey] == this.excludeId)) node.children.push(ret);
        } else {
        }
      });
    } else {
      return node;
    }
    return node;
  }
  checkIfIncludedInValue(code) {
    const v = this.fControl?.getRawValue();

    if (!this.fControl?.getRawValue()) return false;
    if (!this.multi)
      return this.returnObject || this.useResponsibilityMode
        ? (this.fControl.getRawValue()[this.dataKey] as String) == code
        : (this.fControl.getRawValue() as String) == code;
    return (
      (this.fControl?.getRawValue() as String[])?.findIndex((x) =>
        this.returnObject || this.useResponsibilityMode ? x[this.dataKey] == code : x == code
      ) != -1
    );
  }
  getMatchingValue(code) {
    if (this.multi) {
      return (this.fControl?.getRawValue() as String[])?.find((x) =>
        this.returnObject || this.useResponsibilityMode ? x[this.dataKey] == code : x == code
      );
    } else {
      return this.fControl.getRawValue();
    }
  }
  // checkIfIncludedInSelectedNodes(code) {
  //     if (!this.selectedNodes) return false;
  //     return ((this.selectedNodes)?.findIndex(x => x.data[this.dataKey] == code) != -1)
  // }
  checkIfIncluded(code, data: any[]) {
    if (!data) return -1;
    return data?.findIndex((x) =>
      this.returnObject
        ? (x[this.dataKey] as String) == code
        : this.useResponsibilityMode
          ? (x.code as String) == code
          : (x as String) == code
    );
  }
  onChangeRespMode(mode: { originalEvent?: any; value?: IViewMode }, node) {
    mode?.originalEvent?.stopPropagation();
    if (this.multi) {
      let values = [...this.fControl.getRawValue()];
      let ind = values?.findIndex((x) => x?.code === node?.data?.code);
      if (ind != -1) {
        values[ind] = { code: values[ind].code, mode: mode?.value };
        this.fControl.patchValue(values);
      }
    } else {
      let val = { ...this.fControl.getRawValue() };
      if (val.code === node?.data?.code) {
        val = { ...val, mode: mode?.value };
        this.fControl.patchValue(val);
      }
    }
  }
  onOptionClick(event: { originalEvent?: any; option?: any; index?: any }) {
    event?.originalEvent?.stopPropagation();
  }
  openEditModal() {
    this.editModalControl.patchValue(
      this.returnObject != true
        ? this.control?.getRawValue()
        : this.control?.getRawValue()?.['label'] || this.control?.getRawValue()?.['code']
    );
    this.editModalVisible = true;
  }
  onFocusChange(event) {
    this.openEditModal();
  }
}
