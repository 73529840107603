import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { environment } from '@env/environment';
import { BaseForm, DataTypeEnum, IAction } from '@shared/classes';
import { ViewModeService } from '@shared/services/view-mode.service';
import { FilesDataService } from 'app/modules/file-manager-module/services/files-data.service';
import { FileUpload } from 'primeng/fileupload';

@Component({
  selector: 'app-import-package-form',
  templateUrl: './import-package-form.component.html',
  styleUrls: ['./import-package-form.component.scss'],
})
export class ImportPackageFormComponent extends BaseForm<any> implements OnInit {
  @ViewChild('fileBrowser', { static: false }) fileBrowser: FileUpload;

  dataTypeEnum = DataTypeEnum;

  showCancelFile = false;
  cancelFileSelectionAction: IAction = {
    id: 2,
    label: 'Cancel',
    buttonType: 'button',
    command: this.clearFile.bind(this),
    icon: 'pi pi-times',
  };
  constructor(
    public viewModeService: ViewModeService,
    private fileService: FilesDataService
  ) {
    super(viewModeService);
  }

  ngOnInit(): void {}

  getData() {
    // return this.viewModeService.viewMode == 'create' ? this.getDataKeyValueFormat() : this.getChangedFormValues().updateItems
    return { ...this.formGroup.getRawValue() };
  }

  setData(data: any) {
    this.formGroup.patchValue({ ...data });
    this.data = data;
  }

  initFormStructure(): void {
    this.formGroup = new FormGroup({
      documentUrl: new FormControl(null, Validators.required),
    });
  }
  onTargetTypeChange(targetType) {}
  onBasicUpload(event) {
    for (let file of event.files) {
      this.fileService.uploadFile(file, { description: file.name, versionName: file.name }).subscribe((res) => {
        this.formGroup.patchValue({ documentUrl: `${environment.websiteUrl}/api/do/document/download/${res.fileId}` });
        this.clearFile(this.fileBrowser);
      });
    }
  }
  onSelectFile() {
    this.showCancelFile = true;
  }
  clearFile(fileBrowser) {
    fileBrowser?.clear();
    this.showCancelFile = false;
  }
}
