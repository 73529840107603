import { Component, ContentChild, Input, OnInit, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { ContentViewDto, UnsubscribeOnDestroyAdapter } from '@shared/classes';
import { AppDialogService, PathResolverService, ToastService } from '@shared/services';

@Component({
  selector: 'app-widget-custom',
  templateUrl: './widget-custom.component.html',
  styleUrl: './widget-custom.component.scss',
})
export class WidgetCustomComponent extends UnsubscribeOnDestroyAdapter implements OnInit {
  @ContentChild('headerTemplate') headerTemplate: TemplateRef<any>;
  @Input() styleClass: string = 'relative card m-0';
  @Input() title: string = 'some title';
  @Input() isLoading: boolean = true;
  private _cardContent: ContentViewDto = null;
  @Input() set cardContent(cardContent: ContentViewDto) {
    this._cardContent = cardContent;
    this.initData();
  }
  get cardContent() {
    return this._cardContent;
  }
  constructor(
    public appDialogService: AppDialogService,
    public router: Router,
    private pathResolverService: PathResolverService,
    private toastService: ToastService
  ) {
    super();
  }
  ngOnInit(): void {}
  initData() {}
}
