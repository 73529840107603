import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import {
  AppPermissions,
  Base,
  DataTypeEnum,
  EntityTypeFieldDto,
  getEnumOptions,
  ITablePageable,
  IViewMode,
  SearchBody,
} from '@shared/classes';
import { AppDialogService } from '@shared/services/app-dialog.service';
import { UserDataService } from 'app/modules/users/services/data/user-data.service';
import { uniqBy } from 'lodash-es';
import { NgxPermissionsService } from 'ngx-permissions';
import { PickList } from 'primeng/picklist';
import { debounceTime, distinctUntilChanged, forkJoin, takeUntil } from 'rxjs';
import { EntityFilterPopupComponent } from '../risk-entities-selector/entity-filter-popup/entity-filter-popup.component';

@Component({
  selector: 'app-user-picklist-selector',
  templateUrl: './user-picklist-selector.component.html',
  styleUrls: ['./user-picklist-selector.component.scss'],
})
export class UserPicklistSelectorComponent extends Base implements OnInit {
  customProjectionFields: string[] = ['id', 'name', 'username', 'owner', 'recordStatus'];
  pageInfo: ITablePageable = new ITablePageable();

  loading: boolean = false;
  originalElements: any[] = [];
  sourceElements: any[] = [];

  @Input()
  targetElements: any[] = [];

  @ViewChild('pickList', { static: false }) pickList: PickList;

  @Output()
  resultlements: EventEmitter<any[]> = new EventEmitter();
  @Output()
  initialSetElements: EventEmitter<any[]> = new EventEmitter();
  @Input() viewMode?: IViewMode = IViewMode.edit;
  @Input() showCategoriesSelector?: boolean = false;
  @Input() categoryList: EntityTypeFieldDto.CategoryEnum[] = [];
  @Input() showTargetFilter?: boolean = false;
  dataType: DataTypeEnum = DataTypeEnum.Text;
  sourceFilterValue = '';
  appliedSearchBody: SearchBody = {
    projectionFields: ['id', 'name', 'username', 'owner', 'recordStatus'],
    filters: [{ property: 'category', operation: 'IN', value: this.categoryList }],
  };
  listOfCategories = getEnumOptions(EntityTypeFieldDto.CategoryEnum);
  categoriesControl = new FormControl([]);
  constructor(
    private service: UserDataService,
    private _dialogService: AppDialogService,
    private permissionService: NgxPermissionsService
  ) {
    super();
  }

  ngOnInit(): void {
    if (!this.targetElements) this.targetElements = [];
    if (!this.sourceElements) this.sourceElements = [];
    this.appliedSearchBody = {
      projectionFields: ['id', 'name', 'username', 'owner', 'recordStatus'],
      filters: [{ property: 'category', operation: 'IN', value: this.categoryList }],
    };
    // this.fetchData();
    this.categoriesControl.valueChanges
      .pipe(debounceTime(500), distinctUntilChanged(), takeUntil(this.destroy$))
      .subscribe((data) => {
        this.categoryList = data;
        this.fetchData();
      });
    this.categoriesControl.patchValue(this.categoryList);
  }
  fetchData() {
    this.permissionService.hasPermission(AppPermissions.ReadUser).then((isAllowed) => {
      if (isAllowed) {
        this.loading = true;
        forkJoin({
          users: this.service
            .getList
            // { showLoading: false, showMsg: false }
            (),
        })
          .pipe(takeUntil(this.destroy$))
          .subscribe({
            next: (data) => {
              this.loading = false;
              let list = [];
              list = data?.users as any[];

              this.originalElements = [...list];
              this.sourceElements = [...list];

              if (this.targetElements && this.targetElements.length > 0) {
                this.targetElements = this.sourceElements.filter(
                  (item) =>
                    !!this.targetElements.find(
                      (ell) =>
                        (ell?.username != undefined && item?.username != undefined && ell.username == item.username) ||
                        ell.id == item.id
                    )
                );
                this.sourceElements = this.sourceElements.filter(
                  (item) =>
                    !this.targetElements.find(
                      (ell) =>
                        (ell?.username != undefined && item?.username != undefined && ell.username == item.username) ||
                        ell.id == item.id
                    )
                );
              }

              this.initialSetElements.emit(this.targetElements);
              this.resultlements.emit(this.targetElements);
            },
            error: (err) => {
              this.loading = false;
            },
          });
      }
    });
  }
  onChange() {
    this.resultlements.emit(this.targetElements);
  }

  onCreateReq(): void {
    this._dialogService.showDialog(
      EntityFilterPopupComponent,
      'Apply Filter',
      (e) => {
        this._addRecord(e);
        this.resetPickListFilter();
      },
      {
        data: {
          categoryList: this.categoryList,
        },
      }
    );
    // this._dialogService.open({
    //     title: 'Apply Filter',
    //     dialogType: DialogTypeEnum.form,
    //     componentType: EntityFilterPopup,
    //     submitCallBack: (data: any) => {
    //         this._addRecord(data);
    //     }
    // });
  }

  private _addRecord(entity): void {
    if (entity && entity?.entities?.length) {
      this.targetElements = this.originalElements?.filter(
        (el) => !!entity?.entities.find((el2) => el2 == el?.username)
      );

      this.sourceElements = this.originalElements?.filter((el) => !entity?.entities.find((el2) => el2 == el?.username));

      // this.targetElements.push(...entity?.entities)

      this.sourceElements = uniqBy(this.sourceElements, 'username');
      this.targetElements = uniqBy(this.targetElements, 'username');

      this.resultlements.emit(this.targetElements);
    }

    // this._dialogService.ref.close();
  }
  setCategories(cats) {
    // this.categoryList = cats;
    // this.fetchData();
  }

  resetPickListFilter() {
    if (this.pickList) {
      this.pickList.filterValueSource = '';

      this.sourceFilterValue = ''; // Set sourceFilterValue to an empty string
    }
  }
}
