import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-avatar-info-card-item',
  templateUrl: './avatar-info-card-item.component.html',
  styleUrls: ['./avatar-info-card-item.component.scss'],
})
export class AvatarInfoCardItemComponent implements OnInit {
  @Input() user: string;
  @Input() date: string | Date;
  constructor() {}

  ngOnInit(): void {}
}
