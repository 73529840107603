<div class="flex flex-row md:flex-nowrap flex-wrap gap-1 w-full algin-items-start">
  @if (floatLabel) {
    @if (label && !instanceInFormRepeater) {
      <label [for]="name || label" class="md:w-12rem md:max-w-12rem md:min-w-12rem w-full input-field-label"
        >{{ label }}
        @if (isControlRequired) {
          <span class="text-red-500"> *</span>
        }
        @if (resetFieldCheckVisable) {
          <br />
          <app-reset-switch-input
            [checked]="!resetable"
            (onChange)="setFieldResetable($event)"
          ></app-reset-switch-input>
        }
      </label>
    }
  }
  @if (!floatLabel) {
    @if (label && !instanceInFormRepeater) {
      <label [for]="name || label" class="md:w-12rem md:max-w-12rem md:min-w-12rem w-full input-field-label"
        >{{ label }}
        @if (isControlRequired) {
          <span class="text-red-500"> *</span>
        }
        @if (resetFieldCheckVisable) {
          <br />
          <app-reset-switch-input
            [checked]="!resetable"
            (onChange)="setFieldResetable($event)"
          ></app-reset-switch-input>
        }
      </label>
    }
  }
  <div
    class="w-full overflow-auto"
    [class.border-1]="control?.invalid && control?.dirty"
    [class.border-round]="control?.invalid && control?.dirty"
    [class.border-red-500]="control?.invalid && control?.dirty"
    [class.p-1]="control?.invalid && control?.dirty"
  >
    @if (title) {
      <h5>{{ title }}</h5>
    }
    @if (viewMode != 'view' && showAddForm && !showAddFormAsPopup) {
      <form [formGroup]="baseFormGroup" (ngSubmit)="onAddForm($event)" pFocusTrap>
        <div class="flex flex-col align-items-end justify-content-start gap-2">
          <div class="formgrid grid flex-1 p-fluid">
            @for (field of cols; track field) {
              @if (!field.options.hideInFormRepeater) {
                <div class="min-w-18rem" [class]="cols.length > 5 ? 'col-3' : 'col'">
                  <ng-template
                    appDynamicComponent
                    [mode]="viewMode"
                    [dynamicComponentInfo]="field"
                    [liveData]="control?.value"
                  ></ng-template>
                </div>
              }
            }
          </div>
          <div class="flex flex-none mb-3">
            <app-button #submitButton [action]="submitButtonAction"></app-button>
          </div>
        </div>
      </form>
    }
    @if (viewMode != 'view' && showAddForm && showAddFormAsPopup) {
      <div class="w-full flex flex-row justify-content-end">
        <app-button [action]="showAddFormPopupAction"></app-button>
      </div>
    }
    <p-table
      [value]="tableItems"
      [loading]="loading"
      dataKey="id"
      editMode="row"
      [tableStyle]="tableStyle ?? { 'min-width': '30rem' }"
      (onRowReorder)="updateControl()"
      [selectionMode]="selectionMode"
      [(selection)]="selectedItems"
      (onRowSelect)="onRowSelect($event)"
      (onRowUnselect)="onRowUnselect($event)"
      (selectionChange)="onSelectionChange($event)"
      [paginator]="offlineConfig?.paginator"
      [rows]="pageInfo?.pagination.size"
      [totalRecords]="pageInfo?.totalElements"
      [rowsPerPageOptions]="rowsPerPageOptions"
      (onFilter)="onFiltersChanged($event)"
      [lazy]="offlineConfig?.lazy"
      [lazyLoadOnInit]="lazyLoadOnInit"
      (onLazyLoad)="onLazyLoad($event)"
      [paginatorDropdownAppendTo]="'body'"
      #myTable
      [rowHover]="false"
    >
      <ng-template pTemplate="header">
        <tr class="flex mb-3">
          @if (viewMode != 'view' && showReorder) {
            <th style="width: 2rem"></th>
          }
          @if (selectionMode == 'multiple' && showSelection) {
            <th>
              <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
            </th>
          }
          @for (col of cols; track col) {
            @if (!col.options.hideInFormRepeater) {
              @if (!!!col?.options?.labelList) {
                <th class="flex-1 flex align-items-start justify-content-start" [style]="headerStyle ?? cellsStyle">
                  {{ col.options.label }}
                </th>
              }
              @if (!!col?.options?.labelList) {
                @for (label of col.options.labelList; track label; let last = $last) {
                  <th
                    class="flex-1 pr-0 flex align-items-start justify-content-start"
                    [class.pr-0]="!last"
                    [style]="headerStyle ?? cellsStyle"
                  >
                    {{ label }}
                  </th>
                }
              }
            }
          }
          @if (viewMode != 'view' && !flyMode) {
            @if (floatActions) {
              <th
                pFrozenColumn
                [frozen]="true"
                [alignFrozen]="'right'"
                [class]="actionHeaderClass ?? ' flex-none flex align-items-center justify-content-center'"
                style="min-width: 6.2rem; left: auto; position: sticky; background: inherit; z-index: 1"
              >
                Actions
              </th>
            } @else {
              <th
                [class]="actionHeaderClass ?? 'w-2 flex-none flex align-items-center justify-content-center'"
                style="min-width: 6.2rem"
              >
                Actions
              </th>
            }
          }
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-product let-editing="editing" let-ri="rowIndex">
        <tr [pEditableRow]="product" class="flex" [pReorderableRow]="ri" pFocusTrap>
          @if (viewMode != 'view' && showReorder) {
            <td>
              <span class="pi pi-bars" pReorderableRowHandle></span>
            </td>
          }
          @if (selectionMode == 'multiple' && showSelection) {
            <td>
              <p-tableCheckbox [value]="product"></p-tableCheckbox>
            </td>
          }

          @for (field of product.fields; track field) {
            @if (!field.options.hideInFormRepeater) {
              <td class="flex-1 flex align-items-start justify-content-start" [style]="cellsStyle">
                <p-cellEditor class="w-full">
                  <ng-template pTemplate="input">
                    <ng-template
                      appDynamicComponent
                      [mode]="field.viewModeOnly ? 'view' : viewMode"
                      [dynamicComponentInfo]="field"
                    ></ng-template>
                  </ng-template>
                  <ng-template pTemplate="output">
                    <ng-template appDynamicComponent [mode]="'view'" [dynamicComponentInfo]="field"></ng-template>
                  </ng-template>
                </p-cellEditor>
              </td>
            }
          }

          @if (!flyMode && (viewMode == 'edit' || viewMode == 'create')) {
            @if (floatActions) {
              <td
                pFrozenColumn
                [frozen]="true"
                [alignFrozen]="'right'"
                [class]="actionBodyClass ?? 'auto-left flex-none flex align-items-start justify-content-center'"
                style="min-width: 6.2rem; position: sticky; background: inherit; z-index: 1"
              >
                <div class="flex align-items-start justify-content-center gap-2" style="min-width: 78px">
                  @if (showOptionsAction && !editing) {
                    <app-button [action]="configDialogAction" [commandData]="{ item: product, index: ri }"></app-button>
                  }
                  @if (!editing && showEditButton) {
                    <app-button [action]="tableActions[0]" [commandData]="product" pInitEditableRow></app-button>
                  }
                  @if (!editing && showDeleteButton) {
                    <app-button [action]="tableActions[1]" [commandData]="ri"></app-button>
                  }
                  @for (action of extraActions; track action) {
                    @if (!editing) {
                      <app-button [action]="action" [commandData]="{ item: product, index: ri }"></app-button>
                    }
                  }
                  <!-- <button *ngIf="!editing" pButton pRipple type="button" pInitEditableRow icon="pi pi-pencil" (click)="onRowEditInit(product)" class="p-button-rounded p-button-text"></button> -->
                  @if (editing && product?.form?.valid) {
                    <button
                      pButton
                      pRipple
                      type="button"
                      pSaveEditableRow
                      icon="pi pi-check"
                      (click)="onRowEditSave(product, $event)"
                      class="p-button-rounded p-button-text p-button-success mr-2"
                    ></button>
                  }
                  @if (editing && !product?.form?.valid) {
                    <button
                      pButton
                      pRipple
                      type="button"
                      icon="pi pi-exclamation-triangle"
                      (click)="onRowEditSave(product, $event)"
                      pTooltip="Invalid Form Data"
                      tooltipPosition="top"
                      class="p-button-rounded p-button-text p-button-danger mr-2"
                    ></button>
                  }
                  @if (editing) {
                    <button
                      pButton
                      pRipple
                      type="button"
                      pCancelEditableRow
                      icon="pi pi-times"
                      (click)="onRowEditCancel(product, ri)"
                      class="p-button-rounded p-button-text p-button-danger"
                    ></button>
                  }
                </div>
              </td>
            } @else {
              <td [class]="actionBodyClass ?? 'w-2 flex-none flex align-items-start justify-content-center'">
                <div class="flex align-items-start justify-content-center gap-2" style="min-width: 78px">
                  @if (showOptionsAction && !editing) {
                    <app-button [action]="configDialogAction" [commandData]="{ item: product, index: ri }"></app-button>
                  }
                  @if (!editing && showEditButton) {
                    <app-button [action]="tableActions[0]" [commandData]="product" pInitEditableRow></app-button>
                  }
                  @if (!editing && showDeleteButton) {
                    <app-button [action]="tableActions[1]" [commandData]="ri"></app-button>
                  }
                  @for (action of extraActions; track action) {
                    @if (!editing) {
                      <app-button [action]="action" [commandData]="{ item: product, index: ri }"></app-button>
                    }
                  }
                  <!-- <button *ngIf="!editing" pButton pRipple type="button" pInitEditableRow icon="pi pi-pencil" (click)="onRowEditInit(product)" class="p-button-rounded p-button-text"></button> -->
                  @if (editing && product?.form?.valid) {
                    <button
                      pButton
                      pRipple
                      type="button"
                      pSaveEditableRow
                      icon="pi pi-check"
                      (click)="onRowEditSave(product, $event)"
                      class="p-button-rounded p-button-text p-button-success mr-2"
                    ></button>
                  }
                  @if (editing && !product?.form?.valid) {
                    <button
                      pButton
                      pRipple
                      type="button"
                      icon="pi pi-exclamation-triangle"
                      (click)="onRowEditSave(product, $event)"
                      pTooltip="Invalid Form Data"
                      tooltipPosition="top"
                      class="p-button-rounded p-button-text p-button-danger mr-2"
                    ></button>
                  }
                  @if (editing) {
                    <button
                      pButton
                      pRipple
                      type="button"
                      pCancelEditableRow
                      icon="pi pi-times"
                      (click)="onRowEditCancel(product, ri)"
                      class="p-button-rounded p-button-text p-button-danger"
                    ></button>
                  }
                </div>
              </td>
            }
          }
        </tr>
      </ng-template>
    </p-table>
  </div>
  @if (control?.dirty) {
    @if (control?.hasError('required')) {
      <small class="p-error p-invalid">{{ label }} {{ 'is Required' }}</small>
    }
    @if (control?.hasError('minlength')) {
      <small class="p-error p-invalid">
        {{ label }} must have a minimum length of {{ control?.errors?.minlength?.requiredLength }}
      </small>
    }
    @if (control?.hasError('maxlength')) {
      <small class="p-error p-invalid">
        {{ label }} must have a maximum length of {{ control?.errors?.maxlength?.requiredLength }}
      </small>
    }
    @if (control?.hasError('min')) {
      <small class="p-error p-invalid">
        {{ label }} must be greater than or equal to {{ control?.errors?.min?.min }}
      </small>
    }
    @if (control?.hasError('max')) {
      <small class="p-error p-invalid">
        {{ label }} must be less than or equal to {{ control?.errors?.max?.max }}
      </small>
    }
  }
</div>
<p-dialog
  header="Add Item"
  [modal]="true"
  [style]="{ width: '70%' }"
  [draggable]="false"
  [resizable]="true"
  [(visible)]="baseFormDialog"
>
  @if (viewMode != 'view') {
    <form [formGroup]="baseFormGroup" (ngSubmit)="onAddForm($event)" pFocusTrap>
      <div class="flex flex-column align-items-end justify-content-start gap-2">
        <div class="formgrid grid flex-1 p-fluid w-full">
          @for (field of cols; track field) {
            @if (!field.options.hideInFormRepeater) {
              <div [class]="cols.length > 2 ? 'col-6' : 'col'">
                <ng-template
                  appDynamicComponent
                  [mode]="viewMode"
                  [dynamicComponentInfo]="field"
                  [liveData]="control?.value"
                ></ng-template>
              </div>
            }
          }
        </div>
        <div class="flex flex-none">
          <app-button [action]="submitButtonAction"></app-button>
        </div>
      </div>
    </form>
  }
</p-dialog>
