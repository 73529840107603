<app-dashlet [config]="{ title: title, icon: icon, color: color }">
  @if (isLoading) {
    <div class="w-full">
      <div style="flex: 1; gap: 0.75rem">
        <p-skeleton width="25%" height="2rem" styleClass="m-auto mt-3"></p-skeleton>
      </div>
    </div>
  }
  @if (imageSrc) {
    <div class="image_holder">
      <div class="image_div_class">
        <img [src]="imageSrc" class="image_class" />
      </div>
      @if (!isLoading) {
        <app-html-text-view class="text_class" [data]="value"></app-html-text-view>
      }
    </div>
  }
  @if (!imageSrc) {
    @if (!isLoading) {
      <app-html-text-view [data]="value"></app-html-text-view>
    }
  }
  <!-- <div *ngIf="!isLoading"
    class="p-3 text-900 font-medium text-3xl text-xl text-center"
    >
    <app-html-text-view [data]="value"></app-html-text-view>

  </div> -->
</app-dashlet>
