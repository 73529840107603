import { Component, Input } from '@angular/core';
import { SettingsCardType } from '@shared/classes/view/SettingsCardType';

@Component({
  selector: 'app-settings-button-list',
  templateUrl: './settings-button-list.component.html',
  styleUrl: './settings-button-list.component.scss'
})
export class SettingsButtonListComponent {
  @Input() data: SettingsCardType[];
}
