<app-input-view-switch
  [showLabelInViewMode]="showLabelInViewMode"
  [mode]="viewMode"
  [data]="
    data?.name ||
    control?.value?.name ||
    data ||
    control?.value?.label ||
    control?.value?.name ||
    control?.value?.value ||
    control?.value
  "
  [dataType]="dataType"
  [label]="label"
  [keepValueCallback]="setFieldResetable.bind(this)"
  [resetFieldCheckVisable]="resetFieldCheckVisable"
  [resetableChecked]="resetable"
>
  <app-drop-down-input
    [label]="label ?? 'Palette'"
    [placeholder]="placeholder || 'Search Palettes'"
    [name]="name || 'palette'"
    [optionLabel]="optionLabel"
    [items]="items"
    [optionValue]="optionValue"
    [control]="fControl"
    [multi]="multi"
    [dataKey]="dataKey"
    [floatLabel]="floatLabel"
    [appendTo]="appendTo"
  >
    <ng-template #resetCheckTemplate>
      @if (resetFieldCheckVisable) {
        <app-reset-switch-input [checked]="!resetable" (onChange)="setFieldResetable($event)"></app-reset-switch-input>
      }
    </ng-template>
    <ng-template let-value #selectedItemTemplate>
      <div class="flex flex-row gap-2">
        @for (color of value?.value ?? value | slice: 0 : 4; track color) {
          <app-color-view [size]="'18px'" [data]="color" [copyColorOnClick]="false"></app-color-view>
        }
      </div>
    </ng-template>
    <ng-template let-option #itemTemplate>
      <!-- {{option | json}} -->
      <div class="flex flex-row gap-3">
        @for (color of option.value | slice: 0 : 4; track color) {
          <app-color-view [data]="color" [copyColorOnClick]="false"></app-color-view>
        }
      </div>
    </ng-template>
  </app-drop-down-input>
</app-input-view-switch>
