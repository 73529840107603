<div class="col-12 md:col-6">
  <app-chips-input
    [label]="'To'"
    [placeholder]="'Mail to...'"
    [control]="formGroup?.controls?.toList"
    [viewMode]="viewMode"
    (onAdd)="validateAndAdd($event,'toList')"
  >
  </app-chips-input>
</div>
<div class="col-12 md:col-6">
  <app-chips-input [label]="'CC'" [placeholder]="'CC...'" [control]="formGroup?.controls?.ccList" [viewMode]="viewMode"
  (onAdd)="validateAndAdd($event,'ccList')"
  >
  </app-chips-input>
</div>
<div class="col-12 md:col-6">
  <app-chips-input
    [label]="'BCC'"
    [placeholder]="'BCC...'"
    [control]="formGroup?.controls?.bccList"
    [viewMode]="viewMode"
    (onAdd)="validateAndAdd($event,'bccList')"
  >
  </app-chips-input>
</div>
