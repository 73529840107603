<div class="formgrid grid p-fluid">
  @for (item of readyToLink; track item; let i = $index) {
    <div class="col-6">
      <app-code-navigation [data]="item"></app-code-navigation>
    </div>
    <div class="col-2">
      <i class="pi pi-arrow-right"></i>
    </div>
    <div class="col-4">
      <app-badge-view [data]="'Linked'"></app-badge-view>
    </div>
  }
  @for (item of readyToUnlink; track item; let i = $index) {
    <div class="col-6">
      <app-code-navigation [data]="item"></app-code-navigation>
    </div>
    <div class="col-2">
      <i class="pi pi-arrow-right"></i>
    </div>
    <div class="col-4">
      <app-badge-view [data]="'Unlinked'"></app-badge-view>
    </div>
  }
  @for (item of readyToAddAndLink; track item; let i = $index) {
    <div class="col-6">
      <app-text-view [data]="item"></app-text-view>
    </div>
    <div class="col-2">
      <i class="pi pi-arrow-right"></i>
    </div>
    <div class="col-4">
      <app-badge-view [data]="'Add And Link'"></app-badge-view>
    </div>
  }
</div>
<div class="flex flex-row flex-wrap w-full mt-4 justify-content-end">
  <app-button [action]="confirmAction"></app-button>
</div>
