import { Component, Input, OnInit } from '@angular/core';
import { ImporterColumnDefinition, ImporterValidators } from '@shared/classes';

@Component({
  selector: 'app-importer-column-definition-overlay',
  templateUrl: './importer-column-definition-overlay.component.html',
  styleUrls: ['./importer-column-definition-overlay.component.scss'],
})
export class ImporterColumnDefinitionOverlayComponent implements OnInit {
  @Input() columnDefinition: ImporterColumnDefinition;
  importerValidators = ImporterValidators;
  constructor() {}

  ngOnInit(): void {}
}
