import { ChartViewConfig } from '@shared/classes/model';
import { EChartsOption } from 'echarts';
import { AggregateFunctionOptions, AssignChartDataOptions, EchartBase } from '../../echart-base';

export class TimescaleChart extends EchartBase {
  smooth = true;
  areaStyle = null;
  stack = null;
  chartOptions: EChartsOption = {
    tooltip: {
      trigger: 'axis',
      position: function (pt) {
        return [pt[0], '10%'];
      },
      appendTo: 'body',
    },
    toolbox: {
      feature: {
        dataZoom: {
          yAxisIndex: 'none',
        },
        restore: {},
        saveAsImage: {},
      },
    },
    legend: {
      top: 45,
      left: 'center',
      type: 'scroll',
      orient: 'horizontal',
      padding: [10, 10, 20, 200],
      pageIconColor: '#afafaf', // Set scroll icon color to white
      pageTextStyle: {
        color: '#afafaf', // Set scroll page text color to white
      },
    },
    xAxis: {
      type: 'time',
      boundaryGap: null,
      // data: [],
    },
    yAxis: {
      type: 'value',
      boundaryGap: [0, '100%'],
    },
    dataZoom: [
      {
        type: 'inside',
        start: 0,
        end: 100,
      },
      {
        start: 0,
        end: 100,
      },
    ],
    series: [
      {
        name: '',
        type: 'line',
        symbol: 'none',
        sampling: 'lttb',
        itemStyle: {
          //   color: 'rgb(255, 70, 131)'
        },
        areaStyle: {
          //   color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
          //     {
          //       offset: 0,
          //       color: 'rgb(255, 158, 68)'
          //     },
          //     {
          //       offset: 1,
          //       color: 'rgb(255, 70, 131)'
          //     }
          //   ])
        },
        data: [],
      },
    ],
  };
  chartType: ChartViewConfig.ChartTypeEnum = 'TIME_SCALE';
  assignDataToChartOptions(options: AssignChartDataOptions): EChartsOption {
    let optionsCopy = { ...options?.chartOptions };
    const seriesObj = options?.data?.find((x) => x?.['series']);
    if (seriesObj) {
      optionsCopy.series = seriesObj.series;
    } else {
      // this is not used at all and idk if it should exist
      optionsCopy.series[0].name = options?.name;
      //@TODO IDEK there should be a formatter before here
      optionsCopy.series = (options?.data as any).series;
      optionsCopy.yAxis = (options?.data as any).yAxis;
      optionsCopy.xAxis = (options?.data as any).xAxis;
    }
    return optionsCopy;
  }
  formatAggregateData(options: AggregateFunctionOptions) {
    const statistics: { date: string; data: { value: number; name: string }[]; chartTitle: string }[] = [];
    const statisticsObject: { [x: string]: number[][] } = {}; //this will store [series key]:{series specs + data array}
    options?.collectedStatistics?.forEach((item) => {
      statistics.push({ ...this.aggregateToSingleDimension({...item,drillDownAggregationField:options?.drillDownAggregationField}), date: item.collectionDate });
    });
    statistics.forEach((element) => {
      if (element.data) {
        element.data.forEach((item) => {
          // add date,value to the series
          statisticsObject[item.name] = statisticsObject[item.name]
            ? [...statisticsObject[item.name], [new Date(element.date).getTime(), item.value]]
            : [[new Date(element.date).getTime(), item.value]];
        });
      }
    });
    const seriesList = [];
    Object.entries(statisticsObject).forEach(([key, value]) => {
      seriesList.push({
        name: key,
        type: 'line',
        stack: this.stack,
        symbol: 'none',
        sampling: 'lttb',
        smooth: this.smooth,
        areaStyle: this.areaStyle,
        itemStyle: {
          //   color: 'rgb(255, 70, 131)'
        },
        data: value,
      });
    });
    return {
      data: [{ series: seriesList }] as any,
      chartTitle: null,
    };
  }
}
