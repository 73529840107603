@for (item of formList; track item; let i1 = $index) {
  <div class="flex flex-column gap-4 w-full">
    <p-divider align="left">
      <b>{{ item?.item?.question }}</b>
      @if (item?.item?.scoringType == 'SCORED' && showScore) {
        Score: {{ totalScores?.[i1] || '0' }}
      }
    </p-divider>
    <div class="formgrid grid p-fluid">
      <div class="col-12">
        <app-dynamic-field-list-input
          [showScore]="showScore"
          [label]="''"
          [placeholder]="'Enter Question Value'"
          [control]="$any(formArray?.controls?.[i1])?.controls?.dynamics"
          [viewMode]="viewMode"
          [config]="{ dynamicFields: item?.item?.fields, dynamicFieldValues: item?.value?.dynamics }"
          (onChanges)="onChildControlChanges($event, item?.item?.fields, i1)"
        >
        </app-dynamic-field-list-input>
      </div>
    </div>
  </div>
}
@if (control?.dirty) {
  @if (control?.hasError('scoredFieldRequired')) {
    <small class="p-error p-invalid">At least one scored question answer is required.</small>
  }
}
