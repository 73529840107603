import { Component, OnInit } from '@angular/core';
import { BaseViewItem, StateMachineLog } from '@shared/classes';

@Component({
  selector: 'app-event-action-view',
  templateUrl: './event-action-view.component.html',
  styleUrl: './event-action-view.component.scss',
})
export class EventActionViewComponent extends BaseViewItem<StateMachineLog> implements OnInit {
  constructor() {
    super();
  }

  ngOnInit(): void {}
}
