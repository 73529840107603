<p-tabMenu [model]="model" [activeItem]="activeItem" [scrollable]="scrollable" [style]="style" [styleClass]="styleClass" [ariaLabel]="ariaLabel" [ariaLabelledBy]="ariaLabelledBy"
(activeItemChange)="onActiveItemChange($event)"
> 
    @if(itemTemplate){
        <ng-template pTemplate="item" let-item let-i="index">
            <ng-container *ngTemplateOutlet="itemTemplate; context: { $implicit: item, index: i }"></ng-container>
        </ng-template>
    }
    @if(nextIconTemplate){
        <ng-template pTemplate="nextIconTemplate">
            <ng-template *ngTemplateOutlet="nextIconTemplate"></ng-template>
        </ng-template>
    }
    @if(previousIconTemplate){
        <ng-template pTemplate="nextIconTemplate">
            <ng-template *ngTemplateOutlet="nextIconTemplate"></ng-template>
        </ng-template>
    }
</p-tabMenu>