@if (!hideCard) {
  <div [class.fadeoutleft]="playAnimation" class="w-full surface-border animation-duration-500 relative">
    <div
      class="py-2 w-full gap-0 flex md:align-items-start md:justify-content-between flex-column md:flex-row notification-container"
    >
      <div class="flex align-items-start gap-3 w-full notification-body-container">
        <div style="max-width: calc(100% - 112px)">
          <div class="flex notification-title mb-2">
            <app-avatar-list [size]="null" [users]="notification?.sender || 'No User'"></app-avatar-list>
            <span
              class="white-space-nowrap block text-500 font-medium no-underline m-auto mx-2"
              timeago
              [date]="notification?.creationDate | date: 'yyyy-MM-dd HH:mm:ss'"
              style="font-variant-numeric: lining-nums"
            ></span>
          </div>
          <!-- [routerLink]="pathResolver.getPathByCode(notification?.relatedToCode)" -->
          <a class="notification-body w-full">
            <div class="text-700">
              {{ getNotificationText(notification) }}
              <app-code-navigation [data]="notification?.relatedToCode"></app-code-navigation>
            </div>
          </a>
        </div>
      </div>
      <div
        class="justify-content-end align-items-center flex-row text-center gap-1 notification-actions-container position-top-right"
        style="min-width: 32px"
      >
        @if (loaderService.loadingState | async) {
          @if (!notification?.isSeen) {
            <div class="w-full text-center">
              <i class="pi pi-spin pi-spinner"></i>
            </div>
          }
        } @else {
          @if (!notification?.isSeen) {
            <div
              [pTooltip]="notification.isSeen ? 'Mark as unseen' : 'Mark as seen'"
              [tooltipPosition]="'left'"
              class="w-full text-center"
            >
              <svg
                (click)="markAsSeen()"
                [class.seen]="false"
                class="mark-as-seen-btn cursor-pointer"
                data-testid="unread-indicator"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="24px"
                fill="none"
                height="24px"
              >
                <circle class="seen-circle" cx="50%" cy="50%" r="4"></circle>
              </svg>
            </div>
          }
        }
      </div>
    </div>
    @if (notification?.children?.length > 0) {
      <p-panel
        class="notification-container"
        [toggleable]="true"
        [collapsed]="true"
        collapseIcon="pi pi-angle-down"
        expandIcon="pi pi-angle-up"
      >
        <ng-template pTemplate="header">
          <span class="text-primary-400"> +{{ notification?.children?.length }} Notifications </span>
        </ng-template>
        <app-notifications-list
          [styleClass]="''"
          [notifications]="$any(notification.children)"
        ></app-notifications-list>
      </p-panel>
    }
  </div>
}
