import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import {
  DynamicComponentBase,
  EntityTypeFieldDto,
  IAction,
  ModuleIcons,
  toKebabCase,
  toPascalCase,
} from '@shared/classes';
import { EntityDataService } from 'app/modules/entity-module/entity/entity-data.service';
import { AssetsDataService } from 'app/modules/entity-module/services/data/assets-data.service';
import { EntityTypesDataService } from 'app/modules/entity-module/services/data/entity-types-data.service';
import { EventsDataService } from 'app/modules/entity-module/services/data/events-data.service';
import { GeographiesDataService } from 'app/modules/entity-module/services/data/geographies-data.service';
import { ObjectivesDataService } from 'app/modules/entity-module/services/data/objectives-data.service';
import { ProcessesDataService } from 'app/modules/entity-module/services/data/processes-data.service';
import { ResponsibilitiesDataService } from 'app/modules/entity-module/services/data/responsibilities-data.service';
import { groupBy, uniqBy } from 'lodash-es';
import { TreeNode } from 'primeng/api';
import { forkJoin, of } from 'rxjs';

@Component({
  selector: 'app-entity-org-chart-selector',
  templateUrl: './entity-org-chart-selector.component.html',
  styleUrls: ['./entity-org-chart-selector.component.scss'],
})
export class EntityOrgChartSelectorComponent extends DynamicComponentBase implements OnInit {
  // treeViewModes = getEnumOptions(EntityTypeFieldDto.TreeViewModeEnum);
  formControl = new FormControl();
  entityTypeMap: { [x: string]: EntityTypeFieldDto } = {};
  @Input() multi = false;
  @Input() allowSelection = false;
  @Input() items: any[] = [];
  @Input() returnObject: boolean = false;
  @Input() excludeId: string = null;
  @Input() dataKey: string = 'code';
  @Input() customProjectionFields: string[] = [
    'id',
    'code',
    'name',
    'owner',
    'type',
    'parent',
    'people',
    'category',
    'icon',
    'treeViewMode',
  ];
  @Input() categoryList: EntityTypeFieldDto.CategoryEnum[] = [];
  @Input() color: string = 'green';
  @Input() defaultIcon: string = 'pi pi-gear';

  @Input() actionList: IAction[] = [
    {
      id: 1,
      icon: 'pi pi-eye',
      command: this.viewNode.bind(this),
      tooltipOptions: {
        tooltipLabel: 'View',
      },
    },
    {
      id: 2,
      icon: 'pi pi-pencil',
      command: this.editNode.bind(this),
      tooltipOptions: {
        tooltipLabel: 'Update',
      },
    },
    {
      id: 3,
      icon: 'pi pi-plus-circle',
      command: this.addChild.bind(this),
      tooltipOptions: {
        tooltipLabel: 'Add Child',
      },
    },
  ];
  private selectedNode: TreeNode = null;
  @Input() set selectedEntities(entities: any[]) {
    this.setSelectedItems(entities);
  }

  isLoading: boolean = false;

  selectedNodes: any = null;
  groupedEntities: { [index: string]: any[] };
  groupedEntities1: { [index: string]: any[] };
  formattedEntities: TreeNode[];

  visited: any = {};
  treeNodes: any = {};

  constructor(
    private service: EntityDataService,
    private entityTypeService: EntityTypesDataService,
    private router: Router,
    private respService: ResponsibilitiesDataService,
    private eventService: EventsDataService,
    private assetService: AssetsDataService,
    private geoService: GeographiesDataService,
    private proService: ProcessesDataService,
    private objService: ObjectivesDataService
  ) {
    super();
  }

  ngOnInit() {
    this.getOptions();
    if (this.control) {
      this.subs.sink = this.fControl.valueChanges.subscribe((changes) => {
        this.onChanges.emit(changes);
      });
    }
  }
  setSelectedItems(list: any[]) {
    let newSelections = null;
    Object.keys(this.treeNodes).forEach((nodeId) => {
      if (this.checkIfIncluded(nodeId, list)) {
        if (!newSelections) newSelections = [];
        newSelections.push(this.treeNodes[nodeId]?.data);
      }
    });
    this.selectedNodes = newSelections;
  }
  getOptions(filters: any = []) {
    this.isLoading = true;
    this.subs.sink = forkJoin({
      // entities : this.service.search<any>(
      //     { all: true },
      //     { projectionFields: this.customProjectionFields, filters: [{ "property": "category", "operation": "IN", "value": this.categoryList }] },
      //     { showLoading: false, showMsg: false }
      // ),
      [EntityTypeFieldDto.CategoryEnum.Asset]: this.categoryList.find((x) => x == EntityTypeFieldDto.CategoryEnum.Asset)
        ? this.assetService.search(
            { all: true },
            {
              projectionFields: this.customProjectionFields,
              filters: [],
            },
            { showLoading: false, showMsg: false }
          )
        : of([]),
      [EntityTypeFieldDto.CategoryEnum.Event]: this.categoryList.find((x) => x == EntityTypeFieldDto.CategoryEnum.Event)
        ? this.eventService.search(
            { all: true },
            {
              projectionFields: this.customProjectionFields,
              filters: [],
            },
            { showLoading: false, showMsg: false }
          )
        : of([]),
      [EntityTypeFieldDto.CategoryEnum.Geography]: this.categoryList.find(
        (x) => x == EntityTypeFieldDto.CategoryEnum.Geography
      )
        ? this.geoService.search(
            { all: true },
            {
              projectionFields: this.customProjectionFields,
              filters: [],
            },
            { showLoading: false, showMsg: false }
          )
        : of([]),
      [EntityTypeFieldDto.CategoryEnum.Objective]: this.categoryList.find(
        (x) => x == EntityTypeFieldDto.CategoryEnum.Objective
      )
        ? this.objService.search(
            { all: true },
            {
              projectionFields: this.customProjectionFields,
              filters: [],
            },
            { showLoading: false, showMsg: false }
          )
        : of([]),
      [EntityTypeFieldDto.CategoryEnum.Process]: this.categoryList.find(
        (x) => x == EntityTypeFieldDto.CategoryEnum.Process
      )
        ? this.proService.search(
            { all: true },
            {
              projectionFields: this.customProjectionFields,
              filters: [],
            },
            { showLoading: false, showMsg: false }
          )
        : of([]),
      [EntityTypeFieldDto.CategoryEnum.Responsibility]: this.categoryList.find(
        (x) => x == EntityTypeFieldDto.CategoryEnum.Responsibility
      )
        ? this.respService.search(
            { all: true },
            {
              projectionFields: this.customProjectionFields,
              filters: [],
            },
            { showLoading: false, showMsg: false }
          )
        : of([]),
      types: this.entityTypeService.search<EntityTypeFieldDto[]>(
        { all: true },
        {
          projectionFields: this.customProjectionFields,
          filters: [{ property: 'category', operation: 'IN', value: this.categoryList }],
        },
        { showLoading: false, showMsg: false }
      ),
    }).subscribe({
      next: (data) => {
        this.groupedEntities1 = {};
        let list = [];
        this.categoryList.forEach((element) => {
          this.groupedEntities1[element] = data[element] as any[];
          list = list.concat(data[element] as any[]);
        });

        this.entityTypeMap = {};
        (data.types as any as EntityTypeFieldDto[]).forEach((element) => {
          this.entityTypeMap[element.code] = element;
        });
        list.forEach((entity) => {
          entity.type = this.entityTypeMap[entity.type] as any;
        });

        this.formattedEntities = [];

        // this.groupedEntities1 = groupBy(list, 'category');

        this.selectedNodes = null;

        let i = 0;
        Object.keys(this.groupedEntities1).forEach((category: EntityTypeFieldDto.CategoryEnum, index) => {
          if (this.categoryList.includes(category)) {
            this.formattedEntities.push({
              label: toPascalCase(category),
              expandedIcon: ModuleIcons[category],
              collapsedIcon: ModuleIcons[category],
              icon: ModuleIcons[category],
              children: [],
              expanded: true,
              selectable: false,
            });

            this.groupedEntities = groupBy(this.groupedEntities1[category], 'parent');

            this.visited = {};
            this.formatDataForTreeView(list, i);
            this.items = uniqBy(list, 'code');
            i++;
          }
        });
      },
      complete: () => {
        this.isLoading = false;
      },
    });
  }
  setInputOptions() {
    this.multi = this.inputOptions?.dropDownInput?.multi ?? this.multi;
    this.items = this.inputOptions?.dropDownInput?.items ?? this.items;
  }
  onValueChanges(value: any) {
    // this.data = value;
    if (this.multi) {
      if (this.returnObject) {
        let values: any[] = value?.map((item) => item?.data);
        this.fControl.patchValue(values);
      } else {
        let values: string[] = value?.map((item) => item?.data[this.dataKey]);
        this.fControl.patchValue(values);
      }
    } else {
      if (this.returnObject) {
        this.fControl.patchValue(value?.data);
      } else {
        this.fControl.patchValue(value?.data[this.dataKey]);
      }
    }
  }
  formatDataForTreeView(data: any[], index) {
    this.formatDataForTreeViewRecursive(data, index);
  }
  formatDataForTreeViewRecursive(data: any[], index1) {
    this.formattedEntities[index1].data = data;

    let groupedTreeView = groupBy(this.groupedEntities['null'], 'type.treeViewMode');

    if (groupedTreeView['EXPAND']?.length > 0) {
      groupedTreeView['EXPAND']?.forEach((el, index) => {
        if (!!!this.visited[el[this.dataKey]]) {
          this.visited[el[this.dataKey]] = true;
          if (!(this.excludeId && el[this.dataKey] == this.excludeId))
            this.formattedEntities[index1].children.push(
              this.getFormattedChildren(el[this.dataKey], el, this.formattedEntities[index1].expandedIcon)
            );
        }
      });
    }
    if (groupedTreeView['COLLAPSE']?.length > 0) {
      const el = groupedTreeView['COLLAPSE'][0];
      const code = el[this.dataKey],
        data = el,
        parentIcon = 'pi pi-book';
      let groupedDataByType = groupBy(groupedTreeView['COLLAPSE'], 'type.code');
      let node: TreeNode = {
        label: 'Others',
        data: {
          options: Object.keys(groupedDataByType).map((key) => {
            return {
              code: key,
              name: (groupedDataByType?.[key]?.[0]?.type as any)?.name,
              icon: (groupedDataByType?.[key]?.[0]?.type as any)?.icon ?? parentIcon,
              items: groupedDataByType[key],
              selectable: false,
            };
          }),
          total: groupedTreeView['COLLAPSE'].length,
        },
        expandedIcon: parentIcon,
        collapsedIcon: parentIcon,
        icon: parentIcon,
        children: [],
        expanded: false,
        selectable: this.viewMode != 'view',
        key: data[this.dataKey],
        type: 'collapse',
      };
      this.treeNodes[code] = node;
      if (this.checkIfIncludedInValue(code)) {
        if (this.multi) {
          if (!this.selectedNodes) this.selectedNodes = [];
          this.selectedNodes.push(node);
        } else {
          this.selectedNodes = node;
        }
      }
      //   if (!!this.groupedEntities[code]) {
      //     this.groupedEntities[code].forEach((el, index) => {
      //       if (!!!this.visited[el[this.dataKey]]) {
      //         this.visited[el[this.dataKey]] = true;
      //         let ret = this.getFormattedChildren(el[this.dataKey], el,node.icon);
      //         if(!(this.excludeId && el[this.dataKey] == this.excludeId))
      //         node.children.push(ret)
      //       } else {
      //       }
      //     })
      //   } else {
      //   }
      this.formattedEntities[index1].children.push(node);
    }
  }
  getFormattedChildren(code, data, parentIcon): TreeNode {
    let node: TreeNode = {
      label: data.name,
      data: data,
      expandedIcon: data?.type?.icon ?? parentIcon,
      collapsedIcon: data?.type?.icon ?? parentIcon,
      icon: data?.type?.icon ?? parentIcon,
      children: [],
      expanded: true,
      selectable: this.viewMode != 'view',
      key: data[this.dataKey],
    };
    this.treeNodes[code] = node;
    if (this.checkIfIncludedInValue(code)) {
      if (this.multi) {
        if (!this.selectedNodes) this.selectedNodes = [];
        this.selectedNodes.push(node);
      } else {
        this.selectedNodes = node;
      }
    }
    if (!!this.groupedEntities[code]) {
      let groupedTreeView = groupBy(this.groupedEntities[code], 'type.treeViewMode');
      if (groupedTreeView['EXPAND']?.length > 0) {
        groupedTreeView['EXPAND'].forEach((el, index) => {
          if (!!!this.visited[el[this.dataKey]]) {
            this.visited[el[this.dataKey]] = true;
            let ret = this.getFormattedChildren(el[this.dataKey], el, node.icon);
            if (!(this.excludeId && el[this.dataKey] == this.excludeId)) node.children.push(ret);
          } else {
          }
        });
      }
      if (groupedTreeView['COLLAPSE']?.length > 0) {
        const el = groupedTreeView['COLLAPSE'][0];
        const code = el[this.dataKey],
          data = el,
          parentIcon = 'pi pi-book';
        let groupedDataByType = groupBy(groupedTreeView['COLLAPSE'], 'type.code');
        let node1: TreeNode = {
          label: 'Others',
          data: {
            options: Object.keys(groupedDataByType).map((key) => {
              return {
                code: key,
                name: (groupedDataByType?.[key]?.[0]?.type as any)?.name,
                icon: (groupedDataByType?.[key]?.[0]?.type as any)?.icon ?? parentIcon,
                items: groupedDataByType[key],
                selectable: false,
              };
            }),
            total: groupedTreeView['COLLAPSE'].length,
          },
          expandedIcon: parentIcon,
          collapsedIcon: parentIcon,
          icon: parentIcon,
          children: [],
          expanded: false,
          selectable: this.viewMode != 'view',
          key: data[this.dataKey],
          type: 'collapse',
        };
        this.treeNodes[code] = node1;
        if (this.checkIfIncludedInValue(code)) {
          if (this.multi) {
            if (!this.selectedNodes) this.selectedNodes = [];
            this.selectedNodes.push(node1);
          } else {
            this.selectedNodes = node1;
          }
        }
        //   if (!!this.groupedEntities[code]) {
        //     this.groupedEntities[code].forEach((el, index) => {
        //       if (!!!this.visited[el[this.dataKey]]) {
        //         this.visited[el[this.dataKey]] = true;
        //         let ret = this.getFormattedChildren(el[this.dataKey], el,node.icon);
        //         if(!(this.excludeId && el[this.dataKey] == this.excludeId))
        //         node.children.push(ret)
        //       } else {
        //       }
        //     })
        //   } else {
        //   }
        node.children.push(node1);
        // this.formattedEntities[index1].children.push(node)
      }
    } else {
      return node;
    }
    return node;
  }
  checkIfIncludedInValue(code) {
    if (!this.fControl?.value) return false;
    if (!this.multi)
      return this.returnObject
        ? (this.fControl.value[this.dataKey] as String) == code
        : (this.fControl.value as String) == code;
    return (
      (this.fControl?.value as String[])?.findIndex((x) => (this.returnObject ? x[this.dataKey] == code : x == code)) !=
      -1
    );
  }
  checkIfIncludedInSelectedNodes(code) {
    if (!this.selectedNodes) return false;
    return this.selectedNodes?.findIndex((x) => x.data[this.dataKey] == code) != -1;
  }
  checkIfIncluded(code, data: any[]) {
    if (!data) return false;
    return data?.findIndex((x) => x[this.dataKey] == code) != -1;
  }
  viewNode() {
    this.router.navigateByUrl(
      `entity/${(this.selectedNode.data as any)?.type?.category?.toLowerCase()}/view/${(this.selectedNode.data as any)?.code}`
    );
  }
  editNode() {
    this.router.navigateByUrl(
      `entity/${(this.selectedNode.data as any)?.type?.category?.toLowerCase()}/edit/${(this.selectedNode.data as any)?.code}`
    );
  }
  addChild() {
    this.router.navigateByUrl(
      `entity/${(this.selectedNode.data as any)?.type?.category?.toLowerCase()}/create/${(this.selectedNode.data as any)?.code}`
    );
  }
  setCommandData(data) {
    this.selectedNode = data;
  }
  onBrowseEntityClick(event) {
    this.router.navigateByUrl(
      `entity/${toKebabCase(event.value.category)}/view/${(event.value as any).code || (event.value as any).id}`
    );
  }
}
