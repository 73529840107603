import { Component, Input, OnInit } from '@angular/core';
import { Base, IViewMode, PermissionActions } from '@shared/classes';
import { AppDialogService } from '@shared/services/app-dialog.service';
import { PathResolverService } from '@shared/services/path-resolver.service';
import { ViewModeService } from '@shared/services/view-mode.service';
import { NgxPermissionsService } from 'ngx-permissions';
import { takeUntil } from 'rxjs';

@Component({
  selector: 'app-view-mode-switch',
  templateUrl: './view-mode-switch.component.html',
  styleUrls: ['./view-mode-switch.component.scss'],
})
export class ViewModeSwitchComponent extends Base implements OnInit {
  @Input() hasEditChanges: boolean = true;
  @Input() set moduleKeyword(moduleKeyword: string) {
    this._moduleKeyword = moduleKeyword;
    this.checkViewOptionsPermissions();
  }
  private _moduleKeyword: string;
  get moduleKeyword() {
    return this._moduleKeyword;
  }
  // @Output() onViewModeChange: EventEmitter<IViewMode> = new EventEmitter();

  pageViewMode: string = 'view';
  editOption = { label: '', value: 'edit', icon: 'pi pi-pencil', tooltip: 'Edit' };
  viewOption = { label: '', value: 'view', icon: 'pi pi-eye', tooltip: 'View' };
  viewModeOptions = [
    { label: '', value: 'view', icon: 'pi pi-eye', tooltip: 'View' },
    { label: '', value: 'edit', icon: 'pi pi-pencil', tooltip: 'Edit' },
  ];
  constructor(
    // private breadcrumbService: AppBreadcrumbService,
    public viewModeService: ViewModeService,
    // private router: Router,
    private perms: NgxPermissionsService,
    private appDialogService: AppDialogService,
    private pathResolverService: PathResolverService
  ) {
    super();
    this.viewModeService.viewModeState.pipe(takeUntil(this.destroy$)).subscribe((viewMode) => {
      this.pageViewMode = viewMode;
    });
  }

  ngOnInit(): void {
    this.checkViewOptionsPermissions();
  }
  onChangeViewMode(mode: { event?: any; value?: IViewMode }) {
    if (this.hasEditChanges && mode.value == 'view') {
      setTimeout(() => {
        this.pageViewMode = 'edit';
      }, 10);
      this.appDialogService.confirm(
        {
          accept: () => {
            this.pageViewMode = 'view';
            this.pathResolverService.changeViewByPath(mode.value);
            // this.onViewModeChange.emit(mode.value);
          },
        },
        { message: 'Any Changes Will be lost, Proceed?' }
      );
    } else {
      this.pathResolverService.changeViewByPath(mode.value);
      // this.onViewModeChange.emit(mode.value);
    }
  }
  checkViewOptionsPermissions() {
    if (this.moduleKeyword) {
      let options = [];
      this.perms.hasPermission(PermissionActions.Update + this.moduleKeyword).then((res) => {
        if (res) options.push(this.editOption);
        this.viewModeOptions = [...options];
      });
      this.perms.hasPermission(PermissionActions.Read + this.moduleKeyword).then((res) => {
        if (res) options.push(this.viewOption);
        this.viewModeOptions = [...options];
      });
      this.viewModeOptions = [...options];
    }
  }
}
