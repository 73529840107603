<form [formGroup]="formGroup" (ngSubmit)="onSubmitForm()">
  <div class="formgrid grid p-fluid">
    <div class="col-12 md:col-12">
      <app-user-and-group-selector
        [label]="'Add Watchers'"
        [placeholder]="'Search...'"
        [principleMode]="true"
        [itemTypes]="['RESPONSIBILITIES', 'GROUPS', 'USERS']"
        [control]="formGroup?.controls?.principle"
        [optionValue]="undefined"
        [multi]="true"
        [viewMode]="fieldViewMode"
      >
      </app-user-and-group-selector>
      <!--
      <app-user-selector
        [label]="'Watchers'"
        [placeholder]="'Search Users'"
        [control]="formGroup?.controls?.people"
        [multi]="true"
        [viewMode]="fieldViewMode"

        >
      </app-user-selector> -->
    </div>
  </div>
  <!-- <ng-content></ng-content> -->
  @if (formGroup?.controls?.principle?.value?.length > 0) {
    <div class="flex flex-row-reverse gap-2">
      @if (fieldViewMode != 'view' && showSaveAndClose) {
        <app-button [action]="submitButtonAction"></app-button>
      }
      @if (showSaveButton) {
        <app-button [action]="submitSaveButtonAction"></app-button>
      }
    </div>
  }
</form>
