import { Component, ContentChild, Input, OnInit, TemplateRef } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import {
  AggregateType,
  AppPermissions,
  BadgeColor,
  ChartViewConfig,
  ContentViewDto,
  DashletTypeEnum,
  UnsubscribeOnDestroyAdapter,
  getEnumOptions,
  humanizeCasedString,
  parseAggregateDataV2,
} from '@shared/classes';
import { PathResolverService } from '@shared/services';
import { NgxPermissionsService } from 'ngx-permissions';
import { of } from 'rxjs';
@Component({
  selector: 'app-widget',
  templateUrl: './widget.component.html',
  styleUrls: ['./widget.component.scss'],
})
export class WidgetComponent extends UnsubscribeOnDestroyAdapter implements OnInit {
  @ContentChild('headerTemplate') headerTemplate: TemplateRef<any>;
  @Input() showChartViewControls = true;
  @Input() displayCard = true;
  @Input() title: string = 'some title';
  @Input() cardContent: ContentViewDto = {
    permissions: AppPermissions.ReadControlGroup,
    type: ContentViewDto.TypeEnum.Chart,
    targetType: 'CONTROL_OBJECTIVE',
    filterView: {
      code: 'FIL-1',
      name: 'Control Objective Aggre filter',
      filter: null,
      aggregationBody: {
        aggregationFields: [
          {
            operation: 'COUNT',
            fieldName: 'controlClassification',
          },
        ],
        groupByFields: [{ fieldName: 'controlClassification', typeShape: 'NORMAL' }],
      },
      type: 'AGGREGATION',
      targetType: 'CONTROL_OBJECTIVE',
    },
    tableViewConfig: null,
    chartViewConfig: {
      chartType: ChartViewConfig.ChartTypeEnum.Doughnut,
      colorPalette: ['#fcfcfc', '#54454'],
      viewType: ChartViewConfig.ViewTypeEnum.ChartAndData,
      // scaleType: ChartViewConfig.ScaleTypeEnum.Standard,
    },
  };
  // card: ViewCardDto = {
  //     name: 'Control Objectives by Classification',
  //     displayConfig: {
  //         cols: 6,
  //         rows: 3,
  //         x: 0,
  //         y: 0,
  //         order: 0,
  //     },
  //     cardView: {
  //         code:null,
  //         permissions:AppPermissions.ReadControlGroup,
  //         type: ContentViewDto.TypeEnum.Chart,
  //         targetType: 'CONTROL_OBJECTIVE',
  //         filterView: {
  //             code:'FIL-1',
  //             name: 'Control Objective Aggre filter',
  //             filter: null,
  //             aggregationBody: {
  //                 aggregationFields: [
  //                     {
  //                         operation: "COUNT",
  //                         fieldName: "controlClassification",
  //                     }
  //                 ],
  //                 groupByFields: ["controlClassification"]
  //             },
  //             type: 'AGGREGATION',
  //             targetType: 'CONTROL_OBJECTIVE',
  //         },
  //         tableViewConfig: null,
  //         chartViewConfig: {
  //             chartType: ChartViewConfig.ChartTypeEnum.Doughnut,
  //             colorPalette: ['#fcfcfc', '#54454'],
  //             viewType: ChartViewConfig.ViewTypeEnum.ChartAndData,
  //             scaleType: ChartViewConfig.ScaleTypeEnum.Standard
  //         }
  //     },
  // }
  plugin = {
    id: 'centerText',
    beforeDraw: function (chart) {
      if (chart.config.options?.elements?.center) {
        // Get ctx from string
        var ctx = chart.ctx;
        const outerRadius = chart._metasets?.[0]?.data?.[0]?.outerRadius;
        const innerRadius = chart._metasets?.[chart?._metasets?.length - 1]?.data?.[0]?.innerRadius;

        // Get options from the center object in options
        var centerConfig = chart.config.options.elements.center;
        var fontStyle = centerConfig.fontStyle || 'Arial';
        var txt = centerConfig.text;
        var color = centerConfig.color || '#000';
        var maxFontSize = centerConfig.maxFontSize || 75;
        var sidePadding = centerConfig.sidePadding || 20;
        var sidePaddingCalculated = (sidePadding / 100) * (innerRadius * 2);
        // Start with a base font of 30px
        ctx.font = '30px ' + fontStyle;

        // Get the width of the string and also the width of the element minus 10 to give it 5px side padding
        var stringWidth = ctx.measureText(txt).width;
        var elementWidth = innerRadius * 2 - sidePaddingCalculated;

        // Find out how much the font can grow in width.
        var widthRatio = elementWidth / stringWidth;
        var newFontSize = Math.floor(30 * widthRatio);
        var elementHeight = innerRadius * 2;

        // Pick a new font size so it will not be larger than the height of label.
        var fontSizeToUse = Math.min(newFontSize, elementHeight, maxFontSize);
        var minFontSize = centerConfig.minFontSize;
        var lineHeight = centerConfig.lineHeight || 25;
        var wrapText = false;

        if (minFontSize === undefined) {
          minFontSize = 20;
        }

        if (minFontSize && (fontSizeToUse < minFontSize || !fontSizeToUse)) {
          fontSizeToUse = minFontSize;
          wrapText = true;
        }

        // Set font settings to draw it correctly.
        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle';
        var centerX = (chart.chartArea.left + chart.chartArea.right) / 2;
        var centerY = (chart.chartArea.top + chart.chartArea.bottom) / 2;
        ctx.font = fontSizeToUse + 'px ' + fontStyle;
        ctx.fillStyle = color;

        if (!wrapText) {
          ctx.fillText(txt, centerX, centerY);
          return;
        }

        var words = txt.split(' ');
        var line = '';
        var lines = [];

        // Break words up into multiple lines if necessary
        for (var n = 0; n < words.length; n++) {
          var testLine = line + words[n] + ' ';
          var metrics = ctx.measureText(testLine);
          var testWidth = metrics.width;
          if (testWidth > elementWidth && n > 0) {
            lines.push(line);
            line = words[n] + ' ';
          } else {
            line = testLine;
          }
        }

        // Move the center up depending on line height and number of lines
        centerY -= (lines.length / 2) * lineHeight;

        for (var n = 0; n < lines.length; n++) {
          ctx.fillText(lines[n], centerX, centerY);
          centerY += lineHeight;
        }
        //Draw text in center
        ctx.fillText(line, centerX, centerY);
      }
    },
  };
  isLoading: boolean = false;
  item: {
    id: number;
    dragEnabled: boolean;
    resizeEnabled: boolean;
    type: any;
    isLoading: () => boolean;
    permissions: (string | string[])[];
    onInit: any;
    config: {
      title: string;
      listData: () => any;
      totalAmount: () => any;
      chartData: () => any;
      horizontalMode: boolean;
      chartOptions: { plugins: { legend: { labels: { color: string }; display: boolean } } };
      plugin: { id: string; beforeDraw: (chart: any) => void };
      showList: boolean;
      showChart: boolean;
    };
  };
  chartTypeItems = getEnumOptions(ChartViewConfig.ChartTypeEnum);
  chartViewTypeItems = getEnumOptions(ChartViewConfig.ViewTypeEnum);
  formGroup = new FormGroup({
    chartType: new FormControl('DOUGHNUT'),
    viewType: new FormControl('CHART_AND_DATA'),
  });
  constructor(
    private permissionService: NgxPermissionsService,
    private pathResolverService: PathResolverService
  ) {
    super();
  }

  ngOnInit(): void {
    this.initItem();
    this.formGroup.valueChanges.subscribe((values) => {
      this.cardContent.chartViewConfig.chartType = values.chartType as any;
      this.cardContent.chartViewConfig.viewType = values.viewType as any;
      this.initItem();
    });
    // this.d3Init();
  }
  initItem() {
    this.formGroup.patchValue(
      {
        chartType: this.cardContent?.chartViewConfig?.chartType,
        viewType: this.cardContent?.chartViewConfig?.viewType,
      },
      { emitEvent: false }
    );
    this.item = {
      id: 1,
      // cols: this.card?.displayConfig?.cols, rows: this.card?.displayConfig?.rows,
      dragEnabled: false,
      resizeEnabled: false,
      type: dashletTypeMapper[this.cardContent?.chartViewConfig?.chartType],
      isLoading: () => this.isLoading,
      permissions: [this.cardContent?.permissions],
      onInit: this.fetchControlObjClassifications.bind(this),
      config: {
        title: this.title,
        listData: () => this.chartCObjClassificationsData,
        totalAmount: () => this.chartCObjClassificationsTotal,
        chartData: () => this.chartCObjClassificationsDetails,
        horizontalMode: true,
        chartOptions: {
          plugins: {
            legend: {
              labels: {
                color: '#868C9B',
              },
              display: false,
            },
          },
        },
        plugin: this.plugin,
        showList: this.cardContent?.chartViewConfig?.viewType == ChartViewConfig.ViewTypeEnum.Chart ? false : true,
        showChart: this.cardContent?.chartViewConfig?.viewType == ChartViewConfig.ViewTypeEnum.Data ? false : true,
      },
    };
  }
  chartCObjClassificationsData;
  chartCObjClassificationsTotal;
  chartCObjClassificationsDetails;
  aggregationResult;
  fetchControlObjClassifications() {
    this.isLoading = true;
    this.permissionService.hasPermission(this.cardContent?.permissions).then((isAllowed) => {
      const dataService = this.pathResolverService.getDataServiceByTargetType(this.cardContent?.targetType as any);
      const mappingService = this.pathResolverService.getMappingServiceByTargetType(
        this.cardContent?.targetType as any
      );
      this.subs.sink = (
        isAllowed ? dataService.aggregate(this.cardContent?.filterView?.aggregationBody) : of([] as any)
      ).subscribe((res) => {
        this.isLoading = false;
        const aggregateField = this.cardContent?.filterView?.aggregationBody?.groupByFields?.[0]?.fieldName;
        const aggregationFieldOperation: any =
          this.cardContent?.filterView?.aggregationBody?.aggregationFields
            ?.find((x) => x?.fieldName == aggregateField)
            ?.operation?.toLowerCase() || AggregateType.Count;
        let chartDataList: { [x: string]: any } = {};
        this.cardContent?.filterView?.aggregationBody?.aggregationFields?.forEach((field) => {
          chartDataList[field.fieldName] = parseAggregateDataV2(
            res?.aggregation_value,
            field.fieldName,
            aggregationFieldOperation,
            mappingService?.mappedFields?.[aggregateField]?.tableDefinition?.filter?.enumClass || null,
            field.fieldName
          );
        });

        this.aggregationResult = {
          fields: this.cardContent?.filterView?.aggregationBody?.aggregationFields,
          value: res?.aggregation_value,
        };
        this.chartCObjClassificationsData = parseAggregateDataV2(
          res?.aggregation_value,
          aggregateField,
          aggregationFieldOperation,
          mappingService?.mappedFields?.[aggregateField]?.tableDefinition?.filter?.enumClass,
          this.cardContent?.filterView?.aggregationBody?.aggregationFields?.length > 1 ? 'recordStatus' : null
        );
        this.chartCObjClassificationsTotal = this.getTotalAmount(this.chartCObjClassificationsData);
        this.chartCObjClassificationsDetails = this.getChartData(this.chartCObjClassificationsData);
      });
    });
  }
  getTotalAmount(obj: { [x: string]: number }) {
    if (!obj) return undefined;
    let total = 0;
    Object.keys(obj).forEach((item) => {
      total += obj[item];
    });
    return total;
  }

  getChartData(charData, colorList = null) {
    if (!charData) return {};
    const root = document.documentElement;
    const style = getComputedStyle(root);
    const badgeColors = BadgeColor;
    return {
      labels: Object.keys(charData).map((x) => humanizeCasedString(x)),
      datasets: [
        {
          data: Object.keys(charData).map((x) => charData[x]),
          backgroundColor: Object.keys(charData).map((x) => {
            return colorList ? colorList[x] : style.getPropertyValue(`--${badgeColors[x]}-500`);
          }),
          // [
          //     "#66BB6A",
          //     "#FFA726",
          //     '#AB47BC',
          // ],
          // hoverBackgroundColor: [
          //     "#81C784",
          //     "#FFB74D",
          //     '#FF47BC',
          // ],
          borderColor: 'transparent',
          fill: true,
          tension: 0.4,
        },
      ],
    };
  }
}
const dashletTypeMapper = {
  [ChartViewConfig.ChartTypeEnum.Doughnut]: DashletTypeEnum.Doughnut,
  [ChartViewConfig.ChartTypeEnum.VerticalBar]: DashletTypeEnum.Bar,
  [ChartViewConfig.ChartTypeEnum.Pie]: DashletTypeEnum.Pie,
  [ChartViewConfig.ChartTypeEnum.Radar]: DashletTypeEnum.Radar,
  // [ChartViewConfig.ChartTypeEnum.PolarArea]: DashletTypeEnum.PolarArea,
  [ChartViewConfig.ChartTypeEnum.Line]: DashletTypeEnum.Line,
};
