import { Component, EventEmitter, HostBinding, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LicenseHandlerService } from '@core/services/license-handler/license-handler.service';
import { AccessRecord, Base } from '@shared/classes';
import { LoaderService } from '@shared/services';
import { Listbox } from 'primeng/listbox';

@Component({
  selector: 'app-unlinking-base-page',
  templateUrl: './unlinking-base-page.component.html',
  styleUrl: './unlinking-base-page.component.scss'
})
export class UnlinkingBasePageComponent extends Base implements OnInit {


  dataLoaded: boolean = false;
  @ViewChild('plistbox') listbox: Listbox;

  @Input() isListLoading: boolean = true;
  @Input() isDataLoading: boolean = true;
  @Input() moduleKeyword: string;
  @Input() nameKey: string = 'name';
  @Output() onProcessLinking: EventEmitter<any> = new EventEmitter();

  private _currentData: {
    id?: any;
    code?: string;
    lockedBy?: string;
    lockedUntil?: any;
    recordStatus?: any;
    label?: string;
    lastModificationDate?: string;
    lastModifierName?: string;
    creatorName?: string;
    creationDate?: string;
    userAccessLevel?: AccessRecord;
  } | any = null;
  get currentData() {
    return this._currentData;
  }
  @Input() set currentData(data: {
    id?: any;
    code?: string;
    lockedBy?: string;
    lockedUntil?: any;
    recordStatus?: any;
    label?: string;
    lastModificationDate?: string;
    lastModifierName?: string;
    creatorName?: string;
    creationDate?: string;
    userAccessLevel?: AccessRecord;
  } | any) {
    this._currentData = data;
    this.listCurrentData = [data];
  }

  listCurrentData;

  @Input() listData: any[] = [];
  @Output() onItemSelected: EventEmitter<any> = new EventEmitter();

  constructor(
    public loaderService: LoaderService,
    public licenseService: LicenseHandlerService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    super();
  }

  @HostBinding('class.vertical') isVertical = false;

  ngOnInit() {
    this.checkScreenSize();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    this.checkScreenSize();
  }

  private checkScreenSize() {
    // Adjust the threshold based on your needs
    this.isVertical = window.innerWidth < 992; // Example for small screens
  }

  itemSelected(event) {
    this.onItemSelected.emit(event?.option?.code);
  }

  processLinking(event) {
    this.onProcessLinking.emit(event);
  }
}
