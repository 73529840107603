import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@env/environment';
import { ModuleKeywords, RequestHandlerOptions } from '@shared/classes';
import { PageableOptions } from '@shared/classes/model/frontend/pageable-options';
import { Observable } from 'rxjs';
import { BaseRequestControllerService, DataService } from '../api';

@Injectable({
  providedIn: 'root'
})
export class TagsSearchDataService extends BaseRequestControllerService<any> {
  constructor(
    private dataService: DataService,
    private router: Router
  ) {
    super(dataService, environment.hub, '/tags', ['code', 'label', 'recordStatus', 'module']);
  }

  globalSearch(
    query: string,
    tags: string[],
    typesFilter: ModuleKeywords[] = [],
    pageOptions: PageableOptions,
    projectionFields: string[] = this.defaultProjectionFields,
    options: RequestHandlerOptions = new RequestHandlerOptions()
  ) {
    const body = { query: query, tags: tags, typesFilter: typesFilter, projectionFields: projectionFields };
    return this.dataService.postData<any>(
      this.url + `/search`,
      body,
      {
        ...options,
        params: { ...pageOptions, ...options.params },
      }
    ) as Observable<any>;
  }
}
