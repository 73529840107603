<app-dashlet [styleClass]="styleClass" [config]="{ title: title }">
  <app-table
    [moduleKeyword]="moduleKeyword"
    [cols]="cols"
    [tableActions]="showStatusActions ? tableActions : []"
    [actionCol]="actionCol"
    [showExportPackageButton]="showPackageExportAction && showOperationActions"
    [showExportDataButton]="showFileExportAction && showOperationActions"
    [showSearchField]="showGlobalFilter"
    [showControlActions]="showActions"
    [pageActions]="pageActions"
    [pageActionsCommandData]="null"
    [inputData]="tableData"
    [pageInfo]="tableEvent.pageInfo"
    [selectionMode]="
      (showPackageExportAction || showBulkOperationAction || showReviseRelationsAction || showFileExportAction) && showOperationActions ? 'multiple' : null
    "
    [rowsPerPageOptions]="null"
    [useAutoUpdate]="false"
    [lazyLoadOnInit]="true"
    [isLoading]="isLoading"
    [offlineConfig]="{
      lazy: true,
      paginator: true,
      showActionBar: showActions || showGlobalFilter,
    }"
    (filtersChanged)="onFilterChange($event)"
    [settingsUrl]="showSettingsLink ? settingsUrl : null"
    [bulkOperationUrl]="bulkOperationUrl"
    [showBulkOperationButton]="showBulkOperationAction && showOperationActions"
    [showFixRelationsButton]="showReviseRelationsAction && showOperationActions"
    [defaultSort]="defaultSort"
    class="h-full widget-table"
  ></app-table>
  <ng-content></ng-content>
  @if (headerTemplate) {
    <ng-template let-config #titleTemplate>
      <ng-container *ngTemplateOutlet="headerTemplate; context: { $implicit: config }"></ng-container>
    </ng-template>
  }
</app-dashlet>
