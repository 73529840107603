import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import {
  Base,
  DataTypeEnum,
  EntityTypeFieldDto,
  ITablePageable,
  IViewMode,
  ModuleKeywords,
  PermissionActions,
  SearchBody,
  getEnumOptions,
  isNullObj,
} from '@shared/classes';
import { AppDialogService } from '@shared/services/app-dialog.service';
import { EntityDataService } from 'app/modules/entity-module/entity/entity-data.service';
import { AssetsDataService } from 'app/modules/entity-module/services/data/assets-data.service';
import { EventsDataService } from 'app/modules/entity-module/services/data/events-data.service';
import { GeographiesDataService } from 'app/modules/entity-module/services/data/geographies-data.service';
import { ObjectivesDataService } from 'app/modules/entity-module/services/data/objectives-data.service';
import { OrganizationsDataService } from 'app/modules/entity-module/services/data/organizations-data.service';
import { ProcessesDataService } from 'app/modules/entity-module/services/data/processes-data.service';
import { ResponsibilitiesDataService } from 'app/modules/entity-module/services/data/responsibilities-data.service';
import { uniqBy } from 'lodash-es';
import { NgxPermissionsService } from 'ngx-permissions';
import { PickList } from 'primeng/picklist';
import { debounceTime, distinctUntilChanged, forkJoin, of, takeUntil } from 'rxjs';
import { EntityFilterPopupComponent } from './entity-filter-popup/entity-filter-popup.component';

@Component({
  selector: 'app-risk-entities-selector',
  templateUrl: './risk-entities-selector.component.html',
  styleUrls: ['./risk-entities-selector.component.scss'],
})
export class RiskEntitiesSelectorComponent extends Base implements OnInit {
  customProjectionFields: string[] = ['id', 'name', 'code', 'owner', 'recordStatus'];
  pageInfo: ITablePageable = new ITablePageable();

  loading: boolean = false;
  originalElements: any[] = [];
  sourceElements: any[] = [];

  @Input()
  targetElements: any[] = [];

  @ViewChild('pickList', { static: false }) pickList: PickList;

  @Output()
  resultlements: EventEmitter<any[]> = new EventEmitter();
  @Input() viewMode?: IViewMode = IViewMode.edit;
  @Input() showCategoriesSelector?: boolean = false;
  @Input() categoryList: EntityTypeFieldDto.CategoryEnum[] = [];
  dataType: DataTypeEnum = DataTypeEnum.Text;
  sourceFilterValue = '';
  appliedSearchBody: SearchBody = {
    projectionFields: ['id', 'name', 'code', 'owner', 'recordStatus'],
    filters: [{ property: 'category', operation: 'IN', value: this.categoryList }],
  };
  listOfCategories = getEnumOptions(EntityTypeFieldDto.CategoryEnum);
  categoriesControl = new FormControl([]);
  constructor(
    private entityDataService: EntityDataService,
    private respService: ResponsibilitiesDataService,
    private eventService: EventsDataService,
    private assetService: AssetsDataService,
    private geoService: GeographiesDataService,
    private proService: ProcessesDataService,
    private objService: ObjectivesDataService,
    private orgService: OrganizationsDataService,
    private _dialogService: AppDialogService,
    private permissionService: NgxPermissionsService
  ) {
    super();
  }

  ngOnInit(): void {
    if (!this.targetElements) this.targetElements = [];
    if (!this.sourceElements) this.sourceElements = [];
    this.appliedSearchBody = {
      projectionFields: ['id', 'name', 'code', 'owner', 'recordStatus'],
      filters: [{ property: 'category', operation: 'IN', value: this.categoryList }],
    };
    // this.fetchData();
    this.categoriesControl.valueChanges
      .pipe(debounceTime(500), distinctUntilChanged(), takeUntil(this.destroy$))
      .subscribe((data) => {
        this.categoryList = data;
        this.fetchData();
      });
    this.categoriesControl.patchValue(this.categoryList);
  }
  fetchData() {
    this.loading = true;
    forkJoin({
      [EntityTypeFieldDto.CategoryEnum.Asset]: this.permissionService.hasPermission(
        PermissionActions.Read + ModuleKeywords.Asset
      ),
      [EntityTypeFieldDto.CategoryEnum.Event]: this.permissionService.hasPermission(
        PermissionActions.Read + ModuleKeywords.Event
      ),
      [EntityTypeFieldDto.CategoryEnum.Geography]: this.permissionService.hasPermission(
        PermissionActions.Read + ModuleKeywords.Geography
      ),
      [EntityTypeFieldDto.CategoryEnum.Objective]: this.permissionService.hasPermission(
        PermissionActions.Read + ModuleKeywords.Objective
      ),
      [EntityTypeFieldDto.CategoryEnum.Process]: this.permissionService.hasPermission(
        PermissionActions.Read + ModuleKeywords.Process
      ),
      [EntityTypeFieldDto.CategoryEnum.Responsibility]: this.permissionService.hasPermission(
        PermissionActions.Read + ModuleKeywords.Responsibility
      ),
      [EntityTypeFieldDto.CategoryEnum.Organization]: this.permissionService.hasPermission(
        PermissionActions.Read + ModuleKeywords.Organization
      ),
    }).subscribe((perms) => {
      forkJoin({
        // entities : this.service.search<any>(
        //     { all: true },
        //     { projectionFields: this.customProjectionFields, filters: [
        //         // { "property": "category", "operation": "IN", "value": this.categoryList }
        //     ] },
        //     { showLoading: false, showMsg: false }
        // ),
        [EntityTypeFieldDto.CategoryEnum.Asset]:
          perms[EntityTypeFieldDto.CategoryEnum.Asset] &&
          this.categoryList.find((x) => x == EntityTypeFieldDto.CategoryEnum.Asset)
            ? this.assetService.search(
                { all: true },
                {
                  projectionFields: this.customProjectionFields,
                  filters: [],
                },
                { showLoading: false, showMsg: false }
              )
            : of([]),
        [EntityTypeFieldDto.CategoryEnum.Event]:
          perms[EntityTypeFieldDto.CategoryEnum.Event] &&
          this.categoryList.find((x) => x == EntityTypeFieldDto.CategoryEnum.Event)
            ? this.eventService.search(
                { all: true },
                {
                  projectionFields: this.customProjectionFields,
                  filters: [],
                },
                { showLoading: false, showMsg: false }
              )
            : of([]),
        [EntityTypeFieldDto.CategoryEnum.Geography]:
          perms[EntityTypeFieldDto.CategoryEnum.Geography] &&
          this.categoryList.find((x) => x == EntityTypeFieldDto.CategoryEnum.Geography)
            ? this.geoService.search(
                { all: true },
                {
                  projectionFields: this.customProjectionFields,
                  filters: [],
                },
                { showLoading: false, showMsg: false }
              )
            : of([]),
        [EntityTypeFieldDto.CategoryEnum.Objective]:
          perms[EntityTypeFieldDto.CategoryEnum.Objective] &&
          this.categoryList.find((x) => x == EntityTypeFieldDto.CategoryEnum.Objective)
            ? this.objService.search(
                { all: true },
                {
                  projectionFields: this.customProjectionFields,
                  filters: [],
                },
                { showLoading: false, showMsg: false }
              )
            : of([]),
        [EntityTypeFieldDto.CategoryEnum.Process]:
          perms[EntityTypeFieldDto.CategoryEnum.Process] &&
          this.categoryList.find((x) => x == EntityTypeFieldDto.CategoryEnum.Process)
            ? this.proService.search(
                { all: true },
                {
                  projectionFields: this.customProjectionFields,
                  filters: [],
                },
                { showLoading: false, showMsg: false }
              )
            : of([]),
        [EntityTypeFieldDto.CategoryEnum.Responsibility]:
          perms[EntityTypeFieldDto.CategoryEnum.Responsibility] &&
          this.categoryList.find((x) => x == EntityTypeFieldDto.CategoryEnum.Responsibility)
            ? this.respService.search(
                { all: true },
                {
                  projectionFields: this.customProjectionFields,
                  filters: [],
                },
                { showLoading: false, showMsg: false }
              )
            : of([]),
        [EntityTypeFieldDto.CategoryEnum.Organization]:
          perms[EntityTypeFieldDto.CategoryEnum.Organization] &&
          this.categoryList.find((x) => x == EntityTypeFieldDto.CategoryEnum.Organization)
            ? this.orgService.search(
                { all: true },
                {
                  projectionFields: this.customProjectionFields,
                  filters: [],
                },
                { showLoading: false, showMsg: false }
              )
            : of([]),
      })
        // this.entityDataService
        //         .search(this.pageInfo.pagination, this.appliedSearchBody,{showLoading:false,showMsg:false})
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: (data) => {
            this.loading = false;
            const groupedEntities1 = {};
            let list = [];
            this.categoryList.forEach((element) => {
              groupedEntities1[element] = data[element] as any[];
              list = list.concat(data[element] as any[]);
            });
            this.originalElements = [...list];
            this.sourceElements = [...list];

            if (this.targetElements && this.targetElements.length > 0) {
              this.targetElements = this.sourceElements.filter(
                (item) =>
                  !!this.targetElements.find(
                    (ell) =>
                      (!isNullObj(ell?.code) && !isNullObj(item?.code) && ell.code == item.code) ||
                      (!isNullObj(ell?.id) && !isNullObj(item?.id) && ell.id == item.id)
                  )
              );
              this.sourceElements = this.sourceElements.filter(
                (item) =>
                  !this.targetElements.find(
                    (ell) =>
                      (!isNullObj(ell?.code) && !isNullObj(item?.code) && ell.code == item.code) ||
                      (!isNullObj(ell?.id) && !isNullObj(item?.id) && ell.id == item.id)
                  )
              );
            }
          },
          error: (err) => {
            this.loading = false;
          },
        });
    });
  }
  onChange() {
    this.resultlements.emit(this.targetElements);
  }

  onCreateReq(): void {
    this._dialogService.showDialog(
      EntityFilterPopupComponent,
      'Apply Filter',
      (e) => {
        this._addRecord(e);
        this.resetPickListFilter();
      },
      {
        data: {
          categoryList: this.categoryList,
        },
      }
    );
    // this._dialogService.open({
    //     title: 'Apply Filter',
    //     dialogType: DialogTypeEnum.form,
    //     componentType: EntityFilterPopup,
    //     submitCallBack: (data: any) => {
    //         this._addRecord(data);
    //     }
    // });
  }

  private _addRecord(entity): void {
    if (entity && entity?.entities?.length) {
      this.targetElements = this.originalElements?.filter((el) => !!entity?.entities.find((el2) => el2 == el?.code));

      this.sourceElements = this.originalElements?.filter((el) => !entity?.entities.find((el2) => el2 == el?.code));

      // this.targetElements.push(...entity?.entities)

      this.sourceElements = uniqBy(this.sourceElements, 'code');
      this.targetElements = uniqBy(this.targetElements, 'code');

      this.resultlements.emit(this.targetElements);
    }

    // this._dialogService.ref.close();
  }
  setCategories(cats) {
    // this.categoryList = cats;
    // this.fetchData();
  }

  resetPickListFilter() {
    if (this.pickList) {
      this.pickList.filterValueSource = '';

      this.sourceFilterValue = ''; // Set sourceFilterValue to an empty string
    }
  }
}
