import { Component, Input, OnInit } from '@angular/core';
import { BaseViewItem, RiskMethodology } from '@shared/classes';
import { find } from 'lodash-es';

@Component({
  selector: 'app-risk-item-cell',
  templateUrl: './risk-item-cell.component.html',
  styleUrls: ['./risk-item-cell.component.scss'],
})
export class RiskItemCellComponent extends BaseViewItem implements OnInit {
  progressBarList = [];
  maxRisk = 1;
  valueColor;
  valueName;
  private _value;
  private _riskMeth;
  get value() {
    return this._value;
  }
  get riskMeth() {
    return this._riskMeth;
  }
  @Input() set value(value) {
    this._value = value;
    this.valueColor = this.getCellColor(this.riskMeth, value);
    this.data = value;
  }
  @Input() set riskMeth(riskMeth: RiskMethodology) {
    if (riskMeth) {
      this._riskMeth = riskMeth;
      this.valueColor = this.getCellColor(riskMeth, this.value);
    }
  }
  constructor() {
    super();
  }

  ngOnInit(): void {}
  getCellColor(meth: RiskMethodology, cellValue) {
    let threshold = find(meth?.thresholdValues, function (o) {
      return o.value >= cellValue;
    });

    if (threshold != undefined) return threshold.color;
    else return 'transparent';
  }
}
