<p
  [pTooltip]="tooltipContent"
  tooltipPosition="top"
  [autoHide]="false"
  style="max-width: 18rem"
  class="line-clamp-1-ellipsis"
>
  {{ data | HtmlToPlaintextPipe | highlight: filter }}
</p>
<ng-template #tooltipContent>
  <div class="max-h-30rem max-w-30rem overflow-auto">
    <span [innerHtml]="data | highlight: filter"></span>
  </div>
</ng-template>
