import { Component, ContentChild, Input, OnInit, TemplateRef } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ContentViewDto, DashboardDto, IAction, UnsubscribeOnDestroyAdapter, ViewCard, ViewCardItem } from '@shared/classes';
import { PathResolverService } from '@shared/services';
import { GridsterItem } from 'angular-gridster2';
import { NgxPermissionsService } from 'ngx-permissions';

@Component({
  selector: 'app-widget-switch',
  templateUrl: './widget-switch.component.html',
  styleUrl: './widget-switch.component.scss',
})
export class WidgetSwitchComponent extends UnsubscribeOnDestroyAdapter implements OnInit {
  @ContentChild('stickyHeaderTemplate') stickyHeaderTemplate: TemplateRef<any>;
  sidebarVisible = false;
  private _item: GridSterItemExtended = null;
  private _widgetSwitchItems: ViewCardItem[];
  get widgetSwitchItems() {
    return this.item?.data?.viewCardItems?.map((x) => {
      return { ...x, name: x.name || x.contentViewObject?.name };
    });
  }
  @Input() showEditControls = true;
  @Input() dashboardOjbect:DashboardDto;
  @Input() set item(item) {
    this._item = item;
    this.onSetItem();
  }
  get item() {
    return this._item;
  }
  private _directItem: ContentViewDto = null;
  @Input() set directItem(item) {
    this._directItem = item;
    this.item = {
      x: 0,
      y: 0,
      rows: 6,
      cols: 12,
      data: {
        viewCardItems: [{ contentViewCode: item?.code, contentViewObject: item }],
      },
    };
  }
  get directItem() {
    return this._directItem;
  }
  activeCardIndex = 0;
  maximizeButtonAction: IAction = {
    id: 1,
    label: '',
    buttonType: 'button',
    color: 'secondary',
    buttonStyle: 'text',
    command: this.maximizeWidget.bind(this),
    icon: 'pi pi-window-maximize',
    tooltipPosition: 'top',
    tooltipText: 'Maximize',
    // status$: this.formValid$,
    // loading$: this.loadingState$,
    // buttonStyle: 'outlined'
  };
  refreshWidgetAction: IAction = {
    id: 2,
    label: '',
    buttonType: 'button',
    color: 'secondary',
    buttonStyle: 'text',
    command: this.refreshWidget.bind(this),
    icon: 'pi pi-replay',
    tooltipPosition: 'top',
    tooltipText: 'Refresh',
    // status$: this.formValid$,
    // loading$: this.loadingState$,
    // buttonStyle: 'outlined'
  };
  editNameAction: IAction = {
    id: 3,
    label: '',
    buttonType: 'button',
    color: 'secondary',
    buttonStyle: 'text',
    command: this.editName.bind(this),
    icon: 'pi pi-pencil',
    tooltipPosition: 'top',
    tooltipText: 'Edit Name',
  };
  editNameEnabled:boolean = false;
  chartOptionsForm = new FormGroup({
    contentViews: new FormControl(null),
  });
  get activeItem() {
    return this.item?.data?.viewCardItems?.[this.activeCardIndex];
  }
  get activeItemViewObject() {
    return this.activeItem.contentViewObject;
  }
  widgetLastRenderTime:Date = new Date();
  constructor(
    private permissionService: NgxPermissionsService,
    private pathResolverService: PathResolverService
  ) {
    super();
  }

  ngOnInit(): void {
    this.widgetLastRenderTime = new Date();
  }
  widgetVisible = true;
  refreshWidget() {
    this.widgetVisible = false;
    setTimeout(() => {
      this.widgetVisible = true;
      this.widgetLastRenderTime = new Date();
    }, 10);
  }
  editName(){
    if(!this.editNameEnabled){
      this.activeItem.name = this.activeItem.name || this.activeItem?.contentViewObject?.name;
    }
    this.editNameEnabled = !this.editNameEnabled;
    this.editNameAction = {
      id: 3,
      label: '',
      buttonType: 'button',
      color: 'secondary',
      buttonStyle: 'text',
      command: this.editName.bind(this),
      icon: this.editNameEnabled ? 'pi pi-check' : 'pi pi-pencil',
      tooltipPosition: 'top',
      tooltipText: 'Edit Name',
    }
  }
  maximizeWidget() {
    this.sidebarVisible = true;
  }
  swapView(card, item, index) {
    this.activeCardIndex = index;
  }
  swapViewByItem(value: ViewCardItem) {
    this.activeCardIndex =
      this.item?.data?.viewCardItems?.findIndex((x) => x.contentViewCode == value.contentViewCode) || 0;
  }
  onSetItem() {
    this.chartOptionsForm.controls.contentViews.patchValue(this.item?.data?.viewCardItems?.[this.activeCardIndex]);
    this._widgetSwitchItems = this.item?.data?.viewCardItems?.map((x) => {
      return { ...x, name: x.name || x.contentViewObject?.name };
    });
  }
  onChartOptionsChange(values) {
    this.activeItem.contentViewObject.chartViewConfig = { ...values };
    this.activeItem.contentViewConfig = { ...values };
  }
}
interface GridSterItemExtended extends GridsterItem {
  data?: ViewCard;
}
