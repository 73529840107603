<app-base-page-item
  [data]="data"
  [recordStatusActions]="recordStatusActions"
  [extendLockTimeAction]="extendLockTimeAction"
  [showLocked]="showLocked"
  [breadCrumb]="breadCrumb"
  [sourceCode]="itemId"
  [hasEditChanges]="hasEditChanges$ | async"
  [fromType]="fromType"
  [showRelations]="false"
  [showViewSwitch]="false"
  [showCommentsButton]="false"
  [showCountDown]="false"
  (onViewModeChange)="onViewModeChange($event)"
  [moduleKeyword]="moduleKeyword"
>
  <ng-container page-header>
    <h4 class="mb-0">User Preferences</h4>
  </ng-container>

  <app-user-preference-item-form
    (formSubmit)="onSubmit($event)"
    (formSubmitSave)="onSubmit($event, 'PAGE')"
    (formSubmitInPlace)="onSubmit($event, 'NONE')"
    (cancelEvent)="onCancel($event)"
    [resetForm]="resetFormTrigger"
    (valueEditChanged)="valueEditChanged($event)"
    [formData]="data"
  ></app-user-preference-item-form>
</app-base-page-item>
