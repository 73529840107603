<!-- dynamic-object-viewer.component.html -->
<ng-container>
  <div [class.custom-card]="label">
    <div *ngIf="label" class="custom-card-header hover:surface-200" (click)="toggleCollapse()">
      <span class="toggle-icon" *ngIf="!collapsed"><i class="pi pi-angle-down"></i></span>
      <span class="toggle-icon" *ngIf="collapsed"><i class="pi pi-angle-right"></i></span>
      <span *ngIf="label">{{ label }}</span>
      <span style="float: inline-end"
        ><i
          (click)="copyToClipboard(data, $event)"
          tooltipPosition="top"
          [pTooltip]="'Copy Json!'"
          class="pi pi-copy"
        ></i
      ></span>
    </div>
    <div class="custom-card-body" *ngIf="!collapsed && isObject(data)">
      <ng-container *ngFor="let key of keys">
        <div *ngIf="isObject(data[key]) || isArray(data[key])">
          <div class="custom-card">
            <div class="custom-card-header hover:surface-200" (click)="toggleInnerCollapse(key)">
              <span class="toggle-icon" *ngIf="!this.collapsedItems[key]"><i class="pi pi-angle-down"></i></span>
              <span class="toggle-icon" *ngIf="this.collapsedItems[key]"><i class="pi pi-angle-right"></i></span>
              <span>{{ key | humanize }}</span>
              <span style="float: inline-end"
                ><i
                  (click)="copyToClipboard(data[key], $event)"
                  tooltipPosition="top"
                  [pTooltip]="'Copy Json!'"
                  class="pi pi-copy"
                ></i
              ></span>
            </div>
            <div class="" *ngIf="isObject(data[key]) && !this.collapsedItems[key]">
              <app-dynamic-object-viewer [label]="" [data]="data[key]"></app-dynamic-object-viewer>
            </div>
            <div class="custom-card-body" *ngIf="isArray(data[key]) && !this.collapsedItems[key]">
              <div>
                <ng-container *ngFor="let item of data[key]; let i = index">
                  <ng-container *ngIf="isPrimitive(item); else objectTemplate">
                    <div class="custom-card-element hover:surface-200">
                      <span class="line-clamp-1-ellipsis">
                        {{ item }}
                      </span>
                      <span style="float: inline-end"
                        ><i
                          (click)="copyToClipboard(item, $event)"
                          tooltipPosition="top"
                          [pTooltip]="'Copy!'"
                          class="pi pi-copy"
                        ></i
                      ></span>
                    </div>
                  </ng-container>
                  <ng-template #objectTemplate>
                    <app-dynamic-object-viewer
                      [label]="(key | humanize) + ' #' + i"
                      [data]="item"
                    ></app-dynamic-object-viewer>
                  </ng-template>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
        <div class="custom-card-element hover:surface-200" *ngIf="!isObject(data[key]) && !isArray(data[key])">
          <span class="line-clamp-1-ellipsis"> {{ key | humanize }}: {{ data[key] }} </span>
          <span style="float: inline-end"
            ><i
              (click)="copyToClipboard(data[key], $event)"
              tooltipPosition="top"
              [pTooltip]="'Copy!'"
              class="pi pi-copy"
            ></i
          ></span>
        </div>
      </ng-container>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="!isObject(data) && !isArray(data) && data">
  <div class="custom-card">
    <div class="custom-card-element hover:surface-200">
      <span class="line-clamp-1-ellipsis">
        {{ data }}
      </span>
      <span style="float: inline-end"
        ><i (click)="copyToClipboard(data, $event)" tooltipPosition="top" [pTooltip]="'Copy!'" class="pi pi-copy"></i
      ></span>
    </div>
  </div>
</ng-container>
