import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BaseViewItem } from '@shared/classes';

@Component({
  selector: 'app-info-card',
  templateUrl: './info-card.component.html',
  styleUrls: ['./info-card.component.scss'],
})
export class InfoCardComponent extends BaseViewItem implements OnInit {
  @Input() icon: string = 'pi pi-search';
  @Input() buttonText: string = 'open';
  @Output() onCardClick: EventEmitter<any> = new EventEmitter();
  constructor() {
    super();
  }

  ngOnInit(): void {}
  onCardClickAction(event) {
    this.onCardClick.emit(event);
  }
}
