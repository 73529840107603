import { ChartViewConfig, StatisticViewConfig } from '@shared/classes/model';
import { AreaChart } from './charts/base/area-chart';
import { BubbleChart } from './charts/base/bubble-chart';
import { DoughnutChart } from './charts/base/doughnut-chart';
import { FunnelChart } from './charts/base/funnel-chart';
import { HeatmapChart } from './charts/base/heatmap-chart';
import { HorizontalBarChart } from './charts/base/horizontal-bar-chart';
import { LineChart } from './charts/base/line-chart';
import { PieChart } from './charts/base/pie-chart';
import { RadarChart } from './charts/base/radar-chart';
import { RoseChart } from './charts/base/rose-chart';
import { SankeyChart } from './charts/base/sankey-chart';
import { ScatterChart } from './charts/base/scatter-chart';
import { SemiCircleDoughnutChart } from './charts/base/semi-circle-doughnut-chart';
import { SunburstChart } from './charts/base/sunburst-chart';
import { TimescaleChart } from './charts/base/timescale-chart';
import { TreeMapChart } from './charts/base/tree-map-chart';
import { VerticalBarChart } from './charts/base/vertical-bar-chart';
import { AncestorEmphasisSunburstChart } from './charts/variation/ancestor-emphasis/ancestor-emphasis-sunburst';
import { AreaRadarChart } from './charts/variation/area/area-radar-chart';
import { CurvedAreaChart } from './charts/variation/curved/curved-area-chart';
import { CurvedLineChart } from './charts/variation/curved/curved-line-chart';
import { CurvedScatterChart } from './charts/variation/curved/curved-scatter-chart';
import { DrilldownBarChart } from './charts/variation/drilldown/drilldown-bar-chart';
import { DrilldownDoughnutChart } from './charts/variation/drilldown/drilldown-doughnut-chart';
import { DrilldownHorizontalBarChart } from './charts/variation/drilldown/drilldown-horizontal-bar-chart';
import { DrilldownPieChart } from './charts/variation/drilldown/drilldown-pie-chart';
import { DrilldownRoseChart } from './charts/variation/drilldown/drilldown-rose-chart';
import { DrilldownSemiCircleDoughnutChart } from './charts/variation/drilldown/drilldown-semi-circle-doughnut-chart';
import { DrilldownSunburstChart } from './charts/variation/drilldown/drilldown-sunburst-chart';
import { DrilldownTreeMapChart } from './charts/variation/drilldown/drilldown-tree-map-chart';
import { FullStackedBarChart } from './charts/variation/full-stacked/full-stacked-bar-chart';
import { FullStackedHorizontalBarChart } from './charts/variation/full-stacked/full-stacked-horizontal-bar-chart';
import { GroupsTreeMapChart } from './charts/variation/groups/groups-tree-map-chart';
import { LineRadarChart } from './charts/variation/line/line-radar-chart';
import { LogarithmicAreaChart } from './charts/variation/logarithmic/logarithimic-area-chart';
import { LogarithmicLineChart } from './charts/variation/logarithmic/logarithimic-line-chart';
import { LogarithmicScatterChart } from './charts/variation/logarithmic/logarithimic-scatter-chart';
import { PolarLogarithmicBarChart } from './charts/variation/polar-logarithmic/polar-logarithmic-bar-chart';
import { PolarBarChart } from './charts/variation/polar/polar-bar-chart';
import { PolarBubbleChart } from './charts/variation/polar/polar-bubble-chart';
import { PolarHeatmapChart } from './charts/variation/polar/polar-heat-map-chart';
import { RoundedLogarithmicAreaChart } from './charts/variation/rounded-logarithmic/rounded-logarithmic-area-chart';
import { RoundedLogarithmicLineChart } from './charts/variation/rounded-logarithmic/rounded-logarithmic-line-chart';
import { RoundedLogarithmicScatterChart } from './charts/variation/rounded-logarithmic/rounded-logarithmic-scatter-chart';
import { StackBarChart } from './charts/variation/stacked/stacked-bar-chart';
import { StackHorizontalBarChart } from './charts/variation/stacked/stacked-horizontal-bar-chart';
import { TimescaleAreaChart } from './charts/variation/timescale/timescale-area-chart';
import { TimescaleBarRaceChart } from './charts/variation/timescale/timescale-bar-race-chart';
import { TimescaleLineChart } from './charts/variation/timescale/timescale-line-chart';
import { WheelSankeyChart } from './charts/variation/wheel/wheel-sankey-chart';
import { EchartBase, InitOptions } from './echart-base';

export class EchartFactory {
  static createChart(options: CreateChartOptions): EchartBase {
    const { chartType, chartVariation } = options;
    switch (chartType) {
      case ChartViewConfig.ChartTypeEnum.VerticalBar:
        switch (chartVariation) {
          case ChartViewConfig.ChartVariationEnum.DrillDown:
            return new DrilldownBarChart();
          case ChartViewConfig.ChartVariationEnum.Stacked:
            return new StackBarChart();
          case ChartViewConfig.ChartVariationEnum.FullStacked:
            return new FullStackedBarChart();
          case ChartViewConfig.ChartVariationEnum.Polar:
            return new PolarBarChart();
          case ChartViewConfig.ChartVariationEnum.PolarLogarithmic:
            return new PolarLogarithmicBarChart();
          default:
            return new VerticalBarChart();
        }
      case ChartViewConfig.ChartTypeEnum.HorizontalBar:
        switch (chartVariation) {
          case ChartViewConfig.ChartVariationEnum.DrillDown:
            return new DrilldownHorizontalBarChart();
          case ChartViewConfig.ChartVariationEnum.Stacked:
            return new StackHorizontalBarChart();
          case ChartViewConfig.ChartVariationEnum.FullStacked:
            return new FullStackedHorizontalBarChart();
          case ChartViewConfig.ChartVariationEnum.Polar:
            return new PolarBarChart();
          case ChartViewConfig.ChartVariationEnum.PolarLogarithmic:
            return new PolarLogarithmicBarChart();
          default:
            return new HorizontalBarChart();
        }
      // case ChartViewConfig.ChartTypeEnum.StackedBar:
      //   switch (chartVariation) {
      //     case ChartViewConfig.ChartVariationEnum.DrillDown:
      //       return new DrilldownBarChart();
      //     case ChartViewConfig.ChartVariationEnum.Stacked:
      //       return new StackBarChart();
      //     case ChartViewConfig.ChartVariationEnum.FullStacked:
      //       return new FullStackedBarChart();
      //     case ChartViewConfig.ChartVariationEnum.Polar:
      //       return new PolarBarChart();
      //     case ChartViewConfig.ChartVariationEnum.PolarLogarithmic:
      //       return new PolarLogarithmicBarChart();
      //     default:
      //       return new VerticalBarChart();
      //   }
      case ChartViewConfig.ChartTypeEnum.Pie:
        switch (chartVariation) {
          case ChartViewConfig.ChartVariationEnum.DrillDown:
            return new DrilldownPieChart();
          default:
            return new PieChart();
        }
      case ChartViewConfig.ChartTypeEnum.Doughnut:
        switch (chartVariation) {
          case ChartViewConfig.ChartVariationEnum.DrillDown:
            return new DrilldownDoughnutChart();
          default:
            return new DoughnutChart();
        }
      case ChartViewConfig.ChartTypeEnum.Line:
        switch (chartVariation) {
          case ChartViewConfig.ChartVariationEnum.Curved:
            return new CurvedLineChart();
          case ChartViewConfig.ChartVariationEnum.Logarithmic:
            return new LogarithmicLineChart();
          case ChartViewConfig.ChartVariationEnum.CurvedLogarithmic:
            return new RoundedLogarithmicLineChart();
          default:
            return new LineChart();
        }
      case ChartViewConfig.ChartTypeEnum.Area:
        switch (chartVariation) {
          case ChartViewConfig.ChartVariationEnum.Curved:
            return new CurvedAreaChart();
          case ChartViewConfig.ChartVariationEnum.Logarithmic:
            return new LogarithmicAreaChart();
          case ChartViewConfig.ChartVariationEnum.CurvedLogarithmic:
            return new RoundedLogarithmicAreaChart();
          default:
            return new AreaChart();
        }
      case ChartViewConfig.ChartTypeEnum.Scattered:
        switch (chartVariation) {
          case ChartViewConfig.ChartVariationEnum.Curved:
            return new CurvedScatterChart();
          case ChartViewConfig.ChartVariationEnum.Logarithmic:
            return new LogarithmicScatterChart();
          case ChartViewConfig.ChartVariationEnum.CurvedLogarithmic:
            return new RoundedLogarithmicScatterChart();
          default:
            return new ScatterChart();
        }
      case ChartViewConfig.ChartTypeEnum.Radar:
        switch (chartVariation) {
          case ChartViewConfig.ChartVariationEnum.Area:
            return new AreaRadarChart();
          case ChartViewConfig.ChartVariationEnum.Line:
            return new LineRadarChart();
          default:
            return new RadarChart();
        }
      case ChartViewConfig.ChartTypeEnum.TreeMap:
        switch (chartVariation) {
          case ChartViewConfig.ChartVariationEnum.DrillDown:
            return new DrilldownTreeMapChart();
          case ChartViewConfig.ChartVariationEnum.Groups:
            return new GroupsTreeMapChart();
          default:
            return new TreeMapChart();
        }
      case ChartViewConfig.ChartTypeEnum.SunBurst:
        switch (chartVariation) {
          case ChartViewConfig.ChartVariationEnum.DrillDown:
            return new DrilldownSunburstChart();
          case ChartViewConfig.ChartVariationEnum.AncestorEmphasis:
            return new AncestorEmphasisSunburstChart();
          default:
            return new SunburstChart();
        }
      case ChartViewConfig.ChartTypeEnum.Bubble:
        switch (chartVariation) {
          case ChartViewConfig.ChartVariationEnum.Polar:
            return new PolarBubbleChart();
          default:
            return new BubbleChart();
        }
      case StatisticViewConfig.ChartTypeEnum.TimeScale:
        switch (chartVariation) {
          case StatisticViewConfig.ChartVariationEnum.Line:
            return new TimescaleLineChart();
          case StatisticViewConfig.ChartVariationEnum.Area:
            return new TimescaleAreaChart();
          default:
            return new TimescaleChart();
        }
      case StatisticViewConfig.ChartTypeEnum.BarRace:
        switch (chartVariation) {
          case StatisticViewConfig.ChartVariationEnum.Standard:
            return new TimescaleBarRaceChart();
          default:
            return new TimescaleBarRaceChart();
        }
      case ChartViewConfig.ChartTypeEnum.SemiCircleDoughnut:
        switch (chartVariation) {
          case ChartViewConfig.ChartVariationEnum.DrillDown:
            return new DrilldownSemiCircleDoughnutChart();
          default:
            return new SemiCircleDoughnutChart();
        }

      case ChartViewConfig.ChartTypeEnum.Funnel:
        switch (chartVariation) {
          default:
            return new FunnelChart();
        }

      case ChartViewConfig.ChartTypeEnum.Heatmap:
        switch (chartVariation) {
          case ChartViewConfig.ChartVariationEnum.Polar:
            return new PolarHeatmapChart();
          default:
            return new HeatmapChart();
        }
      case ChartViewConfig.ChartTypeEnum.Sankey:
        switch (chartVariation) {
          case ChartViewConfig.ChartVariationEnum.Wheel:
            return new WheelSankeyChart();
          default:
            return new SankeyChart();
        }

      case ChartViewConfig.ChartTypeEnum.Rose:
        switch (chartVariation) {
          case ChartViewConfig.ChartVariationEnum.DrillDown:
            return new DrilldownRoseChart();
          default:
            return new RoseChart();
        }
      // Add cases for other chart types
      default:
        throw new Error('Unknown chart type or variation');
    }
  }
  static getChartOptions(initOptions: InitOptions) {
    const chart = this.createChart({
      chartType:
        initOptions?.viewCardItem?.contentViewObject?.type == 'CHART'
          ? initOptions?.viewCardItem?.contentViewConfig?.chartType
          : initOptions?.viewCardItem?.contentViewObject?.statisticViewConfig?.chartType,
      chartVariation: initOptions?.viewCardItem?.contentViewConfig?.chartVariation,
    });
    return chart.getEChartOptions(initOptions);
  }
}
export interface CreateChartOptions {
  chartType: ChartViewConfig.ChartTypeEnum | StatisticViewConfig.ChartTypeEnum;
  chartVariation: ChartViewConfig.ChartVariationEnum | StatisticViewConfig.ChartVariationEnum;
}
