<div class="meter-container">
  <span class="tag align-items-start min-tag flex flex-column">
    <span class="vertical-text input-field-label">MIN</span>
    @if (isDate(data.min)) {
      <app-date-view [data]="data.min?.toISOString()"></app-date-view>
    } @else {
      {{ data.min }}
    }
  </span>
  <div class="line">
    @if (data.avg != undefined) {
      <span class="tag align-items-center avg-tag flex flex-column" [ngStyle]="{ 'left.%': avgPosition }">
        <span class="vertical-text input-field-label">AVG</span>
        @if (isDate(data.avg)) {
          <app-date-view [data]="data.avg?.toISOString()"></app-date-view>
        } @else {
          {{ data.avg }}
        }
      </span>
    }
  </div>
  <span class="tag align-items-end max-tag flex flex-column">
    <span class="vertical-text input-field-label">MAX</span>
    @if (isDate(data.max)) {
      <app-date-view [data]="data.max?.toISOString()"></app-date-view>
    } @else {
      {{ data.max }}
    }
  </span>
</div>
