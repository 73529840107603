import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormControl, Validators } from '@angular/forms';
import { DynamicComponentBase } from '@shared/classes/view/DynamicComponentBase';

@Component({
  selector: 'app-multi-network-input',
  templateUrl: './multi-network-input.component.html',
  styleUrls: ['./multi-network-input.component.scss'],
})
export class MultiNetworkInputComponent extends DynamicComponentBase implements OnInit {
  _disbaledBlocksControl: AbstractControl<any>;

  @Input() set disbaledBlocksControl(control: AbstractControl<any>) {
    this._disbaledBlocksControl = control;
  }

  @Input() set disabledList(disabledList: any) {
    this.setForms(disabledList);
    this.checkValidity();
  }

  get disbaledBlocksControl() {
    return this._disbaledBlocksControl;
  }

  formObjects: { label: string; form: AbstractControl<any>; active: boolean }[] = [];

  setForms(dissabledList) {
    this.formObjects = [1, 2, 3, 4, 5, 6, 7, 8].map((x) => {
      return {
        label: 'Block ' + x,
        form: new FormControl(null, Validators.required),
        active: false,
      };
    });

    dissabledList.forEach((element) => {
      this.formObjects[element].active = true;
      this.formObjects[element].form.valueChanges.subscribe((x) => {
        const list = [...this.control.value];
        list[element] = x;
        this.control.patchValue(list);
        this.checkValidity();
      });
    });

    this.control.value.forEach((element, i) => {
      if (element) {
        this.formObjects[i].form.patchValue(element);
      }
    });
  }

  checkValidity() {
    for (let i = 0; i < this.disbaledBlocksControl.value.length; i++) {
      const element = this.disbaledBlocksControl.value[i];
      if (!this.formObjects[element].form.value) {
        this.formObjects[element].form.markAsDirty();
        this.control.markAsDirty();
        this.control.setErrors({ dsd: 'Error' });
        return;
      } else {
        this.formObjects[element].form.markAsPristine();
      }
    }
    this.control.setErrors(null);
    this.control.markAsPristine();
  }

  constructor() {
    super();
  }

  ngOnInit(): void {}
  setInputOptions() {}
  onFocusChange(event) {}
}
