<app-input-view-switch
  [showLabelInViewMode]="showLabelInViewMode"
  [mode]="viewMode"
  [data]="data || control?.value"
  [dataType]="dataType"
  [label]="label"
>
  <app-drop-down-input
    [label]="label ?? 'Roles'"
    [placeholder]="'Search Roles'"
    [name]="'dropdown'"
    [items]="items"
    [control]="fControl"
    [multi]="multi"
    [appendTo]="appendTo"
    [floatLabel]="floatLabel"
    [loading]="isLoading"
    [lazy]="true"
    [onFilterCommand]="onFilter.bind(this)"
    [customFilter]="true"
  ></app-drop-down-input>
</app-input-view-switch>
