<div class="grid p-fluid">
  <div class="col-12 md:col-6">
    <app-basic-input
      label="Name"
      [placeholder]="'Enter Name'"
      [control]="formGroup?.controls?.nameControl"
      [viewMode]="'create'"
      (onChanges)="onInputValueChange($event)"
    ></app-basic-input>
  </div>
  <div class="col-12 md:col-6">
    <app-drop-down-input
      [label]="'Target Type'"
      [placeholder]="'Select Item'"
      [name]="'multi-dropdown'"
      [optionLabel]="'label'"
      [multi]="false"
      [items]="targetTypes"
      [optionValue]="'value'"
      [control]="formGroup?.controls?.moduleControl"
      [badgeView]="true"
      [viewMode]="'create'"
      (onChanges)="onChangeModuleControl($event)"
    ></app-drop-down-input>
  </div>
  <div class="col-12 md:col-6">
    <app-drop-down-input
      [label]="'Result Type'"
      [placeholder]="'Select Item'"
      [name]="'resultType'"
      [optionLabel]="'label'"
      [multi]="false"
      [items]="typeOptions"
      [optionValue]="'value'"
      [control]="formGroup?.controls?.typeControl"
      [badgeView]="true"
      [viewMode]="'create'"
      [showClear]="false"
    ></app-drop-down-input>
  </div>
  <div class="col-12 md:col-6">
    <app-drop-down-input
      [label]="'Filter Type'"
      [placeholder]="'Select Item'"
      [name]="'filterType'"
      [optionLabel]="'label'"
      [multi]="false"
      [items]="filterTypeOptions"
      [optionValue]="'value'"
      [control]="formGroup?.controls?.filterTypeControl"
      [badgeView]="true"
      [viewMode]="'create'"
      [showClear]="false"
    ></app-drop-down-input>
  </div>
</div>
<p-accordion *ngIf="formGroup?.controls?.moduleControl?.value" class="w-full" [multiple]="true">
  <!-- Projection Fields -->
  <p-accordionTab>
    <ng-template pTemplate="header">
      <div class="flex flex-row justify-content-between">
        <h4 class="mb-0 text-900">Fields</h4>
      </div>
    </ng-template>
    <ng-template pTemplate="content">
      <!-- {{projectionFieldsControl?.value | json}} -->
      <div class="w-full flex flex-column gap-2">
        <app-drop-down-input
          [label]="''"
          [placeholder]="'Search...'"
          [name]="'projection_fields'"
          [optionLabel]="'fieldName'"
          [items]="formattedTableFields"
          [optionValue]="undefined"
          [control]="formGroup?.controls?.projectionFieldsControl"
          [multi]="true"
          [dataKey]="formGroup?.controls?.typeControl?.value == 'AGGREGATION' ? 'uid' : 'fieldName'"
          [appendTo]="'body'"
          [floatLabel]="false"
          [showClear]="false"
          [showStatusBadge]="false"
          [badgeView]="false"
          [listBoxMode]="true"
          [viewMode]="'create'"
          [allowDuplicates]="formGroup?.controls?.typeControl?.value == 'AGGREGATION'"
          [onFilterCommand]="onFilter.bind(this)"
          (onChanges)="onInputValueChange($event)"
        >
          <ng-template let-node #postItemTemplate>
            @if(formGroup?.controls?.typeControl?.value == 'AGGREGATION' && node?._pickListTargetItem){
              <p-dropdown
                [inputId]="'operation'"
                (onClick)="$event?.stopPropagation()"
                [style]="{ 'z-index': 1 }"
                [showClear]="false"
                [autoDisplayFirst]="false"
                [options]="getAggregationOperationOptions(mappedFields[node.fieldName]?.dataType)"
                [optionLabel]="'label'"
                [optionValue]="'value'"
                [placeholder]="'Select Operation'"
                [filter]="true"
                [appendTo]="'body'"
                [resetFilterOnHide]="true"
                [(ngModel)]="node.operation"
                (ngModelChange)="onInputValueChange({ event: $event, node: node })"
              >
                <ng-container>
                  <ng-template let-option pTemplate="selectedItem">
                    <ng-container>
                      <app-badge-item [status]="option?.value" [text]="option?.label"></app-badge-item>
                    </ng-container>
                  </ng-template>
                </ng-container>
                <ng-template let-option pTemplate="item">
                  <div class="flex">
                    <ng-container>
                      <app-badge-item [text]="option.label" [status]="option.value"></app-badge-item>
                    </ng-container>
                  </div>
                </ng-template>
              </p-dropdown>
            }
            @if(formGroup?.controls?.typeControl?.value == 'AGGREGATION' && node?._pickListTargetItem && node?.operation == 'GROUP_BY_INTERVAL'){
              <p-dropdown
                [inputId]="'dateTimePart'"
                (onClick)="$event?.stopPropagation()"
                [style]="{ 'z-index': 1 }"
                [showClear]="false"
                [autoDisplayFirst]="false"
                [options]="dateTimePartOptions"
                [optionLabel]="'label'"
                [optionValue]="'value'"
                [placeholder]="'Select Datetime Part'"
                [filter]="true"
                [appendTo]="'body'"
                [resetFilterOnHide]="true"
                [(ngModel)]="node.dateTimePart"
                (ngModelChange)="onInputValueChange({ event: $event, node: node })"
              >
                <ng-container>
                  <ng-template let-option pTemplate="selectedItem">
                    <ng-container>
                      <app-badge-item [status]="option?.value" [text]="option?.label"></app-badge-item>
                    </ng-container>
                  </ng-template>
                </ng-container>
                <ng-template let-option pTemplate="item">
                  <div class="flex">
                    <ng-container>
                      <app-badge-item [text]="option.label" [status]="option.value"></app-badge-item>
                    </ng-container>
                  </div>
                </ng-template>
              </p-dropdown>
            }
            <!-- <app-drop-down-input [label]="'Classification'"
                [placeholder]="'Select Item'"
                [name]="'operation'"
                [optionLabel]="'label'"
                [items]="operationEnumOptions"
                [optionValue]="'value'"
                [control]="formGroup?.controls?.controlClassification"
                [viewMode]="canEditField"
                [badgeView]="true"
                >
                </app-drop-down-input> -->
          </ng-template>
          <ng-template let-node #itemTemplate>
            <app-text-view [data]="node?.label || mappedFields?.[node?.fieldName]?.name | humanize"></app-text-view>
          </ng-template>
        </app-drop-down-input>
        <!-- <app-switch-input
          [label]="'Statistics Mode'"
          [name]="'StatisticsMode'"
          [control]="formGroup?.controls?.statisticsModeSwitch"
          [viewMode]="'create'"
          (onChanges)="onInputValueChange($event)"
        >
        </app-switch-input> -->
      </div>
    </ng-template>
  </p-accordionTab>
  <!-- Filters -->
  <p-accordionTab>
    <ng-template pTemplate="header">
      <div class="flex flex-row justify-content-between">
        <h4 class="mb-0 text-900">Filters</h4>
      </div>
    </ng-template>
    <ng-template pTemplate="content">
      <div class="w-full min-w-0 flex md:flex-row flex-column gap-2">
        <p-tree
          class="source-tree md:w-4 w-12"
          [value]="sourceTreeItems"
          [draggableNodes]="true"
          [droppableNodes]="false"
          emptyMessage="No Filters Available"
        >
          <ng-template let-node pTemplate="operator">
            <div class="cursor-move hover:bg-black-alpha-10" style="padding: 0.571rem">
              <div class="w-fit flex flex-row gap-2">
                <app-badge-item
                  [status]="node.matchMode || 'and'"
                  [displayText]="false"
                  [showIcon]="true"
                  [minWidth]="'fit-content'"
                ></app-badge-item>
                <span>{{ node?.label }}</span>
              </div>
            </div>
          </ng-template>
          <ng-template let-node pTemplate="line">
            <hr />
          </ng-template>
          <ng-template let-node pTemplate="default">
            <div class="cursor-move hover:bg-black-alpha-10" style="padding: 0.571rem">
              <div class="w-fit flex flex-row gap-2">
                <app-badge-item
                  [status]="dataTypeMapper[node?.dataType]"
                  [displayText]="false"
                  [showIcon]="true"
                  [minWidth]="'fit-content'"
                ></app-badge-item>
                <span>{{ node?.name || node?.label }}</span>
              </div>
            </div>
          </ng-template>
          <ng-template let-node pTemplate="node">
            <div class="cursor-move hover:bg-black-alpha-10" style="padding: 0.571rem">
              <div class="w-fit flex flex-row gap-2">
                <app-badge-item
                  [status]="dataTypeMapper[node?.dataType]"
                  [displayText]="false"
                  [showIcon]="true"
                  [minWidth]="'fit-content'"
                ></app-badge-item>
                <span>{{ node?.name || node?.label }}</span>
              </div>
            </div>
          </ng-template>
        </p-tree>
        <div class="md:w-8 w-12 flex flex-column gap-2">
          <p-tree
            [value]="emptyTree"
            [draggableNodes]="true"
            [droppableNodes]="true"
            (onNodeDrop)="onNodeDrop($event)"
            emptyMessage="No Filters Added"
            styleClass="h-full"
            [validateDrop]="true"
            class="h-full"
          >
            <ng-template let-node pTemplate="operator">
              <div
                class="w-full flex flex-row justify-content-between align-items-center hover:bg-black-alpha-10 pr-0"
                style="padding: 0.571rem"
              >
                <div class="w-fit flex flex-row gap-2">
                  <app-badge-item
                    [status]="node.matchMode || 'and'"
                    [displayText]="false"
                    [showIcon]="true"
                    [minWidth]="'fit-content'"
                  ></app-badge-item>
                  <span>{{ node?.matchMode == 'or' ? 'Match Any' : 'Match All' }}</span>
                </div>
                <div class="w-fit flex flex-row gap-2">
                  <i
                    class="border-circle hover:bg-white-alpha-10 pi pi-sync cursor-pointer p-2 text-gray-400"
                    pTooltip="Change Matching Type"
                    tooltipPosition="top"
                    (click)="changeMatchMode(node)"
                  ></i>
                  <i
                    class="border-circle hover:bg-white-alpha-10 pi pi-times cursor-pointer p-2 text-gray-400"
                    pTooltip="Remove"
                    tooltipPosition="top"
                    (click)="onRemoveTargetNode(node)"
                  ></i>
                </div>
              </div>
            </ng-template>
            <ng-template let-node pTemplate="line">
              <hr />
            </ng-template>
            <ng-template let-node pTemplate="node">
              <div
                class="flex flex-row w-full min-w-0 justify-content-between align-items-center hover:bg-black-alpha-10"
              >
                <app-badge-item
                  style="margin-left: 0.571rem"
                  [status]="dataTypeMapper[node?.dataType]"
                  [displayText]="false"
                  [showIcon]="true"
                  [minWidth]="'fit-content'"
                ></app-badge-item>
                <app-table
                  [cols]="[node]"
                  [tableActions]="[]"
                  [actionCol]="null"
                  [inputData]="[]"
                  [offlineConfig]="offlineConfig"
                  [passIndexWithRowDataToAction]="true"
                  [sessionStorageKey]="undefined"
                  [selectionMode]="null"
                  class="filter-builder-table w-full"
                  [useTableToSearchFilterMappingV2]="true"
                  (filtersChanged)="onFilterChange(node, $event)"
                  [useAutoUpdate]="false"
                  [resizableColumns]="false"
                  [headerBackground]="false"
                  [lazyLoadOnInit]="false"
                  [assignFiltersToTable]="node?.tableEvent?.tableOriginalFilters"
                ></app-table>
                <i
                  class="border-circle hover:bg-white-alpha-10 pi pi-times cursor-pointer p-2 text-gray-500"
                  pTooltip="Remove"
                  tooltipPosition="top"
                  (click)="onRemoveTargetNode(node)"
                ></i>
              </div>
            </ng-template>
          </p-tree>
        </div>
      </div>
      <!-- <ng-container *ngFor="let col of tableFields">
                <app-table pDraggable [cols]="[col]"
                    [tableActions]="[]"
                    [actionCol]="null"
                    [inputData]="[]"
                    [offlineConfig]="offlineConfig"
                    [passIndexWithRowDataToAction]="true"
                    [sessionStorageKey]="undefined"
                    [selectionMode]="null"
                    class="filter-builder-table w-full"
                    [useTableToSearchFilterMappingV2]="true"
                    (filtersChanged)="onFilterChange($event)"
                    [useAutoUpdate]="false"
                    ></app-table>
            </ng-container> -->
    </ng-template>
  </p-accordionTab>
  <!-- Relation Filters -->
   @if(formGroup?.controls?.filterTypeControl?.value == 'RELATIONS_BASED'){
     <p-accordionTab>
       <ng-template pTemplate="header">
         <div class="flex flex-row justify-content-between">
           <h4 class="mb-0 text-900">Relation Filters</h4>
         </div>
       </ng-template>
       <ng-template pTemplate="content">
         <div class="w-full min-w-0 flex md:flex-row flex-column gap-2">
           <p-tree
             class="source-tree md:w-4 w-12"
             [value]="relationSourceTreeItems"
             [draggableNodes]="true"
             [droppableNodes]="false"
             emptyMessage="No Filters Available"
           >
             <ng-template let-node pTemplate="operator">
               <div class="cursor-move hover:bg-black-alpha-10" style="padding: 0.571rem">
                 <div class="w-fit flex flex-row gap-2">
                   <app-badge-item
                     [status]="node.matchMode || 'and'"
                     [displayText]="false"
                     [showIcon]="true"
                     [minWidth]="'fit-content'"
                   ></app-badge-item>
                   <span>{{ node?.label }}</span>
                 </div>
               </div>
             </ng-template>
             <ng-template let-node pTemplate="line">
               <hr />
             </ng-template>
             <ng-template let-node pTemplate="default">
               <div class="cursor-move hover:bg-black-alpha-10" style="padding: 0.571rem">
                 <div class="w-fit flex flex-row gap-2">
                   <app-badge-item
                     [status]="dataTypeMapper[node?.dataType]"
                     [displayText]="false"
                     [showIcon]="true"
                     [minWidth]="'fit-content'"
                   ></app-badge-item>
                   <span>{{ node?.name || node?.label }}</span>
                 </div>
               </div>
             </ng-template>
             <ng-template let-node pTemplate="node">
               <div class="cursor-move hover:bg-black-alpha-10" style="padding: 0.571rem">
                 <div class="w-fit flex flex-row gap-2">
                   <app-badge-item
                     [status]="dataTypeMapper[node?.dataType]"
                     [displayText]="false"
                     [showIcon]="true"
                     [minWidth]="'fit-content'"
                   ></app-badge-item>
                   <span>{{ node?.name || node?.label }}</span>
                 </div>
               </div>
             </ng-template>
           </p-tree>
           <div class="md:w-8 w-12 flex flex-column gap-2">
             <p-tree
               [value]="relationTree"
               [draggableNodes]="true"
               [droppableNodes]="true"
               (onNodeDrop)="onRelationNodeDrop($event)"
               emptyMessage="No Filters Added"
               styleClass="h-full"
               [validateDrop]="true"
               class="h-full"
             >
               <ng-template let-node pTemplate="operator">
                 <div
                   class="w-full flex flex-row justify-content-between align-items-center hover:bg-black-alpha-10 pr-0"
                   style="padding: 0.571rem"
                 >
                   <div class="w-fit flex flex-row gap-2">
                     <app-badge-item
                       [status]="node.matchMode || 'and'"
                       [displayText]="false"
                       [showIcon]="true"
                       [minWidth]="'fit-content'"
                     ></app-badge-item>
                     <span>{{ node?.matchMode == 'or' ? 'Match Any' : 'Match All' }}</span>
                   </div>
                   <div class="w-fit flex flex-row gap-2">
                     <i
                       class="border-circle hover:bg-white-alpha-10 pi pi-sync cursor-pointer p-2 text-gray-400"
                       pTooltip="Change Matching Type"
                       tooltipPosition="top"
                       (click)="changeMatchMode(node)"
                     ></i>
                     <i
                       class="border-circle hover:bg-white-alpha-10 pi pi-times cursor-pointer p-2 text-gray-400"
                       pTooltip="Remove"
                       tooltipPosition="top"
                       (click)="onRemoveRelationTargetNode(node)"
                     ></i>
                   </div>
                 </div>
               </ng-template>
               <ng-template let-node pTemplate="line">
                 <hr />
               </ng-template>
               <ng-template let-node pTemplate="node">
                 <div
                   class="flex flex-row w-full min-w-0 justify-content-between align-items-center hover:bg-black-alpha-10"
                 >
                   <app-badge-item
                     style="margin-left: 0.571rem"
                     [status]="dataTypeMapper[node?.dataType]"
                     [displayText]="false"
                     [showIcon]="true"
                     [minWidth]="'fit-content'"
                   ></app-badge-item>
                   <app-table
                     [cols]="[node]"
                     [tableActions]="[]"
                     [actionCol]="null"
                     [inputData]="[]"
                     [offlineConfig]="offlineConfig"
                     [passIndexWithRowDataToAction]="true"
                     [sessionStorageKey]="undefined"
                     [selectionMode]="null"
                     class="filter-builder-table w-full"
                     [useTableToSearchFilterMappingV2]="true"
                     (filtersChanged)="onRelationFilterChange(node, $event)"
                     [useAutoUpdate]="false"
                     [resizableColumns]="false"
                     [headerBackground]="false"
                     [lazyLoadOnInit]="false"
                     [assignFiltersToTable]="node?.tableEvent?.tableOriginalFilters"
                   ></app-table>
                   <i
                     class="border-circle hover:bg-white-alpha-10 pi pi-times cursor-pointer p-2 text-gray-500"
                     pTooltip="Remove"
                     tooltipPosition="top"
                     (click)="onRemoveRelationTargetNode(node)"
                   ></i>
                 </div>
               </ng-template>
             </p-tree>
           </div>
         </div>
         <!-- <ng-container *ngFor="let col of tableFields">
                   <app-table pDraggable [cols]="[col]"
                       [tableActions]="[]"
                       [actionCol]="null"
                       [inputData]="[]"
                       [offlineConfig]="offlineConfig"
                       [passIndexWithRowDataToAction]="true"
                       [sessionStorageKey]="undefined"
                       [selectionMode]="null"
                       class="filter-builder-table w-full"
                       [useTableToSearchFilterMappingV2]="true"
                       (filtersChanged)="onFilterChange($event)"
                       [useAutoUpdate]="false"
                       ></app-table>
               </ng-container> -->
       </ng-template>
     </p-accordionTab>
   }
  <!-- Sorting -->
  <p-accordionTab>
    <ng-template pTemplate="header">
      <div class="flex flex-row justify-content-between">
        <h4 class="mb-0 text-900">Sort</h4>
      </div>
    </ng-template>
    <ng-template pTemplate="content">
      <!-- <app-sort-row-input
            [formControl]="formGroup?.controls?.sortControl"
            [control]="formGroup?.controls?.sortControl"
            [viewMode]="viewMode"
            [availableFields]="availableFields"
            class="grid"
            >
            </app-sort-row-input> -->
      @if (formRepeaterFields?.length > 0) {
        <app-form-repeater
          [viewMode]="viewMode != 'view' ? 'create' : 'view'"
          [title]="''"
          [label]="''"
          [cols]="formRepeaterFields"
          [control]="formGroup?.controls?.sortControl"
          [showOptionsAction]="false"
          [showDeleteButton]="viewMode != 'view'"
          [showAddForm]="viewMode != 'view'"
          [showReorder]="false"
          [showSelection]="false"
          [flyMode]="false"
          [showAddFormAsPopup]="false"
          (onChanges)="onInputValueChange($event)"
        ></app-form-repeater>
      }
    </ng-template>
  </p-accordionTab>

  <!-- Display Options (pagination goes here) -->

  <!-- Drill Down Options -->
</p-accordion>
<!-- <div class="flex flex-row-reverse w-full">
    <app-button
    [action]="submitAction"
    >
    </app-button>
</div> -->
