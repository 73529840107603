<form [formGroup]="formGroup" (ngSubmit)="onSubmitForm()">
  <div class="formgrid grid p-fluid">
    <!-- <div class="col-12">
    <app-dynamic-field-type-selector
      [control]="formGroup?.controls?.type"
      [viewMode]="fieldViewMode"
      [appendTo]="'body'"
      label="Field Type"
      [placeholder]="'Search Types'"
      >
    </app-dynamic-field-type-selector>
  </div> -->
    <app-dynamic-field-type-options
      [control]="formGroup?.controls?.options"
      [viewMode]="fieldViewMode"
      label="Field Options"
      [placeholder]="'Field Options'"
      [fieldType]="formGroup?.controls?.type?.value"
      class="formgrid grid p-fluid w-full m-auto"
    ></app-dynamic-field-type-options>
  </div>

  <div class="flex flex-row-reverse gap-2">
    @if (fieldViewMode != 'view') {
      <app-button [action]="submitButtonAction"></app-button>
    }
    <!-- <app-button *ngIf="showSaveButton" [action]="submitSaveButtonAction"></app-button> -->
  </div>
</form>
