<app-input-view-switch
  [showLabelInViewMode]="showLabelInViewMode"
  [mode]="viewMode"
  [data]="data || control?.value"
  [dataType]="dataType"
  [label]="label"
>
  @if (!loading) {
    <div class="field" [class.mb-1]="control?.dirty && this.control.errors">
      <span
        class="p-inputgroup flex flex-row relative hide-selection flex flex-row md:flex-nowrap flex-wrap w-full algin-items-start"
        [class.p-float-label]="floatLabel"
      >
        @if (!floatLabel) {
          @if (label && !instanceInFormRepeater) {
            <label class="md:w-12rem md:max-w-12rem md:min-w-12rem w-full mr-1 input-field-label"
              >{{ label }}
              @if (isControlRequired) {
                <span class="text-red-500"> *</span>
              }
            </label>
          }
        }
        <input
          [type]="'text'"
          pInputText
          readonly
          [formControl]="
            questionnaireValueCode || fieldViewMode == 'view' ? placeholderCheckControl : placeholderControl
          "
          [placeholder]="placeholder"
          (focus)="onFocusChange($event)"
          class="w-full border-round-left cursor-pointer"
          (click)="onEditQuestionnaire()"
          (keydown)="onEditModalInputKeyDown($event)"
          [style]="
            'color:transparent; ' +
            (!fControl?.disabled ? 'width:calc(100% - (41px + 12rem)) !important; ' : '') +
            ('height: max(32px,' + '2.4' + 'rem)')
          "
          [class.ng-invalid]="fControl?.invalid"
          [class.ng-dirty]="fControl?.dirty"
        />
        @if (floatLabel) {
          @if (label && !instanceInFormRepeater) {
            <label class="md:w-12rem md:max-w-12rem md:min-w-12rem w-full mr-1 input-field-label"
              >{{ label }}
              @if (isControlRequired) {
                <span class="text-red-500"> *</span>
              }
            </label>
          }
        }
        <div
          (click)="onEditQuestionnaire()"
          class="inner-absolute-input-value border-round-left p-inputtext flex flex-column gap-2"
          [style]="
            'background:transparent; width:calc(100% - (41px + ' +
            (label ? '12rem' : '0rem') +
            ')); position:absolute; height:100%;'
          "
          [class.left-0]="!label"
        >
          @if (questionnaireValueCode || questionnaireCode) {
            <app-code-navigation
              [showTooltip]="true"
              [data]="questionnaireValueCode || questionnaireCode"
            ></app-code-navigation>
          }
        </div>
        @if (!fControl?.disabled) {
          <button
            type="button"
            pButton
            (click)="onEditQuestionnaire()"
            class="white-space-nowrap"
            [label]="fieldViewMode != 'view' ? 'Edit' : 'View'"
          ></button>
        }
      </span>
      <!-- <span class="p-float-label p-inputgroup flex flex-row" >
      <input class="w-full" [type]="type" pInputText readonly [formControl]="(questionnaireValueCode || fieldViewMode == 'view') ? placeholderCheckControl : placeholderControl" [placeholder]="placeholder"
        (focus)="onFocusChange($event)"
        class="cursor-pointer"
        (click)="onEditQuestionnaire()"
        >
      <label>{{label}}<span *ngIf="label && !instanceInFormRepeater" class="text-red-500"> *</span></label>
      <button type="button" pButton (click)="onEditQuestionnaire()" class="white-space-nowrap" [label]="fieldViewMode != 'view' ? 'Edit' : 'View'"></button>
    </span> -->
      @if (control?.dirty) {
        @if (control?.hasError('required')) {
          <small class="p-error p-invalid">{{ label }} {{ 'is Required' }}</small>
        }
        @if (control?.hasError('pattern')) {
          <small class="p-error p-invalid">{{ label }} {{ patternError }}</small>
        }
        @if (customError && control?.invalid) {
          <small class="p-error p-invalid">{{ customErrorLabel }}</small>
        }
      }
    </div>
  }
  @if (loading) {
    <p-skeleton width="100%" height="2rem"></p-skeleton>
  }
</app-input-view-switch>
