import { ChartViewConfig } from '@shared/classes/model';
import { EChartsOption } from 'echarts';
import { AssignChartDataOptions, EchartBase } from '../../echart-base';

export class DoughnutChart extends EchartBase {
  chartOptions: EChartsOption = {
    animationDurationUpdate: (idx) => idx * 600,
    series: [
      {
        name: '',
        type: 'pie',
        radius: ['35%', '65%'],
        center: ['50%', '65%'],
        avoidLabelOverlap: false,
        padAngle: 1,
        itemStyle: {
          borderRadius: 10,
        },
        label: {
          show:true,
          formatter: '{c}',
          position: 'inner',
        },
        // label: {
        //   show: false,
        //   position: 'center',
        // },
        emphasis: {
          label: {
            show: true,
            fontSize: 20,
            fontWeight: 'bold',
          },
        },
        // labelLine: {
        //   show: false,
        // },
        data: [],
      },
    ],
  };
  chartType: ChartViewConfig.ChartTypeEnum = 'DOUGHNUT';
  assignDataToChartOptions(options: AssignChartDataOptions): EChartsOption {
    let optionsCopy = { ...options?.chartOptions };
    optionsCopy.series[0].name = options?.name;
    optionsCopy.series[0].data = options?.data;
    return optionsCopy;
  }
}
