import { Component, OnInit } from '@angular/core';
import { DataTypeEnum, DynamicComponentBase } from '@shared/classes';

@Component({
  selector: 'app-masked-input',
  templateUrl: './masked-input.component.html',
  styleUrls: ['./masked-input.component.scss'],
  providers: [{ provide: DynamicComponentBase, useExisting: MaskedInputComponent }],
})
export class MaskedInputComponent extends DynamicComponentBase implements OnInit {
  dataType: DataTypeEnum = DataTypeEnum.Text;
  constructor() {
    super();
  }

  ngOnInit(): void {}
  setInputOptions() {}
}
