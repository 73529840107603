import { Component, ContentChild, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { BadgeColor, BaseViewItem, ContentViewDto, humanizeCasedString } from '@shared/classes';
import * as d3 from 'd3';
import { MenuItem } from 'primeng/api';

@Component({
  selector: 'app-dashlet-doughnut-chart',
  templateUrl: './dashlet-doughnut-chart.component.html',
  styleUrls: ['./dashlet-doughnut-chart.component.scss'],
})
export class DashletDoughnutChartComponent extends BaseViewItem implements OnInit {
  @ContentChild('chartTitleTemplate') chartTitleTemplate: TemplateRef<any>;
  @Input() chartType: any = 'doughnut';
  @Input() title: string = '';
  @Input() useD3: boolean = false;
  @Input() drillDownList: any = {};
  @ViewChild('my_dataviz', { static: false }) chartCont: any;

  _chartData: any = {};

  @Input() set chartData(value: any) {
    this.checkEmptyChartData(value);
  }
  _chartOptions;
  get chartOptions() {
    return this._chartOptions;
  }
  @Input() set chartOptions(chartOptions: any) {
    if (chartOptions && !chartOptions?.elements && this.totalAmount) {
      const style = getComputedStyle(document.documentElement);
      const _this = this;
      chartOptions.elements = {
        center: {
          text: '' + this.totalAmount,
          title: 'Total Amount: ' + this.totalAmount,
          color: style.getPropertyValue(`--primary-500`), // Default is #000000
          fontStyle: 'Arial', // Default is Arial
          sidePadding: 20, // Default is 20 (as a percentage)
          minFontSize: 12, // Default is 20 (in px), set to false and text will not wrap.
          maxFontSize: 40, // Default is 20 (in px), set to false and text will not wrap.
          lineHeight: 25, // Default is 25 (in px), used for when text wraps
        },
      };
      // chartOptions.onClick = function (evt, item) {

      //     const chart = evt?.chart;
      //     const canvasPosition = getRelativePosition(evt, chart);

      //     // Substitute the appropriate scale IDs
      //     const dataX = chart?.scales?.x?.getValueForPixel(canvasPosition.x);
      //     const dataY = chart?.scales?.y?.getValueForPixel(canvasPosition.y);

      //     let activePoints = chart.getElementsAtEvent(evt);

      //     if(activePoints.length > 0)
      //     {
      //       //get the internal index of slice in pie chart
      //       let clickedElementindex = activePoints[0]["_index"];

      //       //get specific label by index
      //       let label = chart.data.labels[clickedElementindex];

      //       //get value by index
      //       let value = chart.data.datasets[0].data[clickedElementindex];

      //       /* other stuff that requires slice's label and value */
      //    }
      // }
      // chartOptions.events = ['mousemove', 'mouseout', 'click', 'touchstart', 'touchmove'];
    }
    this._chartOptions = chartOptions;
  }

  @Input() listColors: any = {};

  @Input() plugin: any = {};

  @Input() listData: any = {};
  _totalAmount = {};
  get totalAmount() {
    return this._totalAmount;
  }
  @Input() set totalAmount(totalAmount: any) {
    this._totalAmount = totalAmount;
    const chartOptions = { ...this.chartOptions };
    if (chartOptions && !chartOptions?.elements && this.totalAmount) {
      const style = getComputedStyle(document.documentElement);
      const _this = this;
      chartOptions.elements = {
        center: {
          text: '' + this.totalAmount,
          title: 'Total Amount: ' + this.totalAmount,
          color: style.getPropertyValue(`--blue-500`), // Default is #000000
          fontStyle: 'Arial', // Default is Arial
          sidePadding: 20, // Default is 20 (as a percentage)
          minFontSize: 12, // Default is 20 (in px), set to false and text will not wrap.
          maxFontSize: 40, // Default is 20 (in px), set to false and text will not wrap.
          lineHeight: 25, // Default is 25 (in px), used for when text wraps
        },
      };
      // chartOptions.onClick = function (evt, item) {

      //     const chart = evt?.chart;
      //     const canvasPosition = getRelativePosition(evt, chart);

      //     // Substitute the appropriate scale IDs
      //     const dataX = chart?.scales?.x?.getValueForPixel(canvasPosition.x);
      //     const dataY = chart?.scales?.y?.getValueForPixel(canvasPosition.y);

      //     let activePoints = chart.getElementsAtEvent(evt);

      //     if(activePoints.length > 0)
      //     {
      //       //get the internal index of slice in pie chart
      //       let clickedElementindex = activePoints[0]["_index"];

      //       //get specific label by index
      //       let label = chart.data.labels[clickedElementindex];

      //       //get value by index
      //       let value = chart.data.datasets[0].data[clickedElementindex];

      //       /* other stuff that requires slice's label and value */
      //    }
      // }
      // chartOptions.events = ['mousemove', 'mouseout', 'click', 'touchstart', 'touchmove'];
    }
    this.chartOptions = chartOptions;
  }

  @Input() isLoading: boolean = true;

  @Input() showList: boolean = true;

  @Input() showChart: boolean = true;

  @Input() horizontalMode: boolean = false;

  @Output() onInit: EventEmitter<any> = new EventEmitter();

  badgeColors = BadgeColor;

  emptyChart = true;
  drillDownPreviousOptions: MenuItem[] = [];
  @Input() currentCardContent: ContentViewDto;
  @Input() tableCardContent: ContentViewDto;
  home: MenuItem = { icon: 'pi pi-home' };
  drillDownDataSet = null;
  ngOnInit(): void {
    if (this.onInit) {
      this.onInit.emit();
    }
  }

  checkEmptyChartData(value) {
    if (value && value.datasets[0].data?.length > 0) {
      value.datasets[0].data.forEach((element) => {
        if (element && (element as number) > 0) {
          this.emptyChart = false;
        }
      });
    }
    if (this.emptyChart) {
      this._chartData = {
        datasets: [{ backgroundColor: ['transparent'], borderColor: '#85838d', data: [1] }],
        labels: ['Chart'],
      };
      return;
    }
    this._chartData = value;
    if (this.useD3) this.d3Init();
  }
  d3Init() {
    // set the dimensions and margins of the graph
    const width = 450,
      height = 450,
      margin = 40;

    // The radius of the pieplot is half the width or half the height (smallest one). I subtract a bit of margin.
    const radius = Math.min(width, height) / 2 - margin;

    // append the svg object to the div called 'my_dataviz'
    const svg = this.chartCont
      .append('svg')
      .attr('width', width)
      .attr('height', height)
      .append('g')
      .attr('transform', `translate(${width / 2},${height / 2})`);

    // Create dummy data
    const data = {};
    (this._chartData.labels as string[]).forEach((label, index) => {
      data[label] = this._chartData.datasets[0].data[index];
    });

    // set the color scale
    const color = d3.scaleOrdinal().range(this._chartData.datasets[0].backgroundColor);

    // Compute the position of each group on the pie:
    const pie = d3.pie().value((d) => d[1]);

    const data_ready = pie(Object.entries(data));

    // Build the pie chart: Basically, each part of the pie is a path that we build using the arc function.
    svg
      .selectAll('whatever')
      .data(data_ready)
      .join('path')
      .attr(
        'd',
        d3
          .arc()
          .innerRadius(100) // This is the size of the donut hole
          .outerRadius(radius)
      )
      .attr('fill', (d) => color(d.data[0]))
      .attr('stroke', 'black')
      .style('stroke-width', '1px')
      .style('opacity', 0.7);
  }
  onDataSelect(event) {
    const chData = this.drillDownPreviousOptions?.length > 0 ? this.drillDownDataSet : this._chartData;

    const value = chData?.datasets?.[event?.element?.datasetIndex]?.data?.[event?.element?.index];

    const label = chData?.labels?.[event?.element?.index];

    this.changeDrillDownView(label);
  }
  changeDrillDownView(label) {
    if (this.drillDownList?.fields?.length > this.drillDownPreviousOptions?.length) {
      const fieldName = this.drillDownList?.fields?.[this.drillDownPreviousOptions?.length + 1]?.fieldName;
      const oldFieldName = this.drillDownList?.fields?.[this.drillDownPreviousOptions?.length]?.fieldName;
      const formattedLabel = (label as string).toUpperCase().replace(/ /g, '_');
      const extraLabels = ['No ' + fieldName];
      let ret = {};
      this.drillDownList?.value
        ?.filter((x) => x.group_id?.[oldFieldName] == formattedLabel)
        .forEach((item) => {
          if (item?.group_id?.[fieldName]) {
            ret[item?.group_id?.[fieldName]] = ret[item?.group_id?.[fieldName]]
              ? ret[item?.group_id?.[fieldName]] + item?.[fieldName + '_count']
              : item?.[fieldName + '_count'];
          } else {
            ret['No ' + fieldName] = ret['No ' + fieldName]
              ? ret['No ' + fieldName] + item?.[fieldName + '_count']
              : item?.[fieldName + '_count'];
          }
        });
      this.drillDownDataSet = {
        datasets: [
          {
            backgroundColor: ['#6c6a76', '#4caf50'],
            borderColor: 'transparent',
            data: Object.entries(ret).map((x) => x[1]),
            fill: true,
          },
        ],
        labels: Object.entries(ret).map((x) => x[0]),
      };

      // this.drillDownPreviousOptions.push({
      //     label: humanizeCasedString(fieldName),
      // });
      // this.drillDownPreviousOptions.push({
      //     label: humanizeCasedString(oldFieldName),
      // });
      this.drillDownPreviousOptions.push({
        label: humanizeCasedString(label),
        currentField: oldFieldName,
        currentLabelValue: formattedLabel,
        currentLabel: label,
      });
      this.drillDownPreviousOptions = [...this.drillDownPreviousOptions];
      this.tableCardContent = {
        ...this.currentCardContent,
        tableViewConfig: {
          ...this.currentCardContent?.tableViewConfig,
          showActions: false,
          showGlobalFilter: false,
          showOperationActions: false,
          showDeleteAction: false,
          showEditAction: false,
          showLockAction: false,
          showActivateAction: false,
          showDeactivateAction: false,
          showStatusActions: false,
          showSettingsLink: false,
          showFileExportAction: false,
          showPackageExportAction: false,
          showBulkOperationAction: false,
        },
        filterView: {
          ...this.currentCardContent?.filterView,
          filter: {
            ...this.currentCardContent?.filterView.filter,
            filters: [
              ...(this.currentCardContent?.filterView?.filter?.filters
                ? this.currentCardContent?.filterView?.filter?.filters
                : []),
              ...this.drillDownPreviousOptions.map((x) => {
                return { property: x?.currentField, operation: 'EQUAL', value: x?.currentLabelValue } as any;
              }),
            ],
          },
        },
      };
    }
  }
  onDrillDownItemClick(event) {
    const item = event.item;
    if (item.icon) {
      this.drillDownPreviousOptions = [];
    } else {
      const index = this.drillDownPreviousOptions.indexOf(item);
      this.drillDownPreviousOptions.splice(index, this.drillDownPreviousOptions.length - index);
      this.changeDrillDownView(item.currentLabel);
    }
  }
}
