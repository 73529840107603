<div class="col-12 md:col-6">
  <app-drop-down-input
    [label]="'Field'"
    [placeholder]="'Select Item'"
    [name]="'multi-dropdown'"
    [optionLabel]="'label'"
    [multi]="false"
    [items]="availableFields"
    [optionValue]="'value'"
    [control]="formGroup?.controls?.fieldName"
    [badgeView]="true"
    [viewMode]="viewMode"
  ></app-drop-down-input>
</div>
<div class="col-12 md:col-6">
  <app-drop-down-input
    [label]="'Sort Direction'"
    [placeholder]="'Select Item'"
    [name]="'multi-dropdown'"
    [optionLabel]="'label'"
    [multi]="false"
    [items]="sortOperations"
    [optionValue]="'value'"
    [control]="formGroup?.controls?.sortOperation"
    [badgeView]="true"
    [viewMode]="viewMode"
  ></app-drop-down-input>
</div>
