<div [class]="viewMode != 'view' ? 'grid p-fluid' : 'flex gap-3'">
  <div [class]="viewMode != 'view' ? 'col-6' : 'w-3'">
    <app-drop-down-input
      label="Access Type"
      [placeholder]="'Select Access Type'"
      [name]="'accessType'"
      [optionLabel]="'label'"
      [optionValue]="'value'"
      [items]="accessTypeList"
      [control]="accessForm.controls.accessType"
      [viewMode]="viewMode"
      [badgeView]="true"
      [appendTo]="'body'"
      [showLabelInViewMode]="showLabelInViewMode"
      [showClear]="false"
    ></app-drop-down-input>
  </div>
  <!-- <div [class]="viewMode != 'view' ? 'col-6' : 'w-3'">
  <app-switch-input
    [label]="'Apply On Update'"
    [name]="'onUpdate'"
    [showLabelInViewMode]="false"
    [control]="accessForm.controls.applyOnUpdate"
    [viewMode]="viewMode"
    [styleClass]="'mb-0'"
    >
  </app-switch-input>
</div> -->
  @if (accessForm.controls.accessType.value == 'DEFAULT_INHERITED' || viewMode == 'view') {
    <div [class]="viewMode != 'view' ? 'col-6' : 'w-3'">
      <app-drop-down-input
        label="Inherited Access Mode"
        [placeholder]="'Select Inherit Access Mode'"
        [name]="'inheritedAccessModeList'"
        [optionLabel]="'label'"
        [optionValue]="'value'"
        [items]="inheritedAccessModeList"
        [control]="accessForm.controls.inheritedAccessMode"
        [viewMode]="viewMode"
        [badgeView]="true"
        [appendTo]="'body'"
        [showLabelInViewMode]="showLabelInViewMode"
        [showClear]="false"
      ></app-drop-down-input>
    </div>
  }
  @if (accessForm.controls.inheritedAccessMode.value == 'DIRECT' || viewMode == 'view') {
    <div [class]="viewMode != 'view' ? 'col-6' : 'w-3'">
      <app-drop-down-input
        label="Access Level"
        [placeholder]="'Select Access Level'"
        [name]="'accessLevelList'"
        [optionLabel]="'label'"
        [optionValue]="'value'"
        [items]="accessLevelList"
        [control]="accessForm.controls.accessLevel"
        [viewMode]="viewMode"
        [badgeView]="true"
        [appendTo]="'body'"
        [showLabelInViewMode]="showLabelInViewMode"
      ></app-drop-down-input>
    </div>
  }
  @if (accessForm.controls.accessType.value == 'DEFAULT_DIRECT' || viewMode == 'view') {
    <div [class]="viewMode != 'view' ? 'col-6' : 'w-3'">
      @if (accessForm.controls.accessType.value == 'DEFAULT_DIRECT') {
        <app-drop-down-input
          label="Discretionary Access Mode"
          [placeholder]="'Select Discretionary Access Mode'"
          [name]="'discretionaryAccessMode'"
          [optionLabel]="'label'"
          [optionValue]="'value'"
          [items]="discTypeList"
          [control]="accessForm.controls.discretionaryAccessMode"
          [viewMode]="viewMode"
          [badgeView]="true"
          [appendTo]="'body'"
          [showLabelInViewMode]="showLabelInViewMode"
        ></app-drop-down-input>
      }
    </div>
  }
  @if (accessForm.controls.accessType.value == 'DEFAULT_DIRECT') {
    <div [class]="viewMode != 'view' ? 'col-6' : 'w-3'">
      <app-user-and-group-selector
        [label]="''"
        [placeholder]="'Users'"
        [control]="accessForm.controls.directAccessPrinciples"
        [optionValue]="undefined"
        [principleMode]="true"
        [strictPrincipleMode]="true"
        [optionLabel]="'principle'"
        [multi]="true"
        [viewMode]="viewMode"
        [floatLabel]="false"
        [itemTypes]="['GROUPS', 'RESPONSIBILITIES', 'USERS']"
      >
      </app-user-and-group-selector>
      <!-- <app-drop-down-input
    label="Discretionary Access Mode"
    [placeholder]="'Select Discretionary Access Mode'"
    [name]="'discretionaryAccessMode'"
    [optionLabel]="'label'"
    [items]="discTypeList"
    [control]="accessForm.controls.directAccessPrinciples"
    [viewMode]="viewMode"
    [badgeView]="true"
    [appendTo]="'body'"
    [showLabelInViewMode]="showLabelInViewMode"
  ></app-drop-down-input> -->
    </div>
  }
  @if (accessForm.controls.accessType.value == 'DEFAULT_FIELDS_BASED') {
    <div [class]="viewMode != 'view' ? 'col-6' : 'w-3'">
      <app-drop-down-input
        label="Entity Access Fields"
        [placeholder]="'Select Entity Access Fields'"
        [name]="'discretionaryAccessMode'"
        [optionLabel]="'label'"
        [optionValue]="'value'"
        [items]="fieldList"
        [control]="accessForm.controls.entityAccessFields"
        [viewMode]="viewMode"
        [badgeView]="false"
        [appendTo]="'body'"
        [multi]="true"
        [showLabelInViewMode]="showLabelInViewMode"
      ></app-drop-down-input>
    </div>
  }
  @if (accessForm.controls.accessType.value == 'DEFAULT_INHERITED') {
    <div [class]="viewMode != 'view' ? 'col-6' : 'w-3'">
      <app-drop-down-input
        label="Inherited Access Fields"
        [placeholder]="'Select Inherited Access Fields'"
        [name]="'discretionaryAccessMode'"
        [optionLabel]="'label'"
        [optionValue]="'value'"
        [items]="fieldList2"
        [control]="accessForm.controls.inheritedAccessFields"
        [viewMode]="viewMode"
        [badgeView]="false"
        [multi]="true"
        [appendTo]="'body'"
        [showLabelInViewMode]="showLabelInViewMode"
      ></app-drop-down-input>
    </div>
  }
  <!-- instanceInFormRepeater ? 'view' :  -->
</div>
